import { useInvestedPools } from 'core';
import { isNil } from 'lodash-es';
import { ClaimAllRewardsCard } from 'modules';
import { Container, Skeleton, Text } from 'ui';

export function PositionListRewards() {
  const { data: pools } = useInvestedPools();

  const hasLPPositions = isNil(pools) ? null : pools.baseList.length > 0;

  if (hasLPPositions === null) {
    return (
      <Container column fullWidth>
        <Skeleton className="w-[130px] h-[17px] !rounded-lg" />
        <Skeleton className="w-full h-[100px] !rounded-lg mt-6" />
      </Container>
    );
  }

  return (
    <Container column fullWidth>
      {hasLPPositions && (
        <>
          <Text type="title-3" id="position.rewards.title" className="mb-6" />
          <ClaimAllRewardsCard className="mb-1" />
        </>
      )}
    </Container>
  );
}
