import { useParams } from 'react-router';
import { useInvestedPools } from './useInvestedPools';

export const useInvestedPoolFromPath = () => {
  const params = useParams();
  const { data } = useInvestedPools();

  const poolSymbol = params?.poolSymbol;
  const pool = poolSymbol
    ? data?.list.find(({ symbols }) => poolSymbol === symbols.join('-'))
    : null;
  const isNotFound = poolSymbol && data && !pool;

  return {
    pool,
    isNotFound,
    poolSymbol,
  };
};
