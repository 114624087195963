import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import { InjectedWallet, QueryOptional } from '../../../../services';
import { ApiPromise } from '@polkadot/api';
import { BN } from '@polkadot/util';
import { get3rdPartyLiquidityDeactivationTxs } from '../../deactivateLiqFor3rdPartyRewards/services/deactivateLiqFor3rdPartyRewardsService';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';

interface DeactivateLiquidityParams {
  liquidityTokenId: string;
  amount: BN;
  symbol: string;
  active3rdPartyLiquidities: [string, BN][];
}

export const deactivateLiquidity =
  (
    api: ApiPromise | null,
    address: string | undefined,
    wallet: QueryOptional<InjectedWallet>,
    transactionStore: TransactionStore,
  ) =>
  async ({
    liquidityTokenId,
    amount,
    active3rdPartyLiquidities,
    symbol,
  }: DeactivateLiquidityParams) => {
    if (!api || !address || !wallet) {
      return null;
    }

    const extrinsic = createDeactivateLiquidityTx(
      api,
      liquidityTokenId,
      amount,
      active3rdPartyLiquidities,
    );
    return new ExtrinsicTx(api, transactionStore, wallet, address)
      .create(TxType.DeactivateLP)
      .setMetadata({ amount: fromBN(amount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS), symbol })
      .setTx(extrinsic)
      .build()
      .send();
  };

const createDeactivateLiquidityTx = (
  api: ApiPromise,
  liquidityTokenId: string,
  amount: BN,
  active3rdPartyLiquidities: [string, BN][],
) => {
  const nativeDeactivationTx = api.tx.proofOfStake.deactivateLiquidityForNativeRewards(
    liquidityTokenId,
    amount.toString(),
  );

  if (active3rdPartyLiquidities.length > 0) {
    return api.tx.utility.batchAll([
      ...get3rdPartyLiquidityDeactivationTxs(api, liquidityTokenId, active3rdPartyLiquidities),
      nativeDeactivationTx,
    ]);
  }

  return nativeDeactivationTx;
};
