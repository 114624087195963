import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional, useApi } from '../../../../../services';
import {
  getCollatorState,
  listCollators,
  listSelectedCandidates,
} from '../services/collatorService';
import {
  transformCollatorList,
  transformCollatorState,
  transformSelectedCandidates,
} from '../transformers/collatorTransformer';
import { useTransaction } from '../../../../transaction';

export const useCollatorQuery = () => {
  const api = useApi();

  const collatorsMapQuery = useQuery(['collator-map'], listCollators(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
    select: transformCollatorList,
  });

  const selectedCandidatesQuery = useQuery(['active-collator-set'], listSelectedCandidates(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
    select: transformSelectedCandidates,
  });

  return {
    collatorsMapQuery,
    selectedCandidatesQuery,
  };
};

export const useCollatorStateQuery = (collatorAddress: QueryOptional<string>) => {
  const api = useApi();
  const { stakingTxCount } = useTransaction();

  const collatorStateQuery = useQuery(
    ['collator-state', collatorAddress, stakingTxCount],
    getCollatorState(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!api && !!collatorAddress,
      select: transformCollatorState,
      keepPreviousData: true,
    },
  );

  return {
    collatorStateQuery,
  };
};
