import { useCollatorQuery } from './collatorState/query/useCollatorQuery';

export function useCollators() {
  const { collatorsMapQuery, selectedCandidatesQuery } = useCollatorQuery();

  return {
    collatorsMapQuery,
    selectedCandidatesQuery,
  };
}
