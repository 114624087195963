import { useQuery } from '@tanstack/react-query';
import {
  Asset,
  ReserveSource,
  TxType,
  useAccounts,
  useAdaptiveFee,
  useReservesForTx,
} from '../../../..';
import { getDelegateFee } from '../services/DelegateFeeService';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';

export const useDelegateFeeQuery = (
  collatorAddress: QueryOptional<string>,
  stakedAsset: QueryOptional<Asset>,
  reserveSource: QueryOptional<ReserveSource>,
  candidateDelegationCount: QueryOptional<number>,
  delegatorDelegationCount: QueryOptional<number>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, stakedAsset?.id);

  const delegateFeeQuery = useQuery(
    [
      'delegate-fee',
      selectedAccount?.address,
      collatorAddress,
      stakedAsset,
      reserveSource,
      candidateDelegationCount,
      delegatorDelegationCount,
    ],
    getDelegateFee(api, activateLiqSources),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled: isAdaptiveFeeReady && !!selectedAccount?.address && !!api && !!reserveSource,
    },
  );

  return { delegateFeeQuery };
};
