import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum WalletStatus {
  NotConnected = 'notConnected',
  SelectWallet = 'selectWallet',
  SelectAccount = 'selectAccount',
  Connected = 'connected',
}

export interface WalletUIStore {
  activeTab: number;
  isWalletOpen: boolean;
  status: WalletStatus;
  setStatus: (status: WalletStatus) => void;
  setActiveTab: (tab: number) => void;
  setIsWalletOpen: (open: boolean) => void;
  toggleSelectWallet: () => void;
}

export const useWalletUIStore = create(
  devtools<WalletUIStore>((set) => ({
    isWalletOpen: false,
    activeTab: 0,
    status: WalletStatus.NotConnected,
    setStatus: (status) => set({ status }),
    setActiveTab: (activeTab) => set({ activeTab }),
    setIsWalletOpen: (isWalletOpen) => set({ isWalletOpen }),
    toggleSelectWallet: () =>
      set(({ isWalletOpen, status }) => {
        const isSelectWalletVisible = isWalletOpen && status === WalletStatus.SelectWallet;

        return {
          isWalletOpen: !isSelectWalletVisible,
          status: isSelectWalletVisible ? WalletStatus.NotConnected : WalletStatus.SelectWallet,
        };
      }),
  })),
);
