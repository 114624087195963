import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { Container, FormatAmount, Skeleton, Text } from 'ui';
import { ReactComponent as BoltIcon } from 'icons/lightning.svg';
import { useIntl } from 'react-intl';

export interface StakingRewardsBadgeProps extends ClassName, TestId {
  showIcon?: boolean;
  suffix?: string;
  isLoading?: boolean;
  value: string | null;
}

export function StakingRewardsBadge({
  className,
  'data-testid': testId,
  showIcon = true,
  isLoading,
  suffix: _suffix,
  value,
}: StakingRewardsBadgeProps) {
  const intl = useIntl();

  const suffix = _suffix ?? intl.formatMessage({ id: 'common.mainToken' });
  return value ? (
    <Container
      inline
      alignItems="center"
      justifyContent="center"
      data-testid={testId}
      className={cls('rounded py-1', className)}
    >
      {showIcon && <BoltIcon className="h-[20px] w-auto mr-1 fill-icon-accent" />}
      {isLoading ? (
        <Skeleton height="12px" width="80px" rounded />
      ) : (
        <Text type="title-2" color="accent">
          <FormatAmount value={value} options={{ precision: 2, maxChars: 10 }} /> {suffix}
        </Text>
      )}
    </Container>
  ) : null;
}
