import { useSpring, useTransition } from '@react-spring/web';
import { RefObject, useEffect, useState } from 'react';
import { DepositUIState, useDepositStore } from '../store/useDepositStore';
import { Transaction, TxStatus } from 'core';

export const useRollupDepositTransitions = (
  containerRef: RefObject<HTMLDivElement>,
  approveTx: Transaction | null,
  depositTx: Transaction | null,
) => {
  const [containerHeight, setContainerHeight] = useState(400);

  const { uiState } = useDepositStore();

  useEffect(() => {
    const ref = containerRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (uiState === DepositUIState.Default) {
          setContainerHeight(entry.target.clientHeight);
        }
      }
    });
    if (ref) {
      resizeObserver.observe(ref);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, uiState]);

  const containerSpring = useSpring({
    minHeight: uiState === DepositUIState.Default ? 0 : containerHeight,
  });

  const selectTokenTransition = useTransition(uiState === DepositUIState.SelectToken, {
    from: { x: '100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: { x: '100%', opacity: 0 },
    duration: 150,
  });

  const txInfoTransition = useTransition(
    uiState === DepositUIState.ApproveSpendingCap || uiState === DepositUIState.Depositing,
    {
      from: { x: '100%', opacity: 0 },
      enter: { x: '0%', opacity: 1, height: containerHeight },
      leave: {
        x: (depositTx ?? approveTx)?.status === TxStatus.Success ? '-100%' : '100%',
        opacity: 0,
      },
      duration: 150,
    },
  );

  const successTransition = useTransition(uiState === DepositUIState.Success, {
    from: { x: '100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: { x: '100%', opacity: 0 },
    duration: 150,
  });

  const defaultStateTransition = useTransition(uiState === DepositUIState.Default, {
    from: { x: '-100%', opacity: 0 },
    enter: { x: '0%', opacity: 1 },
    leave: { x: '-100%', opacity: 0 },
    initial: null,
    duration: 150,
  });

  return {
    containerSpring,
    selectTokenTransition,
    txInfoTransition,
    defaultStateTransition,
    successTransition,
  };
};
