import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { QueryOptional } from '../../../../../services';
import { EnvConfig } from '../../../../../envConfig';
import { CollatorRewards } from '../CollatorRewards';

export type CollatorRewardsQueryKey = [string, QueryOptional<string>];

export const getCollatorDailyRewards = async ({
  queryKey: [, address],
}: QueryFunctionContext<CollatorRewardsQueryKey>) => {
  if (!address) {
    return null;
  }

  const { data } = await axios.get<CollatorRewards[]>(
    `${EnvConfig.STASH_URL}/collator/${address}/staking/dailyReward`,
  );

  return data;
};
