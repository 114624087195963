import { keyBy } from 'lodash-es';
import {
  BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
  BN_HUNDRED,
  fromBN,
  BN_ZERO,
  BN_DIV_NUMERATOR_MULTIPLIER,
} from '@mangata-finance/sdk';
import { Asset } from '../../../token';
import { PoolWithShare } from '../../Pool';
import { isFirstPoolTokenLeaderType } from '../../../token/bucket/TokenBucket';
import { TransformPoolsResult } from '../../all/transformers/AllPoolsTransformers';
import { BN } from '@polkadot/util';
import { InternalBalance } from '../../../balance';
import {
  PalletMultipurposeLiquidityReserveStatusInfo,
  PalletProofOfStakeRewardInfo,
} from '@polkadot/types/lookup';
import { QueryOptional } from '../../../../services';

const getInvertedPool = (pool: PoolWithShare) => ({
  ...pool,
  secondAsset: pool.firstAsset,
  secondTokenAmount: pool.firstTokenAmount,
  secondTokenId: pool.firstTokenId,
  secondTokenRatio: pool.firstTokenRatio,
  firstAsset: pool.secondAsset,
  firstTokenAmount: pool.secondTokenAmount,
  firstTokenId: pool.secondTokenId,
  firstTokenRatio: pool.secondTokenRatio,
  id: `${pool.secondTokenId}-${pool.firstTokenId}`,
  symbols: [pool.secondAsset.symbol, pool.firstAsset.symbol],
});

export const transformInvestedPools = (
  pools: QueryOptional<TransformPoolsResult>,
  assets: QueryOptional<Asset[]>,
  balances: QueryOptional<Map<string, InternalBalance>>,
  reserves: QueryOptional<Map<string, PalletMultipurposeLiquidityReserveStatusInfo>>,
  tokensIssuance: QueryOptional<Map<string, BN>>,
  nativeRewardsInfo: QueryOptional<Map<string, PalletProofOfStakeRewardInfo>>,
  isFirstPoolTokenLeader: isFirstPoolTokenLeaderType,
) => {
  if (!pools || !assets) {
    return null;
  }

  const basePools = pools.baseList
    .map((pool) => {
      const internalLPBalance = balances?.get(pool.liquidityTokenId);
      const lpIssuance = tokensIssuance?.get(pool.liquidityTokenId);
      const lpReserves = reserves?.get(pool.liquidityTokenId);
      const lpNativeRewards = nativeRewardsInfo?.get(pool.liquidityTokenId);

      const allNonActivatedLPTokens =
        (internalLPBalance?.free || BN_ZERO)
          .add(lpReserves?.stakedUnactivatedReserves || BN_ZERO)
          .add(lpReserves?.unspentReserves || BN_ZERO) || BN_ZERO;
      const allActivatedLPTokens = (lpReserves?.stakedAndActivatedReserves || BN_ZERO).add(
        lpReserves?.activatedUnstakedReserves || BN_ZERO,
      );
      const ownedLPTokens = allNonActivatedLPTokens.add(allActivatedLPTokens);
      const share =
        ownedLPTokens.gtn(0) && lpIssuance?.gtn(0)
          ? ownedLPTokens.mul(BN_DIV_NUMERATOR_MULTIPLIER).div(lpIssuance || BN_ZERO)
          : BN_ZERO;

      if (allNonActivatedLPTokens.eq(BN_ZERO) && allActivatedLPTokens.eq(BN_ZERO)) {
        return null;
      }

      const serializedPool: PoolWithShare = {
        ...pool,
        id: `${pool.firstTokenId}-${pool.secondTokenId}`,
        symbols: [pool.firstAsset.symbol, pool.secondAsset.symbol],
        share,
        sharePercentage: fromBN(share.mul(BN_HUNDRED), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
        // TODO: rename to nativeActivatedLPTokens
        activatedLPTokens: lpNativeRewards?.activatedAmount.toBn() || BN_ZERO,
        nonActivatedLPTokens: allNonActivatedLPTokens,
        totalLPTokens: allNonActivatedLPTokens.add(allActivatedLPTokens),
        firstTokenRatio: pool.firstTokenRatio,
        secondTokenRatio: pool.secondTokenRatio,
      };

      return isFirstPoolTokenLeader(serializedPool)
        ? getInvertedPool(serializedPool)
        : serializedPool;
    })
    .filter(Boolean) as PoolWithShare[];

  const invertedPools = basePools.map(getInvertedPool);

  const poolsData = [...basePools, ...invertedPools].filter(Boolean) as PoolWithShare[];

  return {
    baseList: basePools,
    list: poolsData,
    byId: keyBy(poolsData, 'id'),
  };
};
