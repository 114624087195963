import { useEffect } from 'react';
import { isNil } from 'lodash-es';
import { fromBN } from '@mangata-finance/sdk';
import { useNavigate, useParams } from 'react-router';
import { Container, Skeleton, Text, FormatCurrency, FormatAmount } from 'ui';
import { useAllPoolsQuery, usePool24hVolume, usePoolTvlQuery, usePromotedPools } from 'core';
import { PoolLiquidityHistory } from './liquidityHistory/PoolLiquidityHistory';
import { PoolDetailHeader } from './header/PoolDetailHeader';
import { PoolDetailPosition } from './position/PoolDetailPosition';

export function PoolDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const poolSymbol = params?.poolSymbol;
  const { poolRewardsByLPId, poolAprsByLPId } = usePromotedPools();
  const { allPoolsQuery } = useAllPoolsQuery();

  const pool = poolSymbol
    ? allPoolsQuery.data?.list.find(({ symbols }) => poolSymbol === symbols.join('-'))
    : null;

  const poolTvl = usePoolTvlQuery(pool?.id);
  const { poolVolumeHistoryQuery } = usePool24hVolume(pool?.symbols.join('-') || null);
  const poolVolume = poolVolumeHistoryQuery.data;
  const isNotFound = allPoolsQuery.data && !pool;

  useEffect(() => {
    if (isNotFound) {
      navigate('/pools');
    }
  }, [isNotFound, navigate]);

  if (!pool) {
    return null;
  }

  const mothlyRewards = null || poolRewardsByLPId[pool.liquidityTokenId]?.monthlyMgxRewards;
  const isVolumeError = poolVolumeHistoryQuery.error;
  const VolumePlaceholder = isVolumeError ? '-' : <Skeleton className="w-[60px] h-[12px] mt-2" />;

  return (
    <Container justifyContent="center" className="mt-[120px] w-[480px] pb-[80px]" column>
      <PoolDetailHeader pool={pool} poolApr={poolAprsByLPId[pool.liquidityTokenId]} />
      <PoolDetailPosition pool={pool} className="mt-10" data-testid="position-details" />
      <Container
        className="mt-4"
        alignItems="center"
        justifyContent={mothlyRewards ? 'space-between' : 'start'}
        data-testid="pool-statistics"
        fullWidth
      >
        <Container className="bg-widget px-4 py-3 w-full rounded" data-testid="tvl" column>
          <Text type="label" color="secondary" className="text-center mb-1" id="pool.detail.tvl" />
          <Text type="body-l">
            <FormatCurrency value={poolTvl || null} />
          </Text>
        </Container>
        <Container className="bg-widget px-4 py-3 w-full ml-4 rounded" data-testid="volume" column>
          <Text
            type="label"
            color="secondary"
            className="text-center mb-1"
            id="pool.detail.volume"
          />
          {isNil(poolVolume) ? (
            VolumePlaceholder
          ) : (
            <Text type="body-l">
              <FormatCurrency value={poolVolume?.toString() || null} />
            </Text>
          )}
        </Container>
        {mothlyRewards && (
          <Container
            className="bg-widget px-4 rounded py-3 w-full whitespace-nowrap ml-4"
            data-testid="monthly-rewards"
            column
          >
            <Text
              type="label"
              color="secondary"
              className="text-center mb-1"
              id="pool.detail.rewards.monthly"
            />
            <Text type="body-l" color="highlight" className="mr-4">
              <FormatAmount value={fromBN(mothlyRewards, 18)} options={{ precision: 0 }} /> MGX
            </Text>
          </Container>
        )}
      </Container>
      {pool && <PoolLiquidityHistory pool={pool} />}
    </Container>
  );
}
