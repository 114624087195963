import * as React from 'react';
import cls from 'classnames';

import { Button } from '../Button/Button';

import { ClassName, TestId } from '../../types';

type ButtonSize = 'l' | 'm' | 's' | 'xs' | 'xxs';

export interface IconButtonProps
  extends ClassName,
    TestId,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  size?: ButtonSize;
}

const SIZES = {
  l: {
    btn: 'w-[48px] h-[48px]',
    icon: 'w-[20px] ',
  },
  m: {
    btn: 'w-[40px] h-[40px]',
    icon: 'w-[26px] ',
  },
  s: {
    btn: 'w-[32px] h-[32px]',
    icon: 'w-[26px]',
  },
  xs: {
    btn: 'w-[28px] h-[28px]',
    icon: 'w-[24px]',
  },
  xxs: {
    btn: 'w-[26px] h-[26px]',
    icon: 'w-[23px]',
  },
};

export function IconButton({
  Icon,
  className,
  onClick,
  'data-testid': testId,
  size = 'm',
}: IconButtonProps) {
  return (
    <Button
      variant="secondary"
      size="m"
      className={cls('min-w-[0] bg-transparent px-1', SIZES[size].btn, className)}
      onClick={onClick}
      data-testid={testId}
    >
      <Icon className={cls('h-[auto] fill-icon-secondary', SIZES[size].icon)} />
    </Button>
  );
}
