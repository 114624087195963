import { ITuple, Codec } from '@polkadot/types/types';
import { Vec, Option, u64, StorageKey } from '@polkadot/types';
import { PalletProofOfStakeSchedule } from '@polkadot/types/lookup';
import { isNull } from 'lodash-es';

export const transform3rdPartyRewardsScheduleList = (
  _data: [StorageKey<[u64]>, Option<ITuple<[PalletProofOfStakeSchedule, Option<u64>]>>][] | null,
): PalletProofOfStakeSchedule[] | null => {
  if (!_data) {
    return null;
  }

  return _data
    .map(([, _opt]) => _opt.unwrapOr(null)?.[0] || null)
    .filter((x) => x !== null) as PalletProofOfStakeSchedule[];
};

export const transformAll3rdPartyRewardsAmount = (
  _data: Vec<ITuple<[Codec, Codec, Codec]>> | null,
) => {
  if (isNull(_data)) {
    return null;
  }

  return _data.reduce((acc, [_liquidityId, _rewardTokenId, amount]) => {
    const liquidityId = _liquidityId.toString();
    const rewardTokenId = _rewardTokenId.toString();

    if (!liquidityId || !rewardTokenId) {
      return acc;
    }

    if (!acc.has(liquidityId)) {
      acc.set(liquidityId, new Map<string, Codec>());
    }

    acc.get(liquidityId)?.set(rewardTokenId, amount);

    return acc;
  }, new Map<string, Map<string, Codec>>());
};

export const transformRewardsSchedulesList = (
  _data: Array<
    [StorageKey<[u64]>, Option<ITuple<[PalletProofOfStakeSchedule, Option<u64>]>>]
  > | null,
) => {
  if (!_data) {
    return null;
  }

  const data = _data.reduce((acc, [, _metadata]) => {
    const metadata = _metadata.unwrapOr(null)?.[0];
    const lpId = metadata?.liqToken.toString();
    const rewardTokenId = metadata?.rewardToken.toString();

    if (!lpId || !rewardTokenId || !metadata) {
      return acc;
    }

    if (!acc.has(lpId)) {
      acc.set(lpId, new Map<string, PalletProofOfStakeSchedule>());
    }

    acc.get(lpId)?.set(rewardTokenId, metadata);

    return acc;
  }, new Map<string, Map<string, PalletProofOfStakeSchedule>>());

  return data;
};
