import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../../services';

export type DelegatorStateQueryKey = [
  queryKey: string,
  delegatorAddress: QueryOptional<string>,
  txCount: number,
];

export const getDelegatorState =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<DelegatorStateQueryKey>) => {
    const [, delegatorAddress] = queryKey;

    if (!api || !delegatorAddress) {
      return null;
    }

    return api.query.parachainStaking.delegatorState(delegatorAddress);
  };

export type DelegatorDetailQueryKey = Readonly<[queryKey: string, address: string | undefined]>;

export const listDelegators = (api: ApiPromise | null) => () => {
  if (!api) {
    return null;
  }

  return api.query.parachainStaking.delegatorState.entries();
};
