import cls from 'classnames';
import { Fragment, useState } from 'react';
import { ReactComponent as ChevronRightIcon } from 'icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { Container, Text, TokenIcon, IconButton } from 'ui';
import { ClassName, TestId } from 'core';
import { useSwapStore } from '../../../store/useSwapStore';

type RoutingDetailsProps = ClassName & TestId;

export const RoutingDetails = ({
  className,
  'data-testid': testId = 'routingDetails',
}: RoutingDetailsProps) => {
  const { firstToken, secondToken, routeDetails, isAutoRoutingEnabled } = useSwapStore();
  const [isRoutingExpanded, setRountingExpanded] = useState(false);

  const route = routeDetails?.bestRoute;

  if (!route || !firstToken || !secondToken) {
    return null;
  }

  const routeWithoutLast = route.slice(0, -1);
  const middleRoute = routeWithoutLast.slice(1);

  const toggleOpen = () => {
    setRountingExpanded(!isRoutingExpanded);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const renderMiddlePools = () =>
    routeWithoutLast.map((asset, index) => (
      <Fragment key={`${asset.id}-${asset.symbol}`}>
        <div className="border border-1 border-strong border-dashed mx-[5px] h-[1px] w-full flex-1" />
        <Container
          className="rounded-full border border-2 border-strong border-dashed px-[6px] py-[6px] pr-2"
          alignItems="center"
          data-testid="middle-pool"
        >
          <TokenIcon
            size="xs"
            token={[asset, routeWithoutLast[index + 1] || secondToken]}
            isCompact={false}
          />
        </Container>
      </Fragment>
    ));

  return (
    <Container
      fullWidth
      className={cls(
        'rounded-xl p-3 mt-4 duration-300 transition-all [&>*]:duration-300 overflow-hidden',
        isRoutingExpanded ? 'bg-btn-secondary-hover flex-col h-[158px] px-6 py-4' : 'h-[40px] px-4',
        isAutoRoutingEnabled && !isRoutingExpanded && 'cursor-pointer hover:bg-base',
        className,
      )}
      alignItems={isRoutingExpanded ? 'start' : 'center'}
      onClick={!isRoutingExpanded && isAutoRoutingEnabled ? toggleOpen : undefined}
      data-testid={testId}
    >
      <Container fullWidth alignItems="center" justifyContent="space-between">
        <Text
          id="swap.routing.title"
          type={isRoutingExpanded ? 'title-3' : 'label'}
          className="mr-2"
        />
        {isRoutingExpanded && (
          <IconButton
            size="xxs"
            data-testid={`${testId}-close`}
            Icon={CloseIcon}
            onClick={handleClose}
          />
        )}
        {!isRoutingExpanded && !isAutoRoutingEnabled && (
          <Text
            id="common.disabled"
            type="body-m"
            color="secondary"
            className="bg-widget py-1 px-2 rounded"
          />
        )}
      </Container>
      {isAutoRoutingEnabled &&
        (isRoutingExpanded ? (
          <Container column fullWidth>
            <Text
              id="swap.routing.desc"
              type="body-m"
              color="secondary"
              className="mr-2 mt-1 mb-4"
            />
            <Container
              fullWidth
              justifyContent="space-between"
              alignItems="center"
              className="relative"
            >
              <TokenIcon token={firstToken} />
              {renderMiddlePools()}
              <div className="border border-1 border-strong border-dashed mx-[5px] h-[1px] w-full flex-1" />
              <TokenIcon token={secondToken} />
            </Container>
          </Container>
        ) : (
          <Container justifyContent="space-between">
            <TokenIcon size="xs" token={firstToken} />
            <ChevronRightIcon className="fill-icon-secondary mt-[2px]" />
            {middleRoute.length > 0 && (
              <>
                <TokenIcon token={middleRoute.map((asset) => asset)} size="xs" isCompact={false} />
                <ChevronRightIcon className="fill-icon-secondary mt-[2px]" />
              </>
            )}
            <TokenIcon token={secondToken} size="xs" />
          </Container>
        ))}
    </Container>
  );
};
