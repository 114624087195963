import { Decimal } from 'decimal.js';
import { Web3 } from 'web3';
import { QueryOptional } from '../../../../services';

export const fetchRollupGasPrice = (web3: QueryOptional<Web3>) => async () => {
  if (!web3) {
    return null;
  }

  const res = (await web3.eth.getGasPrice())?.toString() || 0;

  return new Decimal(res).toFixed();
};
