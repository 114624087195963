import { useCallback, useMemo } from 'react';
import { fromBN } from '@mangata-finance/sdk';

import { EnvConfig } from '../../../envConfig';
import { useInternalBalancesQuery } from './query/useInternalBalancesQuery';
import { Asset } from '../../token';

const MGX_ID = EnvConfig.TOKEN_ID;

export const useInternalBalance = () => {
  const { internalBalancesQuery } = useInternalBalancesQuery();

  const balances = internalBalancesQuery.data;
  const mgxBalance = useMemo(() => balances?.get(MGX_ID), [balances]);

  const getFreeBalance = useCallback(
    (asset: Asset | null | undefined) => {
      if (!asset) {
        return null;
      }
      const balanceBN = balances?.get(asset.id);

      if (balanceBN) {
        return fromBN(balanceBN.free, asset.decimals);
      }

      return null;
    },
    [balances],
  );

  return {
    getFreeBalance,
    mgxBalance,
    balances,
    isLoading: internalBalancesQuery.isLoading,
    internalBalancesQuery,
  };
};
