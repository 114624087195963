import { Container, Text, FormatAmount, Trend, Skeleton } from 'ui';
import { Decimal } from 'decimal.js';
import { TestId } from 'core';

interface TokenDetailHeaderCardProps extends TestId {
  titleId: string;
  value?: string;
  trend: Decimal;
}

export function TokenDetailHeaderCard({
  titleId,
  value,
  trend,
  'data-testid': testId,
}: TokenDetailHeaderCardProps) {
  return (
    <Container
      column
      className="p-4 bg-widget rounded-lg min-w-[160px]"
      data-testid={testId}
      fullWidth
    >
      <Text type="title-4" id={titleId} color="secondary" />
      <Container fullWidth alignItems="center" justifyContent="space-between">
        {value !== undefined ? (
          <Text>
            $
            <FormatAmount
              value={value}
              options={{ precision: 2, nonZeroPrecision: true }}
              data-testid={`${testId}-value`}
            />
          </Text>
        ) : (
          <Skeleton className="mt-2 w-[70px] h-[10px]" />
        )}

        {value !== undefined ? (
          <Trend value={trend} />
        ) : (
          <Skeleton className="mt-2 w-[55px] h-[10px]" />
        )}
      </Container>
    </Container>
  );
}
