import { toBN } from '@mangata-finance/sdk';
import {
  EnvConfig,
  RouteDataError,
  useInternalBalance,
  useMangataAsset,
  useSwap,
  useUserStore,
} from 'core';
import { useEffect, useCallback } from 'react';
import { SwapUIStore, useSwapStore } from './store/useSwapStore';

export enum SwapFormError {
  InsufficientFunds = 'InsufficientFunds',
  InsufficientMgx = 'InsufficientMgx',
}

export const useSwapValidation = () => {
  const { getFreeBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();
  const { mgxLockAmount, isAmountAboveThreshold } = useSwap();
  const { selectedAccount } = useUserStore();

  const {
    firstToken,
    secondToken,
    firstAmount,
    secondAmount,
    routeDetails,
    formError,
    setFormError,
    setGasless,
    isGasless,
  } = useSwapStore();

  const isSingleSwap = (routeDetails?.bestRoute?.length || 0) === 2;
  const isDisabled = !firstToken || !secondToken || !firstAmount || !secondAmount || !!formError;
  const firstTokenBalance = getFreeBalance(firstToken);
  const mgxBalance = getFreeBalance(mangataAsset) || '0';

  const feeTxAsset = mangataAsset
    ? {
        ...mangataAsset,
        amount: isGasless ? '0' : mgxLockAmount,
      }
    : null;

  const validateForm = useCallback(() => {
    if (!(firstAmount && firstToken && secondAmount && secondToken && mgxLockAmount)) {
      return false;
    }

    if (!selectedAccount?.address) {
      setFormError(null);
      return false;
    }

    const mgxLockAmountBN = toBN(mgxLockAmount, mangataAsset?.decimals);
    const firstAmountBN = toBN(firstAmount, firstToken?.decimals);
    const firstTokenBalanceBN = toBN(firstTokenBalance || '0', firstToken?.decimals);
    const mgxBalanceBN = toBN(mgxBalance, mangataAsset?.decimals);

    const isGasless =
      isSingleSwap &&
      isAmountAboveThreshold(
        { ...firstToken, amount: firstAmount },
        { ...secondToken, amount: secondAmount },
      );

    setGasless(isGasless);
    switch (true) {
      case firstAmountBN.gt(firstTokenBalanceBN):
        setFormError(SwapFormError.InsufficientFunds);
        return false;
      case firstToken.id === EnvConfig.TOKEN_ID:
        if (firstAmountBN.gt(firstTokenBalanceBN)) {
          setFormError(SwapFormError.InsufficientFunds);
          return false;
        } else if (!isGasless && firstAmountBN.gt(firstTokenBalanceBN.sub(mgxLockAmountBN))) {
          setFormError(SwapFormError.InsufficientMgx);
          return false;
        }
        break;
      case !isGasless: {
        if (mgxBalanceBN.lt(mgxLockAmountBN)) {
          setFormError(SwapFormError.InsufficientMgx);
          return false;
        }
        break;
      }
      default:
        break;
    }

    setFormError(null);
  }, [
    firstAmount,
    firstToken,
    secondAmount,
    secondToken,
    isAmountAboveThreshold,
    setFormError,
    setGasless,
    mgxLockAmount,
    firstTokenBalance,
    mgxBalance,
    mangataAsset?.decimals,
    selectedAccount?.address,
    isSingleSwap,
  ]);

  useEffect(() => {
    if (secondToken && firstToken && (firstAmount || secondAmount)) {
      validateForm();
    }
  }, [secondToken, firstToken, firstAmount, secondAmount, validateForm]);

  return {
    validateForm,
    isDisabled,
    feeTxAsset,
  };
};

export const getSubmitMessage = (
  state: SwapUIStore,
  isWalletConnected: boolean,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case !isWalletConnected:
      return 'swap.button.connectWallet';
    case !state.firstToken || !state.secondToken:
    case state.routeDetails?.error === RouteDataError.NoPools:
    case state.routeDetails?.error === RouteDataError.InsufficientLiquidity:
      return 'swap.button.selectTokens';
    case !state.firstAmount || !state.secondAmount:
      return 'swap.button.enterAmount';
    case state.formError === SwapFormError.InsufficientFunds:
      return 'swap.button.insufficientFunds';
    case state.formError === SwapFormError.InsufficientMgx:
      return 'swap.button.insufficientMgx';
    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';

    default:
      return 'swap.button.cta';
  }
};
