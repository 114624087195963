import { Decimal } from 'decimal.js';
import {
  useWithdrawals,
  EnvConfig,
  useExternalBalances,
  useStashTokens,
  useInternalBalance,
} from 'core';
import { useMemo } from 'react';
import { useWithdrawalStore } from './store/useWithdrawalStore';
import { useIntl } from 'react-intl';
import { formatAmount } from 'ui';

export enum WithdrawalValidationError {
  MinAmount,
  InsufficientBalance,
  InsufficientBalanceForOriginFee,
  OriginZeroBalance,
}

export const useXcmWithdrawalValidation = () => {
  const intl = useIntl();
  const { chain, asset, amount, destinationAccount } = useWithdrawalStore();

  const { balanceByChannelTokenKey } = useExternalBalances(
    chain?.id ?? null,
    destinationAccount?.address ?? null,
  );
  const { getFreeBalance } = useInternalBalance();

  const {
    withdrawalFee: { data: fee },
  } = useWithdrawals({
    destinationAccount,
    channelId: chain?.id ?? null,
    assetId: asset?.id ?? null,
    amount,
  });

  const {
    stashTokensQuery: { data: stashTokens },
  } = useStashTokens();

  const originED = useMemo(() => {
    if (!asset) return new Decimal(0);
    const ed = stashTokens?.xcmTokens.find(
      (token) =>
        token.symbol === asset.symbol && token.channelId === EnvConfig.MANGATA_PARACHAIN_ID,
    )?.existentialDeposit;

    if (!ed) {
      return new Decimal(0);
    }

    return new Decimal(`${ed}e${asset.decimals}`);
  }, [asset, stashTokens?.xcmTokens]);

  const destinationED = useMemo(() => {
    if (!asset) return new Decimal(0);
    const ed = stashTokens?.xcmTokens.find(
      (token) => token.symbol === asset.symbol && token.channelId === chain?.id,
    )?.existentialDeposit;

    if (!ed) {
      return new Decimal(0);
    }

    return new Decimal(`${ed}e${asset.decimals}`);
  }, [asset, stashTokens?.xcmTokens, chain?.id]);

  const originAssetBalance = useMemo(() => {
    if (!asset) return new Decimal(0);

    const balance = balanceByChannelTokenKey[`${chain?.id}:${asset.id}`];
    const balanceBN = new Decimal(`${balance.data ?? 0}e${asset.decimals}`);

    return balanceBN;
  }, [asset, chain?.id, balanceByChannelTokenKey]);

  const minAmount = useMemo(() => {
    if (!asset || !fee) return null;

    const {
      raw: { destination },
    } = fee;

    const destinationFee = new Decimal(destination.amount);

    const min = new Decimal(0)
      .add(destination.asset.symbol === asset.symbol ? destinationFee : new Decimal(0))
      .add(originAssetBalance.lt(destinationED) ? destinationED : new Decimal(0));

    return min;
  }, [fee, asset, destinationED, originAssetBalance]);

  const maxAmount = useMemo(() => {
    if (!asset || !fee || !amount) return null;

    const assetBalance = new Decimal(`${getFreeBalance(asset) ?? 0}e${asset.decimals}`);

    const netAmount = assetBalance.sub(originED);

    return netAmount.lt(0) ? new Decimal(0) : netAmount;
  }, [amount, asset, originED, fee, getFreeBalance]);

  const canPayForOriginFee = useMemo(() => {
    if (!fee || !asset || !amount) return null;

    return new Decimal(getFreeBalance(fee.origin) || 0).gte(fee.origin.amount);
  }, [fee, asset, amount, getFreeBalance]);

  const getErrorText = () => {
    const feeAsset = fee?.raw.destination.asset;
    const minAmountValue = `${formatAmount(
      minAmount?.div(`1e${feeAsset?.decimals}`).toDP(asset?.decimals).toFixed(),
    )} ${feeAsset?.symbol}`;

    switch (getError()) {
      case WithdrawalValidationError.InsufficientBalance:
        return intl.formatMessage({ id: 'bridge.error.insufficientBalance' });
      case WithdrawalValidationError.OriginZeroBalance:
        return intl.formatMessage({ id: 'withdrawal.error.minAmount' }, { value: minAmountValue });
      case WithdrawalValidationError.MinAmount:
        return intl.formatMessage({ id: 'withdrawal.error.minAmount' }, { value: minAmountValue });
      case WithdrawalValidationError.InsufficientBalanceForOriginFee:
        return intl.formatMessage(
          { id: 'withdrawal.error.originFee' },
          { token: fee?.origin.symbol },
        );
    }
  };

  const hasError = (e: WithdrawalValidationError | WithdrawalValidationError[]) => {
    const error = getError();

    if (Array.isArray(e)) {
      return e.some(($) => $ === error);
    }
    return error === e;
  };

  const getError = () => {
    if (!asset || !fee) return null;
    if (!amount || !minAmount) return null;

    const amountBN = new Decimal(`${amount}e${asset.decimals}`);
    const originFee = new Decimal(`${fee.origin.amount}e${fee.origin.decimals}`);

    if (amountBN.lt(minAmount)) {
      if (originAssetBalance.lt(destinationED)) {
        return WithdrawalValidationError.OriginZeroBalance;
      }
      return WithdrawalValidationError.MinAmount;
    }
    if (amountBN.gt(maxAmount ?? 0)) return WithdrawalValidationError.InsufficientBalance;

    if (
      !canPayForOriginFee ||
      amountBN.add(asset.id === fee.origin.id ? originFee : 0).gt(maxAmount || 0)
    ) {
      return WithdrawalValidationError.InsufficientBalanceForOriginFee;
    }

    return null;
  };

  return {
    fee,
    getError,
    hasError,
    getErrorText,
    minAmount,
  };
};
