import { Signer } from '@polkadot/api/types';
import { ExtrinsicPath, StashChannel, StashToken } from '../../../stash';
import { ApiPromise } from '@polkadot/api';
import { buildBeneficiary } from './payload/beneficiary';
import { buildAsset } from './payload/asset';
import { buildWeightLimit } from './payload/weight';
import { buildDestination } from './payload/destination';
import { limitedReserveTransferAssets, transferMultiasset } from '../../extrinsics';
import { PaymentInfo } from '../fee/fee';

export interface DepositExtrinsic {
  send: () => Promise<void>;
  paymentInfo: () => Promise<PaymentInfo>;
  metadata: DepositMetadata;
}

export interface DepositMetadata {
  asset: StashToken;
  amount: string;
  channel: StashChannel;
  address: string;
}

export interface DepositParams {
  address: string;
  tokenId: string;
  amount: string;
  signer: Signer;
}

export interface DepositBaseParams {
  address: string;
  amount: string;
  asset: StashToken;
  channel: StashChannel;
  api: ApiPromise;
}

export async function depositExtrinsic({
  address,
  amount,
  api,
  asset,
  channel,
}: DepositBaseParams) {
  const account = api.createType('AccountId32', address).toHex();
  const beneficiary = buildBeneficiary(account);
  const baseParams = {
    api,
    address,
    asset: buildAsset(asset.location, amount, channel.xcmVersion, asset.extrinsicPath),
    weightLimit: buildWeightLimit(channel),
    destination: buildDestination(account, asset.extrinsicPath, channel),
  };

  switch (asset.extrinsicPath) {
    case ExtrinsicPath.TransferMultiasset:
      return transferMultiasset(baseParams);
    case ExtrinsicPath.PolkadotXcmLimitedTransfer:
      return limitedReserveTransferAssets({ ...baseParams, beneficiary });
    case ExtrinsicPath.XcmPalletLimitedTransfer:
      return limitedReserveTransferAssets({ ...baseParams, beneficiary }, 'xcmPallet');
    default:
      throw new Error(`Extrinsic path ${asset.extrinsicPath} not supported`);
  }
}
