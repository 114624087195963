import { useTransition, animated } from '@react-spring/web';
import {
  Button,
  Container,
  Modal,
  AmountTooltip,
  Skeleton,
  Text,
  TokenInput,
  WidgetDetailRow,
  ErrorBanner,
  OldAccountSelect,
  TokenIcon,
  shortenAddress,
} from 'ui';
import { useWithdrawalStore } from './store/useWithdrawalStore';
import {
  FeeAssetAmount,
  TxAsset,
  TxType,
  useChannels,
  useInternalBalance,
  useMangataAsset,
  useSafeTransaction,
  XcmOperation,
} from 'core';
import { EthereumAccountInfo } from './components/EthereumAccountInfo/EthereumAccountInfo';
import { ReactComponent as ArrowUpRightIcon } from 'assets/icons/ArrowCircleUpRight.svg';
import { useXcmWithdrawalState } from './useXcmWithdrawalState';
import {
  WithdrawalValidationError,
  useXcmWithdrawalValidation,
} from './useXcmWithdrawalValidation';
import { OldChainSelector } from './components/ChainSelector_Old/ChainSelector';

export function XcmWithdrawal() {
  const {
    isWithdrawalOpen,
    submitWithdrawal,
    selectedAccount,
    accounts,
    withdrawalAssets,
    withdrawalBalances,
    handleClose,
    fee,
    handleAccountSelect,
    isWithdrawalReady,
    isEthChain,
    isWithdrawing,
  } = useXcmWithdrawalState();

  const { getError, hasError, getErrorText } = useXcmWithdrawalValidation();
  const { getFreeBalance } = useInternalBalance();
  const { channelsQuery } = useChannels();
  const { mangataAsset } = useMangataAsset();

  const error = getError();

  const ethBannerTransition = useTransition(isEthChain, {
    from: { maxHeight: 0 },
    enter: { maxHeight: 100 },
    leave: { maxHeight: 0 },
  });

  const {
    destinationAccount,
    computed: { buttonLabelId, hasAmount },
    asset,
    setAsset,
    amount,
    setAmount,
    chain,
    setChain,
  } = useWithdrawalStore();

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: asset && amount ? [{ ...asset, amount }] : [],
      adaptiveFee: fee?.raw.feeData,
      type: TxType.Withdraw,
    },
    submitWithdrawal,
  );

  const renderFee = (
    fee?: FeeAssetAmount | TxAsset | null,
    watchError?: WithdrawalValidationError[],
  ) => {
    if (fee) {
      return (
        <Text
          type="label"
          color={hasError(watchError || []) ? 'alert' : 'secondary'}
          className="mr-2"
          data-testid="fee-value"
        >
          <AmountTooltip
            id={fee.amount}
            value={fee.amount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
          />
          {'asset' in fee ? fee.asset.symbol : fee.symbol}
        </Text>
      );
    }

    return <Skeleton width="80px" height="10px" rounded />;
  };

  return (
    <Modal
      isClosableOnOverlayClick={false}
      isOpen={isWithdrawalOpen}
      onClose={handleClose}
      className="w-[480px] rounded-xl py-6"
      overlayClassName="bg-base/80"
      data-testid="withdrawal-modal"
    >
      <Container fullWidth column>
        <Text id="bridge.withdrawal.title" type="display-2" />

        <OldChainSelector
          chain={chain}
          setChain={setChain}
          operation={XcmOperation.Withdrawal}
          channels={channelsQuery.data}
        />

        <Container className="mt-3 pl-6" fullWidth>
          <OldAccountSelect
            accounts={accounts}
            selectedAccount={destinationAccount}
            onSelect={handleAccountSelect}
            data-testid="account-select-from"
          />
        </Container>

        {chain && withdrawalAssets && (
          <TokenInput
            isInvalid={hasError([
              WithdrawalValidationError.InsufficientBalance,
              WithdrawalValidationError.MinAmount,
              WithdrawalValidationError.OriginZeroBalance,
            ])}
            selectedToken={asset}
            onTokenSelect={setAsset}
            onAmountChange={setAmount}
            tokens={withdrawalAssets}
            balances={withdrawalBalances}
            amount={amount || ''}
            freeBalance={getFreeBalance(asset)}
            className="mt-6 mb-2"
            settingsVisible={false}
          />
        )}

        {ethBannerTransition((style, visible) =>
          visible ? (
            <Container fullWidth className="mt-2" column>
              <animated.div style={style} className="w-full overflow-hidden">
                <EthereumAccountInfo operation={XcmOperation.Withdrawal} chain={chain?.title} />
              </animated.div>
            </Container>
          ) : null,
        )}

        {chain && asset && amount && hasAmount && destinationAccount && (
          <Container column fullWidth className="my-2">
            <WidgetDetailRow
              label={
                <Text
                  id="bridge.details.originFee"
                  type="label"
                  color={
                    hasError(WithdrawalValidationError.InsufficientBalanceForOriginFee)
                      ? 'alert'
                      : 'secondary'
                  }
                  className="mr-2"
                />
              }
              value={renderFee(fee?.origin, [
                WithdrawalValidationError.InsufficientBalanceForOriginFee,
              ])}
              data-testid="origin-fee"
            />
            <WidgetDetailRow
              label={
                <Text
                  color={
                    hasError([
                      WithdrawalValidationError.MinAmount,
                      WithdrawalValidationError.OriginZeroBalance,
                    ])
                      ? 'alert'
                      : 'secondary'
                  }
                  id="bridge.details.destinationFee"
                  type="label"
                  className="mr-2"
                />
              }
              value={renderFee(fee?.destination, [
                WithdrawalValidationError.MinAmount,
                WithdrawalValidationError.OriginZeroBalance,
              ])}
              data-testid="destination-fee"
            />
          </Container>
        )}

        {error !== null && (
          <ErrorBanner data-testid="withdrawal-error-message">{getErrorText()}</ErrorBanner>
        )}

        {selectedAccount && (
          <Container column className="mt-4" fullWidth>
            <Container alignItems="center" className="-mx-6" style={{ width: 'calc(100% + 48px)' }}>
              <div className="h-[1px] bg-input w-6" />
              <Text
                id="bridge.withdraw.from"
                uppercase
                color="secondary"
                className="mx-2 whitespace-nowrap"
              />
              <div className="h-[1px] bg-input w-full" />
            </Container>

            <Container
              fullWidth
              alignItems="center"
              justifyContent="space-between"
              className="mt-4 rounded-xl bg-widget p-4"
            >
              <Container alignItems="center">
                <ArrowUpRightIcon className="w-8 h-auto fill-icon-default" />
                <Container column className="ml-4">
                  <Text type="title-3">{selectedAccount.name}</Text>
                  <Text type="body-s" color="secondary">
                    {shortenAddress(selectedAccount.address)}
                  </Text>
                </Container>
              </Container>
              {mangataAsset && (
                <Container>
                  <Text color="secondary" id="common.on" />
                  <TokenIcon token={mangataAsset} size="xs" className="mx-2" />
                  <Text id="common.defaultChain" />
                </Container>
              )}
            </Container>
          </Container>
        )}

        <Button
          isDisabled={!isWithdrawalReady || error !== null || isWithdrawing}
          variant="primary"
          size="l"
          fullWidth
          onClick={submitTxWithChecks}
          data-testid="submit-withdrawal-button"
          className="mt-6"
        >
          <Text id={buttonLabelId ?? ''} type="body-l" />
        </Button>
      </Container>
    </Modal>
  );
}
