import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS } from '../../../../services';
import { fetchTokenBuckets } from '../service/TokenBucketService';
import { getTokenRank, isFirstPoolTokenLeader } from '../selectors/TokenBucketSelectors';

export const useTokenBucketQuery = () => {
  const tokenBucketQuery = useQuery(['token-bucket'], fetchTokenBuckets, {
    ...DEFAULT_QUERY_OPTIONS,
  });

  const data = useMemo(() => tokenBucketQuery.data || [], [tokenBucketQuery.data]);
  const getTokenRankMemoized = useMemo(() => getTokenRank(data), [data]);
  const isFirstPoolTokenLeaderMemoized = useMemo(() => isFirstPoolTokenLeader(data), [data]);

  return {
    tokenBucketQuery,
    getTokenRank: getTokenRankMemoized,
    isFirstPoolTokenLeader: isFirstPoolTokenLeaderMemoized,
  };
};
