import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { getTradeableTokenIds } from '../services/TradeableTokensService';

export const useTradeableTokenIds = () => {
  const api = useApi();

  const tradeableTokenIdsQuery = useQuery(['tradeable-tokens'], getTradeableTokenIds(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
  });

  return {
    tradeableTokenIdsQuery,
  };
};
