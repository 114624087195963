import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { CollatorRewards, CollatorRewardsRes } from '../CollatorRewards';
import {
  CollatorRewardsQueryKey,
  getCollatorDailyRewards,
} from '../services/collatorRewardsService';
import { transformCollatorRewards } from '../transformers/collatorRewardsTransformer';

type CollatorRewardsQueryOptions = UseQueryOptions<
  CollatorRewardsRes[] | null,
  unknown,
  CollatorRewards[] | null,
  CollatorRewardsQueryKey
>;

const getDailyRewardsQuery = (address: string): CollatorRewardsQueryOptions => ({
  queryKey: ['collator-daily-rewards', address],
  queryFn: getCollatorDailyRewards,
  select: transformCollatorRewards(address),
  enabled: !!address,
  ...DEFAULT_QUERY_OPTIONS,
});

export const useCollatorRewardsQuery = (address: QueryOptional<string[]>) => {
  const collatorDailyRewardsQueries = useQueries({
    queries: (address ?? [])?.map(getDailyRewardsQuery),
  });

  const isLoading = collatorDailyRewardsQueries.some((query) => query.isLoading);

  return {
    collatorDailyRewardsQueries,
    isLoading,
  };
};
