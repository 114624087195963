import { useAccounts } from '../../../account';
import { useTransaction } from '../../../transaction';
import { useWallet } from '../../../wallet';
import { useApi } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { claim3rdPartyRewards } from '../services/3rdPartyRewardsService';

export const useClaim3rdPartyRewardsMutation = () => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const { transactionStore } = useTransaction();

  const claim3rdPartyRewardsMutation = useMutation(
    ['claim-3rd-party-rewards'],
    claim3rdPartyRewards(api, selectedAccount?.address, wallet, transactionStore),
  );

  return {
    claim3rdPartyRewardsMutation,
  };
};
