import { useAccounts } from '../../../account';
import { QueryOptional } from '../../../../services';
import { CollatorRewards, useCollators } from '../../collator';
import { useCollatorRewardsQuery } from '../../collator/rewards/query/useCollatorRewardsQuery';
import { useDelegators } from '../useDelegators';
import Decimal from 'decimal.js';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from '@mangata-finance/sdk';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { useMemo } from 'react';

export const useDelegatorRewards = (collators: QueryOptional<string[]>) => {
  const { selectedAccount } = useAccounts();
  const { collatorDailyRewardsQueries, isLoading } = useCollatorRewardsQuery(collators);
  const { collatorsMapQuery } = useCollators();
  const {
    delegatorMapQuery: { data: delegators },
  } = useDelegators();

  const collatorDailyRewards = useMemo(() => {
    return new Map(
      collatorDailyRewardsQueries
        .map((query) => query.data)
        .filter((data): data is CollatorRewards[] => !!data)
        .flat()
        .map((item) => [item.collator, item.dailyRewards]),
    );
  }, [collatorDailyRewardsQueries]);

  const collatorData = useMemo(() => {
    return new Map(
      collators
        ?.map((collator) => {
          const data = collatorsMapQuery.data?.get(collator);
          if (!data) {
            return null;
          }
          return [collator, data];
        })
        .filter((record): record is [string, ParachainStakingCollatorCandidate] => !!record),
    );
  }, [collators, collatorsMapQuery.data]);

  const ownDelegations = delegators
    ?.get(selectedAccount?.address ?? '')
    ?.delegations.filter((delegation) => collatorData.has(delegation.owner.toString()));

  const dailyRewards = useMemo(() => {
    return ownDelegations
      ?.map((delegation) => {
        const collator = collatorData.get(delegation.owner.toString());
        const rewards = collatorDailyRewards.get(delegation.owner.toString());

        if (!collator || !rewards) {
          return null;
        }

        const share = new Decimal(delegation.amount.toString()).div(collator.bond.toString());

        const amount = share
          .mul(new Decimal(rewards).div(`1e${BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS}`))
          .toString();

        return [delegation.owner.toString(), amount];
      })
      .filter((reward): reward is [string, string] => !!reward);
  }, [collatorDailyRewards, collatorData, ownDelegations]);

  return {
    dailyRewards,
    isLoading,
  };
};
