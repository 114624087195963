import Decimal from 'decimal.js';
import { Asset, WalletAccount } from 'core';
import { ChainSelectItem } from 'ui';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum DepositUIState {
  Default,
  SelectToken,
  ApproveSpendingCap,
  Depositing,
  Success,
}

export interface DepositUIStore {
  chain: ChainSelectItem | null;
  originAccount: WalletAccount | null;
  amount: string | null;
  computed: {
    buttonLabelId: string | null;
    hasAmount: boolean;
  };
  asset: Asset | null;
  isSuccess: boolean;
  uiState: DepositUIState;
  setSuccess: (isSuccess: boolean) => void;
  setChain: (chain: ChainSelectItem | null, withAccountReset?: boolean) => void;
  setOriginAccount: (originAccount: WalletAccount | null) => void;
  setAsset: (asset: Asset | null) => void;
  setUIState: (state: DepositUIState) => void;
  setAmount: (amount: string | null) => void;
  dispose: () => void;
}

export const useDepositStore = create(
  devtools<DepositUIStore>((set, get) => ({
    chain: null,
    originAccount: null,
    amount: null,
    asset: null,
    isSuccess: false,
    uiState: DepositUIState.Default,
    computed: {
      get buttonLabelId() {
        return computeButtonLabel(get());
      },
      get hasAmount() {
        return computeHasAmount(get());
      },
    },
    setSuccess: (isSuccess) => set({ isSuccess }),
    setAmount: (amount) => set({ amount }),
    setChain: (chain, withAccountReset = true) => {
      set(
        withAccountReset
          ? { chain, asset: null, amount: null, originAccount: null }
          : { chain, asset: null, amount: null },
      );
    },
    setOriginAccount: (originAccount) => set({ originAccount, asset: null, amount: null }),
    setAsset: (asset) => set({ asset }),
    setUIState: (state) => set({ uiState: state }),
    dispose: () =>
      set({
        chain: null,
        originAccount: null,
        amount: null,
        asset: null,
        isSuccess: false,
        uiState: DepositUIState.Default,
      }),
  })),
);

function computeHasAmount(state: DepositUIStore) {
  if (!state || !state.asset || !state.amount) return false;

  try {
    const hasAmount = new Decimal(state.amount).gt(new Decimal(0));
    return hasAmount;
  } catch {
    return false;
  }
}

function computeButtonLabel(state: DepositUIStore): string | null {
  switch (true) {
    case state === undefined:
      return null;
    case state.chain === null:
      return 'bridge.button.selectChain';
    case state.asset === null:
      return 'bridge.button.selectToken';
    case state.amount === null:
      return 'bridge.button.amount';
    case state.originAccount === null:
      return 'bridge.button.selectAccount';
    default:
      return 'bridge.button.deposit';
  }
}
