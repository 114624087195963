import { useAccountsQuery } from './query/useAccountsQuery';
import { useUserStore } from '../user/useUserStore';
import { groupBy } from 'lodash-es';

export const useAccounts = () => {
  const { selectedAccount, setSelectedAccount, disconnectWallet } = useUserStore();

  const accountsQuery = useAccountsQuery();
  const accountsByType = groupBy(accountsQuery.data, 'type');

  return {
    accountsQuery,
    setSelectedAccount,
    selectedAccount,
    disconnectWallet,
    accountsByType,
  };
};
