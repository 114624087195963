import { useInternalBalance } from '../../balance/internal/useInternalBalance';
import { useFilteredAssets } from './filter/useFilteredAssets';

export const useFilteredInternalAssets = () => {
  const { getFreeBalance, isLoading } = useInternalBalance();
  const { filteredAssets } = useFilteredAssets();

  const tokenListFreeBalances = new Map<string, string>(
    filteredAssets.reduce((acc: [string, string][], asset) => {
      const freeBalance = getFreeBalance(asset);
      return [...acc, [asset.id, freeBalance ?? '0']];
    }, []),
  );

  return {
    tokenListFreeBalances,
    isLoading,
    filteredAssets,
    getFreeBalance,
  };
};
