import { useQuery } from '@tanstack/react-query';
import { getTokensIssuance } from '../services/TokenIssuanceService';
import { useApi } from '../../../../services';
import { transformTokensIssuance } from '../transformers/TokensIssuanceTransformer';

export const useTokensIssuanceQuery = () => {
  const api = useApi();
  const tokensIssuanceQuery = useQuery(['tokens-issuance'], getTokensIssuance(api), {
    enabled: !!api,
    select: transformTokensIssuance,
  });

  return { tokensIssuanceQuery };
};
