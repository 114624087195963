import { PoolWithRatio } from '../../../pool';
import { GetTokenRank, TokenBucket, TokenRank, isFirstPoolTokenLeaderType } from '../TokenBucket';

export const getTokenRank =
  (bucketList: TokenBucket[]): GetTokenRank =>
  (tokenSymbol: string | null | undefined) => {
    if (!tokenSymbol) {
      return { bucketRank: null, tokenRank: null };
    }
    const bucketRank = bucketList.findIndex((bucket) =>
      bucket.tokens.some((token) => token === tokenSymbol),
    );

    if (bucketRank === -1) {
      return { bucketRank: null, tokenRank: null };
    }

    const tokenRank = bucketList[bucketRank].tokens.findIndex((token) => token === tokenSymbol);

    if (tokenRank === -1) {
      return { bucketRank, tokenRank: null };
    }

    return { bucketRank, tokenRank };
  };

export const isFirstPoolTokenLeader =
  (bucketList: TokenBucket[]): isFirstPoolTokenLeaderType =>
  (pool: PoolWithRatio) => {
    const getRank = getTokenRank(bucketList);

    const firstTokenRank = getRank(pool.firstAsset.symbol);
    const secondTokenRank = getRank(pool.secondAsset.symbol);

    if (firstTokenRank.bucketRank === null) {
      return false;
    }

    if (secondTokenRank.bucketRank === null) {
      return true;
    }

    if (firstTokenRank.bucketRank !== secondTokenRank.bucketRank) {
      return firstTokenRank.bucketRank < secondTokenRank.bucketRank;
    }

    return (
      (firstTokenRank.tokenRank && firstTokenRank.tokenRank < (secondTokenRank.tokenRank ?? 0)) ||
      !secondTokenRank.tokenRank
    );
  };

export const sortByAssetRank = (aRank: TokenRank, bRank: TokenRank) => {
  if (aRank.bucketRank === null || aRank.tokenRank === null) return 1;
  if (bRank.bucketRank === null || bRank.tokenRank === null) return -1;

  if (aRank.bucketRank !== bRank.bucketRank) {
    return aRank.bucketRank > bRank.bucketRank ? 1 : -1;
  }

  return aRank.tokenRank > bRank.tokenRank ? 1 : -1;
};
