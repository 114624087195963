import { Container, Text } from '../..';
import { ClassName } from 'core';
import { ReactComponent as AlertIcon } from '../../../assets/icons/info.svg';
import cls from 'classnames';
import { Children, TestId } from '../../types';

interface ErrorBannerProps extends ClassName, Children, TestId {
  msgId?: string;
}

export const ErrorBanner = ({
  className,
  msgId,
  children,
  'data-testid': testId,
}: ErrorBannerProps) => {
  return (
    <Container
      inline
      alignItems="stretch"
      className={cls('rounded w-full rounded bg-alert/[0.1] mt-2', className)}
      data-testid={testId}
    >
      <Container alignItems="center" className="bg-alert p-2">
        <AlertIcon className="w-[24px] h-[24px] fill-icon-alert" />
      </Container>
      {msgId ? (
        <Text className="py-2 tracking-[.5px] pl-4 pr-2" color="alert" id={msgId} />
      ) : (
        <Text className="py-2 tracking-[.5px] pl-4 pr-2" color="alert">
          {children}
        </Text>
      )}
    </Container>
  );
};
