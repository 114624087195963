import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import {
  FetchPriceDiscoveryQueryKey,
  PriceDiscoveryResult,
  fetchPriceDiscovery,
} from '../services/PriceDiscoveryService';
import { TokenSymbol } from '@mangata-finance/sdk';
import { transformPriceDiscovery } from '../transformers/PriceDiscoveryTransformer';
import { zipObject } from 'lodash-es';

type PriceDiscoveryQueryOptions = UseQueryOptions<
  PriceDiscoveryResult | null,
  unknown,
  PriceDiscoveryResult['current_price'] | null,
  FetchPriceDiscoveryQueryKey
>;

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
  retry: false,
};

const getQuery = (tokenSymbol: TokenSymbol | undefined): PriceDiscoveryQueryOptions => ({
  queryKey: ['price-discovery', tokenSymbol],
  queryFn: fetchPriceDiscovery,
  select: transformPriceDiscovery,
  ...queryOptions,
  enabled: !!tokenSymbol,
});

export const usePriceDiscoveryQuery = (tokenSymbol: TokenSymbol) => {
  const priceDiscoveryQuery = useQuery(getQuery(tokenSymbol));

  return { priceDiscoveryQuery };
};

export const usePriceDiscoveryQueries = (tokenSymbols: TokenSymbol[] | null) => {
  const priceQueries = useQueries({ queries: tokenSymbols?.map(getQuery) || [] });

  if (!tokenSymbols) {
    return {
      priceDiscoveryQueriesBySymbol: {},
    };
  }

  return {
    priceDiscoveryQueriesBySymbol: zipObject(tokenSymbols, priceQueries),
  };
};
