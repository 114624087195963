import { Web3 } from 'web3';
import { createContext, useContext } from 'react';

export interface Web3Contexts {
  [chainId: string]: Web3;
}

export const Web3Context = createContext<Web3Contexts | null>(null);

export const useWeb3 = () => {
  const web3s = useContext(Web3Context);

  return web3s;
};
