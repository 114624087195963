import { zipObject } from 'lodash-es';
import { PromotedPoolWeightResult } from '../../metadata/services/PromotedPoolMetadataService';
import { BN } from '@polkadot/util';
import { EnvConfig } from '../../../../../envConfig';

const ANNUAL_REWARDS = new BN(EnvConfig.ANNUAL_MGX_POOL_REWARDS);

export interface PromotedPoolsRewards {
  [liquidityTokenId: string]: {
    annualMgxRewards: BN;
    monthlyMgxRewards: BN;
  };
}

export const getPromotedPoolsRewards = (promotedPoolMetadata?: PromotedPoolWeightResult | null) => {
  if (!promotedPoolMetadata) {
    return {};
  }

  const liquidityTokenIds = Object.keys(promotedPoolMetadata.promotedPoolWeightData);
  const rewards = liquidityTokenIds.map((liquidityTokenId) => {
    const poolWeight = new BN(
      promotedPoolMetadata.promotedPoolWeightData[liquidityTokenId].weight.toString(),
    );

    const annualMgxRewards = ANNUAL_REWARDS.mul(poolWeight).div(promotedPoolMetadata.totalWeight);

    return {
      annualMgxRewards,
      monthlyMgxRewards: annualMgxRewards.divn(12),
    };
  });

  return zipObject(liquidityTokenIds, rewards);
};
