import { useAccounts } from '../../account';
import { useInvestedPools } from '../../pool';
import { useNativeRewardsMutation } from './query/useNativeRewardsMutation';
import { useNativeRewardAmountQueries, useNativeRewardsQuery } from './query/useNativeRewardsQuery';

export const useNativeRewards = () => {
  const { data: investedPools } = useInvestedPools();
  const { selectedAccount } = useAccounts();

  const { rewardQueriesByLpId } = useNativeRewardAmountQueries(
    selectedAccount?.address,
    investedPools?.baseList,
  );
  const { claimAllRewardsFeeQuery } = useNativeRewardsQuery(
    selectedAccount?.address,
    rewardQueriesByLpId,
  );

  const { claimAllRewardsMutation, claimRewardsMutation } =
    useNativeRewardsMutation(rewardQueriesByLpId);

  return {
    rewardQueriesByLpId,
    claimAllRewardsMutation,
    claimRewardsMutation,
    claimAllRewardsFeeQuery,
  };
};
