import { useCallback, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { some } from 'lodash-es';
import { AdaptiveTxFee, useInternalBalance } from 'core';
import { RemoveLiquidityUIStore, useRemoveLiquidityStore } from './store/useRemoveLiquidityStore';
import { RemoveLiquidityFormError } from './RemoveLiquidity';

export const useRemoveLiquidityValidation = (
  feeData: AdaptiveTxFee['current'] | null | undefined,
  stakedPerc: number,
) => {
  const { formError, setFormError, removeAmount } = useRemoveLiquidityStore();
  const { getFreeBalance } = useInternalBalance();

  const isInvalid = some(formError, Boolean);
  const feeBalanceStr = getFreeBalance(feeData) ?? '0';
  const maxAmount = 100 - stakedPerc;
  const isAboveMaxAmount = removeAmount > maxAmount;

  const validateForm = useCallback(() => {
    const feeBalance = new Decimal(feeBalanceStr);
    const newFormError: Partial<Record<RemoveLiquidityFormError, boolean>> = {};

    if (feeData) {
      if (feeBalance.lessThan(feeData.amount ?? 0)) {
        newFormError[RemoveLiquidityFormError.InsufficientFee] = true;
      }
    }

    if (isAboveMaxAmount || maxAmount === 0) {
      newFormError[RemoveLiquidityFormError.AboveMaxAmount] = true;
    }

    setFormError(newFormError);
    return null;
  }, [feeData, maxAmount, isAboveMaxAmount, feeBalanceStr, setFormError]);

  useEffect(() => {
    validateForm();
  }, [feeData, validateForm]);

  return {
    isInvalid,
    isAboveMaxAmount,
    maxAmount,
  };
};

export const getSubmitMessage = (
  state: RemoveLiquidityUIStore,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case (!state.removeAmount || state.removeAmount === 0) &&
      !state.formError[RemoveLiquidityFormError.AboveMaxAmount]:
      return 'pool.remove.submit.enterAmount';
    case !!state.formError[RemoveLiquidityFormError.InsufficientFee]:
      return 'pool.remove.submit.insufficientFee';

    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';

    case !!state.formError[RemoveLiquidityFormError.AboveMaxAmount]:
    default:
      return 'pool.remove.submit.cta';
  }
};
