import { useRef, useEffect, useState } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { useChannels } from '../../domains/channel';

export const useChannelApi = (channelId: string | null) => {
  const {
    channelsQuery: { data: channels },
  } = useChannels();
  const api = useRef<Record<string, ApiPromise>>({});
  const [isApiReady, setIsApiReady] = useState(false);

  const channelData = channels?.find((channel) => channel.channelId === channelId);

  useEffect(() => {
    if (channelId && channelData && !(channelId in api.current)) {
      (async () => {
        setIsApiReady(false);
        const chainApi = await ApiPromise.create({
          provider: new WsProvider(channelData.url),
          noInitWarn: true,
        });

        api.current[channelId] = chainApi;
        setIsApiReady(true);
      })();
    }
  }, [channelData, channelId]);

  return { api: channelId ? api.current[channelId] : null, isApiReady, channel: channelData };
};
