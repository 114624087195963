import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { get3rdPartyTokens } from '../services/3rdPartyRewardsService';
import { transform3rdPartyTokens } from '../transformer/3rdPartyTokensTransformer';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
  retry: false,
};

export const use3rdPartyTokensQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const thirdPartyTokensQuery = useQuery({
    queryKey: ['3rdParty-tokens', finalisedTxCount],
    queryFn: get3rdPartyTokens(api),
    select: transform3rdPartyTokens,
    ...queryOptions,
    enabled: !!api,
  });

  return { thirdPartyTokensQuery };
};
