import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { PoolWithRatio, PoolWithShare } from 'core/src/domains/pool/Pool';
import { TokenIcon, Container, Text, Button } from 'ui';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { RewardsAprBadge } from 'modules';
import { useTotalPoolRewardsApr } from 'core';

export interface PositionDetailHeaderProps {
  pool: PoolWithRatio | PoolWithShare;
}

export const PositionDetailHeader = ({ pool }: PositionDetailHeaderProps) => {
  const navigate = useNavigate();
  const { totalApr } = useTotalPoolRewardsApr(pool);

  const handleBack = () => {
    navigate(`/positions`);
  };

  return (
    <>
      <Button variant="secondary" LeadIcon={BackIcon} onClick={handleBack} className="py-0">
        <FormattedMessage id="positions.detail.back.cta" />
      </Button>
      <Container
        className="mt-10 whitespace-nowrap"
        alignItems="center"
        justifyContent="space-between"
        fullWidth
      >
        <Container alignItems="center">
          <TokenIcon token={[pool.firstAsset, pool.secondAsset]} size="l" />
          <Text type="display-2" className="text-center ml-4">
            {pool.firstAsset.symbol} / {pool.secondAsset.symbol}
          </Text>
        </Container>
        {totalApr && totalApr !== '0' && <RewardsAprBadge value={totalApr} />}
      </Container>
    </>
  );
};
