import { ChartHistoryInterval } from 'core';

export interface Timeframe {
  key: string;
  interval: ChartHistoryInterval;
  days: number;
}

export const timeframes: Timeframe[] = [
  {
    key: 'D',
    interval: ChartHistoryInterval.Hour,
    days: 1,
  },
  {
    key: 'W',
    interval: ChartHistoryInterval.Day,
    days: 7,
  },
  {
    key: 'M',
    interval: ChartHistoryInterval.Day,
    days: 30,
  },
  {
    key: 'Y',
    interval: ChartHistoryInterval.Month,
    days: 365,
  },
];
