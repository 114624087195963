import { QueryOptional } from '../../../../../services';
import { Decimal } from 'decimal.js';
import { RollupToken } from '../../../stash/RollupStash';

export const fetchRollupTokenAllowance =
  (
    rolldownAddress: QueryOptional<string>,
    userAddress: QueryOptional<string>,
    token: QueryOptional<RollupToken>,
  ) =>
  async () => {
    if (!userAddress || !token || !rolldownAddress || token.isNative) return null;

    const res =
      (await token.contract.methods.allowance(userAddress, rolldownAddress).call())?.toString() ||
      '0';

    return new Decimal(res).div(`1e${token.decimals}`).toFixed();
  };
