import { useEffect } from 'react';
import { Container, Modal } from 'ui';
import { isEmpty } from 'lodash-es';
import { useModalStore, useUserStore, useAccounts } from 'core';
import { LockedWalletLayout } from '../../Wallet/layouts/Locked/LockedWalletLayout';
import { NoAccountsLayout } from '../../Wallet/layouts/SelectAccount/noAccounts/NoAccountsLayout';
import { useDepositStore } from '../store/useDepositStore';
import { RollupWithdrawalForm } from './RollupWithdrawalForm';

export function RollupWithdrawal() {
  const { dispose } = useDepositStore();
  const { isWithdrawalOpen, closeWithdrawal } = useModalStore();
  const { isWalletUnlocked } = useUserStore();
  const {
    accountsQuery: { data: accounts },
  } = useAccounts();

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  const renderContent = () => {
    switch (true) {
      case !isWalletUnlocked:
        return <LockedWalletLayout className="my-8" />;
      case isEmpty(accounts):
        return <NoAccountsLayout className="my-8" />;
      default:
        return <RollupWithdrawalForm />;
    }
  };

  return (
    <Modal
      isClosableOnOverlayClick={false}
      isOpen={isWithdrawalOpen}
      onClose={closeWithdrawal}
      className="w-[480px] rounded-xl py-6"
      overlayClassName="bg-base/80"
      data-testid="withdrawal-modal"
    >
      <Container fullWidth column>
        {renderContent()}
      </Container>
    </Modal>
  );
}
