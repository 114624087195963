import { useMutation } from '@tanstack/react-query';
import { submitRollupDeposit } from '../services/rollupDepositService';
import { useSDK as useMetamask } from '@metamask/sdk-react';
import {
  DEFAULT_QUERY_OPTIONS,
  QueryOptional,
  useRollupGasPriceQuery,
  useTransactionStore,
} from '../../../../..';
import { useRolldownContract } from '../../useRolldownContract';

export const useRollupDepositMutation = (chainId: QueryOptional<string>) => {
  const { contract } = useRolldownContract(chainId);
  const { provider } = useMetamask();
  const transactionStore = useTransactionStore();
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);

  const rollupDepositMutation = useMutation({
    mutationFn: submitRollupDeposit(transactionStore, provider, contract, rollupGasPriceQuery.data),
    mutationKey: ['rollup-deposit-submit'],
    ...DEFAULT_QUERY_OPTIONS,
  });

  return {
    rollupDepositMutation,
  };
};
