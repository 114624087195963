import { QueryOptional, Web3Contexts } from '../../../../../services';
import { Decimal } from 'decimal.js';
import { Web3, ETH_DATA_FORMAT } from 'web3';
import { RollupToken } from '../../../stash/RollupStash';
import { QueryFunctionContext } from '@tanstack/react-query';

export type GetRollupTokenBalancesQueryKey = [
  key: string,
  userAddress: QueryOptional<string>,
  chainId: QueryOptional<string>,
];

export const fetchRollupTokenBalances =
  (web3Contexts: QueryOptional<Web3Contexts>, tokens: QueryOptional<RollupToken[]>) =>
  async ({ queryKey }: QueryFunctionContext<GetRollupTokenBalancesQueryKey>) => {
    const [, userAddress, chainId] = queryKey;

    if (!chainId) {
      return new Map();
    }

    const balances = await Promise.all(
      tokens?.map(async (token) => {
        if (token.isNative) {
          return (
            (await fetchRollupNativeTokenBalance(web3Contexts?.[chainId], userAddress)()) || '0'
          );
        }

        const balanceRes = await fetchRollupErc20Balances(userAddress, token)();
        return new Decimal(balanceRes || '0').div(`1e${token.decimals}`).toFixed();
      }) ?? [],
    );

    return new Map(tokens?.map((token, index) => [token.address, balances[index]]) ?? []);
  };

export const fetchRollupErc20Balances =
  (userAddress: QueryOptional<string>, token: QueryOptional<RollupToken>) => async () => {
    if (!userAddress || !token) {
      return null;
    }

    return (await token.contract.methods.balanceOf(userAddress).call())?.toString();
  };

export const fetchRollupNativeTokenBalance =
  (web3: QueryOptional<Web3>, userAddress: QueryOptional<string>) => async () => {
    if (!userAddress || !web3) {
      return null;
    }

    const balanceWei = (await web3.eth.getBalance(userAddress, undefined, ETH_DATA_FORMAT)) || 0;

    return web3.utils.fromWei(balanceWei, 'ether');
  };
