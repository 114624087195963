import { ClassName, PoolWithShare, useDelegatorRewards, useDelegators } from 'core';
import { Container, FormatAmount, Skeleton, Text } from 'ui';
import Decimal from 'decimal.js';
import cls from 'classnames';
import { ReactComponent as LightningIcon } from 'icons/lightning.svg';

export interface PositionStakingRewardsCardProps extends ClassName {
  pool: PoolWithShare;
}
export const PositionStakingRewardsCard = ({
  pool,
  className,
}: PositionStakingRewardsCardProps) => {
  const {
    delegatorStateQuery: { data: delegator },
  } = useDelegators();

  const collators = delegator?.delegations
    .filter((d) => d.liquidityToken.toString() === pool.liquidityTokenId)
    .map((d) => d.owner.toString());

  const { dailyRewards, isLoading } = useDelegatorRewards(collators);

  const dailyRewardsSum = dailyRewards?.reduce((acc, r) => acc.add(r[1]), new Decimal(0));

  return (collators ?? []).length > 0 ? (
    <Container
      className={cls('bg-widget rounded-xl shadow-xl border border-default', className)}
      fullWidth
      justifyContent="space-between"
      alignItems="center"
      column
    >
      <Container
        alignItems="center"
        fullWidth
        justifyContent="space-between"
        className="py-6 px-4 bg-gradient-to-r from-app/[.1] from-0% via-app/[.1] to-accent/[.1] to-100%"
      >
        <Container alignItems="center">
          <LightningIcon className="fill-icon-accent w-[26px] h-auto" />
          <Container column className="ml-4">
            <Text type="title-3" weight="normal" id="position.overview.staking.rewards.title" />
            <Text id="position.overview.staking.rewards.desc" color="secondary" />
          </Container>
        </Container>
      </Container>
      <div className="w-full border border-default" />
      <Container column fullWidth className="pb-4 px-6">
        <Container alignItems="center" justifyContent="space-between" fullWidth className="mt-4">
          <Container alignItems="center">
            <Text type="title-4" id="position.overview.staking.rewards.24h" weight="normal" />
          </Container>

          {isLoading ? (
            <Skeleton width="80px" height="15px" rounded />
          ) : (
            <Text color="accent">
              <FormatAmount
                value={dailyRewardsSum?.toString()}
                options={{ precision: 2, maxChars: 13 }}
              />{' '}
              <Text id="common.mainToken" />
            </Text>
          )}
        </Container>
      </Container>
    </Container>
  ) : null;
};
