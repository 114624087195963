import { useQuery } from '@tanstack/react-query';
import {
  fetchSingleTokenPriceHistory,
  fetchTokenPairPriceHistory,
} from '../services/tokenPriceHistoryService';
import { ChartHistoryInterval } from '../../../../../types';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';

interface TokenPriceHistoryParams {
  days: number;
  interval: ChartHistoryInterval;
}
export const useTokenPriceHistoryQuery = (
  firstTokenSymbol: QueryOptional<string>,
  secondTokenSymbol?: QueryOptional<string>,
  params?: TokenPriceHistoryParams,
) => {
  const pairPriceHistoryQuery = useQuery(
    ['pair-price-history', firstTokenSymbol, secondTokenSymbol, params?.days, params?.interval],
    fetchTokenPairPriceHistory,
    { ...DEFAULT_QUERY_OPTIONS, enabled: !!(firstTokenSymbol && secondTokenSymbol), retry: 1 },
  );

  const singlePriceHistoryQuery = useQuery(
    ['single-price-history', firstTokenSymbol, params?.days, params?.interval],
    fetchSingleTokenPriceHistory,
    { ...DEFAULT_QUERY_OPTIONS, enabled: !!firstTokenSymbol && !secondTokenSymbol, retry: 1 },
  );

  return { pairPriceHistoryQuery, singlePriceHistoryQuery };
};
