import { useQuery } from '@tanstack/react-query';
import { useSDK } from '../../../../services/sdk/useSDK';
import { useTransaction } from '../../../transaction/useTransaction';
import { getAllPools } from '../services/AllPoolsService';
import { transformAllPools } from '../transformers/AllPoolsTransformers';
import { useTokenBucketQuery } from '../../../token/bucket/query/useTokenBucketQuery';
import { useNativeTokenQuery } from '../../../token';

export const useAllPoolsQuery = () => {
  const sdk = useSDK();
  const { finalisedTxCount } = useTransaction();
  const { isFirstPoolTokenLeader } = useTokenBucketQuery();
  const {
    nativeAssetsQuery: { data: assets },
  } = useNativeTokenQuery();

  const allPoolsQuery = useQuery(['all-pools', finalisedTxCount], getAllPools(sdk), {
    select: transformAllPools(assets, isFirstPoolTokenLeader),
    enabled: !!assets,
    keepPreviousData: true,
  });

  return { allPoolsQuery };
};
