import { useEffect } from 'react';

import { useCopyToClipboard } from '../../hooks';
import { TestId } from '../../types';
import { Text } from '../Text/Text';
import { Container } from '../Container/Container';
import { Tooltip } from '../Tooltip/Tooltip';
import { shortenAddress } from '../../utils';

export interface ShortAddressProps extends TestId {
  address: string;
  id?: string;
  withTooltip?: boolean;
  separator?: string;
  charsCount?: number;
  leftOffset?: boolean;
}

const COPY_MESSAGE_TIMEOUT = 2000;

export function ShortAddress({
  'data-testid': testId = 'shortAddress',
  address,
  id,
  withTooltip = true,
  separator,
  charsCount = 4,
  leftOffset = true,
}: ShortAddressProps) {
  const { isCopied, copyToClipboard, resetIsCopied } = useCopyToClipboard(address);

  useEffect(() => {
    if (isCopied) {
      setTimeout(resetIsCopied, COPY_MESSAGE_TIMEOUT);
    }
  }, [isCopied, resetIsCopied]);

  const shortAddress = shortenAddress(address, charsCount, separator);

  return withTooltip ? (
    <Tooltip
      id={id || address}
      onClick={copyToClipboard}
      data-testid={testId}
      leftOffset={leftOffset}
      className="cursor-pointer"
      tooltipContent={
        <Container alignItems="center" data-testid={`${testId}-content`}>
          <Text color="inverted">{address}</Text>
          <Text
            color="inverted"
            className="ml-2"
            weight="semibold"
            data-testid={`${testId}-copy`}
            id={`address.tooltip.${isCopied ? 'copied' : 'copy'}`}
          />
        </Container>
      }
      inPortal
    >
      {shortAddress}
    </Tooltip>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{shortAddress}</>
  );
}
