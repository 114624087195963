import { Button } from '../../Button/Button';
import cls from 'classnames';
import { Timeframe } from './Timeframe';

interface TimeframeButtonProps {
  timeframe: Timeframe;
  onClick: () => void;
  isActive: boolean;
}

export const TimeframeButton = ({ timeframe, onClick, isActive }: TimeframeButtonProps) => {
  return (
    <Button
      variant="base"
      className={cls(
        'mr-2 font-body-xs text-secondary pt-[2px] w-[24px] h-[24px] flex align-center justify-center _highlight-accent',
        isActive
          ? 'text-highlight border-highlight border-1'
          : 'hover:bg-widget hover:text-neutral',
      )}
      onClick={onClick}
    >
      {timeframe.key}
    </Button>
  );
};
