import { Decimal } from 'decimal.js';
import { StashChannel, StashToken } from '../../../../services';
import { WithdrawalFeeInfo } from '../../Bridge';
import { GetAdaptiveFee } from '../../../fee/adaptive/AdaptiveTxFee';

interface TransformFeeData {
  channel: StashChannel | null;
  asset: StashToken | null;
  tokens: StashToken[] | null;
  getAdaptiveFee: GetAdaptiveFee;
}

export const transformWithdrawalFee =
  ({ tokens, channel, asset, getAdaptiveFee }: TransformFeeData) =>
  (mgxFee: string | null): WithdrawalFeeInfo | null => {
    const fee = getAdaptiveFee(mgxFee);

    if (!fee || !channel || !asset || !tokens || !fee.current) {
      return null;
    }

    const destinationFeeAsset = tokens?.find(
      (token) => token.symbol === asset.symbol && token.channelId === channel.channelId,
    );
    if (!destinationFeeAsset) {
      return null;
    }

    const fps = destinationFeeAsset.feePerSec === '' ? '0' : destinationFeeAsset.feePerSec;

    const feePerSec = new Decimal(fps);
    const exponent = new Decimal(`1e${destinationFeeAsset.decimals}`);

    const destinationFee = feePerSec
      .mul(channel.xcmTransferWeight)
      .div(1000000000000)
      .div(exponent);

    return {
      origin: fee.current,
      destination: {
        amount: destinationFee.toString(),
        asset: destinationFeeAsset,
      },
      raw: {
        feeData: fee,
        destination: {
          amount: destinationFee.mul(`1e${destinationFeeAsset.decimals}`).toString(),
          asset: destinationFeeAsset,
        },
      },
    };
  };
