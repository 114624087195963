import { ClassName, EnvConfig, Transaction } from 'core';
import cls from 'classnames';
import { TxStatus } from 'core';
import { Spinner, Container, IconButton } from 'ui';
import { TxModalContent } from './TxModalContent/TxModalContent';
import { TxModalStatus } from './TxModalStatus/TxModalStatus';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useTx } from '../useTx';
import { getSpinnerStatus } from '../helpers';

interface TxProps extends ClassName {
  tx: Transaction;
}

export function TxNotification({ tx, className }: TxProps) {
  const { hide } = useTx();

  const isPending = tx.status === TxStatus.Pending || tx.status === TxStatus.Confirming;
  const isError = tx.status === TxStatus.Error;
  const isSuccess = tx.status === TxStatus.Success;

  const closeTx = () => {
    hide(tx.id);
  };

  const CONTAINER_BG = cls(
    'shadow-xl',
    isError && EnvConfig.isKusamaEnv ? 'bg-alert-no-opacity' : 'bg-widget',
  );

  const CONTAINER_LAYOUT = 'pl-4 pr-4 py-3 min-w-[300px] min-h-[60px] w-full rounded-full';

  return (
    <Container
      alignItems="center"
      justifyContent="space-between"
      inline
      className={cls(CONTAINER_BG, CONTAINER_LAYOUT, className)}
      data-testid={`tx-notification-${tx.type}-${tx.status}`}
    >
      <Container fullWidth alignItems="center">
        <Container alignItems="center">
          <Spinner state={getSpinnerStatus(tx.status)} />
        </Container>
        <Container fullWidth column className="ml-4">
          {(isPending || isSuccess) && <TxModalContent tx={tx} />}
          <TxModalStatus tx={tx} />
        </Container>
      </Container>
      {isError && <IconButton onClick={closeTx} Icon={CloseIcon} />}
    </Container>
  );
}
