import { Outlet } from 'react-router';
import { Container, Text } from 'ui';

export function TokensPage() {
  return (
    <Container className="pt-[120px] max-h-[100vh]" column data-testid="tokens-page">
      <Text type="display" className="text-center mt-4 pb-0 px-4" id="tokens.page.title" />
      <Text
        type="body-l"
        className="text-center mt-3 px-4"
        color="secondary"
        id="tokens.page.desc"
      />
      <Outlet />
    </Container>
  );
}
