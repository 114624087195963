import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { QueryOptional, useApi } from '../../../../../services';
import { executeDelegationRequest } from '../services/executeDelegationRequestService';
import { useAccounts } from '../../../../account';
import { useWallet } from '../../../../wallet';

export const useExecuteDelegationRequestMutation = (collatorAddress: QueryOptional<string>) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { transactionStore } = useTransaction();

  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();

  const executeDelegationRequestMutation = useMutation(
    ['executeDelegationRequest'],
    executeDelegationRequest(
      api,
      selectedAccount?.address,
      collatorAddress,
      wallet,
      transactionStore,
    ),
  );

  return { executeDelegationRequestMutation };
};
