import { ProvideLiquidityWidget, ProvideLiquidityWidgetType } from 'modules';
import { useNavigate } from 'react-router';
import { PoolWithRatio } from 'core';

export const CreatePoolPage = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(`/pools`);
  };

  const handleSuccess = (pool: PoolWithRatio) => {
    navigate(`/pools/${pool.symbols.join('-')}`);
  };

  return (
    <ProvideLiquidityWidget
      type={ProvideLiquidityWidgetType.Create}
      title="pool.create.title"
      onCancel={handleCancel}
      onSuccess={handleSuccess}
      className="mt-10"
      data-testid="create-liquidity-widget"
    />
  );
};
