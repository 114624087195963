import { StorageKey } from '@polkadot/types';
import { Codec } from '@polkadot/types-codec/types';
import { AssetRegistryAssetMetadata } from '../../../../services';

export const transformAssetMetadata = (
  data: Array<[StorageKey, Codec]> | null,
): AssetRegistryAssetMetadata[] | null => {
  if (data === null) {
    return null;
  }

  const tokens = data
    .map(([storageKey, codec]) => {
      const key = storageKey.toHuman();
      const id = Array.isArray(key) ? key[0]?.toString() : null;

      const metadata = codec.toHuman() as Omit<AssetRegistryAssetMetadata, 'id'>;

      if (!id || !metadata) {
        return null;
      }

      return {
        id: id || storageKey.toString(),
        ...metadata,
      };
    })
    .filter(Boolean) as AssetRegistryAssetMetadata[];

  return tokens.length === 0 ? null : tokens;
};
