import type { LimitedReserveTransferAssetsParams } from './types';

type LimitedReserveTransferPallet = 'polkadotXcm' | 'xcmPallet';

export async function limitedReserveTransferAssets(
  { api, asset, destination, weightLimit, beneficiary }: LimitedReserveTransferAssetsParams,
  pallet: LimitedReserveTransferPallet = 'polkadotXcm',
) {
  return api.tx[pallet].limitedReserveTransferAssets(
    destination,
    beneficiary,
    asset,
    0,
    weightLimit,
  );
}
