import { FormattedMessage } from 'react-intl';
import { Button, Container, Text } from 'ui';
import { ReactComponent as NoResultImage } from 'images/search-clouds.svg';

interface PoolsSearchListEmptyProps {
  onCreatePoolClick: () => void;
}

export const PoolsSearchListEmpty = ({ onCreatePoolClick }: PoolsSearchListEmptyProps) => {
  return (
    <Container column alignItems="center" justifyContent="center" className="w-full py-10">
      <NoResultImage className="w-[64px] h-auto " />
      <Text type="title-2" id="pools.page.search.empty.title" className="mt-5" />
      <Text
        color="secondary"
        id="pools.page.search.empty.desc"
        className="mt-2 whitespace-pre-line text-center"
      />
      <Button onClick={onCreatePoolClick} className="mt-5">
        <FormattedMessage id="pools.page.search.empty.cta" />
      </Button>
    </Container>
  );
};
