import { ReactComponent as WalletIcon } from 'icons/empty-wallet.svg';
import { InjectedWindowProvider, QueryOptional, TestId } from 'core';
import { Container, Text } from 'ui';
import { WalletCard } from '../WalletCard/WalletCard';

interface InstallWalletsProps extends TestId {
  onSelect: (wallet: string) => void;
  walletData: QueryOptional<Map<string, InjectedWindowProvider>>;
}
export function InstalledWallets({
  walletData,
  onSelect,
  'data-testid': testId = 'installedWallets',
}: InstallWalletsProps) {
  const wallets = Array.from(walletData?.entries() ?? []);

  const renderEmptyContent = () => (
    <Container
      alignItems="center"
      column
      fullWidth
      className="rounded-lg bg-widget px-4 py-10"
      data-testid={testId}
    >
      <WalletIcon className="w-[40px] h-auto stroke-icon-neutral" />
      <Container column fullWidth alignItems="center" className="text-center mt-4 px-4">
        <Text type="title-3" id="wallet.connect.empty.title" />
        <Text color="secondary" id="wallet.connect.empty.description" className="mt-2" />
      </Container>
    </Container>
  );

  const renderWallets = () => {
    return (
      <Container column fullWidth className="px-2" data-testid={testId}>
        <Text
          id="wallet.connect.installedWallets"
          type="title-4"
          color="secondary"
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4 w-full">
          {wallets.map(([name]) => (
            <WalletCard
              key={name}
              name={name}
              onClick={onSelect}
              data-testid={`${testId}-walletCard`}
            />
          ))}
        </div>
      </Container>
    );
  };
  return wallets.length === 0 ? renderEmptyContent() : renderWallets();
}
