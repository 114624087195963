import { useUserStore } from '../user/useUserStore';
import { useWalletQuery } from './query/useWalletQuery';

export function useWallet() {
  const { selectedWallet, setSelectedWallet } = useUserStore();
  const { listWalletsQuery, selectedWalletQuery } = useWalletQuery(selectedWallet);

  return {
    listWalletsQuery,
    selectedWalletQuery,
    selectedWallet,
    setSelectedWallet,
  };
}
