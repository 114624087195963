import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, BN_ZERO, fromBN } from '@mangata-finance/sdk';
import { useAssets, useDelegators, useInvestedPoolFromPath, useReserves } from 'core';
import { isEmpty } from 'lodash-es';
import { Container } from 'ui';
import { StakeCtaCard } from '../../components';
import { StakingCard } from '../../components/StakingCard/StakingCard';
import { EmptyStaking } from '../empty/EmptyStaking';

export function PositionDetailStaking() {
  const {
    delegatorStateQuery: { data: delegator, isLoading },
  } = useDelegators();

  const { assetsMap } = useAssets();

  const { pool } = useInvestedPoolFromPath();
  const lpAsset = assetsMap.get(pool?.liquidityTokenId.toString() ?? '');

  const { totalBalance } = useReserves(lpAsset?.id);

  const delegations = delegator?.delegations.filter((d) =>
    d.liquidityToken.eq(pool?.liquidityTokenId),
  );

  const requests = delegator?.requests.requests;

  const renderDelegations = () => {
    return delegations?.map((d) => {
      return (
        <StakingCard requests={requests} key={d.owner.toString()} delegation={d} className="mb-6" />
      );
    });
  };

  return (
    <Container fullWidth column>
      {!isLoading && isEmpty(delegations) && <EmptyStaking />}
      {renderDelegations()}
      {totalBalance?.gt(BN_ZERO) && !isEmpty(delegations) && (
        <StakeCtaCard
          balance={fromBN(totalBalance, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
          symbol={lpAsset?.symbol ?? ''}
        />
      )}
    </Container>
  );
}
