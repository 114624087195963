import { BN, BN_ZERO } from '@polkadot/util';
import { useMemo } from 'react';
import { useMangataAsset } from '../../token';
import { use3rdPartyRewards } from '../3rdParty';
import { useNativeRewards } from '../native';

export const useAllRewards = () => {
  const { mangataAsset } = useMangataAsset();
  const {
    thirdPartyRewardsAmountQuery: { data: rewardsAmount },
  } = use3rdPartyRewards();

  const { rewardQueriesByLpId } = useNativeRewards();

  const rewards = useMemo(() => {
    const rewards = new Map<string, BN>();

    const nativeRewardsAmount = Object.values(rewardQueriesByLpId).reduce((acc, { data }) => {
      return acc.add(data ?? BN_ZERO);
    }, BN_ZERO);

    if (nativeRewardsAmount.gt(BN_ZERO) && mangataAsset) {
      rewards.set(mangataAsset.id, nativeRewardsAmount);
    }

    const poolRewards = Array.from(rewardsAmount?.entries() ?? []);

    poolRewards
      .map(([, rewards]) => {
        return Array.from(rewards.entries());
      })
      .flat()
      .forEach(([id, amount]) => {
        const newRewardsAmount = new BN(amount.toString());

        if (newRewardsAmount.gt(BN_ZERO)) {
          if (rewards.has(id)) {
            const existingRewards = rewards.get(id) ?? '0';
            rewards.set(id, new BN(existingRewards).add(newRewardsAmount));
          }

          rewards.set(id, newRewardsAmount);
        }
      });

    return rewards;
  }, [mangataAsset, rewardQueriesByLpId, rewardsAmount]);

  return rewards;
};
