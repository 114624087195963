import { Container, Text, Toggle } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route.svg';
import { ReactComponent as SlippageIcon } from 'assets/icons/slippage.svg';
import { useSwapStore } from '../../store/useSwapStore';

interface SwapWidgetSettingsProps {
  onClose: () => void;
}

export const SwapWidgetSettings = ({ onClose }: SwapWidgetSettingsProps) => {
  const { maxSlippagePerc, isAutoRoutingEnabled, setRoutingEnabled } = useSwapStore();

  return (
    <Container column className="pb-4">
      <Container alignItems="center" fullWidth justifyContent="space-between">
        <Text type="title-1" id="swap.widget.settings.title" />
        <CloseIcon
          className="w-12 h-auto fill-icon-secondary p-3 cursor-pointer"
          onClick={onClose}
        />
      </Container>
      <Container className="mt-8" alignItems="center" justifyContent="space-between" fullWidth>
        <Container>
          <SlippageIcon className="w-6 h-auto fill-icon-secondary mr-3 self-start" />
          <Container column>
            <Text type="title-3" id="swap.widget.settings.slippage.title" className="mb-1" />
            <Text
              type="body-m"
              color="secondary"
              id="swap.widget.settings.slippage.desc"
              className="whitespace-pre-line"
            />
          </Container>
        </Container>
        <Text
          type="body-m"
          id="swap.widget.settings.slippage.value"
          className="whitespace-nowrap self-start"
          values={{ percentage: maxSlippagePerc }}
        />
      </Container>
      <Container className="mt-8" alignItems="center" justifyContent="space-between" fullWidth>
        <Container>
          <RouteIcon className="w-6 h-auto stroke-icon-secondary mr-3 self-start" />
          <Container column>
            <Text type="title-3" id="swap.widget.settings.routing.title" className="mb-1" />
            <Text
              type="body-m"
              color="secondary"
              id="swap.widget.settings.routing.desc"
              className="whitespace-pre-line"
            />
          </Container>
        </Container>
        <Toggle value={isAutoRoutingEnabled} onChange={setRoutingEnabled} className="self-start" />
      </Container>
    </Container>
  );
};
