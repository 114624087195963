import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { Container } from '../../Container/Container';
import { Text } from '../../';

export type BetaBadgeProps = ClassName & TestId;

export function BetaBadge({ className, 'data-testid': testId }: BetaBadgeProps) {
  return (
    <Container
      inline
      alignItems="center"
      justifyContent="center"
      data-testid={testId}
      className={cls(
        'rounded-full py-[2px] px-2 bg-accent/[0.1] border border-1 border-highlight',
        className,
      )}
    >
      <Text type="label" color="accent" weight="black">
        BETA
      </Text>
    </Container>
  );
}
