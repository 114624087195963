import { AdaptiveTxFee, PoolWithShare } from 'core';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Text,
  TokenIcon,
  FormatAmount,
  Tooltip,
  WidgetDetailRow,
  Skeleton,
  AmountTooltip,
} from 'ui';
import { useRemoveLiquidityStore } from '../store/useRemoveLiquidityStore';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { RemoveLiquidityFormError } from '../RemoveLiquidity';

interface RemoveLiquidityDetailsProps {
  pool: PoolWithShare;
  estFirstAmount: string;
  estSecondAmount: string;
  feeData: AdaptiveTxFee['current'] | null;
  isFeeLoading: boolean;
}

export const RemoveLiquidityDetails = ({
  pool,
  estFirstAmount,
  estSecondAmount,
  feeData,
  isFeeLoading,
}: RemoveLiquidityDetailsProps) => {
  const { formError } = useRemoveLiquidityStore();
  const isFeeInsufficient = formError[RemoveLiquidityFormError.InsufficientFee];

  const Fee = feeData && (
    <Text type="label" color={isFeeInsufficient ? 'alert' : 'primary'}>
      ≈
      <FormatAmount
        value={feeData?.amount ?? ''}
        options={{ precision: 3, maxChars: 20 }}
        className="mx-1"
        data-testid="removeLiq-fee-value"
      />
      {feeData?.symbol || ''}
    </Text>
  );

  const renderFee = () => {
    switch (true) {
      case feeData && isFeeInsufficient:
        return (
          <Tooltip
            tooltipContent={<FormattedMessage id="pool.provide.fee.insufficient" />}
            className="flex items-center"
            inPortal
            leftOffset={false}
          >
            {Fee}
            <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-icon-alert'} />
          </Tooltip>
        );
      case !feeData && isFeeLoading:
        return (
          <Skeleton height="14px" width="70px" className={isFeeInsufficient ? 'my-[5px]' : ''} />
        );

      case !!feeData:
        return Fee;

      default:
        return (
          <Text type="label" color="secondary">
            -
          </Text>
        );
    }
  };

  return (
    <Container column className="mt-6" fullWidth>
      <Text id="position.remove.widget.receive.label" type="title-4" color="secondary" />
      <Container alignItems="center" justifyContent="space-between" className="mt-4" fullWidth>
        <Container alignItems="center">
          <TokenIcon token={pool.firstAsset} />
          <Text className="ml-2">{pool.firstAsset.symbol}</Text>
        </Container>
        <Text>
          <AmountTooltip
            value={estFirstAmount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
            id={`remove-amount-${pool.firstAsset.id}`}
          />
          {pool.firstAsset.symbol}
        </Text>
      </Container>
      <Container alignItems="center" justifyContent="space-between" className="mt-4 mb-2" fullWidth>
        <Container alignItems="center">
          <TokenIcon token={pool.secondAsset} />
          <Text className="ml-2">{pool.secondAsset.symbol}</Text>
        </Container>
        <Text>
          <AmountTooltip
            value={estSecondAmount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
            id={`remove-amount-${pool.secondAsset.id}`}
          />
          {pool.secondAsset.symbol}
        </Text>
      </Container>
      <WidgetDetailRow
        label={
          <Text
            id="pool.provide.details.pool.fee"
            type="label"
            color={isFeeInsufficient ? 'alert' : 'secondary'}
            className="mr-2"
          />
        }
        value={renderFee()}
        data-testid="fee"
      />
    </Container>
  );
};
