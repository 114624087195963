import { Asset } from 'core';
import { useRemoveStakeStore } from './store/useRemoveStakeStore';

export const useRemoveStakeInputs = (selectedAsset: Asset | undefined | null) => {
  const { amount, setAmount } = useRemoveStakeStore();

  const handleAmountChange = (value: number | null) => {
    setAmount(value || 0);
  };

  return {
    amount,
    handleAmountChange,
  };
};
