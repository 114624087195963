import { useAssets, useTokenStats } from 'core';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'ui';
import { TokenDetailChart } from './chart/TokenDetailChart';
import { TokenDetailHeader } from './header/TokenDetailHeader';
import { TokenDetailPools } from './pools/TokenDetailPools';

export function TokenDetail() {
  const { assetsMap, symbolIdMap } = useAssets();
  const { symbol } = useParams<{ symbol: string }>();
  const navigate = useNavigate();

  const asset = assetsMap.get(symbolIdMap.get(symbol ?? '') ?? '');

  const {
    tokenStatsDetailQuery: { data: stats },
  } = useTokenStats(asset?.symbol);

  useEffect(() => {
    if (assetsMap.size === 0) return;

    if (!asset) {
      navigate('/tokens');
    }
  }, [asset, assetsMap, navigate]);

  return (
    <Container column fullWidth className="mt-[120px]" data-testid="token-details">
      <TokenDetailHeader asset={asset} stats={stats} />
      <TokenDetailChart asset={asset} />
      <TokenDetailPools asset={asset} />
    </Container>
  );
}
