import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { ReactComponent as ExpandIcon } from 'icons/expand.svg';
import { TestId } from 'core';

interface ConnectWalletProps extends TestId {
  onToggle: () => void;
}
export function ConnectWallet({ onToggle, 'data-testid': testId }: ConnectWalletProps) {
  const OPEN_WALLET_CLASS = cls(
    'transition-all uppercase ml-0',
    'group-hover:opacity-100 group-hover:w-min group-hover:ml-3 group-hover:w-[40px] duration-300 w-0 opacity-0',
  );

  return (
    <Container
      data-testid={`${testId}-connect`}
      alignItems="center"
      onClick={onToggle}
      className={cls('transition-all cursor-pointer h-full _highlight-accent')}
    >
      <div className="mr-3 h-[20px] w-[20px]">
        <InfoIcon className="fill-icon-highlight w-[20px] h-[20px]" />
      </div>
      <Text
        color="highlight"
        id="wallet.connect"
        className="whitespace-nowrap mr-5 cursor-default"
      />

      <Container data-testid={`${testId}-expand`} alignItems="center">
        <div className="h-[16px] w-[16px]">
          <ExpandIcon className="fill-icon-secondary w-[16px] h-[16px]" />
        </div>
        <Text color="secondary" id="wallet.open" className={OPEN_WALLET_CLASS} />
      </Container>
    </Container>
  );
}
