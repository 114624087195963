import cls from 'classnames';
import { ClassName } from 'core';
import { ReactComponent as LightningIcon } from 'icons/lightning.svg';

interface BlinkingBoltProps extends ClassName {
  size?: 's' | 'm' | 'l';
}

const SIZE_MAP = {
  s: 'w-[12px]',
  m: 'w-[16px]',
  l: 'w-[20px]',
};

const SIZE_HEIGHT_MAP = {
  s: 'h-[12px]',
  m: 'h-[16px]',
  l: 'h-[20px]',
};

export function BlinkingBolt({ className, size }: BlinkingBoltProps) {
  return (
    <div
      className={cls(
        'relative mr-2',
        SIZE_MAP[size ?? 'm'],
        SIZE_HEIGHT_MAP[size ?? 'm'],
        className,
      )}
    >
      <LightningIcon
        className={cls(
          '_highlight-accent absolute fill-icon-highlight h-auto animate-shine blur-sm opacity-80',
          SIZE_MAP[size ?? 'm'],
        )}
      />
      <LightningIcon
        className={cls('absolute fill-icon-highlight h-auto animate-scale', SIZE_MAP[size ?? 'm'])}
      />
    </div>
  );
}
