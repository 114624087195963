import cls from 'classnames';
import { Asset, ClassName, EnvConfig, TestId, isAmountGtZero } from 'core';
import { FormattedMessage } from 'react-intl';
import { Button, Container, FormatAmount, Text, TokenIcon } from 'ui';
import { ReactComponent as StakingIcon } from 'icons/staking.svg';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';
export interface StartStakingWidgetBannerProps extends ClassName, TestId {
  asset?: Asset | null;
  currentStakeAmount?: string | null;
  positionId?: string | null;
  onOpen: () => void;
}

export const StartStakingWidgetBanner = ({
  asset,
  currentStakeAmount,
  positionId,
  onOpen,
  'data-testid': testId = 'stake-widget-banner',
}: StartStakingWidgetBannerProps) => {
  if (isAmountGtZero(currentStakeAmount) && asset && positionId) {
    return (
      <Container
        className={cls(
          'rounded-lg p-5',
          'bg-gradient-to-r from-icon-accent/[.4] from-5% via-panel/[0.1] via-80% to-panel/[0.1] to-100%',
        )}
        alignItems="center"
        justifyContent="space-between"
        data-testid={`${testId}-staked`}
        fullWidth
      >
        <Container alignItems="center">
          <TokenIcon token={asset} className=" mr-4" type="diagonal" />
          <Container column>
            <Text id="staking.new.banner.myStake" color="secondary" className="" />
            <Text className="whitespace-pre-line mr-5" type="title-2">
              <FormatAmount
                value={currentStakeAmount}
                options={{ precision: 3, nonZeroPrecision: true }}
              />{' '}
              {asset.symbol}
            </Text>
          </Container>
        </Container>
        <Button
          data-testid={`${testId}-manage-position`}
          variant="base"
          element="link"
          to={`/positions/${asset.symbol.replace(/\//g, '-')}`}
          className="text-secondary p-2"
        >
          <Text color="secondary" id="staking.new.banner.manage" />
          <ArrowRight className="stroke-icon-secondary w-[18px] h-[18px] ml-2" />
        </Button>
      </Container>
    );
  }

  return (
    <Container
      className={cls(
        'rounded-lg p-6 _highlight-accent',
        EnvConfig.isRollupEnv ? 'bg-highlight/[.2]' : 'bg-accent/[.2]',
      )}
      alignItems="center"
      justifyContent="space-between"
      data-testid={`${testId}-new`}
      fullWidth
    >
      <Container alignItems="center">
        <StakingIcon className="fill-icon-highlight stroke-icon-highlight w-[34px] h-[34px] mr-4" />
        <Text id="staking.new.banner.title" className="whitespace-pre-line mr-5" />
      </Container>
      <Button size="s" onClick={onOpen} className="font-bold" data-testid={`${testId}-new-cta`}>
        <FormattedMessage id="staking.new.banner.cta" />
      </Button>
    </Container>
  );
};
