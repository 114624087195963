import { useQuery } from '@tanstack/react-query';
import { useReservesForTx } from '../../../balance';
import { TxType, useAccounts, useAdaptiveFee, useInternalBalance } from '../../../../';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../services';
import { getActivateLiquidityFor3rdPartyRewardsFee } from '../services/activateLiqFor3rdPartyRewardsFeeService';

export const useActivateLiqudityFor3rdPartyRewardsFeeQuery = (
  liquidityId: QueryOptional<string>,
  rewardsTokenId: QueryOptional<string>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { sources } = useReservesForTx(TxType.ActivateLPFor3rdPartyRewards, liquidityId);
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const activateLiquidityFor3rdPartyRewardsFeeQuery = useQuery(
    ['activate-3rd-party-liquidity-fee', liquidityId, selectedAccount?.address, rewardsTokenId],
    getActivateLiquidityFor3rdPartyRewardsFee(api, sources),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!liquidityId &&
        !!rewardsTokenId &&
        !!balances &&
        !!sources,
    },
  );

  return { activateLiquidityFor3rdPartyRewardsFeeQuery };
};
