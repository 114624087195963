import { FormattedMessage } from 'react-intl';
import { Container, Text, Button, TokenInput } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useStartStakingInputs } from './useStartStakingInputs';
import { StartStakingUIStoreFormError, useStartStakingStore } from './store/useStartStakingStore';
import { StartStakingWidgetDetails } from './details/StartStakingWidgetDetails';
import { getSubmitMessage, useStartStakingValidation } from './useStartStakingValidation';
import {
  useDelegate,
  Asset,
  isAmountGtZero,
  TestId,
  useReserves,
  useReservesAggregator,
  useReservesForTx,
  TxType,
  TxAsset,
  useSafeTransaction,
  useMetamaskNetwork,
} from 'core';
import { fromBN, toBN } from '@mangata-finance/sdk';
import { useTx } from '../../../Tx';

export interface StartStakingWidgetProps extends TestId {
  collatorAddress: string;
  minStakeAmount: string;
  onClose?: () => void;
  stakedAsset: Asset | null;
  delegatorDelegationCount: number | null;
  candidateDelegationCount: number | null;
}

export const StartStakingWidget = ({
  collatorAddress,
  minStakeAmount,
  stakedAsset,
  onClose,
  delegatorDelegationCount,
  candidateDelegationCount,
  'data-testid': testId = 'new-stake-widget',
}: StartStakingWidgetProps) => {
  const store = useStartStakingStore();
  const { amount, formError, dispose } = store;
  const { handleAmountChange } = useStartStakingInputs(stakedAsset);
  const { totalBalance } = useReserves(stakedAsset?.id);
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, stakedAsset?.id);
  const { reserveSource } = useReservesAggregator(toBN(amount), stakedAsset?.id);
  const { isChainSwitchRequired } = useMetamaskNetwork();

  const { isAllowed } = useTx();
  const { delegateMutation, delegateFeeQuery } = useDelegate(
    collatorAddress,
    stakedAsset,
    isAmountGtZero(amount) ? reserveSource : null,
    candidateDelegationCount,
    delegatorDelegationCount,
  );
  const { isInvalid } = useStartStakingValidation(
    delegateFeeQuery.data?.current,
    stakedAsset,
    minStakeAmount,
    reserveSource,
  );

  const handleSubmit = () => {
    if (!stakedAsset || !reserveSource) return;

    delegateMutation.mutate({
      collatorAddress,
      txAsset: {
        ...stakedAsset,
        amount,
      },
      reserveSource,
      activateLiqSources,
      candidateDelegationCount,
      delegatorDelegationCount,
      onDone: dispose,
    });
  };

  const txAsset: TxAsset | null = stakedAsset ? { ...stakedAsset, amount } : null;
  const feeTxAsset = delegateFeeQuery.data?.current;

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: txAsset ? [txAsset] : [],
      feeTxAsset,
      type: TxType.Swap,
    },
    handleSubmit,
  );

  return (
    <Container
      fullWidth
      className="transition-all bg-widget p-6 rounded-lg"
      alignItems="start"
      justifyContent="start"
      data-testid={`${testId}-container`}
      column
    >
      <Container alignItems="center" fullWidth justifyContent="space-between" className="mb-4">
        <Text type="title-1" id="staking.new.title" />
        <Button
          variant="secondary"
          TrailIcon={CloseIcon}
          onClick={onClose}
          className="py-0"
          data-testid="cancel-button"
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      </Container>
      <TokenInput
        isSelectable={false}
        labelId="staking.new.amount.label"
        selectedToken={stakedAsset}
        onAmountChange={handleAmountChange}
        isInvalid={formError[StartStakingUIStoreFormError.InsufficientFunds]}
        amount={amount}
        freeBalance={totalBalance ? fromBN(totalBalance, stakedAsset?.decimals) : null}
        data-testid={`${testId}-tokenInput`}
      />
      <StartStakingWidgetDetails
        feeData={delegateFeeQuery.data?.current}
        isFeeLoading={delegateFeeQuery.isLoading}
        minStakeAmount={minStakeAmount}
        stakedAsset={stakedAsset}
        data-testid={`${testId}-details`}
      />
      <Container fullWidth className="mt-6">
        <Button
          variant="primary"
          size="l"
          fullWidth
          isDisabled={
            isInvalid ||
            !isAmountGtZero(amount) ||
            !isAllowed(TxType.Stake) ||
            delegateFeeQuery.isLoading
          }
          data-testid={`${testId}-submit`}
          onClick={submitTxWithChecks}
        >
          <FormattedMessage id={getSubmitMessage(store, isChainSwitchRequired)} />
        </Button>
      </Container>
    </Container>
  );
};
