import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as StatusIcon } from 'icons/status.svg';
import { BlinkingBolt } from '../BlinkingBolt/BlinkingBolt';
import { EnvConfig } from 'core';

interface CollatorStateChipProps {
  isActive?: boolean;
}

function renderIcon(isActive: boolean) {
  return isActive ? (
    <BlinkingBolt />
  ) : (
    <div className="relative w-[20px] h-[20px] mr-1">
      <StatusIcon
        className={cls('absolute w-[20px] h-auto fill-icon-warning blur-[3px] opacity-90')}
      />
      <StatusIcon className={cls('absolute w-[20px] h-auto fill-icon-warning')} />
    </div>
  );
}

export function CollatorStateChip({ isActive = false }: CollatorStateChipProps) {
  return (
    <Container
      className={cls(
        'py-1 pl-1 pr-2 rounded _highlight-accent',
        isActive ? (EnvConfig.isRollupEnv ? 'bg-highlight/[.1]' : 'bg-accent/[.1]') : 'bg-warning',
      )}
      justifyContent="center"
      alignItems="center"
    >
      {renderIcon(isActive)}
      <Text
        type="title-4"
        color={isActive ? 'highlight' : 'warning'}
        id={isActive ? 'collator.state.active' : 'collator.state.waiting'}
      />
    </Container>
  );
}
