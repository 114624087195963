import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../../services';
import { BN, BN_ZERO } from '@polkadot/util';
import { ApiPromise } from '@polkadot/api';
import { get3rdPartyLiquidityDeactivationTxs } from '../../deactivateLiqFor3rdPartyRewards/services/deactivateLiqFor3rdPartyRewardsService';

type RemoveLiquidityFeeQueryKey = [
  key: string,
  firstAssetId: QueryOptional<string>,
  secondAssetId: QueryOptional<string>,
  liqId: QueryOptional<string>,
  lpAmount: QueryOptional<string>,
  selectedAddress: QueryOptional<string>,
  active3rdPartyLiquidities: QueryOptional<[string, BN][]>,
];

export const getRemoveLiquidityFee =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<RemoveLiquidityFeeQueryKey>) => {
    const [
      ,
      firstAssetId,
      secondAssetId,
      liqId,
      lpAmount,
      selectedAddress,
      active3rdPartyLiquidities,
    ] = queryKey;

    if (
      !selectedAddress ||
      !api ||
      !firstAssetId ||
      !secondAssetId ||
      !lpAmount ||
      !active3rdPartyLiquidities ||
      !liqId
    ) {
      return null;
    }

    const removeLiqTx = api.tx.xyk.burnLiquidity(firstAssetId, secondAssetId, new BN(lpAmount));

    if (active3rdPartyLiquidities.length > 0) {
      const txs = [
        ...get3rdPartyLiquidityDeactivationTxs(api, liqId, active3rdPartyLiquidities),
        removeLiqTx,
      ];

      const txsFeeInfo = await Promise.all(txs.map((tx) => tx.paymentInfo(selectedAddress)));
      const batchingFeeInfo = await api.tx.utility.batchAll([]).paymentInfo(selectedAddress);

      const totalFee = txsFeeInfo
        .reduce((acc, curr) => acc.add(curr.partialFee), BN_ZERO)
        .add(batchingFeeInfo.partialFee);

      return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    } else {
      const feeInfo = await removeLiqTx.paymentInfo(selectedAddress);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    }
  };
