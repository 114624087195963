import { useQuery } from '@tanstack/react-query';
import { hexToBn } from '@polkadot/util';
import { keyBy } from 'lodash-es';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import { getFeeMetadata } from './FeeMetadataService';
import { TokenId, fromBN } from '@mangata-finance/sdk';
import { TxAsset } from '../../transaction';
import { useApi } from '../../../services/sdk/useApi';
import { EnvConfig } from '../../../envConfig';
import { useInternalBalance } from '../../balance';
import { useAllPoolsQuery } from '../../pool/all/query/useAllPoolsQuery';
import { useMangataAsset } from '../../token';

const MANGATA_TOKEN_ID = EnvConfig.TOKEN_ID;

export const useFeeMetadataQuery = () => {
  const api = useApi();
  const { allPoolsQuery } = useAllPoolsQuery();
  const { internalBalancesQuery } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();

  const poolsById = allPoolsQuery.data?.byId;
  const poolsCount = allPoolsQuery.data?.list.length || 0;

  const feeMetadataQuery = useQuery(['fee-metadata', poolsCount], getFeeMetadata(api), {
    enabled: !!api && poolsCount > 0 && internalBalancesQuery.isSuccess,
  });

  const metadata = feeMetadataQuery.data;
  const mgxThreshold =
    metadata &&
    mangataAsset &&
    fromBN(hexToBn(metadata?.swapValueThreshold), mangataAsset.decimals);
  const mgxLockAmount = metadata?.feeLockAmount
    ? fromBN(hexToBn(metadata?.feeLockAmount), mangataAsset?.decimals)
    : '50';

  const getTokenThreshold = useCallback(
    (tokenId: TokenId) => {
      if (!poolsById || !mgxThreshold) {
        return null;
      }

      if (tokenId === MANGATA_TOKEN_ID) {
        return mgxThreshold;
      }

      const pool = poolsById[`${tokenId}-${MANGATA_TOKEN_ID}`];

      if (pool) {
        const pricePerToken = fromBN(pool.secondTokenRatio, pool.firstAsset.decimals);

        return new Decimal(mgxThreshold).mul(pricePerToken).toString();
      }

      return null;
    },
    [poolsById, mgxThreshold],
  );

  const whitelistedById = useMemo(
    () =>
      keyBy(
        [
          ...(metadata
            ? metadata.whitelistedTokens.map((tokenId: number) => ({
                id: tokenId.toString(),
                threshold: getTokenThreshold(tokenId.toString()),
              }))
            : []),
          { id: MANGATA_TOKEN_ID, threshold: mgxThreshold },
        ],
        'id',
      ),
    [metadata, mgxThreshold, getTokenThreshold],
  );

  const isAmountAboveThreshold = useCallback(
    (firstToken: TxAsset, secondToken: TxAsset) => {
      const firstThreshold = whitelistedById[firstToken.id]?.threshold;
      const secondThreshold = whitelistedById[secondToken.id]?.threshold;

      if ([firstToken.id, secondToken.id].includes(MANGATA_TOKEN_ID)) {
        return (
          (!!firstThreshold &&
            new Decimal(firstToken.amount || 0).gte(new Decimal(firstThreshold))) ||
          (!!secondThreshold &&
            new Decimal(secondToken.amount || 0).gte(new Decimal(secondThreshold)))
        );
      }

      return false;
    },
    [whitelistedById],
  );

  return { feeMetadataQuery, isAmountAboveThreshold, mgxLockAmount };
};
