import { isEmpty, isUndefined } from 'lodash-es';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseSearchOptions<Entry, L = Array<Entry> | null | undefined> {
  list: L;
  toSearch: Array<keyof Entry>;
}

interface UseSearchResult<T, RefEl> {
  searchState: [boolean, (isOpen: boolean) => void];
  query: string[] | undefined;
  entries: T[] | null | undefined;
  isActive: boolean;
  ref: RefObject<RefEl>;
  toggleSearch: () => void;
}

export const useSearch = <Entry, RefEl = HTMLDivElement>(
  options: UseSearchOptions<Entry>,
): UseSearchResult<Entry, RefEl> => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef<RefEl>(null);

  const setIsOpenAndResetValue = (isOpen: boolean) => {
    if (!isOpen) {
      setSearchParams({ search: '' });
    }
    setIsOpen(isOpen);
  };

  const toggleSearch = () => setIsOpen(!isOpen);

  const searchQuery = searchParams.get('search')?.toLowerCase().split(/[ /-]/).filter(Boolean);

  const entries = useMemo(() => {
    if (isUndefined(searchQuery) || isEmpty(searchQuery)) {
      return options.list;
    }

    return options.list?.filter((entry) => {
      const toCompare = options.toSearch.map((key) => String(entry[key]).toLowerCase()).join(' ');
      return searchQuery.every((query) => toCompare.includes(query));
    });
  }, [options.list, options.toSearch, searchQuery]);

  useEffect(() => {
    if (!isEmpty(searchQuery) && !isOpen) {
      setIsOpen(true);
    }
  }, [setIsOpen, searchQuery, isOpen]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, [setIsOpen]);

  return {
    searchState: [isOpen, setIsOpenAndResetValue],
    query: searchQuery,
    entries,
    toggleSearch,
    isActive: !isEmpty(searchQuery),
    ref,
  };
};
