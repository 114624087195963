import { Container, Text, WalletLogo } from 'ui';

import { TestId } from 'core';
import { FormattedMessage } from 'react-intl';

interface WalletCardProps extends TestId {
  name: string;
  onClick(wallet: string): void;
}

export const WalletCard = ({ name, onClick, 'data-testid': testId }: WalletCardProps) => {
  const handleWalletSelect = () => onClick(name);
  const defaultWalletName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <Container
      inline
      justifyContent="space-between"
      alignItems="center"
      column
      data-testid={testId}
      onClick={handleWalletSelect}
      className="group/wallet-card w-full bg-widget hover:bg-base rounded cursor-pointer transition-all"
    >
      <Container column alignItems="center" className="p-4 rounded-lg">
        <WalletLogo
          className="w-7 group-hover/wallet-card:scale-[1.15] transition-all"
          wallet={name}
        />
        <Text type="title-3" className="mt-2 max-w-[100px] text-ellipsis overflow-hidden">
          <FormattedMessage id={`wallet.extension.${name}`} defaultMessage={defaultWalletName} />
        </Text>
      </Container>
    </Container>
  );
};
