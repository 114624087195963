import Decimal from 'decimal.js';
import { useTransition, animated } from '@react-spring/web';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { Container, FormatAmount, Skeleton, Text, TokenIcon } from 'ui';
import {
  CollatorDetailCard,
  StartStakingWidget,
  StartStakingWidgetBanner,
  StakingLimitReachedModal,
} from 'modules';
import {
  useAssets,
  useCollatorApy,
  useCollatorRewards,
  useCollatorStateQuery,
  useDelegatorStateQuery,
} from 'core';
import { calculateMinBond } from '../list/section/CollatorListSection';
import { StakingDetailHeader } from '../header/StakingDetailHeader';
import { useStartStakingStore } from 'modules';
import { EnvConfig } from 'core';
import { BN } from '@polkadot/util';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BoltIcon } from 'icons/lightning.svg';
import { isNull } from 'lodash-es';

export function StakingDetailPage() {
  const { assets } = useAssets();
  const [isStakeWidgetOpen, setIsStakeWidgetOpen] = useState(false);
  const params = useParams();
  const collatorAddress = params?.collator;
  const {
    collatorStateQuery: { data: collatorData },
  } = useCollatorStateQuery(collatorAddress);
  const {
    delegatorStateQuery: { data: delegatorData },
  } = useDelegatorStateQuery();
  const { setLimitReachedModalVisibility } = useStartStakingStore();
  const {
    collatorApyQuery: { data: apyData, isLoading: isApyLoading },
  } = useCollatorApy(collatorAddress);
  const apy = apyData ? apyData.apy : null;

  const { collatorDailyRewardsMap, isLoading: areRewardsLoading } =
    useCollatorRewards(collatorAddress);

  const collatorRewards = useMemo(() => {
    if (!collatorAddress) {
      return null;
    }

    return collatorDailyRewardsMap
      .get(collatorAddress)
      ?.find((entry) => entry.tokenId === collatorData?.liquidityToken.toString());
  }, [collatorAddress, collatorDailyRewardsMap, collatorData?.liquidityToken]);

  const stakedTokenId = collatorData?.liquidityToken;
  const stakedAsset = assets.find((asset) => asset.id === stakedTokenId?.toString()) || null;
  const candidateDelegationCount = collatorData?.delegators?.length || 0;
  const delegatorDelegationCount = delegatorData?.delegations?.length || 0;
  const minStakeAmount =
    collatorData && stakedAsset ? calculateMinBond(collatorData, stakedAsset) : null;
  const currentDelegation = delegatorData?.delegations?.find(
    (delegation) => delegation.owner.toString() === collatorAddress,
  );
  const currentStakeAmount =
    currentDelegation && stakedAsset
      ? fromBN(currentDelegation?.amount.toBn(), stakedAsset.decimals)
      : null;

  const bannerTransition = useTransition(!isStakeWidgetOpen, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 130 },
    leave: { opacity: 0, height: 0 },
  });

  const widgetTransition = useTransition(isStakeWidgetOpen, {
    from: { opacity: 0, maxHeight: 0 },
    enter: { opacity: 1, maxHeight: 600 },
    leave: { opacity: 0, maxHeight: 0 },
  });

  const toggleStakeWidget = () => {
    if (new Decimal(delegatorDelegationCount).lt(EnvConfig.MAX_DELEGATION_COUNT)) {
      setIsStakeWidgetOpen(!isStakeWidgetOpen);
    } else {
      setLimitReachedModalVisibility(true);
    }
  };

  useEffect(() => {
    if (currentDelegation?.amount.gtn(0) && isStakeWidgetOpen) {
      setIsStakeWidgetOpen(false);
    }
  }, [currentDelegation?.amount, isStakeWidgetOpen]);

  const handleClose = () => {
    setIsStakeWidgetOpen(false);
  };

  if (!collatorAddress || !collatorData) {
    return null;
  }

  return (
    <>
      <Container className="pt-[120px] max-h-[100vh]" column>
        <StakingDetailHeader collator={collatorData} className="mb-4" />
        {stakedAsset && (
          <Container fullWidth column className="mt-4 mb-4">
            {bannerTransition((style, visible) =>
              visible ? (
                <animated.div style={style} className="w-full overflow-hidden">
                  <Container fullWidth column>
                    <Text id="staking.detail.label.staking" color="secondary" className="mb-2" />
                    <StartStakingWidgetBanner
                      asset={stakedAsset}
                      currentStakeAmount={currentStakeAmount}
                      positionId="1"
                      onOpen={toggleStakeWidget}
                    />
                  </Container>
                </animated.div>
              ) : null,
            )}
            {widgetTransition((style, visible) =>
              visible ? (
                <animated.div style={style} className="w-full overflow-hidden">
                  <StartStakingWidget
                    stakedAsset={stakedAsset}
                    collatorAddress={collatorAddress}
                    minStakeAmount={minStakeAmount || '0'}
                    candidateDelegationCount={candidateDelegationCount}
                    delegatorDelegationCount={delegatorDelegationCount}
                    onClose={handleClose}
                  />
                </animated.div>
              ) : null,
            )}
          </Container>
        )}
        <Text id="staking.detail.label.collator" color="secondary" className="mb-4" />
        <div className="grid w-full grid-cols-2 gap-4">
          <CollatorDetailCard titleId="staking.detail.stats.apr">
            {isApyLoading ? (
              <Skeleton width="50px" height="14px" rounded className="mt-1" />
            ) : collatorRewards ? (
              <Container alignItems="center">
                {!isNull(apy) ? (
                  <Text type="title-3" color="accent">
                    +{apy} %
                  </Text>
                ) : (
                  '-'
                )}
              </Container>
            ) : (
              '-'
            )}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.rewards">
            {areRewardsLoading ? (
              <Skeleton width="120px" height="14px" rounded className="mt-1" />
            ) : collatorRewards ? (
              <Container alignItems="center">
                <BoltIcon className="w-[16px] h-auto fill-icon-accent" />
                <Text color="accent" type="title-3" className="ml-1">
                  <FormatAmount
                    value={fromBN(
                      new BN(collatorRewards.dailyRewards),
                      BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
                    )}
                    options={{ precision: 3, nonZeroPrecision: false, maxChars: 12 }}
                  />{' '}
                  <FormattedMessage id="common.mainToken" />
                </Text>
              </Container>
            ) : (
              '-'
            )}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.token">
            <Container alignItems="center">
              {stakedAsset && (
                <TokenIcon token={stakedAsset} type="diagonal" className="mr-2" size="xxs" />
              )}
              {stakedAsset?.symbol || '-'}
            </Container>
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.minStake">
            {stakedAsset && (
              <FormatAmount value={minStakeAmount} options={{ precision: 2 }} className="mr-1" />
            )}
            {stakedAsset?.symbol || '-'}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.totalStake">
            {stakedAsset && (
              <FormatAmount
                value={fromBN(collatorData?.bond?.toBn() || 0, stakedAsset.decimals)}
                options={{ precision: 2, nonZeroPrecision: true }}
                className="mr-1"
              />
            )}
            {stakedAsset?.symbol || '-'}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.delegators">
            {candidateDelegationCount}
          </CollatorDetailCard>
        </div>
      </Container>
      <StakingLimitReachedModal />
    </>
  );
}
