import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { CollatorApy, CollatorApyRes } from '../CollatorApy';
import { CollatorApyQueryKey, getCollatorApy } from '../services/collatorApyService';
import { transformCollatorApy } from '../transformers/collatorApyTransformer';

export const useCollatorApyQuery = (address: string | undefined) => {
  const collatorApyQuery = useQuery(['collator-apy', address], getCollatorApy, {
    ...DEFAULT_QUERY_OPTIONS,
    select: transformCollatorApy,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!address,
  });

  return {
    collatorApyQuery,
  };
};

type CollatorApyQueryOptions = UseQueryOptions<
  QueryOptional<[string, CollatorApyRes]>,
  unknown,
  QueryOptional<CollatorApy>,
  CollatorApyQueryKey
>;

export const useCollatorApyQueries = (addresses: string[] | undefined) => {
  const queries: CollatorApyQueryOptions[] = addresses
    ? addresses.map((address) => ({
        ...DEFAULT_QUERY_OPTIONS,
        queryKey: ['collator-apy', address],
        queryFn: getCollatorApy,
        select: transformCollatorApy,
        enabled: !!address,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }))
    : [];

  const collatorApyQueries = useQueries({ queries });

  return {
    collatorApyQueries,
  };
};
