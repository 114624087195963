import Big from 'big.js';
import cls from 'classnames';
import { useIntl } from 'react-intl';
import { fromBN } from '@mangata-finance/sdk';
import { Button, Container, Text, GaslessTag, Banner, formatAmount, WidgetDetailRow } from 'ui';
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'icons/chevron-up.svg';
import { RoutingDetails } from './Routing/RoutingDetails';
import { FeeDetails } from './Fee/FeeDetails';
import { SwapFormError } from '../../useSwapValidation';
import { useSwapStore } from '../../store/useSwapStore';

export function SwapDetails() {
  const intl = useIntl();
  const {
    firstToken,
    secondToken,
    firstAmount,
    secondAmount,
    routeDetails,
    formError,
    isGasless,
    areSwapDetailsOpen,
    setSwapDetailsOpen,
  } = useSwapStore();

  if (!firstToken || !secondToken || !routeDetails?.bestRoute) {
    return null;
  }

  const toggleDetails = () => setSwapDetailsOpen(!areSwapDetailsOpen);
  const minAmountOut = routeDetails?.minAmountOutBN
    ? fromBN(routeDetails?.minAmountOutBN, secondToken?.decimals)
    : null;

  const getRate = () => {
    if (!['', '0'].includes(firstAmount) && !['', '0'].includes(secondAmount)) {
      const firstAmountBN = Big(firstAmount);
      const secondAmountBN = Big(secondAmount);

      return firstAmountBN.gt(0) && secondAmountBN.div(firstAmountBN).toString();
    }

    return null;
  };

  const rate = getRate();
  const isMgxInsufficient = formError === SwapFormError.InsufficientMgx;

  return (
    <Container column fullWidth className="mt-6">
      <Container fullWidth justifyContent="space-between" alignItems="center" className="px-4 ">
        <Container alignItems="center" data-testid="trade-rate">
          <Text id="swap.details.rate" type="label" color="secondary" className="mr-2" />
          {rate && secondToken?.decimals && (
            <Text type="label">
              1 {firstToken?.symbol} ={' '}
              {formatAmount(rate, { precision: 2, nonZeroPrecision: true, maxChars: 18 })}{' '}
              {secondToken?.symbol}
            </Text>
          )}
        </Container>
        <Button
          variant="secondary"
          size="m"
          TrailIcon={areSwapDetailsOpen ? ChevronUpIcon : ChevronDownIcon}
          data-testid="toggle-trade-details"
          onClick={toggleDetails}
        >
          {intl.formatMessage({
            id: areSwapDetailsOpen ? 'swap.details.hide' : 'swap.details.show',
          })}
        </Button>
      </Container>

      <Container
        fullWidth
        column
        className={cls(
          'overflow-hidden transition-all duration-500 ease-in-out',
          areSwapDetailsOpen ? 'max-h-[310px]' : 'max-h-0',
        )}
        data-testid="trade-details"
      >
        <Container fullWidth column className="px-4">
          <WidgetDetailRow
            label={intl.formatMessage({ id: 'swap.details.minimumRecieved' })}
            value={`${formatAmount(minAmountOut, { precision: 3, maxChars: 20 })} ${
              secondToken?.symbol
            }`}
            data-testid="minimumRecieved"
          />
          <WidgetDetailRow
            label={intl.formatMessage({ id: 'swap.details.priceImpact' })}
            value={`${formatAmount((routeDetails?.priceImpact || 0).toString(), {
              precision: 2,
              maxChars: 20,
            })} %`}
            data-testid="priceImpact"
          />
          <WidgetDetailRow
            label={intl.formatMessage({ id: 'swap.details.commission' })}
            value="0.3 %"
            data-testid="commission"
          />
          <WidgetDetailRow
            label={
              <Container alignItems="center">
                <Text
                  id="swap.details.fee"
                  type="label"
                  color={!isGasless && isMgxInsufficient ? 'alert' : 'secondary'}
                  className="mr-2"
                />
                <GaslessTag isActive={isGasless || !isMgxInsufficient} />
              </Container>
            }
            value={<FeeDetails isGasless={isGasless} isInsufficient={isMgxInsufficient} />}
            data-testid="fee"
          />
        </Container>
        <RoutingDetails />
      </Container>
      {formError === SwapFormError.InsufficientMgx && !areSwapDetailsOpen && (
        <Banner
          variant="alert"
          data-testid="form-error-alert"
          className={cls('overflow-hidden transition-all duration-200 mt-6')}
          message={intl.formatMessage({ id: `swap.route.error.${formError}` })}
        />
      )}
    </Container>
  );
}
