import { useEffect, useState } from 'react';
import { Button, Container, BetaBadge, Text } from '../../components';
import { ReactComponent as MangataLogo } from '../../../assets/icons/mangatax-logo.svg';
import { ReactComponent as MagicIcon } from '../../../assets/icons/magic.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/feedback.svg';
import { useUserStore } from 'core';

export const WelcomeBetaOverlay = () => {
  const [isClosed, setIsClosed] = useState(false);
  const { selectedAccount } = useUserStore();

  const handleClose = () => setIsClosed(true);

  useEffect(() => {
    if (selectedAccount && !isClosed) {
      setIsClosed(true);
    }
  }, [selectedAccount, setIsClosed, isClosed]);

  return !isClosed && !selectedAccount ? (
    <Container className="w-full h-[100vh] z-40 absolute bg-base" column alignItems="center">
      <Container alignItems="center" justifyContent="space-between" fullWidth className="pt-8 px-6">
        <Container alignItems="center">
          <MangataLogo className="h-[50px] w-[186px]" />
          <BetaBadge />
        </Container>
      </Container>
      <Container alignItems="center" justifyContent="center" className="h-full" column>
        <Container alignItems="center" className="mb-10">
          <MagicIcon className="w-[64px] h-[64px] mr-9" />
          <Container className="w-[384px]" column>
            <Text type="title-2" className="mb-2">
              Beta Magic in progress
            </Text>
            <Text type="body-l" color="secondary">
              As this is a Beta version, please keep in mind some features are still under
              construction and might not work yet.
            </Text>
          </Container>
        </Container>
        <Container alignItems="center">
          <FeedbackIcon className="w-[64px] h-[64px] mr-9" />
          <Container className="w-[384px]" column>
            <Text type="title-2" className="mb-2">
              Contribute to the evolution
            </Text>
            <Text type="body-l" color="secondary">
              Your insights and experiences are invaluable. If you spot a problem or have a
              suggestion, please tell us! There's a feedback button just for that.
            </Text>
          </Container>
        </Container>
        <Button size="l" onClick={handleClose} className="mt-[64px]">
          Try mangata x beta
        </Button>
      </Container>
    </Container>
  ) : null;
};
