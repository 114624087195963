import { ApiPromise } from '@polkadot/api';
import { useEffect, useState } from 'react';
import { useSDK } from './useSDK';

export const useApi = () => {
  const sdk = useSDK();
  const [api, setApi] = useState<ApiPromise | null>(null);

  useEffect(() => {
    (async function () {
      const _api = await sdk.api();
      setApi(_api);
    })();
  }, [sdk]);

  return api;
};
