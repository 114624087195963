import { PoolWithShare, useAccounts, useInvestedPools } from 'core';
import { isNull } from 'lodash-es';
import { useWalletUIStore } from 'modules';
import { useMemo } from 'react';
import { Container } from 'ui';
import { DisconnectedPositions } from '../disconnected/DisconnectedPositions';
import { EmptyPositions } from '../empty/EmptyPositions';
import { InvestedPositionList } from './invested/InvestedPositionList';
import { PositionListRewards } from './rewards/PositionListRewards';

export function PositionsList() {
  const { data: pools } = useInvestedPools();

  const { selectedAccount } = useAccounts();
  const { toggleSelectWallet } = useWalletUIStore();

  const positions = useMemo(() => pools?.baseList.sort(sortPositionsBySize), [pools?.baseList]);

  const hasLPPositions = positions === undefined ? null : positions.length > 0;

  return (
    <Container column fullWidth alignItems="start" justifyContent="center">
      {!isNull(selectedAccount) ? (
        <>
          <PositionListRewards />
          <InvestedPositionList />
          {hasLPPositions === false && <EmptyPositions />}
        </>
      ) : (
        <DisconnectedPositions onCtaClick={toggleSelectWallet} />
      )}
    </Container>
  );
}

function sortPositionsBySize(a: PoolWithShare, b: PoolWithShare) {
  return b.share.cmp(a.share);
}
