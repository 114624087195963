import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { useSDK } from '../../../../services/sdk/useSDK';
import { submitSwap } from './SwapSubmitService';
import { useWallet } from '../../../wallet';

export const useSwapMutation = () => {
  const transactionStore = useTransactionStore();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();
  const sdk = useSDK();

  const swapMutation = useMutation(submitSwap(sdk, transactionStore, selectedWallet));

  return { swapMutation };
};
