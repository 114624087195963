import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../../services';
import { getPromotedPoolMetadata } from '../services/PromotedPoolMetadataService';

export const usePromotedPoolsMetadataQuery = () => {
  const api = useApi();

  const metadataQuery = useQuery(['promoted-pools-metadata'], getPromotedPoolMetadata(api), {
    enabled: !!api,
  });

  return {
    metadataQuery,
  };
};
