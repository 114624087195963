import axios from 'axios';
import { EnvConfig } from '../../envConfig';
import { StashChannel, StashToken } from './Stash';

export async function fetchTokens(): Promise<StashToken[]> {
  const { data } = await axios.get<StashToken[]>(`${EnvConfig.STASH_URL}/xcm/tokens`);

  return data;
}

export async function fetchChannels(): Promise<StashChannel[]> {
  const { data } = await axios.get(`${EnvConfig.STASH_URL}/xcm/channels`);

  return data;
}
