import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS } from '../../../../services';
import { getNativeAssets } from '../services/tokenMetadataService';
import { useAssetMetadataQuery } from './useAssetMetadataQuery';
import { useRollupTokensQuery } from '../../../rollup';
import { useTradeableTokenIds } from '../../../tokens/trading/query/useTradeableTokens';
import { EnvConfig } from '../../../../envConfig';
import { isUndefined } from 'lodash-es';

export const useNativeTokenQuery = () => {
  const {
    assetMetadataQuery: { data: metadata },
  } = useAssetMetadataQuery();
  const {
    rollupTokensQuery: { data: rollupTokens },
  } = useRollupTokensQuery();
  const {
    tradeableTokenIdsQuery: { data: tradeableTokenIds },
  } = useTradeableTokenIds();

  const nativeAssetsQuery = useQuery(
    ['native-assets', !!rollupTokens],
    getNativeAssets({ metadata, rollupTokens, tradeableTokenIds }),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled:
        (EnvConfig.isKusamaEnv && !isUndefined(metadata)) ||
        (EnvConfig.isRollupEnv &&
          !isUndefined(rollupTokens) &&
          !isUndefined(tradeableTokenIds) &&
          !isUndefined(metadata)),
    },
  );

  return {
    nativeAssetsQuery,
  };
};
