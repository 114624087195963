import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';
import { hexToBn, isHex, BN } from '@polkadot/util';

export type GetInternalBalancesQueryKey = Readonly<
  [queryKey: string, address: string | undefined, txCount: number]
>;

export type InternalBalance = {
  free: BN;
  frozen: BN;
  reserved: BN;
};

export const getInternalBalances =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<GetInternalBalancesQueryKey>) => {
    const [, address] = queryKey;

    if (!api || !address) {
      return null;
    }

    const balancesRawResponse = await api.query.tokens.accounts.entries(address);

    const internalBalances: Array<[string, InternalBalance]> = balancesRawResponse.map(
      ([storageKey, value]) => {
        const free = JSON.parse(JSON.stringify(value)).free.toString();
        const frozen = JSON.parse(JSON.stringify(value)).frozen.toString();
        const reserved = JSON.parse(JSON.stringify(value)).reserved.toString();

        const freeBN = isHex(free) ? hexToBn(free) : new BN(free);
        const frozenBN = isHex(frozen) ? hexToBn(frozen) : new BN(frozen);
        const reservedBN = isHex(reserved) ? hexToBn(reserved) : new BN(reserved);

        const key = storageKey.toHuman();
        const id = Array.isArray(key) ? key[1]?.toString() : null;

        return [
          id || storageKey.toString(),
          {
            free: freeBN.sub(frozenBN),
            frozen: frozenBN,
            reserved: reservedBN,
          },
        ];
      },
    );

    return new Map(internalBalances);
  };
