import cls from 'classnames';
import { Container, Modal } from 'ui';
import { useMetamaskNetwork, useModalStore, useTransactionStore } from 'core';
import { DepositUIState, useDepositStore } from '../store/useDepositStore';
import { useEffect, useRef } from 'react';
import { useRollupDepositState } from './useRollupDepositState';
import { getChainIconUrl } from '../components/ChainSelector/ChainSelector';
import { TokenSelect } from '../components/TokenSelect/TokenSelect';
import { animated } from '@react-spring/web';
import { DepositDefaultContent } from '../components/DepositDefaultContent/DepositDefaultContent';
import { Asset } from 'core';
import { useRollupDepositTransitions } from './useRollupDepositTransitions';
import { DepositTxInfo } from '../components/DepositTxInfo/DepositTxInfo';
import { DepositSuccess } from '../components/DepositSuccess/DepositSuccess';
import { isNil } from 'lodash-es';

export function RollupDeposit() {
  const { isDepositOpen, closeDeposit } = useModalStore();
  const { remove } = useTransactionStore();
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    depositTokens,
    depositBalances,
    approveTx,
    depositTx,
    accounts,
    isDepositing,
    isDepositReady,
    isApproveInProgress,
    submitDeposit,
    freeBalance,
  } = useRollupDepositState();

  const {
    containerSpring,
    defaultStateTransition,
    txInfoTransition,
    successTransition,
    selectTokenTransition,
  } = useRollupDepositTransitions(contentRef, approveTx, depositTx);
  const { defaultChain } = useMetamaskNetwork();

  const { chain, setChain, dispose, setAsset, setUIState } = useDepositStore();

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  useEffect(() => {
    if (defaultChain && isNil(chain?.id)) {
      setChain(
        {
          id: defaultChain.chainId,
          title: defaultChain.name,
          icon: getChainIconUrl(defaultChain.chainId),
        },
        false,
      );
    }
  }, [defaultChain, chain?.id, setChain]);

  const handleTokenSelectClick = () => {
    setUIState(DepositUIState.SelectToken);
  };

  const handleTokenSelect = (asset: Asset) => {
    setAsset(asset);
    setUIState(DepositUIState.Default);
  };

  const handleClose = () => {
    closeDeposit();
    remove(approveTx?.id);
    remove(depositTx?.id);
  };

  return (
    <Modal
      isCloseIconVisible={false}
      isClosableOnOverlayClick={false}
      isOpen={isDepositOpen}
      onClose={handleClose}
      className={cls('w-[480px] py-6 overflow-hidden')}
      overlayClassName="bg-base/80"
      data-testid="deposit-modal"
    >
      <animated.div style={containerSpring} className="relative w-full">
        {txInfoTransition((style, visible) =>
          visible ? (
            <Container fullWidth>
              <animated.div style={style} className="w-full absolute">
                <DepositTxInfo approveTx={approveTx} depositTx={depositTx} />
              </animated.div>
            </Container>
          ) : null,
        )}
        {successTransition((style, visible) =>
          visible ? (
            <Container fullWidth>
              <animated.div style={style} className="w-full absolute">
                <DepositSuccess onClose={handleClose} handleActivityTabNavigation={handleClose} />
              </animated.div>
            </Container>
          ) : null,
        )}
        {selectTokenTransition((style, visible) =>
          visible ? (
            <Container fullWidth>
              <animated.div style={style} className="w-full absolute">
                {depositTokens && (
                  <TokenSelect
                    onBack={() => setUIState(DepositUIState.Default)}
                    onClose={closeDeposit}
                    onTokenSelect={handleTokenSelect}
                    tokens={depositTokens}
                    balances={depositBalances}
                  />
                )}
              </animated.div>
            </Container>
          ) : null,
        )}
        {defaultStateTransition((style, visible) =>
          visible ? (
            <Container ref={contentRef} fullWidth>
              <animated.div style={style} className="w-full">
                <DepositDefaultContent
                  onTokenSelectClick={handleTokenSelectClick}
                  accounts={accounts}
                  depositTokens={depositTokens}
                  depositBalances={depositBalances}
                  freeBalance={freeBalance}
                  isApproveInProgress={isApproveInProgress}
                  isDepositReady={isDepositReady}
                  isDepositing={isDepositing}
                  submitDeposit={submitDeposit}
                />
              </animated.div>
            </Container>
          ) : null,
        )}
      </animated.div>
    </Modal>
  );
}
