import Decimal from 'decimal.js';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { useProvideLiquidityStore } from './store/useProvideLiquidityStore';
import { fromBN, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from '@mangata-finance/sdk';
import { TokenInputProps } from 'ui';
import { Asset, TxAsset, useInvestedPools, isAmountGtZero } from 'core';

export enum PoolInput {
  First,
  Second,
}

const getTxAsset = (asset: Asset | null, amount: string): TxAsset | null =>
  asset ? { ...asset, amount } : null;

export const useProvideLiquidityInputs = (pool?: PoolWithRatio | null) => {
  const { data } = useInvestedPools();
  const currentShare = (pool && data?.byId[pool?.id]?.sharePercentage) || '0';
  const {
    firstAmount,
    secondAmount,
    setFirstAmount,
    setSecondAmount,
    firstAsset,
    secondAsset,
    setFirstAsset,
    setSecondAsset,
  } = useProvideLiquidityStore();

  const firstTxAsset = getTxAsset(pool?.firstAsset || firstAsset, firstAmount);
  const secondTxAsset = getTxAsset(pool?.secondAsset || secondAsset, secondAmount);

  const handleAmountChange =
    (type: PoolInput): TokenInputProps['onAmountChange'] =>
    (value) => {
      if (!firstTxAsset || !secondTxAsset) {
        return;
      }

      const isNotEmptyPool = pool && pool.firstTokenAmount.gtn(0) && pool.secondTokenAmount.gtn(0);

      if (isNotEmptyPool && isAmountGtZero(value)) {
        const unscaledRatio = new Decimal(
          fromBN(pool.firstTokenRatio, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
        );
        const decimalDiff = firstTxAsset.decimals - secondTxAsset.decimals;
        const scaledRatio = unscaledRatio.mul(`1e${decimalDiff}`);
        const valueDec = new Decimal(value || 0);

        if (type === PoolInput.First) {
          setFirstAmount(value ?? '');

          const newSecondValue =
            valueDec.gt(0) && scaledRatio.mul(value || 0).toDecimalPlaces(secondTxAsset.decimals);
          if (newSecondValue && newSecondValue.gt(0)) {
            setSecondAmount(newSecondValue.toFixed());
          } else {
            setSecondAmount('');
          }
        } else {
          setSecondAmount(value ?? '');

          const newFirstValue =
            valueDec.gt(0) && valueDec.div(scaledRatio).toDecimalPlaces(firstTxAsset.decimals);
          if (newFirstValue && newFirstValue.gt(0)) {
            setFirstAmount(newFirstValue.toFixed());
          } else {
            setFirstAmount('');
          }
        }
      } else {
        if (type === PoolInput.First) {
          setFirstAmount(value ?? '');
        } else {
          setSecondAmount(value ?? '');
        }
      }
    };

  const handleTokenChange = (type: PoolInput) => (asset: Asset | null) => {
    if (type === PoolInput.First) {
      setFirstAsset(asset);

      if (asset && asset.id === secondAsset?.id) {
        setSecondAsset(null);
        setSecondAmount('');
        return;
      }
    } else {
      setSecondAsset(asset);

      if (asset && asset.id === firstAsset?.id) {
        setFirstAsset(null);
        setFirstAmount('');
        return;
      }
    }
  };

  const getExpectedSharePerc = () => {
    const currentShareDec = new Decimal(currentShare);
    if (!pool || !firstTxAsset || !secondTxAsset || !firstTxAsset.amount) {
      return {
        current: null,
        next: '100',
      };
    }

    const currentShareFactor = currentShareDec.div(100);
    const poolFirstAmount = fromBN(pool.firstTokenAmount, pool.firstAsset.decimals);
    const poolFirstAmountPlusAdded = new Decimal(poolFirstAmount).add(firstTxAsset.amount);
    const currentShareFirstTokenAmount = new Decimal(poolFirstAmount)
      .mul(currentShareFactor)
      .toDecimalPlaces(firstTxAsset.decimals);

    const totalEstimatedShare = new Decimal(firstTxAsset.amount)
      .add(currentShareFirstTokenAmount)
      .div(poolFirstAmountPlusAdded)
      .mul(100);

    return {
      current: currentShareDec.eq(0) ? null : currentShare,
      next: new Decimal(totalEstimatedShare).sub(currentShare).toString(),
    };
  };

  return {
    handleAmountChange,
    handleTokenChange,
    getExpectedSharePerc,
    firstTxAsset,
    secondTxAsset,
  };
};
