import { Container, LinkButton, Text } from 'ui';
import { ClassName } from 'core';
import cls from 'classnames';

type AutoCompoundingProps = ClassName;

export const AutoCompounding = ({ className }: AutoCompoundingProps) => {
  return (
    <Container
      alignItems="center"
      justifyContent="space-between"
      className={cls('w-full pt-6 border-default border-t-1', className)}
    >
      <Container column>
        <Text id="position.detail.autoCompounding.title" weight="bold" uppercase />
        <Text
          id="position.detail.autoCompounding.desc"
          type="body-s"
          color="secondary"
          className="mt-1"
          values={{
            app: (
              <Text color="primary" type="body-s">
                yieldbay
              </Text>
            ),
          }}
        />
      </Container>
      <LinkButton variant="secondary" href="https://mangata.yieldbay.io" target="_blank">
        <Text id="position.detail.autoCompounding.cta" />
      </LinkButton>
    </Container>
  );
};
