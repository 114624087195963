import { Decimal } from 'decimal.js';
import { useDeposits, useExternalBalances, useStashTokens } from 'core';
import { useMemo } from 'react';
import { useDepositStore } from './store/useDepositStore';

export enum DepositValidationError {
  MinAmount,
  OriginFeeDifferentAsset,
  OriginFeeSameAsset,
  InsufficientBalance,
}

export const useDepositValidation = () => {
  const { chain, originAccount, asset, amount } = useDepositStore();

  const {
    depositFeeQuery: { data: fee },
  } = useDeposits({
    channelId: chain?.id ?? null,
    assetId: asset?.id ?? null,
    amount,
    originAccount,
  });

  const {
    stashTokensQuery: { data: stashTokens },
  } = useStashTokens();

  const { balanceByChannelTokenKey } = useExternalBalances(
    chain?.id ?? null,
    originAccount?.address ?? null,
  );

  const existentialDeposit = useMemo(() => {
    if (!asset) return new Decimal(0);
    const ed = stashTokens?.xcmTokens.find(
      (token) => token.symbol === asset.symbol,
    )?.existentialDeposit;

    if (!ed) {
      return new Decimal(0);
    }

    return new Decimal(`${ed}e${asset.decimals}`);
  }, [asset, stashTokens?.xcmTokens]);

  const minAmount = useMemo(() => {
    if (!asset || !fee || !amount) return null;

    const {
      raw: { destination },
    } = fee;

    const destinationFee = new Decimal(destination.amount);

    const minAmount = new Decimal(0).add(
      destination.asset.symbol === asset.symbol ? destinationFee : new Decimal(0),
    );

    return minAmount;
  }, [fee, amount, asset]);

  const maxAmount = useMemo(() => {
    if (!asset || !fee || !amount) return null;

    const {
      raw: { origin, destination },
    } = fee;

    const originFee = new Decimal(origin.amount);

    const assetBalance = new Decimal(
      balanceByChannelTokenKey[`${chain?.id}:${destination.asset.tokenId}`]?.data ?? '0',
    ).mul(`1e${destination.asset.decimals}`);

    const netAmount = assetBalance
      .sub(origin.asset.symbol === asset.symbol ? originFee : new Decimal(0))
      .sub(existentialDeposit);

    return netAmount.lt(0) ? new Decimal(0) : netAmount;
  }, [amount, asset, existentialDeposit, fee, balanceByChannelTokenKey, chain]);

  const getError = () => {
    if (!asset || !fee) return null;
    if (!amount || !minAmount || !originAccount) return null;

    const {
      raw: { origin },
    } = fee;

    const amountBN = new Decimal(`${amount}e${asset.decimals}`);

    if (amountBN.lt(minAmount)) return DepositValidationError.MinAmount;

    const originFeeBalance = new Decimal(
      balanceByChannelTokenKey[`${origin.asset.channelId}:${origin.asset.tokenId}`]?.data ?? '0',
    ).mul(`1e${origin.asset.decimals}`);

    if (origin.asset.symbol === asset.symbol && amountBN.eq(originFeeBalance)) {
      return DepositValidationError.OriginFeeSameAsset;
    }

    if (amountBN.gt(maxAmount ?? 0)) return DepositValidationError.InsufficientBalance;

    if (origin.asset.symbol !== asset.symbol) {
      if (originFeeBalance.sub(existentialDeposit).lt(origin.amount)) {
        return DepositValidationError.OriginFeeDifferentAsset;
      }
    }

    return null;
  };

  return {
    fee,
    getError,
    minAmount,
  };
};
