import { z } from 'zod';

export function EventRecordSchema<T extends z.ZodSchema<EventDataSchema>>(schema: T) {
  return z.object({
    method: z.string(),
    section: z.string(),
    data: schema,
  });
}

export const MessageQueueProcessedDataSchema = z.object({
  id: z.string(),
});

export const XcmpQueueDataSchema = z.object({
  messageHash: z.string(),
});

export const TokensDepositedDataSchema = z.object({
  who: z.string(),
  currencyId: z.string(),
  amount: z.string(),
});

export const BalancesTransferDataSchema = z.object({
  from: z.string(),
  to: z.string(),
  amount: z.string(),
});

export type EventDataSchema = z.infer<
  | typeof XcmpQueueDataSchema
  | typeof TokensDepositedDataSchema
  | typeof BalancesTransferDataSchema
  | typeof MessageQueueProcessedDataSchema
>;

export function FrameSystemEventRecord<T extends z.ZodSchema<EventDataSchema>>(eventDataSchema: T) {
  return z.object({
    phase: z
      .object({
        ApplyExtrinsic: z.string(),
      })
      .or(z.string()),
    event: EventRecordSchema(eventDataSchema),
    topics: z.array(z.string()),
  });
}
