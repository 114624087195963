import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import { QueryOptional } from '../../../../../services';
import { BN } from '@polkadot/util';

type ScheduleBondLessFeeParams = Readonly<
  [
    queryKey: string,
    address: QueryOptional<string>,
    collatorAddress: QueryOptional<string>,
    amount: QueryOptional<BN>,
  ]
>;

export const getScheduleBondLessFee =
  (api: ApiPromise | null) =>
  async ({
    queryKey: [, address, collatorAddress, amount],
  }: QueryFunctionContext<ScheduleBondLessFeeParams>) => {
    if (!api || !address || !collatorAddress || !amount) {
      return null;
    }

    const feeInfo = await api.tx.parachainStaking
      .scheduleDelegatorBondLess(collatorAddress, amount.toString())
      .paymentInfo(address);

    return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
