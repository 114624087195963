import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, fetchTokens } from '../../../../services';
import { transformStashTokens } from '../transformers/stashTokensTransformer';

export const useStashTokensQuery = () => {
  const stashTokensQuery = useQuery(['stash-tokens'], fetchTokens, {
    ...DEFAULT_QUERY_OPTIONS,
    select: transformStashTokens,
  });

  return {
    stashTokensQuery,
  };
};
