import {
  RemoveLiquidityFeeParams,
  useRemoveLiquidityFeeQuery,
} from './query/useRemoveLiquidityFeeQuery';
import { useRemoveLiquidityMutation } from './query/useRemoveLiquidityMutation';

export const useRemoveLiquidity = (params: RemoveLiquidityFeeParams) => {
  const { removeLiquidityFeeQuery } = useRemoveLiquidityFeeQuery(params);
  const { removeLiquidityMutation } = useRemoveLiquidityMutation();

  return {
    removeLiquidityMutation,
    removeLiquidityFeeQuery,
  };
};
