import { Asset, useMetamaskNetwork } from 'core';
import { isEmpty } from 'lodash-es';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Text, ActiveMiningIcon, Button, FormatAmount } from 'ui';
import { RewardsList } from './RewardsList';
import { ReactComponent as CheckIcon } from 'icons/check.svg';

interface RewardsContentProps {
  rewards: Array<[Asset, string]>;
  onClaim: MouseEventHandler<HTMLButtonElement>;
  isExpanded: boolean;
}

const NUMBER_OF_REWARDS_IN_ROW = 4;

export const RewardsContent = ({ rewards, onClaim, isExpanded }: RewardsContentProps) => {
  const slicedRewards = rewards.slice(0, NUMBER_OF_REWARDS_IN_ROW);
  const hasRewards = !isEmpty(rewards);
  const { isChainSwitchRequired, requestDefaultChain } = useMetamaskNetwork();

  const appendEllipsis = (i: number) => {
    return i === slicedRewards.length - 1 && slicedRewards.length < rewards.length && ', ...';
  };

  return (
    <Container fullWidth column className="z-10">
      <Container fullWidth justifyContent="space-between" alignItems="center">
        <Container row alignItems="center">
          <ActiveMiningIcon size="l" className="mr-5" />
          <Container column className="mt-1">
            <Text type="title-2" id="position.claim.all.title" />
            {hasRewards && (
              <Container alignItems="center">
                {slicedRewards.map(([asset, amount], i) => (
                  <Container key={asset.id} className="mr-2" alignItems="center">
                    <Text color="secondary">
                      <FormatAmount value={amount} options={{ maxChars: 8, precision: 2 }} />{' '}
                      {asset.symbol}
                      {i < slicedRewards.length - 1 && ', '}
                      {appendEllipsis(i)}
                    </Text>
                  </Container>
                ))}
              </Container>
            )}

            {!hasRewards && <Text color="secondary" id="position.claim.all.noRewards" />}
          </Container>
        </Container>
        <Container>
          {!isEmpty(rewards) ? (
            <Button
              variant="accent"
              onClick={isChainSwitchRequired ? requestDefaultChain : onClaim}
            >
              <FormattedMessage
                id={
                  isChainSwitchRequired ? 'common.switch.defaultNetwork' : 'position.claim.all.cta'
                }
              />
            </Button>
          ) : (
            <Container alignItems="center">
              <CheckIcon className="mr-2 w-6 h-auto stroke-icon-premium" />
              <Text weight="bold" uppercase id="position.claim.allClaimed" color="highlight" />
            </Container>
          )}
        </Container>
      </Container>
      {hasRewards && <RewardsList rewards={rewards} isExpanded={isExpanded} />}
    </Container>
  );
};
