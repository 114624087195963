import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { useUserStore } from '../../../user/useUserStore';
import { getAll3rdPartyRewardsAmount } from '../services/3rdPartyRewardsService';
import { transformAll3rdPartyRewardsAmount } from '../transformer/3rdPartyRewardsTransformer';

export const useAll3rdPartyRewardsAmountQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { selectedAccount } = useUserStore();

  const address = selectedAccount?.address;

  const thirdPartyRewardsAmountQuery = useQuery(
    ['3rdParty-rewards-amount', address, finalisedTxCount],
    getAll3rdPartyRewardsAmount(api),
    {
      enabled: !!address && !!api,
      select: transformAll3rdPartyRewardsAmount,
      keepPreviousData: true,
    },
  );

  return { thirdPartyRewardsAmountQuery };
};
