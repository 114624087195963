import { ApiPromise } from '@polkadot/api';
import { QueryOptional } from '../../../../../services';
import { QueryFunctionContext } from '@tanstack/react-query';

export type GetCollatorStateQueryKey = [
  queryKey: string,
  collatorAddress: QueryOptional<string>,
  txCount: number,
];

export const listCollators = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  return api.query.parachainStaking.candidateState.entries();
};

export const listSelectedCandidates = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  return api.query.parachainStaking.selectedCandidates();
};

export const getCollatorState =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<GetCollatorStateQueryKey>) => {
    const [, collatorAddress] = queryKey;

    if (!api || !collatorAddress) {
      return null;
    }

    return api.query.parachainStaking.candidateState(collatorAddress);
  };
