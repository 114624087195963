import { Container, Text } from 'ui';
import { Transaction, TxStatus } from 'core';
import { getErrorText } from '../../helpers';

interface TxModalErrorProps {
  tx: Transaction;
}

export function TxModalError({ tx }: TxModalErrorProps) {
  const { status, error } = tx;
  const isError = status === TxStatus.Error && error !== undefined;
  const errorMessageId = isError && getErrorText(error).desc;

  return (
    <Container column>
      <Text
        type="title-4"
        id={isError ? getErrorText(error).title : `modal.transaction.${status}.title`}
      />
      <Text
        color="secondary"
        id={errorMessageId || `modal.transaction.${status}.desc`}
        values={{ error: error?.name }}
      />
    </Container>
  );
}
