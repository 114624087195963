import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { getAssetMetadata } from '../services/tokenMetadataService';
import { transformAssetMetadata } from '../transformers/tokenMetadataTransfomer';

export const useAssetMetadataQuery = () => {
  const api = useApi();

  const assetMetadataQuery = useQuery(['asset-metadata'], getAssetMetadata(api), {
    ...DEFAULT_QUERY_OPTIONS,
    select: transformAssetMetadata,
    enabled: !!api,
  });

  return {
    assetMetadataQuery,
  };
};
