import cls from 'classnames';
import { forwardRef, HTMLProps } from 'react';

import { Children, ClassName, TestId } from '../../types';

type FlexBasePosition = 'start' | 'center' | 'end';
type FlexAlignItems = FlexBasePosition | 'baseline' | 'stretch';
type FlexJustifyContent =
  | FlexBasePosition
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

interface ContainerProps
  extends Children,
    ClassName,
    TestId,
    Pick<HTMLProps<HTMLDivElement>, 'onClick' | 'onTransitionEnd' | 'style' | 'tabIndex' | 'id'> {
  row?: boolean;
  column?: boolean;
  alignItems?: FlexAlignItems;
  justifyContent?: FlexJustifyContent;
  inline?: boolean;
  grow?: boolean;
  shrink?: boolean;
  wrap?: boolean;
  fullWidth?: boolean;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      id,
      row = true,
      column,
      className,
      inline = false,
      alignItems = 'start',
      justifyContent = 'start',
      grow = false,
      shrink,
      onClick,
      wrap = false,
      fullWidth = false,
      'data-testid': testId,
      onTransitionEnd,
      style,
      tabIndex,
    },
    ref,
  ) => {
    const directionClass = cls(row && !column && 'flex-row', column && 'flex-col');
    const alignClass: Record<FlexAlignItems, string> = {
      center: 'items-center',
      start: 'items-start',
      end: 'items-end',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    };
    const justifyClass: Record<FlexJustifyContent, string> = {
      center: 'justify-center',
      start: 'justify-start',
      end: 'justify-end',
      stretch: 'justify-stretch',
      'space-between': 'justify-between',
      'space-around': 'justify-around',
      'space-evenly': 'justify-evenly',
    };

    return (
      <div
        id={id}
        ref={ref}
        style={style}
        onClick={onClick}
        tabIndex={tabIndex}
        onTransitionEnd={onTransitionEnd}
        className={cls(
          inline ? 'inline-flex' : 'flex',
          grow && 'grow',
          shrink ? 'shrink' : shrink === false ? 'shrink-0' : null,
          wrap && 'flex-wrap',
          fullWidth && 'w-full',
          directionClass,
          alignClass[alignItems],
          justifyClass[justifyContent],
          className,
        )}
        data-testid={testId}
      >
        {children}
      </div>
    );
  },
);
Container.displayName = 'Container';
