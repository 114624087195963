import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { submitRemoveLiquidity } from '../services/RemoveLiquidityMutationService';
import { useWallet } from '../../../wallet';
import { useAccounts } from '../../../account';
import { useApi } from '../../../../services';

export const useRemoveLiquidityMutation = () => {
  const transactionStore = useTransactionStore();
  const api = useApi();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();
  const { selectedAccount } = useAccounts();

  const removeLiquidityMutation = useMutation(
    submitRemoveLiquidity(api, transactionStore, selectedAccount?.address, selectedWallet),
  );

  return { removeLiquidityMutation };
};
