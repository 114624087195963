import { Decimal } from 'decimal.js';
import {
  isAmountGtZero,
  useRollupChainsQuery,
  useRollupDepositFeeQuery,
  useRollupTokenBalancesQuery,
  useRollupNativeTokenBalanceQuery,
  useRollupTokenAllowance,
  NATIVE_TOKEN_ADDRESS,
} from 'core';
import { useDepositStore } from '../store/useDepositStore';
import { useIntl } from 'react-intl';

export enum RollupDepositValidationError {
  InsufficientTokenBalance,
  InsufficientNativeTokenBalance,
}

export const useRollupDepositValidation = (isApproveInProgress: boolean) => {
  const intl = useIntl();
  const { chain, originAccount, asset, amount } = useDepositStore();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { isAllowanceSufficient, rollupApproveFeeQuery } = useRollupTokenAllowance(
    chain?.id,
    originAccount?.address,
    asset?.id,
    amount,
  );
  const { rollupDepositFeeQuery } = useRollupDepositFeeQuery(
    chain?.id,
    originAccount?.address,
    asset?.id,
    amount,
    isAllowanceSufficient,
  );
  const { rollupNativeTokenBalanceQuery } = useRollupNativeTokenBalanceQuery(
    chain?.id,
    originAccount?.address,
  );
  const { rollupTokenBalancesQuery } = useRollupTokenBalancesQuery(
    originAccount?.address,
    chain?.id,
  );

  const stashChain = rollupChainsQuery.data?.find((c) => c.chainId === chain?.id);
  const nativeTokenBalance = rollupNativeTokenBalanceQuery.data || 0;
  const erc20TokenBalance = (asset && rollupTokenBalancesQuery.data?.get(asset?.id)) || 0;
  const feeAmount = rollupDepositFeeQuery.data?.ethAmount || rollupApproveFeeQuery.data?.ethAmount;
  const isFeeError = !!(rollupDepositFeeQuery.error || rollupApproveFeeQuery.error);

  const error = (() => {
    if (
      rollupNativeTokenBalanceQuery.isSuccess &&
      new Decimal(feeAmount || 0).gt(nativeTokenBalance)
    ) {
      return RollupDepositValidationError.InsufficientNativeTokenBalance;
    }

    if (
      rollupNativeTokenBalanceQuery.isSuccess &&
      asset?.id === NATIVE_TOKEN_ADDRESS &&
      new Decimal(feeAmount || 0).add(amount || 0).gt(nativeTokenBalance)
    ) {
      return RollupDepositValidationError.InsufficientTokenBalance;
    }

    if (new Decimal(amount || 0).gt(erc20TokenBalance)) {
      return RollupDepositValidationError.InsufficientTokenBalance;
    }

    return null;
  })();

  const getErrorText = () => {
    switch (error) {
      case RollupDepositValidationError.InsufficientTokenBalance:
        return intl.formatMessage(
          { id: 'bridge.error.insufficientTokenBalance' },
          { symbol: asset?.symbol },
        );
      case RollupDepositValidationError.InsufficientNativeTokenBalance:
        return intl.formatMessage(
          { id: 'bridge.error.insufficientNativeTokenBalance' },
          { symbol: stashChain?.nativeToken.symbol },
        );
    }
  };

  const getButtonText = (isDepositChainId: boolean) => {
    switch (true) {
      case isApproveInProgress:
        return 'bridge.button.approving';
      case chain === null:
        return 'bridge.button.selectChain';
      case asset === null:
        return 'bridge.button.selectToken';
      case amount === null || !isAmountGtZero(amount):
        return 'bridge.button.amount';
      case !originAccount:
        return 'bridge.button.selectAccount';
      case !isDepositChainId:
        return 'common.switch.newChain';
      case !isAllowanceSufficient:
        return 'bridge.button.approve';

      default:
        return 'bridge.button.deposit';
    }
  };

  return {
    feeAmount,
    error,
    isFeeError,
    getButtonText,
    getErrorText,
  };
};
