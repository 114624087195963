import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';
import { TotalActivatedLiquidityResultSchema } from '../schema/PromotedPoolATvlSchema';
import { z } from 'zod';

export type PromotedPoolATvlQueryKey = Readonly<
  [
    queryKey: string,
    liquidityTokenId: string | undefined,
    firstTokenId: string | undefined,
    secondTokenId: string | undefined,
  ]
>;

export const getPromotedPoolATvl =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<PromotedPoolATvlQueryKey>) => {
    const [, liquidityTokenId, firstTokenId, secondTokenId] = queryKey;

    if (!api || !firstTokenId || !secondTokenId) {
      return null;
    }

    const totalActivatedLiquidity = await api.query.proofOfStake.totalActivatedLiquidity(
      liquidityTokenId,
    );

    if (
      !totalActivatedLiquidity ||
      !TotalActivatedLiquidityResultSchema.safeParse(totalActivatedLiquidity).success
    ) {
      return null;
    }

    const totalLiquidity = totalActivatedLiquidity as unknown as z.infer<
      typeof TotalActivatedLiquidityResultSchema
    >;

    const totalValueLocked = await api.rpc.xyk.get_burn_amount(
      firstTokenId,
      secondTokenId,
      totalLiquidity,
    );

    return totalValueLocked;
  };
