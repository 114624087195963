import { useEffect } from 'react';

export const useClickOutside = <E extends HTMLElement | null>(
  insideEl: Array<E>,
  handler: (isOutside: boolean) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (insideEl.some((el) => el?.contains(event.target as Node))) {
        handler(false);
        return;
      }
      handler(true);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [handler, insideEl]);
};
