import { TxStatus, TxType, useTransactionStore } from 'core';

export const useTx = () => {
  const { list, hide } = useTransactionStore();

  const isAllowed = (tx: TxType) => {
    return !list.some(
      (item) =>
        item.type === tx &&
        item.isVisible &&
        (item.status === TxStatus.Pending || item.status === TxStatus.Confirming),
    );
  };

  const getTxByType = (tx: TxType) => {
    return list.find((item) => item.type === tx);
  };

  return {
    list,
    hide,
    isAllowed,
    getTxByType,
  };
};
