import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { useApi } from '../../../../services';
import { deactivateLiquidity } from '../services/DeactivateLiquidityMutationService';
import { useAccounts } from '../../../account';
import { useWallet } from '../../../wallet';

export const useDeactivateLiqudityMutation = () => {
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const deactivateLiquidityMutation = useMutation(
    ['deactivate-liquidity'],
    deactivateLiquidity(api, selectedAccount?.address, wallet, transactionStore),
  );
  return { deactivateLiquidityMutation };
};
