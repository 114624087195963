import cls from 'classnames';
import { ClassName, EnvConfig, TestId } from 'core';
import { useEffect, useState } from 'react';
import { Container } from '../Container/Container';
import { isIconAvailable } from '../TokenIcon/isIconAvailable';

interface WalletLogoProps extends TestId, ClassName {
  wallet: string;
}

interface WalletIconMetadata {
  url: string;
  iconAvailable: boolean;
}

const DEFAULT_ICON = `${EnvConfig.ASSETS_URL}/wallets/default.png`;

export function WalletLogo({
  wallet,
  'data-testid': testId = 'wallet-icon',
  className,
}: WalletLogoProps) {
  const [metadata, setMetadata] = useState<WalletIconMetadata | null>(null);

  useEffect(() => {
    fetchMetadata(wallet).then(setMetadata);
  }, [wallet]);

  const renderIcon = () => {
    return (
      <img
        src={metadata?.url}
        alt={`${wallet} wallet icon`}
        className={cls('w-full', metadata?.iconAvailable ? 'rounded-full' : 'rounded')}
        data-testid={`${testId}-${wallet}`}
      />
    );
  };

  return (
    <Container inline data-testid={testId} className={cls(className)}>
      {metadata && renderIcon()}
    </Container>
  );
}

const fetchMetadata = async (wallet: string) => {
  const url = `${EnvConfig.ASSETS_URL}/wallets/${wallet.toLowerCase()}.png`;
  const urlExists = await isIconAvailable(url);
  return { url: urlExists ? url : DEFAULT_ICON, iconAvailable: urlExists };
};
