import { WalletAccount } from '../../../services';
import { useAccounts } from '../../account';
import { useChannels } from '../../channel';
import { useStashTokens } from '../../token';
import { useWithdrawalQuery } from './query/useWithdrawalQuery';

export interface UseWithdrawalsProps {
  amount: string | null;
  assetId: string | null;
  channelId: string | null;
  destinationAccount: WalletAccount | null;
}

export const useWithdrawals = (props: UseWithdrawalsProps) => {
  const { selectedAccount } = useAccounts();
  const {
    stashTokensQuery: { data: tokens },
  } = useStashTokens();

  const {
    channelsQuery: { data: channels },
  } = useChannels();

  const params = { ...props, tokens, channels, selectedAccount };

  const { withdrawalExtrinsicQuery, withdrawalFee, submitWithdrawalMutation } =
    useWithdrawalQuery(params);

  return {
    withdrawalExtrinsicQuery,
    withdrawalFee,
    submitWithdrawalMutation,
  };
};
