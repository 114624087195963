import { groupBy } from 'lodash-es';
import { TxStatus, TxType } from './Transaction';
import { useTransactionStore } from './private/useTransactionStore';
import { useCallback, useMemo } from 'react';

export const useTransaction = () => {
  const transactionStore = useTransactionStore();

  const txList = transactionStore.list;
  const finalisedTxList = txList.filter((tx) => tx.status === TxStatus.Success);
  const finalisedTxCount = finalisedTxList.length;
  const finalisedTxByType = groupBy(finalisedTxList, 'type');

  const stakingTxCount = useMemo(
    () =>
      (finalisedTxByType[TxType.Stake]?.length ?? 0) +
      (finalisedTxByType[TxType.StakeChange]?.length ?? 0) +
      (finalisedTxByType[TxType.ConfirmStakeIncrease]?.length ?? 0) +
      (finalisedTxByType[TxType.ConfirmStakeDecrease]?.length ?? 0),
    [finalisedTxByType],
  );

  const getLatestTxByType = useCallback(
    (type: TxType) => {
      return (
        txList
          .slice()
          .reverse()
          .find((tx) => tx.type === type) ?? null
      );
    },
    [txList],
  );

  return {
    txList,
    getLatestTxByType,
    transactionStore,
    finalisedTxByType,
    finalisedTxCount,
    stakingTxCount,
    removeTx: transactionStore.remove,
  };
};
