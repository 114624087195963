import { StashChannel } from '../../../../stash';
import { XcmWeight } from '../../types';

export function buildWeightLimit({
  channelId,
  xcmTransferWeight,
  proofSize,
}: StashChannel): XcmWeight {
  if (channelId === '1000' || channelId === '2023') {
    return 'Unlimited';
  }

  return {
    Limited: {
      refTime: parseInt(xcmTransferWeight),
      proofSize: parseInt(proofSize),
    },
  };
}
