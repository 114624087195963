import { SyntheticEvent, useCallback, useState } from 'react';

export const useCopyToClipboard = (value: string | null | undefined) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = useCallback(
    async (e?: SyntheticEvent) => {
      e?.stopPropagation();

      if (!navigator?.clipboard) {
        // TODO: log to sentry
        return false;
      }

      if (!value) return;

      await navigator.clipboard.writeText(value);

      setIsCopied(true);
    },
    [value],
  );

  const resetIsCopied = useCallback(() => setIsCopied(false), []);

  return { isCopied, copyToClipboard, resetIsCopied };
};
