import { SignedBlock } from '@polkadot/types/interfaces';
import { Codec } from '@polkadot/types/types';
import { ApiPromise, Keyring } from '@polkadot/api';
import { z } from 'zod';
import { findEvents } from '../block/event';
import { XcmpQueueDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';
import { listExtrinsics } from '../block/extrinsic';
import { KEYRING_TYPE } from '../validator';

export function upwardMessageSentValidator(
  api: ApiPromise,
  eventsCodec: Codec,
  address: string,
  block: SignedBlock,
) {
  const events = findEvents<z.infer<typeof XcmpQueueDataSchema>>(
    eventsCodec,
    api,
    XcmpQueueDataSchema,
    XcmEvent.UpwardMessageSent,
  );

  if (events.length === 0) {
    return null;
  }

  const extrinsicIndex = listExtrinsics(block).findIndex((extrinsic) => {
    return (
      typeof extrinsic.signer !== 'string' &&
      extrinsic.signer?.Id &&
      new Keyring(KEYRING_TYPE).encodeAddress(extrinsic.signer.Id) === address
    );
  });

  if (extrinsicIndex === -1) {
    return null;
  }

  const messageHash = events.find((event) => {
    const applyExtrinsicValue =
      typeof event.phase === 'string' ? event.phase : event.phase.ApplyExtrinsic;
    return applyExtrinsicValue === extrinsicIndex.toString();
  })?.event.data.messageHash;

  if (!messageHash) {
    return null;
  }

  return messageHash;
}
