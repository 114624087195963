import * as React from 'react';
import cls from 'classnames';

import { Children, ClassName, TestId } from '../../types';
import { useToggle } from '../../hooks';
import { useEffect } from 'react';

export interface CheckboxProps extends Children, TestId, ClassName {
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  id: string;
}

export function Checkbox({
  children,
  isDisabled,
  onChange,
  className,
  'data-testid': testId,
  id,
  value = false,
  defaultValue = false,
}: CheckboxProps) {
  const [isChecked, setChecked] = useToggle(defaultValue || value);

  const handleChange = () => {
    if (!isDisabled) {
      if (onChange) {
        onChange(!isChecked);
      }
      setChecked(!isChecked);
    }
  };

  useEffect(() => {
    if (value !== undefined && !isDisabled) {
      setChecked(value);
    }
  }, [value, setChecked, isDisabled]);

  return (
    <div className={cls('flex items-center', className)}>
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        readOnly={isDisabled}
        disabled={isDisabled}
        data-testid={testId}
        className={cls(
          'bg-base form-checkbox text-highlight',
          isDisabled ? 'opacity-40' : 'cursor-pointer',
          'rounded focus:ring-offset-0 border-default border-2 border-icon-secondary !ring-0',
        )}
      />
      {children && (
        <label htmlFor={id} className={cls('pl-2 font-body-s', !isDisabled && 'cursor-pointer')}>
          {children}
        </label>
      )}
    </div>
  );
}
