import { QueryFunctionContext } from '@tanstack/react-query';
import { EnvConfig } from '../../../../envConfig';
import axios from 'axios';

export type FetchPriceDiscoveryQueryKey = Readonly<
  [queryKey: string, tokenSymbol: string | undefined]
>;

export interface PriceDiscoveryResult {
  current_price: Record<string, string>;
}

export const fetchPriceDiscovery = async ({
  queryKey,
}: QueryFunctionContext<FetchPriceDiscoveryQueryKey>): Promise<PriceDiscoveryResult | null> => {
  const [, tokenSymbol] = queryKey;

  const { data } = await axios.get(`${EnvConfig.STASH_URL}/price-discovery/${tokenSymbol}`);

  return data;
};
