import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { EnvConfig } from '../../../../../envConfig';
import { CollatorApyRes } from '../CollatorApy';

export type CollatorApyQueryKey = [string, string | undefined];

export const getCollatorApy = async ({
  queryKey: [, address],
}: QueryFunctionContext<CollatorApyQueryKey>) => {
  if (!address) {
    return null;
  }

  const { data } = await axios.get<CollatorApyRes>(
    `${EnvConfig.STASH_URL}/collator/${address}/staking/apy`,
  );

  return [address, data] as [string, CollatorApyRes];
};
