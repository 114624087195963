import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { ReactComponent as AlertIcon } from '../../../assets/icons/warning.svg';

type BannerVariant = 'alert' | 'warning';

export interface BannerProps extends ClassName, TestId {
  variant: BannerVariant;
  message: string;
  showIcon?: boolean;
}

const VARIANT_STYLES: Record<BannerVariant, Record<string, string>> = {
  alert: {
    icon: 'fill-icon-alert',
    container: 'bg-alert',
    iconBg: 'bg-alert/[.15]',
    text: 'text-alert',
  },
  warning: {
    icon: 'fill-icon-warning',
    container: 'bg-warning',
    iconBg: 'bg-warning/[.15]',
    text: 'text-warning',
  },
};

export function Banner({
  variant = 'alert',
  message,
  showIcon = true,
  className,
  'data-testid': testId,
}: BannerProps) {
  return (
    <Container
      alignItems="stretch"
      data-testid={testId}
      className={cls('rounded-lg w-full p-0', VARIANT_STYLES[variant].container, className)}
    >
      {showIcon && (
        <Container
          alignItems="center"
          className={cls('p-2 py-3 rounded-l-lg', VARIANT_STYLES[variant].iconBg)}
        >
          <AlertIcon className={cls('w-5 h-5', VARIANT_STYLES[variant].icon)} />
        </Container>
      )}
      <Container className="p-3">
        <Text className={VARIANT_STYLES[variant].text}>{message}</Text>
      </Container>
    </Container>
  );
}
