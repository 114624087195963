import { ApiPromise } from '@polkadot/api';
import { BN } from '@polkadot/util';
import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { zipObject } from 'lodash-es';
import { DEFAULT_QUERY_OPTIONS, QueryOptional, useApi } from '../../../../services';
import { PoolWithRatio } from '../../../pool';
import { useTransaction } from '../../../transaction';
import {
  claimAllRewardsFee,
  claimRewardsFee,
  getRewardsAmount,
  GetRewardsAmountQueryKey,
} from '../service/nativeRewardsService';
import { useAdaptiveFee } from '../../../fee/adaptive';
import { useAccounts } from '../../../account';
import { useInternalBalance } from '../../../balance';

type RewardsQueryOptions = UseQueryOptions<BN | null, unknown, BN | null, GetRewardsAmountQueryKey>;

const getRewardsQuery =
  (api: ApiPromise | null, address: string | undefined, txId: number) =>
  (lpId: string | undefined): RewardsQueryOptions => {
    return {
      ...DEFAULT_QUERY_OPTIONS,
      queryKey: ['pool-reward-amount', lpId, address, txId],
      queryFn: getRewardsAmount(api),
      enabled: !!lpId && !!address && !!api,
    };
  };

export const useNativeRewardAmountQueries = (
  address: string | undefined,
  pools: QueryOptional<PoolWithRatio[]>,
) => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const lpIds = pools?.map((pool) => pool.liquidityTokenId);

  const rewardQueries = useQueries({
    queries: lpIds?.map(getRewardsQuery(api, address, finalisedTxCount)) || [],
  });

  return {
    rewardQueriesByLpId: lpIds ? zipObject(lpIds, rewardQueries) : {},
  };
};

export const useNativeRewardsQuery = (
  address: string | undefined,
  rewardQueriesByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
) => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const rewardsFetched =
    Object.keys(rewardQueriesByLpId).length > 0 &&
    Object.values(rewardQueriesByLpId).every((query) => {
      return query.data !== undefined && query.data !== null;
    });

  const claimAllRewardsFeeQuery = useQuery(
    ['claim-all-rewards-fee', address, finalisedTxCount, rewardsFetched],
    claimAllRewardsFee(api, rewardQueriesByLpId),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!address && !!api && rewardsFetched,
    },
  );

  return {
    claimAllRewardsFeeQuery,
  };
};

export const useClaimNativeRewardsFeeQuery = (liquidityId: string | undefined) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const claimRewardsFeeQuery = useQuery(
    ['claim-rewards-fee', liquidityId, selectedAccount?.address],
    claimRewardsFee(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady && !!selectedAccount?.address && !!api && !!liquidityId && !!balances,
    },
  );

  return { claimRewardsFeeQuery };
};
