import { FormattedMessage } from 'react-intl';
import { AdaptiveTxFee, Asset, TestId } from 'core';
import { RemoveStakeUIStoreFormError, useRemoveStakeStore } from '../store/useRemoveStakeStore';
import {
  Text,
  Tooltip,
  FormatAmount,
  Skeleton,
  WidgetDetailRow,
  Container,
  AmountTooltip,
} from 'ui';

import { ReactComponent as InfoIcon } from 'icons/info.svg';
import Decimal from 'decimal.js';

interface RemoveStakeWidgetDetailsProps extends TestId {
  feeData: AdaptiveTxFee['current'] | null | undefined;
  isFeeLoading: boolean;
  stakedAsset: Asset | null;
  stakeRemoveAmount: string | null;
  currentStakeAmount: string | null;
}

export const RemoveStakeWidgetDetails = ({
  feeData,
  isFeeLoading,
  stakedAsset,
  'data-testid': testId = 'stake-details',
  stakeRemoveAmount,
  currentStakeAmount,
}: RemoveStakeWidgetDetailsProps) => {
  const { formError, amount } = useRemoveStakeStore();

  if (!stakedAsset) return null;

  const isFeeInsufficient = !!formError[RemoveStakeUIStoreFormError.InsufficientFee];
  const stakeAmountAfterTx = new Decimal(currentStakeAmount || '0')
    .sub(stakeRemoveAmount || '0')
    .toFixed();

  const isAmountGtZero = amount !== 0;

  const Fee = feeData && (
    <Text type="label" color={isFeeInsufficient ? 'alert' : 'primary'}>
      ≈
      <FormatAmount
        value={feeData?.amount ?? ''}
        options={{ precision: 3, maxChars: 20 }}
        className="mx-1"
      />
      {feeData?.symbol || ''}
    </Text>
  );

  const renderFee = () => {
    switch (true) {
      case isAmountGtZero && feeData && isFeeInsufficient:
        return (
          <Tooltip
            tooltipContent={<FormattedMessage id="pool.provide.fee.insufficient" />}
            className="flex items-center"
            inPortal
            leftOffset={false}
          >
            {Fee}
            <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-icon-alert'} />
          </Tooltip>
        );
      case isAmountGtZero && !feeData && isFeeLoading:
        return (
          <Skeleton height="14px" width="70px" className={isFeeInsufficient ? 'my-[5px]' : ''} />
        );

      case isAmountGtZero && !!feeData:
        return Fee;

      default:
        return (
          <Text type="label" color="secondary">
            -
          </Text>
        );
    }
  };

  return (
    <Container column fullWidth className="mt-5" data-testid={testId}>
      <WidgetDetailRow
        label={
          <Text
            id="position.remove.widget.receive.label"
            type="label"
            color={'secondary'}
            className="mr-2"
          />
        }
        value={
          <Text>
            <AmountTooltip
              value={stakeRemoveAmount}
              options={{ precision: 3, nonZeroPrecision: true }}
              className="mr-1"
              id={`remove-amount-tooltip`}
            />
            {stakedAsset.symbol}
          </Text>
        }
        data-testid={`${testId}-remove-amount`}
      />
      <WidgetDetailRow
        label={
          <Text
            id="staking.remove.newAmount.info"
            type="label"
            color={'secondary'}
            className="mr-2"
          />
        }
        value={
          <Text>
            <AmountTooltip
              value={stakeAmountAfterTx}
              options={{ precision: 3, nonZeroPrecision: true }}
              className="mr-1"
              id={`amount-after-tooltip`}
            />
            {stakedAsset.symbol}
          </Text>
        }
        data-testid={`${testId}-amount-after`}
      />
      <WidgetDetailRow
        label={<Text id="staking.remove.fee" type="label" color={'secondary'} className="mr-2" />}
        value={renderFee()}
        data-testid={`${testId}-fee`}
      />
    </Container>
  );
};
