import { MangataInstance, toBN } from '@mangata-finance/sdk';
import { PoolWithRatio } from '../../../Pool';
import { QueryFunctionContext } from '@tanstack/react-query';
import { BN } from '@polkadot/util';

export type PromotedPoolBlockRewardsQueryKey = [string, string | undefined | null];

const BLOCK_COUNT_30_DAYS = 216000;

export const getPromotedPoolBlockRewards =
  (sdk: MangataInstance | null, pool: PoolWithRatio | null | undefined) =>
  async ({ queryKey }: QueryFunctionContext<PromotedPoolBlockRewardsQueryKey>) => {
    const [, firstAmount] = queryKey;

    if (!sdk || !pool || !firstAmount) {
      return null;
    }

    const totalIssuance = await sdk.query.getTotalIssuance(pool.liquidityTokenId);

    const firstTokenAmountBN = toBN(firstAmount, pool.firstAsset.decimals);
    const mintingAmount = firstTokenAmountBN.mul(totalIssuance).div(pool.firstTokenAmount);

    const estimatedRewards = await sdk.util.calculateMintingFutureRewards(
      pool.liquidityTokenId,
      mintingAmount,
      new BN(BLOCK_COUNT_30_DAYS),
    );

    return estimatedRewards;
  };
