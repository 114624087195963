import { useQuery } from '@tanstack/react-query';
import { fetchPoolVolumeHistory } from '../services/PoolVolumeHistoryService';
import { transform24hVolumeHistory } from '../transformers/PoolVolumeHistoryTransformers';

export enum PoolVolumeHistoryInterval {
  Hour = 'hour',
  Day = 'day',
  Week = 604800,
  Month = 2592000,
}

export const usePool24hVolume = (poolSymbols: string | null) => {
  const poolVolumeHistoryQuery = useQuery(
    ['pool-volume-history', poolSymbols, 1, PoolVolumeHistoryInterval.Hour],
    fetchPoolVolumeHistory,
    { enabled: !!poolSymbols, select: transform24hVolumeHistory, retry: 1 },
  );

  return { poolVolumeHistoryQuery };
};

export const usePoolVolumeHistory = (
  poolSymbols: string | null,
  days: number,
  interval: PoolVolumeHistoryInterval,
) => {
  const poolVolumeHistoryQuery = useQuery(
    ['pool-volume-history', poolSymbols, days, interval],
    fetchPoolVolumeHistory,
    { enabled: !!poolSymbols },
  );

  return { poolVolumeHistoryQuery };
};
