import { useQuery } from '@tanstack/react-query';
import {
  AssetRegistryAssetMetadata,
  DEFAULT_QUERY_OPTIONS,
  QueryOptional,
} from '../../../../services';
import { getLiquidityAssets } from '../services/tokenMetadataService';
import { PoolWithRatio } from '../../../pool';
import { useTokenBucketQuery } from '../../bucket/query/useTokenBucketQuery';
import { useTradeableTokenIds } from '../../../tokens/trading/query/useTradeableTokens';

export const useLiquidityAssetsQuery = ({
  pools,
  metadata,
}: {
  pools: QueryOptional<PoolWithRatio[]>;
  metadata: QueryOptional<AssetRegistryAssetMetadata[]>;
}) => {
  const { isFirstPoolTokenLeader } = useTokenBucketQuery();
  const {
    tradeableTokenIdsQuery: { data: tradeableTokenIds },
  } = useTradeableTokenIds();

  const liquidityAssetsQuery = useQuery(
    ['liquidity-assets'],
    getLiquidityAssets({ pools, assets: metadata, tradeableTokenIds, isFirstPoolTokenLeader }),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!metadata && !!pools && !!tradeableTokenIds,
    },
  );

  return {
    liquidityAssetsQuery,
  };
};
