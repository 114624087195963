import { EnvConfig } from '../../../../envConfig';
import { RollupStashChain } from '../RollupStash';
import axios from 'axios';

export const fetchRollupChains = async (): Promise<RollupStashChain[]> => {
  const url = `${EnvConfig.STASH_URL}/affirmed-network/list`;
  const { data } = await axios.get<RollupStashChain[]>(url);

  return data;
};
