import { Button, Container, Text } from 'ui';
import { ReactComponent as StakingIcon } from 'icons/staking-large.svg';
import { ClassName, TestId } from 'core';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';

interface DisconnectedWalletProps extends TestId, ClassName {}

export function EmptyStaking({ 'data-testid': testId, className }: DisconnectedWalletProps) {
  const navigate = useNavigate();

  const goToStaking = () => {
    navigate('/staking');
  };

  return (
    <Container
      data-testid={testId}
      column
      fullWidth
      alignItems="center"
      justifyContent="center"
      className={cls('p-14 text-center', className)}
    >
      <StakingIcon className="w-[64px] h-auto stroke-icon-neutral" />
      <Text type="title-2" id="staking.empty.title" className="mt-8" />
      <Text color="secondary" id="staking.empty.desc" className="mt-2" />
      <Button variant="primary" size="m" onClick={goToStaking} className="mt-12">
        <Text weight="semibold" type="btn-m" id="staking.empty.cta" />
      </Button>
    </Container>
  );
}
