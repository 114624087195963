import { ExtrinsicPath } from '../../../../../stash';
import { IdLookupFn } from '../../Xcm';
import { tokensDepositedValidator, xcmpSuccessValidator } from '../../validators';

export const getDestinationIdentifiersForDeposit: IdLookupFn = async (api, token, _, header) => {
  const apiAtHash = await api.at(header.hash);
  const eventsCodec = await apiAtHash.query.system.events();

  switch (token.extrinsicPath) {
    case ExtrinsicPath.XcmPalletLimitedTransfer: {
      return tokensDepositedValidator(api, eventsCodec, token);
    }
    case ExtrinsicPath.TransferMultiasset:
    case ExtrinsicPath.PolkadotXcmLimitedTransfer: {
      return xcmpSuccessValidator(api, eventsCodec);
    }
  }
};
