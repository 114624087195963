import { useRewardsMutation } from './query/rewardsMutation';
import { useClaimAllPoolRewardsFeeQuery } from './query/rewardsQuery';

export const useRewards = (lpId: string | undefined) => {
  const { claimAllPoolRewardsMutation, claimAllRewardsMutation } = useRewardsMutation();
  const { claimAllPoolRewardsFeeQuery, claimAllRewardsFeeQuery } =
    useClaimAllPoolRewardsFeeQuery(lpId);

  return {
    claimAllPoolRewardsFeeQuery,
    claimAllRewardsFeeQuery,
    claimAllPoolRewardsMutation,
    claimAllRewardsMutation,
  };
};
