import { ApiPromise } from '@polkadot/api';
import { StashToken, StashTokenBalancePath } from '../../../../services';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ChannelTokenBalanceResult } from '../transformers/externalBalanceTransformer';

export type GetChannelBalanceQueryKey = Readonly<
  [
    queryKey: string,
    tokenId: string,
    channelId: string | null,
    address: string | null,
    finalisedTxCount: number,
  ]
>;

const getParams = (
  address: string,
  path: StashToken['balancePath'],
  tokenId: StashToken['destinationTokenId'],
) => {
  switch (path) {
    case StashTokenBalancePath.AssetsAccount:
      return [tokenId, address];
    case StashTokenBalancePath.TokensAccounts:
      return [address, tokenId];
    default:
      return [address];
  }
};

export const getChannelTokenBalance =
  (
    api: ApiPromise | null,
    path: StashTokenBalancePath,
    tokenId: StashToken['destinationTokenId'],
  ) =>
  async ({
    queryKey,
  }: QueryFunctionContext<GetChannelBalanceQueryKey>): Promise<
    ChannelTokenBalanceResult | null | undefined
  > => {
    const [pallet, method] = path.split('.');
    const [, , , address] = queryKey;

    if (!address || !api) {
      return undefined;
    }

    return ((await api.query[pallet][method](...getParams(address, path, tokenId))).toJSON() ||
      null) as unknown as ChannelTokenBalanceResult | null;
  };
