import { StorageKey } from '@polkadot/types';
import { u32, u128 } from '@polkadot/types/primitive';
import { BN } from '@polkadot/util';

export const transformTokensIssuance = (_data: [StorageKey<[u32]>, u128][] | null) => {
  if (!_data) {
    return null;
  }

  const tokensIssuance: [string, BN][] = _data.map(([storageKey, data]) => {
    const key = storageKey.toHuman();
    const id = Array.isArray(key) ? key[0]?.toString() : null;

    return [id || storageKey.toString(), data.toBn()];
  });

  return new Map(tokensIssuance);
};
