import { BTreeMap, Option, u32, u128 } from '@polkadot/types';
import { ITuple } from '@polkadot/types/types';
import { isNil } from 'lodash-es';
import { QueryOptional } from '../../../../services';

export const transformerStakingLiquidityTokens = (
  data: QueryOptional<BTreeMap<u32, Option<ITuple<[u128, u128]>>>>,
) => {
  if (isNil(data)) {
    return null;
  }

  const ids = Array.from(data.entries()).map(([id]) => id.toString());
  return new Set(ids);
};
