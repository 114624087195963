import { QueryOptional } from '../../../services';
import { useActivateLiquidityFeeQuery } from './query/useActivateLiquidityFeeQuery';
import { useActivateLiqudityMutation } from './query/useActivateLiquidityMutation';

export const useActivateLiquidity = (
  liquidityId: QueryOptional<string>,
  amount: QueryOptional<string>,
) => {
  const { activateLiquidityFeeQuery } = useActivateLiquidityFeeQuery(liquidityId, amount);
  const { activateLiquidityMutation } = useActivateLiqudityMutation();

  return {
    activateLiquidityFeeQuery,
    activateLiquidityMutation,
  };
};
