import { Asset } from 'core';
import { useStartStakingStore } from './store/useStartStakingStore';

export const useStartStakingInputs = (selectedAsset: Asset | undefined | null) => {
  const { amount, setAmount } = useStartStakingStore();

  const handleAmountChange = (value: string | null) => {
    setAmount(value || '');
  };

  return {
    amount,
    handleAmountChange,
  };
};
