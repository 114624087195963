import cls from 'classnames';
import { ClassName, XcmOperation } from 'core';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { Container, Text } from 'ui';

interface EthereumAccountInfoProps extends ClassName {
  operation: XcmOperation;
  chain: string | undefined;
}

export function EthereumAccountInfo({ operation, chain, className }: EthereumAccountInfoProps) {
  return (
    <Container
      fullWidth
      alignItems="stretch"
      className={cls('bg-accent/[.1] rounded-lg', className)}
    >
      <Container className="bg-accent/[.1] p-2" alignItems="center">
        <InfoIcon className="fill-icon-accent w-6 h-auto" />
      </Container>
      <Container className="p-2 ml-2">
        <Text
          id={
            operation === XcmOperation.Deposit
              ? 'bridge.ethAccInfo.deposit.text'
              : 'bridge.ethAccInfo.withdraw.text'
          }
          values={{
            chain,
          }}
          color="accent"
        />
      </Container>
    </Container>
  );
}
