import { useTokenPrice } from 'core';
import { Text } from '../Text/Text';
import { FormatCurrency, FormatCurrencyOptions } from '../Format';
import { Skeleton } from '../Skeleton/Skeleton';
import { isNull } from 'lodash-es';

interface FiatValueProps {
  id: string;
  amount: string;
  options?: FormatCurrencyOptions;
  prefix?: string;
  withLoader?: boolean;
}

export const FiatValue = ({ id, amount, prefix, withLoader = true, options }: FiatValueProps) => {
  const { getFiatValue, isLoading } = useTokenPrice(id);

  const value = getFiatValue(amount);

  if (isLoading) {
    if (!withLoader) {
      return null;
    }

    return <Skeleton rounded width="50px" height="10px" />;
  }

  if (isNull(value) && !isLoading) {
    return null;
  }

  return (
    <span data-testid="fiat-value" className="w-full">
      {prefix && (
        <Text className="mr-[2px]" color="secondary">
          {prefix}
        </Text>
      )}
      <FormatCurrency options={options} value={value} />
    </span>
  );
};
