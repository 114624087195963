import { TransactionStore } from './private/useTransactionStore';
import { TxStatus } from './Transaction';
import { TxBase } from './TxBase';
import { SDKProvider as MetamaskProvider } from '@metamask/sdk';
import { RequestArguments } from '@metamask/providers/dist/BaseProvider';

export class MetamaskTx extends TxBase {
  private txParams?: RequestArguments['params'];
  private chainExplorerBaseUrl?: string;

  constructor(private provider: MetamaskProvider, store: TransactionStore) {
    super(store);
    this.done = this.done.bind(this);
  }

  setTx(params: RequestArguments['params'], explorerBaseUrl?: string) {
    this.chainExplorerBaseUrl = explorerBaseUrl;
    this.txParams = params;
    return this;
  }

  async send() {
    try {
      const result = await this.execute();

      if (!this.options.doneOnTrigger) {
        this.store.set(this.id, { status: TxStatus.Success });
      }

      return result;
    } catch (e) {
      this.handleError(e);
    } finally {
      if (!this.options.doneOnTrigger) {
        this.scheduleHide();
      }
    }
  }

  private async execute() {
    const txHash = await this.provider.request<string>({
      method: 'eth_sendTransaction',
      params: [this.txParams],
    });

    if (txHash) {
      this.store.set(this.id, { status: TxStatus.Pending });
      this.setExplorerLink(txHash);
    }

    return txHash;
  }

  private setExplorerLink = (txHash: string) => {
    if (this.chainExplorerBaseUrl) {
      this.store.set(this.id, {
        explorerUrl: `${this.chainExplorerBaseUrl}/tx/${txHash}`,
      });
    }
  };
}
