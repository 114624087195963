import { z } from 'zod';
import { Vec, GenericExtrinsic } from '@polkadot/types';
import { SignedBlock } from '@polkadot/types/interfaces';
import { ExtrinsicSchema } from '../schema';

export function listExtrinsics({ block }: SignedBlock) {
  return parseExtrinsicsToSchema<z.infer<typeof ExtrinsicSchema>>(
    block.extrinsics,
    ExtrinsicSchema,
  );
}

export function parseExtrinsicsToSchema<T>(
  extrinsics: Vec<GenericExtrinsic>,
  schema: z.ZodSchema<T>,
) {
  return extrinsics
    .filter((extrinsic) => schema.safeParse(extrinsic.toHuman()).success)
    .map((extrinsic) => schema.parse(extrinsic.toHuman()));
}
