import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { useAdaptiveFee } from '../../../fee/adaptive';
import { useAccounts } from '../../../account';
import { useInternalBalance } from '../../../balance';
import { getClaimAllPoolRewardsFee, getClaimAllRewardsFee } from '../services/rewardsService';
import { use3rdPartyRewards } from '../../3rdParty';
import { useNativeRewards } from '../../native';
import { useInvestedPools } from '../../../pool';

export const useClaimAllPoolRewardsFeeQuery = (liquidityId: string | undefined) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const { data: pools } = useInvestedPools();

  const {
    thirdPartyRewardsAmountQuery: { data: thirdPartyRewardsAmount },
  } = use3rdPartyRewards();
  const { rewardQueriesByLpId } = useNativeRewards();

  const claimAllPoolRewardsFeeQuery = useQuery(
    ['claim-pool-rewards-fee', selectedAccount?.address, liquidityId],
    getClaimAllPoolRewardsFee(api, thirdPartyRewardsAmount, rewardQueriesByLpId),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!liquidityId &&
        !!balances &&
        !!thirdPartyRewardsAmount &&
        !!rewardQueriesByLpId,
    },
  );

  const claimAllRewardsFeeQuery = useQuery(
    ['claim-all-rewards-fee'],
    getClaimAllRewardsFee(
      api,
      thirdPartyRewardsAmount,
      rewardQueriesByLpId,
      pools?.baseList,
      selectedAccount?.address,
    ),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!balances &&
        !!thirdPartyRewardsAmount &&
        !!rewardQueriesByLpId,
    },
  );

  return { claimAllPoolRewardsFeeQuery, claimAllRewardsFeeQuery };
};
