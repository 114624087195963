import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { transformRewardsSchedulesList } from '../transformer/3rdPartyRewardsTransformer';
import { get3rdPartyRewardsSchedulesList } from '../services/3rdPartyRewardsService';

export const useRewardsSchedulesListQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const rewardsSchedulesListQuery = useQuery(
    ['rewards-schedules-list', finalisedTxCount],
    get3rdPartyRewardsSchedulesList(api),
    {
      enabled: !!api,
      keepPreviousData: true,
      select: transformRewardsSchedulesList,
    },
  );

  return { rewardsSchedulesListQuery };
};
