import { z } from 'zod';
import { encodeAddress } from '@polkadot/util-crypto';
import { ApiPromise } from '@polkadot/api';
import { Codec } from '@polkadot/types/types';
import { findEvents } from '../block/event';
import { TokensDepositedDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';
import { StashToken } from '../../../../stash';

export function tokensDepositedValidator(api: ApiPromise, eventsCodec: Codec, asset: StashToken) {
  const events = findEvents<z.infer<typeof TokensDepositedDataSchema>>(
    eventsCodec,
    api,
    TokensDepositedDataSchema,
    XcmEvent.Deposited,
  );

  return events
    .filter(({ event: { data } }) => data.currencyId === asset.tokenId)
    .map(({ event: { data } }) => encodeAddress(data.who))
    .filter((add) => add !== null);
}
