import { ExtrinsicPath, XcmVersion } from '../../../../stash';
import { XcmAsset, XcmAssetLocation } from '../../types';

export function buildAsset(
  location: XcmAssetLocation,
  amount: string,
  version: XcmVersion,
  extrinsicPath: ExtrinsicPath,
): XcmAsset {
  const assetBase = {
    id: {
      Concrete: location,
    },
    fun: {
      Fungible: amount,
    },
  };

  switch (version) {
    case XcmVersion.V2:
      return { V2: assetBase };
    case XcmVersion.V3:
      switch (extrinsicPath) {
        case ExtrinsicPath.TransferMultiasset:
          return { V3: assetBase };
        case ExtrinsicPath.PolkadotXcmLimitedTransfer:
        case ExtrinsicPath.XcmPalletLimitedTransfer:
          return { V3: [assetBase] };
        default:
          throw new Error(`Unsupported extrinsic path: ${extrinsicPath}`);
      }
    default:
      throw new Error(`Unsupported XCM version: ${version}`);
  }
}
