import { StashToken, StashTokenPermission } from '../../../../services';
import { StashTokensData } from '../StashToken';

export const transformStashTokens = (data: StashToken[] | null): StashTokensData => {
  if (data === null) {
    return {
      allTokens: null,
      verifiedTokens: null,
      swapTokens: null,
      xcmTokens: [],
    };
  }

  return {
    allTokens: data,
    verifiedTokens: data.filter(({ permissions }) =>
      permissions.includes(StashTokenPermission.Verified),
    ),
    swapTokens: data.filter(({ permissions }) => permissions.includes(StashTokenPermission.Swap)),
    xcmTokens: data.filter(({ permissions }) => permissions.includes(StashTokenPermission.Xcm)),
  };
};
