import { fromBN, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from '@mangata-finance/sdk';
import { BN, BN_ZERO } from '@polkadot/util';
import {
  useMangataAsset,
  useActivateLiquidity,
  useDeactivateLiquidity,
  TxType,
  useInvestedPoolFromPath,
  usePromotedPools,
  useNativeRewards,
  useAll3rdPartyRewardsInfoQuery,
} from 'core';
import { useReservesForTx } from 'core/src/domains/balance';
import { LiquidityMiningCard } from '../../../components/LiquidityMiningCard/LiquidityMiningCard';

export const NativeLiquidityMiningCard = () => {
  const { mangataAsset } = useMangataAsset();
  const { pool } = useInvestedPoolFromPath();
  const { poolAprsByLPId } = usePromotedPools();
  const { allThirdPartyRewardsInfoQuery } = useAll3rdPartyRewardsInfoQuery();

  const { sources: activateNativeLiqSources } = useReservesForTx(
    TxType.ActivateLP,
    pool?.liquidityTokenId,
  );

  const {
    activateLiquidityMutation: { mutate: activateLiquidity },
    activateLiquidityFeeQuery: { data: activateLiquidityFee },
  } = useActivateLiquidity(
    pool?.liquidityTokenId,
    pool ? fromBN(pool.nonActivatedLPTokens, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS) : null,
  );

  const pool3rdPartyRewardsInfo =
    pool && allThirdPartyRewardsInfoQuery.data?.get(pool.liquidityTokenId);
  const active3rdPartyLiquidities = Array.from(pool3rdPartyRewardsInfo?.entries() || [])
    .map(([id, value]) => value.activatedAmount.gtn(0) && [id, value.activatedAmount.toBn()])
    .filter(($): $ is [string, BN] => !!$);

  const {
    deactivateLiquidityMutation: { mutate: deactivateLiquidity },
    deactivateLiquidityFeeQuery: { data: deactivateLiquidityFee },
  } = useDeactivateLiquidity(
    pool?.liquidityTokenId,
    pool ? fromBN(pool.activatedLPTokens, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS) : null,
    active3rdPartyLiquidities,
  );

  const {
    rewardQueriesByLpId,
    claimRewardsMutation: { mutate: claimRewards },
  } = useNativeRewards();

  if (!mangataAsset || !pool) {
    return null;
  }

  const rewardsAmount = rewardQueriesByLpId[pool.liquidityTokenId]?.data ?? BN_ZERO;
  const aprAmount = poolAprsByLPId[pool?.liquidityTokenId];

  const submitClaimRewards = () => {
    if (!pool) return;

    claimRewards(pool.liquidityTokenId);
  };

  const handleActivate = () => {
    if (!pool) return;

    activateLiquidity({
      id: pool.liquidityTokenId,
      amount: pool.nonActivatedLPTokens,
      symbol: pool.symbols.join('-'),
      balanceSources: activateNativeLiqSources,
    });
  };

  const handleDeactivate = () => {
    if (!pool) return;

    deactivateLiquidity({
      liquidityTokenId: pool.liquidityTokenId,
      amount: pool.activatedLPTokens,
      symbol: pool.symbols.join('-'),
      active3rdPartyLiquidities,
    });
  };

  return (
    <LiquidityMiningCard
      asset={mangataAsset}
      type="native"
      className="mb-5"
      onActivate={handleActivate}
      onDeactivate={handleDeactivate}
      onClaim={submitClaimRewards}
      is3rdPartyLiqActive={active3rdPartyLiquidities.length > 0}
      activateFee={activateLiquidityFee}
      activeLPAmount={pool.activatedLPTokens}
      availableLPAmount={pool.activatedLPTokens.add(pool.nonActivatedLPTokens)}
      deactivateFee={deactivateLiquidityFee}
      rewardsAmount={rewardsAmount}
      isLoading={rewardQueriesByLpId[pool.liquidityTokenId]?.isLoading}
      aprAmount={aprAmount}
    />
  );
};
