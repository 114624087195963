import { ClassName, InjectedWindowProvider, QueryOptional, WalletSource } from 'core';
import { isEmpty } from 'lodash-es';
import { Container, Text } from 'ui';
import { WalletItem } from '../WalletItem/WalletItem';
import { DISABLED_WALLET_SOURCES } from 'core';

interface Wallet {
  id: WalletSource;
  url: string;
  isMobile?: boolean;
}

const wallets = [
  { id: WalletSource.Talisman, url: 'https://talisman.xyz' },
  { id: WalletSource.PolkadotJS, url: 'https://polkadot.js.org/extension/' },
  { id: WalletSource.Enkrypt, url: 'https://www.enkrypt.com/' },
  { id: WalletSource.SubwalletJS, url: 'https://subwallet.app' },
  { id: WalletSource.Metamask, url: 'https://metamask.io' },
  { id: WalletSource.NovaWallet, url: 'https://novawallet.io/', isMobile: true },
].filter(($): $ is Wallet => !!$ && !DISABLED_WALLET_SOURCES.includes($.id));

interface PopularWalletsProps extends ClassName {
  walletData: QueryOptional<Map<string, InjectedWindowProvider>>;
}

export function PopularWallets({ walletData, className }: PopularWalletsProps) {
  const list = wallets.filter((wallet) => !walletData?.has(wallet.id));

  const renderWallets = () => {
    return list.map((wallet, index) => {
      return <WalletItem key={index} id={wallet.id} url={wallet.url} isMobile={wallet.isMobile} />;
    });
  };

  return !isEmpty(list) ? (
    <Container column fullWidth className={className}>
      <Text
        id="wallet.connect.popularWallets"
        type="title-4"
        color="secondary"
        className="ml-2 mb-4"
      />
      <Container fullWidth column>
        {renderWallets()}
      </Container>
    </Container>
  ) : null;
}
