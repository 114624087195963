import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { Button, Container, ShortAddress, Text } from 'ui';
import { ClassName } from 'core';
import { CollatorStateChip } from 'modules';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';

interface StakingDetailHeaderProps extends ClassName {
  collator: ParachainStakingCollatorCandidate | null | undefined;
}

export const StakingDetailHeader = ({ collator, className }: StakingDetailHeaderProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/staking');
  };

  if (!collator) {
    return null;
  }

  return (
    <Container fullWidth column className={className}>
      <Button
        variant="secondary"
        LeadIcon={BackIcon}
        onClick={handleBack}
        className="py-0"
        data-testid="back-button"
      >
        <FormattedMessage id="staking.detail.back" />
      </Button>
      <Container column fullWidth className="mt-10">
        <Text id="staking.detail.collator.title" color="secondary" />
        <Container justifyContent="space-between" alignItems="center" fullWidth>
          <Text type="display-2">
            <ShortAddress leftOffset={false} address={collator?.id.toString()} separator=" .. " />
          </Text>
          <CollatorStateChip isActive={collator?.state.isActive} />
        </Container>
      </Container>
    </Container>
  );
};
