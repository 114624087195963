import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { Container, TabGroup } from 'ui';
import {
  useAccounts,
  useDelegatorStateQuery,
  useInvestedPoolFromPath,
  useStakingMetadata,
} from 'core';
import { PositionDetailHeader } from './header/PositionDetailHeader';
import { useIntl } from 'react-intl';

interface TabPage {
  label: string;
  path: string;
  count?: number;
}

export function PositionDetailPage() {
  const { pool, isNotFound, poolSymbol } = useInvestedPoolFromPath();
  const navigate = useNavigate();
  const { selectedAccount } = useAccounts();
  const {
    stakingLiquidityTokensQuery: { data: stakingLpTokens },
  } = useStakingMetadata();

  const {
    delegatorStateQuery: { data: delegator },
  } = useDelegatorStateQuery();

  const delegationsCount = delegator?.delegations?.length ?? 0;

  const intl = useIntl();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isNotFound || !selectedAccount?.address) {
      navigate('/positions');
    }
  }, [isNotFound, selectedAccount?.address, navigate]);

  if (!pool) {
    return null;
  }

  const tabPages: TabPage[] | null = [
    {
      label: intl.formatMessage({ id: 'position.detail.tab.overview' }),
      path: `/positions/${poolSymbol}`,
    },
    pool.isPromoted
      ? {
          label: intl.formatMessage({ id: 'position.detail.tab.liq.mining' }),
          path: `/positions/${poolSymbol}/mining`,
        }
      : null,
    stakingLpTokens?.has(pool.liquidityTokenId)
      ? {
          label: intl.formatMessage({ id: 'position.detail.tab.staking' }),
          path: `/positions/${poolSymbol}/staking`,
          count: delegationsCount,
        }
      : null,
  ].filter(Boolean) as TabPage[];

  const activeTabIndex = Math.max(
    tabPages.findIndex(({ path }) => pathname === path),
    0,
  );

  const handleTabSelect = (i: number) => {
    navigate(tabPages[i].path);
  };

  return (
    <Container justifyContent="center" className="mt-[120px] w-[480px] pb-[80px]" column>
      <PositionDetailHeader pool={pool} />
      <TabGroup
        activeIndex={activeTabIndex}
        data={tabPages.map(({ label, count }) => ({ label, count }))}
        onSelect={handleTabSelect}
        className="px-0 mt-6 mb-8"
      />
      <Outlet />
    </Container>
  );
}
