import { fromBN, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from '@mangata-finance/sdk';
import { BN, BN_ZERO } from '@polkadot/util';
import { useRef, useState } from 'react';
import { Banner, Container, IconButton, Text, TokenIcon } from 'ui';
import { useTransition, animated } from '@react-spring/web';
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'icons/chevron-up.svg';

import {
  useInternalBalance,
  useClaimNativeRewardsFeeQuery,
  useMangataAsset,
  useSafeTransaction,
  TxType,
  TxAsset,
  useInvestedPoolFromPath,
  Asset,
  ClassName,
  AdaptiveTxFee,
  EnvConfig,
} from 'core';
import { ClaimRewardsCardHeader } from '../../components/ClaimRewardsCard/ClaimRewardsCardHeader';
import Decimal from 'decimal.js';
import {
  ClaimRewardsConfirmModal,
  ClaimRewardsConfirmModalType,
} from '../../components/ClaimRewardsConfirmModal/ClaimRewardsConfirmModal';
import {
  ToggleLiquidityModal,
  ToggleLiquidityModalType,
} from '../../components/ToggleLiquidityModal/ToggleLiquidityModal';
import { AutoCompounding } from '../../components/Compounding/AutoCompounding';
import { RewardsAprBadge } from '../../../Pool';
import { LiquidityActivationCard } from '../../components/LiquidityActivationCard/LiquidityActivationCard';
import cls from 'classnames';
import { LiquidityMiningCardSummary } from './Summary/LiquidityMiningCardSummary';
import { useIntl } from 'react-intl';
import { isNull } from 'lodash-es';

export interface LiquidityMiningCardProps extends ClassName {
  asset: Asset;
  type: 'native' | '3rdParty';
  onActivate: () => void;
  onDeactivate: () => void;
  onClaim: () => void;
  activateFee: AdaptiveTxFee | null | undefined;
  deactivateFee: AdaptiveTxFee | null | undefined;
  activeLPAmount: BN;
  availableLPAmount: BN;
  rewardsAmount: BN;
  isLoading: boolean;
  aprAmount: string | null;
  is3rdPartyLiqActive?: boolean;
}

enum ConfirmModalState {
  InActive,
  Claim,
  Activate,
  Deactivate,
}

export const LiquidityMiningCard = ({
  asset,
  type,
  className,
  onActivate,
  onDeactivate,
  onClaim,
  activateFee,
  deactivateFee,
  activeLPAmount,
  availableLPAmount,
  rewardsAmount,
  aprAmount,
  isLoading,
  is3rdPartyLiqActive,
}: LiquidityMiningCardProps) => {
  const { pool } = useInvestedPoolFromPath();
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const intl = useIntl();

  const contentRef = useRef<HTMLDivElement>(null);

  const [confirmModalState, setConfirmModalState] = useState(ConfirmModalState.InActive);
  const [currentTxType, setCurrentTxType] = useState<TxType | null>(null);
  const { getFreeBalance } = useInternalBalance();

  const { claimRewardsFeeQuery } = useClaimNativeRewardsFeeQuery(pool?.liquidityTokenId);
  const { mangataAsset } = useMangataAsset();

  const amountStr = fromBN(rewardsAmount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  const rewards: Array<[Asset, string]> | null = [[asset, amountStr]];

  const toggleLiquidityFeeAsset =
    currentTxType === TxType.DeactivateLP ? deactivateFee : activateFee;

  const { submitTxWithChecks: handleToggleWithChecks } = useSafeTransaction(
    {
      adaptiveFee: toggleLiquidityFeeAsset,
      type: currentTxType === TxType.DeactivateLP ? TxType.DeactivateLP : TxType.ActivateLP,
    },
    currentTxType === TxType.DeactivateLP ? onDeactivate : onActivate,
  );

  const { submitTxWithChecks: handleClaimWithChecks } = useSafeTransaction(
    {
      receivingAssets: mangataAsset ? [{ ...mangataAsset, amount: amountStr }] : [],
      adaptiveFee: claimRewardsFeeQuery.data,
      type: TxType.Claim,
    },
    onClaim,
  );

  const toggleIsMoreOpen = () => setIsMoreOpen(!isMoreOpen);
  const isNotActive = activeLPAmount.eq(BN_ZERO) && !isLoading;
  const showMoreSectionHeight =
    (type === 'native' && EnvConfig.isKusamaEnv ? 245 : 154) + (isNotActive ? 68 : 0);

  const showMoreTransition = useTransition(isMoreOpen, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: showMoreSectionHeight },
    leave: { opacity: 0, height: 0 },
  });

  const cardTransition = useTransition(isMoreOpen, {
    from: { opacity: 0, height: 0 },
    enter: (_i) => async (next) => {
      await next({ height: 'auto', opacity: 1 });
    },
    leave: { opacity: 0, height: 0 },
  });

  const closeConfirmModal = () => {
    setConfirmModalState(ConfirmModalState.InActive);
  };

  const confirmClaimRewards = () => {
    closeConfirmModal();
    handleClaimWithChecks();
  };

  const confirmToggleLiquidity = () => {
    closeConfirmModal();
    toggleIsMoreOpen();
    handleToggleWithChecks();
  };

  const openActivateLiqModal = () => {
    setCurrentTxType(TxType.ActivateLP);
    openToggleLiqConfirmModal();
  };
  const openDeactivateLiqModal = () => {
    setCurrentTxType(TxType.DeactivateLP);
    openToggleLiqConfirmModal();
  };

  const openToggleLiqConfirmModal = () => {
    setConfirmModalState(
      currentTxType === TxType.DeactivateLP
        ? ConfirmModalState.Deactivate
        : ConfirmModalState.Activate,
    );
  };

  const isFeeSufficient = (feeTxAsset: TxAsset | null) =>
    feeTxAsset
      ? new Decimal(getFreeBalance(feeTxAsset) || '0').gte(feeTxAsset.amount || '0')
      : true;

  if (!pool) {
    return null;
  }

  const DeactivatedMiningBanner = (
    <Banner
      variant="warning"
      message={intl.formatMessage(
        { id: 'positions.liqMiningCard.activate.info' },
        { symbol: asset.symbol },
      )}
    />
  );

  return (
    <Container className={cls('bg-widget rounded-lg', className)} fullWidth column>
      <Container fullWidth column className="p-6 ">
        <Container justifyContent="space-between" fullWidth>
          <Container alignItems="center">
            <TokenIcon token={asset} className="mr-3" size="l" />
            <Container column>
              <Text type="title-2">{asset.symbol}</Text>
              <Text type="title-4" color="secondary" id={`positions.liqMiningCard.type.${type}`} />
            </Container>
          </Container>
          <RewardsAprBadge value={aprAmount} className="" />
        </Container>
        {cardTransition((styles, item) =>
          item ? (
            <animated.div style={styles} className="w-full">
              <ClaimRewardsCardHeader
                amount={rewardsAmount}
                asset={asset}
                onClaim={onClaim}
                isActive={activeLPAmount.gt(BN_ZERO)}
                className="mt-6"
              />
            </animated.div>
          ) : (
            <animated.div style={styles} className="w-full">
              <LiquidityMiningCardSummary
                amount={rewardsAmount}
                pool={pool}
                asset={asset}
                activeLPAmount={activeLPAmount}
                availableLPAmount={availableLPAmount}
                isActive={activeLPAmount.gt(BN_ZERO)}
                className={cls('mt-6')}
              />
            </animated.div>
          ),
        )}
      </Container>

      {pool.isPromoted &&
        showMoreTransition((style, isVisible) =>
          isVisible ? (
            <animated.div style={style} className="min-w-full">
              <div ref={contentRef}>
                <div className="w-full border border-default" />
                <LiquidityActivationCard
                  pool={pool}
                  activeLPAmount={activeLPAmount}
                  availableLPAmount={availableLPAmount}
                  onActivate={openActivateLiqModal}
                  onDeactivate={openDeactivateLiqModal}
                />
                {/* <TimeIncentivizationWidget pool={pool} className="p-6 pt-0" /> */}
                {isNotActive && <Container className="px-5">{DeactivatedMiningBanner}</Container>}
                {type === 'native' && EnvConfig.isKusamaEnv && (
                  <AutoCompounding className={cls('p-6', isNotActive && 'mt-6')} />
                )}
              </div>
            </animated.div>
          ) : (
            isNotActive &&
            !isMoreOpen && (
              <Container className="px-5 w-full pb-6">{DeactivatedMiningBanner}</Container>
            )
          ),
        )}
      <div className="w-full border border-default" />
      <Container
        onClick={toggleIsMoreOpen}
        fullWidth
        className="p-2 px-5 cursor-pointer"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          id={isMoreOpen ? 'common.show.less' : 'common.show.more'}
          uppercase
          color="secondary"
          weight="bold"
        />
        <IconButton Icon={!isMoreOpen ? ChevronDownIcon : ChevronUpIcon} size="s" />
      </Container>
      {!isNull(rewards) && (
        <ClaimRewardsConfirmModal
          type={ClaimRewardsConfirmModalType.Single}
          isOpen={confirmModalState === ConfirmModalState.Claim}
          rewards={rewards}
          feeAsset={claimRewardsFeeQuery.data?.current || null}
          onConfirm={confirmClaimRewards}
          onCancel={closeConfirmModal}
          isFeeInsuff={!isFeeSufficient(claimRewardsFeeQuery.data?.current || null)}
        />
      )}
      <ToggleLiquidityModal
        isOpen={
          confirmModalState === ConfirmModalState.Activate ||
          confirmModalState === ConfirmModalState.Deactivate
        }
        type={
          currentTxType === TxType.DeactivateLP
            ? ToggleLiquidityModalType.Deactivate
            : ToggleLiquidityModalType.Activate
        }
        isBatchDeactivation={
          !!is3rdPartyLiqActive && currentTxType === TxType.DeactivateLP && type === 'native'
        }
        feeAsset={toggleLiquidityFeeAsset?.current || null}
        onConfirm={confirmToggleLiquidity}
        onCancel={closeConfirmModal}
        isFeeInsuff={!isFeeSufficient(toggleLiquidityFeeAsset?.current || null)}
      />
    </Container>
  );
};
