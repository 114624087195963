import cls from 'classnames';

import { Text } from '../Text/Text';
import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { ReactComponent as CrownIcon } from '../../../assets/icons/promoted.svg';
import { Skeleton } from '../Skeleton/Skeleton';
import { useIntl } from 'react-intl';

export interface RewardsBadgeProps extends ClassName, TestId {
  showIcon?: boolean;
  variant?: 'apr' | 'amount';
  showBackground?: boolean;
  isLoading?: boolean;
  value: string | null;
}

export function RewardsBadge({
  className,
  'data-testid': testId,
  showIcon = true,
  showBackground = true,
  variant = 'amount',
  isLoading,
  value,
}: RewardsBadgeProps) {
  const intl = useIntl();

  const body = {
    apr: `${value} %`,
    amount: `${value} ${intl.formatMessage({ id: 'common.mainToken' })}`,
  };

  return value ? (
    <Container
      inline
      alignItems="center"
      justifyContent="center"
      data-testid={testId}
      className={cls(
        'rounded py-1 _accent-premium',
        showBackground && 'bg-accent-soft',
        variant === 'apr' ? 'px-1' : 'px-2',
        className,
      )}
    >
      {showIcon && <CrownIcon className={cls('w-4 h-4 mr-1.5', 'fill-icon-accent')} />}
      {isLoading ? (
        <Skeleton height="12px" width="80px" rounded />
      ) : (
        <Text type="label" color="accent">
          {body[variant]}
        </Text>
      )}
    </Container>
  ) : null;
}
