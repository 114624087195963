import { BN_ZERO, BN_THOUSAND } from '@mangata-finance/sdk';
import { BN } from '@polkadot/util';

export const BN_AFTER_COMMISION: BN = new BN(997); // commission 0.3% per swap

export const getOutputAmount = (inputReserve: BN, outputReserve: BN, amount: BN) => {
  const numerator: BN = outputReserve.mul(amount).mul(BN_AFTER_COMMISION);
  const denominator: BN = inputReserve.mul(BN_THOUSAND).add(amount.mul(BN_AFTER_COMMISION));

  if (numerator.lte(BN_ZERO) || denominator.lte(BN_ZERO)) {
    return {
      value: BN_ZERO,
      isAmountSufficient: true,
    };
  }

  return {
    value: numerator.div(denominator),
    isAmountSufficient: numerator.gt(denominator),
  };
};

export const getInputAmount = (inputReserve: BN, outputReserve: BN, amount: BN) => {
  const numerator: BN = inputReserve.mul(amount).mul(BN_THOUSAND);
  const denominator: BN = outputReserve.sub(amount).mul(BN_AFTER_COMMISION);

  if (numerator.lte(BN_ZERO) || denominator.lte(BN_ZERO)) {
    return {
      value: BN_ZERO,
      isAmountSufficient: true,
    };
  }

  return {
    value: numerator.div(denominator).addn(1),
    isAmountSufficient: numerator.gt(denominator),
  };
};
