import { transformInvestedPools } from './transformers/InvestedPoolsTransformers';
import { useTokenBucketQuery } from '../../token/bucket/query/useTokenBucketQuery';
import { useAssets } from '../../token';
import { useTokensIssuanceQuery } from '../../token';
import { useInternalBalance, useReserveBalancesQuery } from '../../balance';
import { useAllPoolsQuery } from '../all/query/useAllPoolsQuery';
import { useMemo } from 'react';
import { useNativeRewardsInfoQuery } from '../../rewards';

export const useInvestedPools = () => {
  const { assets } = useAssets();
  const { balances } = useInternalBalance();
  const { allPoolsQuery } = useAllPoolsQuery();
  const { nativeRewardsInfoQuery } = useNativeRewardsInfoQuery();
  const { tokensIssuanceQuery } = useTokensIssuanceQuery();
  const { reserveBalancesQuery } = useReserveBalancesQuery();
  const { isFirstPoolTokenLeader } = useTokenBucketQuery();

  const isLoading =
    !allPoolsQuery.data ||
    !assets ||
    !balances ||
    !reserveBalancesQuery.data ||
    !nativeRewardsInfoQuery.data ||
    !tokensIssuanceQuery.data;

  const data = useMemo(() => {
    return transformInvestedPools(
      allPoolsQuery.data,
      assets,
      balances,
      reserveBalancesQuery.data,
      tokensIssuanceQuery.data,
      nativeRewardsInfoQuery.data,
      isFirstPoolTokenLeader,
    );
  }, [
    allPoolsQuery.data,
    assets,
    balances,
    reserveBalancesQuery.data,
    tokensIssuanceQuery.data,
    nativeRewardsInfoQuery.data,
    isFirstPoolTokenLeader,
  ]);

  return { data, isLoading };
};
