import { PoolWithRatio, PoolWithShare, usePromotedPools } from '../pool';
import { use3rdPartyRewardsPoolApr } from './3rdParty';
import Decimal from 'decimal.js';

export const useTotalPoolRewardsApr = (pool: PoolWithRatio | PoolWithShare) => {
  const { aprSum: thirdPartyRewardsApr, aprMap: thirdPartyAprMap } =
    use3rdPartyRewardsPoolApr(pool);
  const { poolAprsByLPId: nativePoolAprsByLPId } = usePromotedPools();

  const nativeRewardsApr = nativePoolAprsByLPId[pool.liquidityTokenId];
  const totalApr = new Decimal(nativeRewardsApr || 0).add(thirdPartyRewardsApr || 0);

  return {
    totalApr: totalApr?.gt(0) ? totalApr.round().toString() : null,
    thirdPartyAprMap,
    nativeRewardsApr,
  };
};
