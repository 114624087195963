import { fromBN } from '@mangata-finance/sdk';
import { useInternalBalance, useMangataAsset } from 'core';
import { Container, FiatValue, FormatAmount, Text } from 'ui';
import { ReactComponent as LockIcon } from 'icons/round-lock.svg';

export const NativeTokenWidgetBalance = () => {
  const { mgxBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();

  if (!mgxBalance || !mangataAsset) {
    return null;
  }

  const free = fromBN(mgxBalance.free, mangataAsset.decimals);
  const locked = fromBN(mgxBalance.reserved, mangataAsset.decimals);

  return (
    <Container column fullWidth className="bg-widget px-5 py-4 rounded-lg">
      <Text type="title-1">
        <FormatAmount
          value={free}
          options={{ maxChars: 10, precision: 0, nonZeroPrecision: false }}
        />
        <Text color="secondary" type="title-1">
          {' '}
          {mangataAsset.symbol}
        </Text>
      </Text>

      <Text color="secondary" type="title-4" className="mt-1">
        <FiatValue id={mangataAsset.id} amount={free} />
      </Text>

      <Container fullWidth alignItems="center" justifyContent="space-between" className="mt-3">
        <Text color="secondary">Temporarily Locked</Text>

        <Container alignItems="center">
          <LockIcon className="mr-1 w-4 h-auto fill-icon-secondary" />
          <Text color="primary">
            <FormatAmount
              value={locked}
              options={{ maxChars: 10, precision: 0, nonZeroPrecision: false }}
            />{' '}
            {mangataAsset.symbol}
          </Text>
        </Container>
      </Container>
    </Container>
  );
};
