import { Container, IconButton, Text, shortenAddress, WalletLogo } from 'ui';
import { ReactComponent as ExpandIcon } from 'icons/expand.svg';
import { ReactComponent as WalletIcon } from 'icons/wallet.svg';
import { ReactComponent as CollapseIcon } from 'icons/collapse.svg';
import cls from 'classnames';
import { TestId, WalletAccount, useModalStore } from 'core';

interface AccountInfoProps extends TestId {
  wallet: string;
  account: WalletAccount;
  open: boolean;
  onToggle: () => void;
}

export function AccountInfo({
  wallet,
  account,
  onToggle,
  open,
  'data-testid': testId,
}: AccountInfoProps) {
  const { openAccountSettings } = useModalStore();

  const ACCOUNT_INFO_CLASS = cls(
    'transition-all group',
    account && 'visible',
    !account && 'invisible hidden',
    open ? 'p-4' : 'px-3 py-2 h-[56px]',
  );

  const renderAccountInfo = () => {
    return (
      <Container
        data-testid={`${testId}-account`}
        alignItems="center"
        justifyContent="space-between"
      >
        <WalletLogo className="rounded-full mr-4 w-8 h-8" wallet={wallet} />
        <Container column>
          <Text
            className={cls(
              'overflow-hidden text-ellipsis whitespace-nowrap',
              open ? 'w-[170px]' : 'w-[160px] group-hover:w-[120px] ',
            )}
          >
            {account.name || shortenAddress(account.address)}
          </Text>
          {account.name && (
            <Text color="secondary" type="section" className="normal-case">
              {shortenAddress(account.address)}
            </Text>
          )}
        </Container>
      </Container>
    );
  };

  const renderActions = () => {
    if (!open) {
      return (
        <Container alignItems="center" className="[&>*]:transition-all [&>*]:duration-300 relative">
          <Container data-testid={`${testId}-expand`} alignItems="center" row>
            <div className="h-[16px] w-[16px]">
              <ExpandIcon className="fill-icon-secondary w-[16px] h-[16px]" />
            </div>
            <Container
              alignItems="center"
              className="group-hover:w-[50px] overflow-hidden w-[0] transition-all duration-300"
            >
              <Text
                color="secondary"
                id="wallet.open"
                className="uppercase ml-3 invisible group-hover:visible "
              />
            </Container>
          </Container>
        </Container>
      );
    }

    return (
      <Container row alignItems="center">
        <IconButton
          data-testid={`${testId}-settings`}
          Icon={WalletIcon}
          size="l"
          onClick={openAccountSettings}
        />
        <IconButton Icon={CollapseIcon} size="l" onClick={onToggle} />
      </Container>
    );
  };

  return (
    <Container
      justifyContent="space-between"
      alignItems="stretch"
      row
      fullWidth
      className={cls(ACCOUNT_INFO_CLASS, !open && 'cursor-pointer')}
      onClick={!open ? onToggle : undefined}
    >
      {renderAccountInfo()}
      {renderActions()}
    </Container>
  );
}
