import { Asset, useAllPoolsQuery, usePromotedPools } from 'core';
import { isEmpty } from 'lodash-es';
import { Container, Skeleton, Text } from 'ui';
import { PoolListItem } from '../../../pools/list/item/PoolListItem';

interface TokenDetailPoolsProps {
  asset?: Asset;
}

export function TokenDetailPools({ asset }: TokenDetailPoolsProps) {
  const {
    allPoolsQuery: { data: _pools, isLoading },
  } = useAllPoolsQuery();

  const { poolAprsByLPId } = usePromotedPools();

  const pools = _pools?.baseList.filter((pool) => pool.symbols.includes(asset?.symbol ?? ''));

  const renderTitle = () => {
    if (isLoading) {
      return <Skeleton className="w-[120px] h-[15px]" />;
    }

    if (isEmpty(pools)) {
      return null;
    }

    return <Text type="title-3" id="token.detail.pools.title" />;
  };

  const renderContent = () => {
    if (pools === undefined) {
      return Array(3)
        .fill(true)
        .map((_, i) => <Skeleton key={i} className="w-full h-[115px] !rounded-lg" />);
    }

    return pools?.map((pool) => (
      <PoolListItem
        key={pool.id}
        pool={pool}
        apr={poolAprsByLPId[pool.liquidityTokenId]}
        data-testid={`pool-item-${pool.firstAsset.symbol}-${pool.secondAsset.symbol}`}
      />
    ));
  };

  return (
    <Container fullWidth column className="my-14" data-testid="token-detail-pools">
      {renderTitle()}
      <div className="grid grid-cols-2 gap-4 mt-4 w-full min-h-[180px]">{renderContent()}</div>
    </Container>
  );
}
