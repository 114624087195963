import { ApiPromise } from '@polkadot/api';
import { KeyringOptions } from '@polkadot/keyring/types';
import { Header } from '@polkadot/types/interfaces';
import { StashChannel, StashToken } from '../../../stash';
import { IdLookupFn, XcmError, XcmOperation } from './Xcm';
import {
  getOriginIdentifiersForDeposit,
  getDestinationIdentifiersForDeposit,
  getOriginIdentifiersForWithdrawal,
  getDestinationIdentifiersForWithdrawal,
} from './identifier';

type ResolveFn<T = void> = (value: T | PromiseLike<T>) => void;
type RejectFn = (reason?: unknown) => void;

const BLOCK_COUNT_BEFORE_FAIL = 15;
export const KEYRING_TYPE: KeyringOptions = { type: 'sr25519' };

export class XcmTransferValidationService {
  private originBlockCount = 0;
  private destinationBlockCount = 0;

  private originIdentifiers: string[] | undefined;
  private destIdentifiers: string[] = [];

  private originUnsubscribe?: () => void;
  private destUnsubscribe?: () => void;

  private resolve?: ResolveFn;
  private reject?: RejectFn;

  constructor(
    private readonly originApi: ApiPromise,
    private readonly destinationApi: ApiPromise,
    private readonly originAddress: string,
    private readonly token: StashToken,
    private readonly channel: StashChannel,
    private readonly operation: XcmOperation,
  ) {}

  async verify() {
    this.originUnsubscribe = await this.subscribeNewHeads(
      this.originApi,
      this.handleFinalizedHeadsForOrigin(
        this.operation === XcmOperation.Deposit
          ? getOriginIdentifiersForDeposit
          : getOriginIdentifiersForWithdrawal,
      ),
    );
    this.destUnsubscribe = await this.subscribeNewHeads(
      this.destinationApi,
      this.handleFinalizedHeadsForDestination(
        this.operation === XcmOperation.Deposit
          ? getDestinationIdentifiersForDeposit
          : getDestinationIdentifiersForWithdrawal,
      ),
    );

    return this.initialize();
  }

  private handleFinalizedHeadsForOrigin = (idLookupFn: IdLookupFn) => async (header: Header) => {
    this.originBlockCount++;

    this.checkForBlockLimit(this.originBlockCount, this.reject, this.originUnsubscribe);

    const ids = await idLookupFn(
      this.originApi,
      this.token,
      this.channel,
      header,
      this.originAddress,
    );

    if (ids !== null) {
      this.originIdentifiers = Array.isArray(ids) ? ids : [ids];
      this.originUnsubscribe?.();
    }
  };

  private handleFinalizedHeadsForDestination =
    (idLookupFn: IdLookupFn) => async (header: Header) => {
      this.destinationBlockCount++;

      this.checkForBlockLimit(this.destinationBlockCount, this.reject, this.destUnsubscribe);

      const ids = await idLookupFn(this.destinationApi, this.token, this.channel, header);

      if (ids !== null) {
        this.destIdentifiers.push(...ids);
      }

      if (
        this.originIdentifiers &&
        this.destIdentifiers.some((id) => this.originIdentifiers?.includes(id))
      ) {
        this.destUnsubscribe?.();
        this.resolve?.();
      }
    };

  private async initialize() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private async subscribeNewHeads(api: ApiPromise, handler: (header: Header) => void) {
    return api.rpc.chain.subscribeNewHeads(handler);
  }

  private checkForBlockLimit(count: number, reject?: RejectFn, unsub?: () => void) {
    if (count >= BLOCK_COUNT_BEFORE_FAIL) {
      unsub?.();
      reject?.(new Error(XcmError.ValidatorBlockLimitReached));
    }
  }
}
