import { useCallback, useEffect } from 'react';
import { Decimal } from 'decimal.js';

import { TxAsset, TxType } from '..';
import { useInternalBalance } from '../../balance/internal';
import { useSafeTxModalStore } from './store/useSafeTxModalStore';
import { AdaptiveTxFee } from '../../fee/adaptive';
import { EnvConfig } from '../../../envConfig';
import { useFeeMetadataQuery } from '../../swap/private/useFeeMetadataQuery';
import { useMetamaskNetwork } from '../../metamask';

interface SafeTransactionProps {
  receivingAssets?: TxAsset[];
  spendingAssets?: TxAsset[];
  adaptiveFee?: AdaptiveTxFee | null | undefined;
  feeTxAsset?: TxAsset | null;
  type: TxType;
}

export const useSafeTransaction = (props: SafeTransactionProps, submitCallback: () => void) => {
  const { getFreeBalance } = useInternalBalance();
  const { triggerModal, dispose, ...store } = useSafeTxModalStore();
  const { mgxLockAmount } = useFeeMetadataQuery();
  const { receivingAssets, spendingAssets, adaptiveFee, feeTxAsset, type } = props;
  const { isChainSwitchRequired, requestDefaultChain } = useMetamaskNetwork();
  const isProceedClicked = store.isProceedClicked && store.type === type;

  useEffect(() => {
    if (isProceedClicked) {
      submitCallback();
      dispose();
    }
  }, [isProceedClicked, dispose, submitCallback]);

  const submitTxWithChecks = useCallback(() => {
    if (isChainSwitchRequired) {
      requestDefaultChain();
      return;
    }

    if (!adaptiveFee?.current && !feeTxAsset) {
      console.error('Fee asset is not defined');
      return;
    }

    const feeTxAssets = adaptiveFee?.all || [feeTxAsset];
    const currentTxFeeAsset = adaptiveFee?.current || feeTxAsset;

    const remainingBalances = feeTxAssets
      .map((feeAsset) => {
        const feeAssetBalance = getFreeBalance(feeAsset);

        if (!feeAssetBalance || !feeAsset) {
          return null;
        }

        const spendingFeeAssetAmount = spendingAssets?.find(
          (txAsset) => txAsset.id === feeAsset?.id,
        )?.amount;

        const receivingFeeAssetAmount = receivingAssets?.find(
          (txAsset) => txAsset.id === feeAsset?.id,
        )?.amount;

        const willBeDeducted = currentTxFeeAsset?.id === feeAsset.id;
        const remainingFeeBalanceAfterTx = new Decimal(feeAssetBalance)
          .sub(spendingFeeAssetAmount || 0)
          .add(receivingFeeAssetAmount || 0)
          .sub(willBeDeducted ? feeAsset.amount : 0);

        return {
          ...feeAsset,
          freeBalance: feeAssetBalance,
          remainingBalance: remainingFeeBalanceAfterTx.lt(0)
            ? '0'
            : remainingFeeBalanceAfterTx.toFixed(),
        };
      })
      .filter((item) => item !== null);

    const isThereEnoughForNextTrade = remainingBalances.some((feeAsset) => {
      if (!feeAsset) {
        return false;
      }
      const nextFeeAmount = feeAsset.id === EnvConfig.TOKEN_ID ? mgxLockAmount : feeAsset.amount;

      return new Decimal(feeAsset.remainingBalance).gte(nextFeeAmount);
    });

    const assetToPreview = remainingBalances.find((fee) => currentTxFeeAsset?.id === fee?.id);

    if (!isThereEnoughForNextTrade && assetToPreview && currentTxFeeAsset) {
      triggerModal(
        {
          asset: currentTxFeeAsset,
          remainingBalanceAfterTx: assetToPreview?.remainingBalance,
          currentBalance: getFreeBalance(assetToPreview) || '0',
          isAdaptive: EnvConfig.isKusamaEnv ? !!adaptiveFee : false,
        },
        type,
      );
      return;
    }

    submitCallback();
  }, [
    spendingAssets,
    receivingAssets,
    adaptiveFee,
    feeTxAsset,
    getFreeBalance,
    triggerModal,
    submitCallback,
    requestDefaultChain,
    mgxLockAmount,
    isChainSwitchRequired,
    type,
  ]);

  return {
    submitTxWithChecks,
  };
};
