import { MangataInstance, toBN } from '@mangata-finance/sdk';
import { TxAsset } from '../../../transaction';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';

type AddLiqudityFeeQueryKey = [
  key: string,
  selectedAddress: string | null,
  firstToken: string | null | undefined,
  secondToken: string | null | undefined,
];

export const TOLERANCE_KOEF = 1.01;

export const getAddLiqudityFee =
  (
    sdk: MangataInstance | null,
    poolIds: string[] | undefined,
    firstToken: TxAsset | undefined | null,
    secondToken: TxAsset | undefined | null,
  ) =>
  async ({ queryKey }: QueryFunctionContext<AddLiqudityFeeQueryKey>) => {
    const [, selectedAddress] = queryKey;

    if (!selectedAddress || !sdk || !firstToken || !secondToken) {
      return null;
    }

    const commonProps = {
      firstTokenId: firstToken.id,
      secondTokenId: secondToken.id,
      firstTokenAmount: toBN(firstToken.amount, firstToken.decimals),
      account: selectedAddress,
    };

    const poolId = `${firstToken.id}-${secondToken.id}`;

    if (poolIds?.includes(poolId)) {
      const mgxFee = await sdk.fee.mintLiquidity({
        ...commonProps,
        expectedSecondTokenAmount: toBN(
          new Decimal(secondToken.amount).mul(TOLERANCE_KOEF).toString(),
          secondToken.decimals,
        ),
      });

      return mgxFee;
    } else {
      const mgxFee = await sdk.fee.createPool({
        ...commonProps,
        secondTokenAmount: toBN(secondToken.amount, secondToken.decimals),
      });

      return mgxFee;
    }
  };
