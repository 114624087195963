import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS } from '../../../../services';
import { getTokenListStats, getTokenDetailStats } from '../services/tokenStatsService';

export const useTokenStatsQuery = (symbol?: string) => {
  const tokenStatsListQuery = useQuery(['token-list-stats'], getTokenListStats, {
    ...DEFAULT_QUERY_OPTIONS,
  });

  const tokenStatsDetailQuery = useQuery(['token-detail-stats', symbol], getTokenDetailStats, {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!symbol,
  });

  return {
    tokenStatsListQuery,
    tokenStatsDetailQuery,
  };
};
