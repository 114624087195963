import { AnyMangataError } from '../../services';
import { uniqueId } from 'lodash-es';
import {
  TransactionError,
  TransactionMetadata,
  TransactionStore,
} from './private/useTransactionStore';
import { TxStatus, TxType } from './Transaction';
import { MangataGenericEvent } from '@mangata-finance/sdk';
import { ISubmittableResult } from '@polkadot/types/types';

export type HandleStatusChangeFn = (result: ISubmittableResult) => void;
export type ExtrinsicStatusCallback = (result: MangataGenericEvent[]) => void;

const DEFAULT_TX_HIDE_TIMEOUT = 5000;
const ERROR_TX_HIDE_TIMEOUT = 30000;

export interface TxOptions {
  useDefaultSigner?: boolean;
  doneOnTrigger?: boolean;
  onDone?: () => void;
  onError?: (e: string) => void;
  isVisible?: boolean;
  showExplorerLink?: boolean;
}
export abstract class TxBase {
  id: string;

  protected options: TxOptions = {
    useDefaultSigner: false,
    doneOnTrigger: false,
    showExplorerLink: true,
    isVisible: true,
  };

  protected type?: TxType;
  protected metadata?: TransactionMetadata;
  protected explorerUrl?: string;

  private timeout: ReturnType<typeof setTimeout> | null = null;

  abstract send(): Promise<unknown>;
  abstract setTx(call: unknown): this;

  constructor(protected store: TransactionStore) {
    this.id = uniqueId();
  }

  setOptions(options: TxOptions) {
    this.options = { ...this.options, ...options };
    return this;
  }

  create(type: TxType) {
    this.type = type;
    return this;
  }

  setMetadata(metadata: TransactionMetadata) {
    this.metadata = metadata;
    return this;
  }

  build() {
    this.store.set(this.id, {
      type: this.type,
      status: TxStatus.Confirming,
      id: this.id,
      isVisible: this.options.isVisible,
      explorerUrl: this.options.showExplorerLink ? this.explorerUrl : undefined,
      metadata: this.metadata,
      options: this.options,
    });

    return this;
  }

  protected handleError(e: unknown) {
    const error = e as AnyMangataError;
    const errMessage = (error.data ?? error.message).toString();

    this.doneWithError({ name: errMessage });
  }

  protected scheduleHide() {
    const tx = this.store.list.find((tx) => tx.id === this.id);

    if (tx?.status === TxStatus.Error) {
      this.hide(ERROR_TX_HIDE_TIMEOUT);
      return;
    }

    this.hide();
  }

  private hide(ms = DEFAULT_TX_HIDE_TIMEOUT) {
    this.resetTimeout();

    this.timeout = setTimeout(() => {
      this.store.hide(this.id);
      this.resetTimeout();
    }, ms);
  }

  done() {
    this.store.set(this.id, { status: TxStatus.Success });
    this.options.onDone?.();
    this.scheduleHide();
  }

  doneWithError(error: TransactionError) {
    this.store.set(this.id, { status: TxStatus.Error, error });
    this.options.onError?.(error.name);
    this.scheduleHide();
  }

  private resetTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
