import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface PoolsUIStore {
  isSearchOpen: boolean;
  setIsSearchOpen: (isOpen: boolean) => void;
}

export const usePoolsUIStore = create(
  devtools<PoolsUIStore>((set) => ({
    isSearchOpen: false,
    setIsSearchOpen: (isSearchOpen) => set({ isSearchOpen }),
  })),
);
