import { useEffect } from 'react';
import {
  Button,
  Container,
  AmountTooltip,
  Skeleton,
  Text,
  TokenInput,
  WidgetDetailRow,
  ErrorBanner,
  AccountSelect,
  TokenIcon,
  shortenAddress,
} from 'ui';
import { ChainSelector, getChainIconUrl } from '../components/ChainSelector/ChainSelector';
import { useWithdrawalStore } from '../store/useWithdrawalStore';
import { useRollupWithdrawalState } from './useRollupWithdrawalState';
import {
  FeeAssetAmount,
  TxAsset,
  TxType,
  useInternalBalance,
  useMangataAsset,
  useMetamaskNetwork,
  useSafeTransaction,
  XcmOperation,
  useRollupChainsQuery,
} from 'core';
import { ReactComponent as ArrowUpRightIcon } from 'assets/icons/ArrowCircleUpRight.svg';
import {
  useRollupWithdrawalValidation,
  WithdrawalValidationError,
} from './useRollupWithdrawalValidation';
import { isEmpty, isNil } from 'lodash-es';

export function RollupWithdrawalForm() {
  const {
    submitWithdrawal,
    selectedAccount,
    withdrawalAccounts,
    withdrawalAssets,
    withdrawalBalances,
    handleAccountSelect,
    isWithdrawalReady,
    isWithdrawing,
  } = useRollupWithdrawalState();
  const { error, fee, getErrorText, getButtonText } = useRollupWithdrawalValidation();
  const { getFreeBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();
  const { isChainSwitchRequired, defaultChain } = useMetamaskNetwork();
  const { destinationAccount, asset, setAsset, amount, setAmount, chain, setChain } =
    useWithdrawalStore();
  const { rollupChainsQuery } = useRollupChainsQuery();

  useEffect(() => {
    if (defaultChain && isNil(chain?.id)) {
      setChain(
        {
          id: defaultChain.chainId,
          title: defaultChain.name,
          icon: getChainIconUrl(defaultChain.chainId),
        },
        false,
      );
    }
  }, [defaultChain, chain?.id, setChain]);

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: asset && amount ? [{ ...asset, amount }] : [],
      adaptiveFee: fee,
      type: TxType.RollupWithdrawal,
    },
    submitWithdrawal,
  );

  const renderFee = (fee?: FeeAssetAmount | TxAsset | null) => {
    if (fee) {
      return (
        <Text
          type="label"
          color={
            error[WithdrawalValidationError.InsufficientBalanceForOriginFee] ? 'alert' : 'secondary'
          }
          className="mr-2"
          data-testid="fee-value"
        >
          <AmountTooltip
            id={fee.amount}
            value={fee.amount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
          />
          {'asset' in fee ? fee.asset.symbol : fee.symbol}
        </Text>
      );
    }

    return <Skeleton width="80px" height="10px" rounded />;
  };

  return (
    <Container fullWidth column>
      <Text id="bridge.withdrawal.title" type="display-2" />

      <Container className="w-[60%] mt-6" alignItems="center">
        <Text color="secondary" id="chainSelector.to" uppercase className="mr-2" />
        <ChainSelector
          chain={chain}
          setChain={setChain}
          operation={XcmOperation.Withdrawal}
          channels={rollupChainsQuery.data}
        />
      </Container>

      <Container className="mt-3 pl-6" fullWidth>
        {withdrawalAccounts && (
          <AccountSelect
            accounts={withdrawalAccounts}
            selectedAccount={destinationAccount}
            onSelect={handleAccountSelect}
            data-testid="account-select-from"
          />
        )}
      </Container>

      {chain && withdrawalAssets && (
        <TokenInput
          isInvalid={error[WithdrawalValidationError.InsufficientBalance]}
          selectedToken={asset}
          onTokenSelect={setAsset}
          onAmountChange={setAmount}
          tokens={withdrawalAssets}
          balances={withdrawalBalances}
          amount={amount || ''}
          freeBalance={getFreeBalance(asset)}
          className="mt-6 mb-2"
          settingsVisible={false}
        />
      )}

      {isWithdrawalReady && (
        <WidgetDetailRow
          className="mb-2"
          label={
            <Text
              color={
                error[WithdrawalValidationError.InsufficientBalanceForOriginFee]
                  ? 'alert'
                  : 'secondary'
              }
              id="bridge.details.originFee"
              type="label"
              className="mr-2"
            />
          }
          value={renderFee(fee?.current)}
          data-testid="destination-fee"
        />
      )}

      {!isEmpty(error) && (
        <ErrorBanner className="mb-2" data-testid="withdrawal-error-message">
          {getErrorText()}
        </ErrorBanner>
      )}

      {selectedAccount && (
        <Container column className="mt-2" fullWidth>
          <Container alignItems="center" className="-mx-6" style={{ width: 'calc(100% + 48px)' }}>
            <div className="h-[1px] bg-input w-6" />
            <Text
              id="bridge.withdraw.from"
              uppercase
              color="secondary"
              className="mx-2 whitespace-nowrap"
            />
            <div className="h-[1px] bg-input w-full" />
          </Container>

          <Container
            fullWidth
            alignItems="center"
            justifyContent="space-between"
            className="mt-4 rounded-xl bg-widget p-4"
          >
            <Container alignItems="center">
              <ArrowUpRightIcon className="w-8 h-auto fill-icon-default" />
              <Container column className="ml-4">
                <Text type="title-3">{selectedAccount.name}</Text>
                <Text type="body-s" color="secondary">
                  {shortenAddress(selectedAccount.address)}
                </Text>
              </Container>
            </Container>
            {mangataAsset && (
              <Container>
                <Text color="secondary" id="common.on" />
                <TokenIcon token={mangataAsset} size="xs" className="mx-2" />
                <Text id="common.defaultChain" />
              </Container>
            )}
          </Container>
        </Container>
      )}

      <Button
        isDisabled={!isEmpty(error) || !isWithdrawalReady || isWithdrawing}
        variant="primary"
        size="l"
        fullWidth
        onClick={submitTxWithChecks}
        data-testid="submit-withdrawal-button"
        className="mt-6"
      >
        <Text id={getButtonText(isChainSwitchRequired)} type="body-l" />
      </Button>
    </Container>
  );
}
