import { useMemo } from 'react';
import { useCollatorApyQueries } from './query/useCollatorApyQuery';

export const useCollatorsApy = (addresses?: string[]) => {
  const { collatorApyQueries } = useCollatorApyQueries(addresses);

  const collatorApyMap = useMemo(
    () =>
      collatorApyQueries.reduce((acc, { data }) => {
        if (!data) {
          return acc;
        }

        const { liquidityTokenId, apy, id } = data;

        if (!acc.has(id)) {
          acc.set(id, new Map());
        }

        acc.get(id)?.set(liquidityTokenId, apy);

        return acc;
      }, new Map<string, Map<string, string>>()),
    [collatorApyQueries],
  );

  return {
    collatorApyMap,
  };
};
