import { ClassName, PoolWithShare } from 'core';

export type RemoveLiquidityWidgetProps = ClassName & {
  onCancel?: () => void;
  onSuccess?: () => void;
  pool: PoolWithShare;
};

export enum RemoveLiquidityFormError {
  InsufficientFee = 'InsufficientFee',
  AboveMaxAmount = 'AboveMaxAmount',
}
