import Decimal from 'decimal.js';
import { Asset, WalletAccount } from 'core';
import { ChainSelectItem } from 'ui';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface DepositUIStore {
  chain: ChainSelectItem | null;
  destinationAccount: WalletAccount | null;
  amount: string | null;
  computed: {
    buttonLabelId: string;
    hasAmount: boolean;
  };
  asset: Asset | null;
  setChain: (chain: ChainSelectItem | null, withAccountReset?: boolean) => void;
  setDestinationAccount: (destinationAccount: WalletAccount | null) => void;
  setAsset: (asset: Asset | null) => void;
  setAmount: (amount: string | null) => void;
  dispose: () => void;
}

export const useWithdrawalStore = create(
  devtools<DepositUIStore>((set, get) => ({
    chain: null,
    destinationAccount: null,
    amount: null,
    asset: null,
    computed: {
      get buttonLabelId() {
        return computeButtonLabel(get());
      },
      get hasAmount() {
        return computeHasAmount(get());
      },
    },
    setAmount: (amount) => set({ amount }),
    setChain: (chain, withAccountReset = true) => {
      set(
        withAccountReset
          ? { chain, asset: null, amount: null, destinationAccount: null }
          : { chain, asset: null, amount: null },
      );
    },
    setDestinationAccount: (destinationAccount) => set({ destinationAccount }),
    setAsset: (asset) => set({ asset }),
    dispose: () => set({ chain: null, destinationAccount: null, amount: null, asset: null }),
  })),
);

function computeHasAmount(state: DepositUIStore) {
  if (!state || !state.asset || !state.amount) return false;

  try {
    const hasAmount = new Decimal(state.amount).gt(new Decimal(0));
    return hasAmount;
  } catch {
    return false;
  }
}

function computeButtonLabel(state: DepositUIStore): string {
  switch (true) {
    case state === undefined:
      return 'bridge.button.withdraw';
    case state.chain === null:
      return 'bridge.button.selectChain';
    case state.asset === null:
      return 'bridge.button.selectToken';
    case state.amount === null:
      return 'bridge.button.amount';
    case state.destinationAccount === null:
      return 'bridge.button.selectAccount';
    default:
      return 'bridge.button.withdraw';
  }
}
