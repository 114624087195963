import { ApiPromise } from '@polkadot/api';
import { z } from 'zod';
import { Codec } from '@polkadot/types/types';
import { XcmEvent } from '../Xcm';
import { EventDataSchema, FrameSystemEventRecord } from '../schema';

export function listEvents(codec: Codec, api: ApiPromise) {
  const events = [...api.createType('Vec<FrameSystemEventRecord>', codec).values()];

  return events;
}

function parseEventsToSchema<T extends EventDataSchema>(
  codec: Codec,
  api: ApiPromise,
  schema: z.ZodSchema<T>,
) {
  const events = listEvents(codec, api);

  return events
    ?.filter((event) => {
      const _ = FrameSystemEventRecord(schema).safeParse(event.toHuman());
      return _.success;
    })
    .map((event) => FrameSystemEventRecord(schema).parse(event.toHuman()));
}

export function findEvents<T extends EventDataSchema>(
  codec: Codec,
  api: ApiPromise,
  schema: z.ZodSchema<T>,
  xcmEvent: XcmEvent,
) {
  const events = listEvents(codec, api);
  const [pallet, method] = xcmEvent.split('.');

  if (events === null || events === undefined || events.length === 0) {
    return [];
  }

  const parsed = parseEventsToSchema<T>(codec, api, schema);

  return parsed.filter(({ event }) => event?.section === pallet && event.method === method);
}
