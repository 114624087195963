import { SignedBlock } from '@polkadot/types/interfaces';
import { Codec } from '@polkadot/types/types';
import { ApiPromise, Keyring } from '@polkadot/api';
import { z } from 'zod';
import { findEvents } from '../block/event';
import { XcmpQueueDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';
import { listExtrinsics } from '../block/extrinsic';
import { KEYRING_TYPE } from '../validator';

export function xcmpMessageSentValidator(
  api: ApiPromise,
  eventsCodec: Codec,
  address: string,
  block: SignedBlock,
) {
  const events = findEvents<z.infer<typeof XcmpQueueDataSchema>>(
    eventsCodec,
    api,
    XcmpQueueDataSchema,
    XcmEvent.XcmpMessageSent,
  );

  if (events.length === 0) {
    return null;
  }

  const extrinsics = listExtrinsics(block);
  const extrinsicIndex = extrinsics.findIndex((extrinsic) => {
    if (typeof extrinsic.signer === 'string') {
      return extrinsic.signer === address;
    }

    if (extrinsic.signer?.Id) {
      return new Keyring(KEYRING_TYPE).encodeAddress(extrinsic.signer.Id) === address;
    }

    return false;
  });

  if (extrinsicIndex === -1) {
    return null;
  }

  const messageHash = events.find((event) => {
    const applyExtrinsicValue =
      typeof event.phase === 'string' ? event.phase : event.phase.ApplyExtrinsic;
    return applyExtrinsicValue === extrinsicIndex.toString();
  })?.event.data.messageHash;

  if (!messageHash) {
    return null;
  }

  return messageHash;
}
