import { Outlet } from 'react-router';
import { Container, Text } from 'ui';

export function PositionsPage() {
  return (
    <Container className="pt-[120px] max-h-[100vh]" column>
      <Text type="display" className="text-center mt-4 pb-0" id="position.page.title" />
      <Text
        type="body-l"
        className="text-center mt-3 mb-10"
        color="secondary"
        id="position.page.desc"
      />
      <Outlet />
    </Container>
  );
}
