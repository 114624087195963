import { Container, Text } from 'ui';
import { ReactComponent as InfoIcon } from 'icons/info.svg';

interface StakingCardStakeChangeChipProps {
  isReady: boolean;
}
export function StakingCardStakeChangeChip({ isReady }: StakingCardStakeChangeChipProps) {
  return (
    <Container className="bg-warning/[.15] rounded-full py-1 px-2">
      <InfoIcon className="fill-icon-warning w-[20px] h-auto" />
      <Text
        color="warning"
        id={isReady ? 'stakingCard.confirmChange' : 'stakingCard.changeScheduled'}
        className="ml-2"
      />
    </Container>
  );
}
