import { XcmAssetLocation } from '../xcm';

export enum StashTokenPermission {
  Xcm = 'xcm',
  Swap = 'swap',
  Verified = 'verified',
}

export enum XcmVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export enum ExtrinsicPath {
  TransferMultiasset = 'xTokens.transferMultiasset',
  PolkadotXcmLimitedTransfer = 'polkadotXcm.limitedReserveTransferAssets',
  XcmPalletLimitedTransfer = 'xcmPallet.limitedReserveTransferAssets',
}

export enum ChainType {
  Parachain = 'parachain',
  RelayChain = 'relaychain',
}

enum ChannelStatus {
  Open = 'open',
}

export interface StashChannel {
  proofSize: string;
  channelId: string;
  name: string;
  status: ChannelStatus;
  unitWeightCost: string;
  xcmTransferWeight: string;
  url: string;
  xcmVersion: XcmVersion;
  chainType: ChainType;
  tags?: StashChannelTag[];
}

export enum StashChannelTag {
  Ethereum = 'ethereum',
}

export interface StashToken {
  tokenId: string;
  name: string;
  symbol: string;
  decimals: string;
  location: XcmAssetLocation;
  feePerSec: string;
  channelId: string;
  permissions: Array<StashTokenPermission>;
  extrinsicPath: ExtrinsicPath;
  balancePath: StashTokenBalancePath;
  destinationTokenId?: string | Record<string, string>;
  existentialDeposit?: string;
}

export enum StashTokenBalancePath {
  TokensAccounts = 'tokens.accounts',
  SystemAccount = 'system.account',
  AssetsAccount = 'assets.account',
}
