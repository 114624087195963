import { fromBN } from '@mangata-finance/sdk';
import { StashToken, StashTokenBalancePath } from '../../../../services';
import { hexToBn, isHex, bnToBn, BN, hexToString, BN_ZERO } from '@polkadot/util';

export type ChannelTokenBalanceResult =
  | TokensAccountsResult
  | AssetsAccountResult
  | SystemAccountResult;

interface TokensAccountsResult {
  free: string | number;
  reserved: string | number;
  frozen: string | number;
}

interface AssetsAccountResult {
  balance: string;
  isFrozen: boolean;
  reason: string;
}

interface SystemAccountResult {
  data: {
    free: string | number;
    reserved: string | number;
    frozen: string | number;
  };
}

export const transformBalanceResponse =
  (token: StashToken) => (result?: ChannelTokenBalanceResult | null) => {
    if (result === undefined) {
      return null;
    }

    if (result === null) {
      return '0';
    }
    const tokenDecimals = parseInt(token.decimals.toString());

    switch (token.balancePath) {
      case StashTokenBalancePath.TokensAccounts: {
        const res = result as TokensAccountsResult;

        const freeBalance = isHex(res.free) ? hexToBn(res.free) : bnToBn(res.free);
        const frozenBalance = isHex(res.frozen) ? hexToBn(res.frozen) : bnToBn(res.frozen);

        return fromBN(freeBalance.sub(frozenBalance), tokenDecimals);
      }
      case StashTokenBalancePath.AssetsAccount: {
        const res = result as AssetsAccountResult;

        if (res.isFrozen) {
          return '0';
        }

        return isHex(res.balance)
          ? hexToString(res.balance)
          : fromBN(new BN(res.balance), tokenDecimals);
      }
      case StashTokenBalancePath.SystemAccount: {
        const res = result as SystemAccountResult;

        const freeBalance = isHex(res.data.free) ? hexToBn(res.data.free) : bnToBn(res.data.free);
        const frozenBalance = isHex(res.data.frozen)
          ? hexToBn(res.data.frozen)
          : bnToBn(res.data.frozen);

        const availableBalance = freeBalance.sub(frozenBalance).gtn(0)
          ? freeBalance.sub(frozenBalance)
          : BN_ZERO;

        return fromBN(availableBalance, tokenDecimals);
      }
    }
  };
