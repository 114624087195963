import { BN } from '@polkadot/util';
import { QueryOptional } from '../../../services';
import { useDeactivateLiquidityFeeQuery } from './query/useDeactivateLiquidityFeeQuery';
import { useDeactivateLiqudityMutation } from './query/useDeactivateLiquidityMutation';

export const useDeactivateLiquidity = (
  liquidityId: QueryOptional<string>,
  amount: QueryOptional<string>,
  active3rdPartyLiquidities: QueryOptional<[string, BN][]>,
) => {
  const { deactivateLiquidityFeeQuery } = useDeactivateLiquidityFeeQuery(
    liquidityId,
    amount,
    active3rdPartyLiquidities,
  );
  const { deactivateLiquidityMutation } = useDeactivateLiqudityMutation();

  return {
    deactivateLiquidityFeeQuery,
    deactivateLiquidityMutation,
  };
};
