import { BN } from '@polkadot/util';
import { SubmittableExtrinsic } from '@polkadot/api/types';

export type CalculateRewardsAmountResult = BN;

export type PromotedPoolATvlResult = [BN, BN];

export interface MangataExtrinsicsApi {
  get tx(): {
    proofOfStake: {
      claimRewardsAll: (lpId: string) => SubmittableExtrinsic<'promise'>;
      activateLiquidity: (
        id: string,
        amount: string,
        useBalanceFrom: ReserveSourceType,
      ) => SubmittableExtrinsic<'promise'>;
      deactivateLiquidity: (id: string, amount: string) => SubmittableExtrinsic<'promise'>;
    };
    parachainStaking: {
      delegate: (
        collatorAddress: string,
        amount: string,
        useBalanceFrom: ReserveSourceType,
        candidateDelegationCount: number,
        delegatorDelegationCount: number,
      ) => SubmittableExtrinsic<'promise'>;
      scheduleDelegatorBondMore: (
        collatorAddress: string,
        amount: string,
        useBalanceFrom: ReserveSourceType,
      ) => SubmittableExtrinsic<'promise'>;
      scheduleDelegatorBondLess: (
        collatorAddress: string,
        amount: string,
      ) => SubmittableExtrinsic<'promise'>;
    };
    utility: {
      batchAll: (extrinsics: SubmittableExtrinsic<'promise'>[]) => SubmittableExtrinsic<'promise'>;
      batch: (extrinsics: SubmittableExtrinsic<'promise'>[]) => SubmittableExtrinsic<'promise'>;
    };
  };
}

export enum ReserveSourceType {
  ActivateKind = 'ActivateKind',
  ActivatedLiquidity = 'ActivatedLiquidity',
  NativeRewardsLiquidity = 'NativeRewardsLiquidity',
  AvailableBalance = 'AvailableBalance',
  UnspentReserves = 'UnspentReserves',
  ActivatedUnstakedReserves = 'ActivatedUnstakedReserves',
  StakedUnactivatedReserves = 'StakedUnactivatedReserves',
}

export type ActivateLiquidityFor3rdPartyRewardsReserveSource =
  | ReserveSourceType.AvailableBalance
  | ReserveSourceType.UnspentReserves
  | ReserveSourceType.StakedUnactivatedReserves
  | ReserveSourceType.NativeRewardsLiquidity
  | ReserveSourceType.ActivatedLiquidity;

export type ActivateLiquidityReserveSource =
  | ReserveSourceType.AvailableBalance
  | ReserveSourceType.UnspentReserves
  | ReserveSourceType.StakedUnactivatedReserves;

export type StakeReserveSource =
  | ReserveSourceType.AvailableBalance
  | ReserveSourceType.UnspentReserves
  | ReserveSourceType.ActivatedUnstakedReserves;

export type AvailableBalanceReserveSource = ReserveSourceType.AvailableBalance;
