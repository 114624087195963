import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../services';
import { getCurrentSession } from '../services/sessionService';

export function useSessionQuery() {
  const api = useApi();

  const currentSession = useQuery(['current-session'], getCurrentSession(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
  });

  return {
    currentSession,
  };
}
