import cls from 'classnames';
import { Children, ClassName, TestId } from 'core';

type GridGap = 'm' | 'l';

interface GridProps extends ClassName, Children, TestId {
  rows?: number;
  cols?: number;
  gap?: GridGap;
  fullWidth?: boolean;
}

const GRID_COLS_ARR = [
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
  'grid-cols-7',
];

const GRID_ROWS_ARR = [
  'grid-rows-1',
  'grid-rows-2',
  'grid-rows-3',
  'grid-rows-4',
  'grid-rows-5',
  'grid-rows-6',
  'grid-rows-7',
];

const GRID_GAP_MAP = {
  m: 'gap-4',
  l: 'gap-6',
};

export function Grid({
  rows,
  cols,
  gap,
  fullWidth,
  className,
  children,
  'data-testid': testId,
}: GridProps) {
  return (
    <div
      data-testid={testId}
      className={cls(
        'grid',
        rows !== undefined && GRID_ROWS_ARR[rows - 1],
        cols !== undefined && GRID_COLS_ARR[cols - 1],
        gap && GRID_GAP_MAP[gap],
        fullWidth && 'w-full',
        className,
      )}
    >
      {children}
    </div>
  );
}
