import { ActiveMiningIcon, Text, Container, FormatAmount, Button } from 'ui';
import { BN, BN_ZERO } from '@polkadot/util';
import cls from 'classnames';
import { Asset, ClassName, useMetamaskNetwork } from 'core';
import { fromBN } from '@mangata-finance/sdk';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PauseIcon } from 'icons/pause-circle.svg';

interface ClaimRewardsCardHeaderProps extends ClassName {
  amount: BN;
  onClaim: () => void;
  asset: Asset;
  isActive: boolean;
}
export function ClaimRewardsCardHeader({
  amount,
  className,
  onClaim,
  isActive,
  asset,
}: ClaimRewardsCardHeaderProps) {
  const { isChainSwitchRequired } = useMetamaskNetwork();

  return (
    <Container
      className={cls(className)}
      fullWidth
      justifyContent="space-between"
      alignItems="center"
    >
      <Container alignItems="center">
        <Container column>
          <Text
            className="uppercase"
            id="liq.mining.detail.widget.claim.rewards.title"
            weight="bold"
          />
          <Container data-testid="claimable-rewards-value" alignItems="center" className="mt-1">
            {isActive ? (
              <ActiveMiningIcon className="mr-2" />
            ) : (
              <PauseIcon className="mr-2 fill-icon-secondary w-[15px] h-auto" />
            )}
            <Text
              type="title-2"
              className="_accent-premium _highlight-accent"
              color={isActive ? 'accent' : 'highlight'}
            >
              <FormatAmount
                value={fromBN(amount, asset.decimals)}
                options={{ nonZeroPrecision: true, precision: 2, maxChars: 22 }}
              />{' '}
              {asset.symbol}
            </Text>
          </Container>
        </Container>
      </Container>
      <Container>
        <Button
          variant={isActive ? 'accent' : 'primary'}
          onClick={onClaim}
          isDisabled={isChainSwitchRequired || amount.eq(BN_ZERO)}
          data-testid="claim"
        >
          <FormattedMessage id="common.claim.cta" />
        </Button>
      </Container>
    </Container>
  );
}
