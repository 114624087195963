import { useAll3rdPartyRewardsAmountQuery } from './query/useAll3rdPartyRewardsAmountQuery';
import { useRewardsSchedulesListQuery } from './query/useRewardsSchedulesListQuery';

export const use3rdPartyRewards = () => {
  const { thirdPartyRewardsAmountQuery } = useAll3rdPartyRewardsAmountQuery();
  const { rewardsSchedulesListQuery } = useRewardsSchedulesListQuery();

  return {
    thirdPartyRewardsAmountQuery,
    rewardsSchedulesListQuery,
  };
};
