import cls from 'classnames';

import { Container } from 'ui';
import { ClassName, TestId } from 'core';

import { ReactComponent as UserIcon } from 'icons/user.svg';
import { ReactComponent as WalletIcon } from 'icons/wallet.svg';
import { ReactComponent as ErrorIcon } from 'icons/warning.svg';

type Variants = 'user' | 'error' | 'wallet';
type Sizes = 'm' | 'l';

export interface IconCircleProps extends TestId, ClassName {
  iconClassName?: string;
  icon: Variants;
  size?: Sizes;
}

export function IconCircle({
  'data-testid': testId = 'circleIcon',
  className,
  iconClassName,
  icon,
  size = 'm',
}: IconCircleProps) {
  const SIZE_MAP = {
    m: {
      icon: 'w-[14px]',
      wrapper: 'w-[40px] h-[40px]',
    },
    l: {
      icon: '!w-[24px]',
      wrapper: 'w-[80px] h-[80px] ',
    },
  };

  const CONTENT_MAP = {
    user: {
      IconComponent: (
        <UserIcon
          data-testid={`${testId}-icon`}
          className={cls('h-full fill-icon-secondary', SIZE_MAP[size].icon, iconClassName)}
        />
      ),
      wrapper: 'bg-widget',
    },
    wallet: {
      IconComponent: (
        <WalletIcon
          data-testid={`${testId}-icon`}
          className={cls('h-full fill-icon-secondary', SIZE_MAP[size].icon, iconClassName)}
        />
      ),
      wrapper: 'bg-widget',
    },
    error: {
      IconComponent: (
        <ErrorIcon
          data-testid={`${testId}-icon`}
          className={cls('h-full fill-icon-alert', SIZE_MAP[size].icon, iconClassName)}
        />
      ),
      wrapper: 'bg-alert',
    },
  };

  return (
    <Container
      className={cls('rounded-full', className, CONTENT_MAP[icon].wrapper, SIZE_MAP[size].wrapper)}
      alignItems="center"
      justifyContent="center"
      data-testid={testId}
    >
      {CONTENT_MAP[icon].IconComponent}
    </Container>
  );
}
