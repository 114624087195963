import { Container, ShortAddress, Skeleton, Text } from 'ui';
import { ReactComponent as UserIcon } from 'icons/user-round.svg';
import { StakingCardProps } from './StakingCard';
import { useCollatorApy } from 'core';
import { CollatorAprChip } from '../../../Staking/components/CollatorAprChip/CollatorAprChip';

export function StakingCardHeader({ delegation }: StakingCardProps) {
  const {
    collatorApyQuery: { data: apy },
  } = useCollatorApy(delegation.owner.toString());
  return (
    <Container fullWidth justifyContent="space-between" className="px-6">
      <Container>
        <UserIcon className="fill-icon-neutral w-[40px] h-auto" />

        <Container column className="ml-3">
          <Text color="secondary" type="title-4" id="common.collator" />
          <Text type="title-4">
            <ShortAddress address={delegation.owner.toString()} />
          </Text>
        </Container>
      </Container>
      {apy ? (
        <Container alignItems="center">
          <Text color="secondary" type="title-4" id="common.stakeApr" className="mr-2" />
          <CollatorAprChip value={apy.apy} isActive />
        </Container>
      ) : (
        <Container>
          <Skeleton width="70px" height="15px" rounded className="mr-2" />
          <Skeleton width="50px" height="15px" rounded />
        </Container>
      )}
    </Container>
  );
}
