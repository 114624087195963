import { ApiPromise } from '@polkadot/api';
import { BN } from '@polkadot/util';
import { z } from 'zod';
import { PromotedPoolRewardsResultSchema } from '../schema/PromotedPoolMetadataSchema';

export type PromotedPoolWeightData = z.infer<typeof PromotedPoolRewardsResultSchema>;
export interface PromotedPoolWeightResult {
  promotedPoolWeightData: PromotedPoolWeightData;
  totalWeight: BN;
}

export const getPromotedPoolMetadata =
  (api: ApiPromise | null) => async (): Promise<PromotedPoolWeightResult | null> => {
    if (!api) {
      return null;
    }

    const promotedPoolRewards = (await api.query.proofOfStake.promotedPoolRewards()).toHuman();

    if (!PromotedPoolRewardsResultSchema.safeParse(promotedPoolRewards).success) {
      return null;
    }

    const promotedPoolWeightData = promotedPoolRewards as PromotedPoolWeightData;

    const totalWeight = Object.values(promotedPoolWeightData).reduce(
      (acc, { weight }) => acc.add(new BN(weight)),
      new BN(0),
    );

    return {
      promotedPoolWeightData,
      totalWeight,
    };
  };
