import { Container } from '../../Container/Container';
import { Timeframe, timeframes } from './Timeframe';
import { TimeframeButton } from './TimeframeButton';

interface TimeframeSelectorProps {
  onSelect: (timeframe: Timeframe) => void;
  currentTimeframe: Timeframe;
}

export const TimeframeSelector = ({ onSelect, currentTimeframe }: TimeframeSelectorProps) => {
  const handleSelect = (timeframe: Timeframe) => () => onSelect(timeframe);

  return (
    <Container>
      {timeframes.map((timeframe) => (
        <TimeframeButton
          key={timeframe.key}
          onClick={handleSelect(timeframe)}
          isActive={timeframe.key === currentTimeframe.key}
          timeframe={timeframe}
        />
      ))}
    </Container>
  );
};
