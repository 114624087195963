import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { getDelegatorState, listDelegators } from '../services/delegatorService';
import {
  transformDelegatorList,
  transformDelegatorState,
} from '../transformers/delegatorTransformer';
import { useAccounts } from '../../../account';
import { useTransaction } from '../../../transaction';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';

export const useDelegatorQuery = () => {
  const api = useApi();
  const { stakingTxCount } = useTransaction();

  const delegatorMapQuery = useQuery(['delegator-map', stakingTxCount], listDelegators(api), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
    select: transformDelegatorList,
  });

  return {
    delegatorMapQuery,
  };
};

export const useDelegatorStateQuery = () => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { stakingTxCount } = useTransaction();
  const delegatorAddress = selectedAccount?.address;

  const delegatorStateQuery = useQuery(
    ['delegator-state', delegatorAddress, stakingTxCount],
    getDelegatorState(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!api && !!delegatorAddress,
      select: transformDelegatorState,
      keepPreviousData: true,
    },
  );

  const delegatorStateRequests: Array<[string, ParachainStakingDelegationRequest]> = Array.from(
    delegatorStateQuery.data?.requests.requests.entries() ?? [],
  ).map(([, req]) => [req.collator.toString(), req]);

  return {
    delegatorStateQuery,
    delegatorStateRequests,
  };
};
