import { useCallback, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { some } from 'lodash-es';
import { AdaptiveTxFee, useInternalBalance } from 'core';
import {
  RemoveStakeUIStore,
  RemoveStakeUIStoreFormError,
  useRemoveStakeStore,
} from './store/useRemoveStakeStore';

export const useRemoveStakeValidation = (feeData: AdaptiveTxFee['current'] | null | undefined) => {
  const { formError, setFormError } = useRemoveStakeStore();
  const { getFreeBalance } = useInternalBalance();

  const isInvalid = some(formError, Boolean);
  const feeBalanceStr = getFreeBalance(feeData) ?? '0';

  const validateForm = useCallback(() => {
    const feeBalance = new Decimal(feeBalanceStr);
    const newFormError: Partial<Record<RemoveStakeUIStoreFormError, boolean>> = {};

    if (feeData) {
      if (feeBalance.lt(feeData.amount ?? 0)) {
        newFormError[RemoveStakeUIStoreFormError.InsufficientFee] = true;
      }
    }

    setFormError(newFormError);
    return null;
  }, [feeData, feeBalanceStr, setFormError]);

  useEffect(() => {
    validateForm();
  }, [feeData, validateForm]);

  return {
    isInvalid,
  };
};

export const getSubmitMessage = (
  state: RemoveStakeUIStore,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case state.amount === 0:
      return 'common.submit.btn.enterAmount';
    case !!state.formError[RemoveStakeUIStoreFormError.InsufficientFee]:
      return 'common.submit.btn.insufficientFunds';

    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';
    default:
      return 'staking.remove.submit.cta';
  }
};
