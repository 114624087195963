import { useQuery } from '@tanstack/react-query';
import { getNativeRewardsInfo } from '../service/nativeRewardsInfoService';
import { useApi } from '../../../../services';
import { transformNativeRewardsInfo } from '../transformers/nativeRewardsInfoTransformer';
import { useTransaction } from '../../../transaction';
import { useUserStore } from '../../../user/useUserStore';

export const useNativeRewardsInfoQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { selectedAccount } = useUserStore();

  const address = selectedAccount?.address;

  const nativeRewardsInfoQuery = useQuery(
    ['rewards-info', address, finalisedTxCount],
    getNativeRewardsInfo(api),
    {
      enabled: !!address && !!api,
      select: transformNativeRewardsInfo,
      keepPreviousData: true,
    },
  );

  return { nativeRewardsInfoQuery };
};
