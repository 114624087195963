import { ApiPromise } from '@polkadot/api';

interface FeeLockMetadata {
  periodLength: string;
  feeLockAmount: string;
  swapValueThreshold: string;
  whitelistedTokens: number[];
}

export const getFeeMetadata = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const feeLock = (
    await api.query.feeLock.feeLockMetadata()
  ).toJSON() as unknown as FeeLockMetadata;

  return feeLock;
};
