import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum StartStakingUIStoreFormError {
  InsufficientFee = 'InsufficientFee',
  InsufficientFunds = 'InsufficientFunds',
  MinAmountNotReached = 'MinAmountNotReached',
}

export interface StartStakingUIStore {
  amount: string;
  formError: Partial<Record<StartStakingUIStoreFormError, boolean>>;
  setFormError: (value: Partial<Record<StartStakingUIStoreFormError, boolean>>) => void;
  setAmount: (value: string) => void;
  setLimitReachedModalVisibility: (value: boolean) => void;
  isLimitReachedModalVisible: boolean;
  dispose: () => void;
}

export const INITIAL_STATE = {
  amount: '',
  formError: {},
  isLimitReachedModalVisible: false,
};

export const useStartStakingStore = create(
  devtools<StartStakingUIStore>((set, get) => ({
    ...INITIAL_STATE,
    setAmount: (amount) => set({ amount }),
    setFormError: (formError) => set({ formError }),
    setLimitReachedModalVisibility: (isLimitReachedModalVisible) =>
      set({ isLimitReachedModalVisible }),
    dispose: () => set(INITIAL_STATE),
  })),
);
