import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { EnvConfig } from '../../../../../envConfig';
import { PoolVolumeHistoryInterval } from '../query/usePoolVolumeHistoryQuery';

export type PoolVolumeHistoryQueryKey = Readonly<
  [queryKey: string, poolSymbols: string | null, days: number, interval: PoolVolumeHistoryInterval]
>;

export interface PoolVolumeHistoryResult {
  volumes: Array<[number, string]>;
}

export const fetchPoolVolumeHistory = async ({
  queryKey,
}: QueryFunctionContext<PoolVolumeHistoryQueryKey>): Promise<PoolVolumeHistoryResult | null> => {
  const [, poolSymbols, days, interval] = queryKey;

  const { data } = await axios.get(
    `${EnvConfig.STASH_URL}/volume-history/pools/${poolSymbols}?days=${days}&interval=${interval}`,
  );

  return data;
};
