import { Container, LinkButton, Text } from 'ui';

export const NativeTokenWidgetOnboarding = () => {
  const steps = [
    'nativeTokenWidget.onboarding.step1',
    'nativeTokenWidget.onboarding.step2',
    'nativeTokenWidget.onboarding.step3',
  ];

  const renderSteps = () => {
    return (
      <Container column className="mt-6">
        {steps.map((step, i) => (
          <Container key={i} className="mb-4">
            <Container
              shrink={false}
              justifyContent="center"
              alignItems="center"
              className="rounded-full border border-highlight bg-accent/[.2] w-5 h-5"
            >
              <Text color="accent" type="body-s">
                {i + 1}
              </Text>
            </Container>
            <Container column className="ml-4 max-w-5">
              <Text type="body-l" id={`${step}.title`} />
              <Text type="body-s" id={`${step}.desc`} color="secondary" />
            </Container>
          </Container>
        ))}
      </Container>
    );
  };

  return (
    <Container fullWidth column className="px-6 py-5 rounded-lg bg-widget">
      <Text type="title-2" id="nativeTokenWidget.onboarding.title" />
      {renderSteps()}
      <Container column className="mt-2">
        <Text id="nativeTokenWidget.onboarding.help.title" />
        <LinkButton
          iconClassName="fill-icon-secondary"
          target="_blank"
          href="https://docs.mangata.finance/welcome/guides/onboarding/how-to-get-mgx"
        >
          <Text color="secondary" type="body-s" id="nativeTokenWidget.onboarding.help.link" />
        </LinkButton>
      </Container>
    </Container>
  );
};
