import cls from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button, Container, LinkButton, ShortAddress, Text } from 'ui';

import { ClassName, TestId, useAccounts } from 'core';
import { IconCircle } from '../IconCircle/IconCircle';
import { EnvConfig } from 'ui/src/environment/EnvConfig';

export interface ConnectedAccountCardProps extends TestId, ClassName {}

export const ConnectedAccountCard = ({
  'data-testid': testId,
  className,
}: ConnectedAccountCardProps) => {
  const { selectedAccount, disconnectWallet } = useAccounts();

  return selectedAccount ? (
    <Container
      justifyContent="space-between"
      alignItems="center"
      data-testid={testId}
      className={cls(
        'px-3 py-4 rounded-lg border-solid border-1 border-default h-[80px]',
        className,
      )}
    >
      <Container alignItems="center" className="h-[32px]">
        <IconCircle icon="user" />
        <Container className="ml-4" column>
          {EnvConfig.EXPLORER_URL ? (
            <LinkButton
              className="text-neutral"
              target="_blank"
              variant="secondary"
              data-testid={`${testId}-explorer`}
              href={`${EnvConfig.EXPLORER_URL}/account/${selectedAccount.address}`}
            >
              {selectedAccount.name || <ShortAddress address={selectedAccount.address} />}
            </LinkButton>
          ) : (
            <Text type="body-l" color="secondary">
              {selectedAccount.name || <ShortAddress address={selectedAccount.address} />}
            </Text>
          )}
          {selectedAccount.name && (
            <Text type="body-s" className="" color="secondary">
              <ShortAddress address={selectedAccount.address} id="selected-address" />
            </Text>
          )}
        </Container>
      </Container>
      <Button
        data-testid={`${testId}-disconnect`}
        variant="outlined"
        className="mr-3"
        size="s"
        onClick={disconnectWallet}
      >
        <FormattedMessage id="wallet.modal.account.disconnect" />
      </Button>
    </Container>
  ) : null;
};
