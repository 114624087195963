import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { ReactComponent as GaslessIcon } from '../../../assets/icons/gasless.svg';
import cls from 'classnames';
import { EnvConfig } from '../../environment/EnvConfig';

interface GaslessTagProps {
  isActive?: boolean;
}
export function GaslessTag({ isActive = false }: GaslessTagProps) {
  const CONTAINER_CLASS = cls(
    'rounded-md py-1 px-2',
    !isActive ? 'bg-alert' : EnvConfig.isRollupEnv ? 'bg-highlight/[.1]' : 'bg-accent/[.1]',
  );
  const ICON_CLASS = cls(
    'mr-2 w-[10px] _highlight-accent',
    isActive ? 'fill-icon-highlight' : 'fill-icon-alert',
  );

  return (
    <Container inline alignItems="center" className={CONTAINER_CLASS}>
      <GaslessIcon className={ICON_CLASS} />
      <Text
        id="gaslessTag.title"
        color={isActive ? (EnvConfig.isRollupEnv ? 'highlight' : 'accent') : 'alert'}
        type="body-s"
        className="leading-none"
      />
    </Container>
  );
}
