import { useQuery } from '@tanstack/react-query';
import { useAccounts, useAdaptiveFee } from '../../../..';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { BN } from '@polkadot/util';
import { getScheduleBondLessFee } from '../services/ScheduleDelegatorBondLessFeeService';
import { useDebounce } from '../../../../../hooks';

export const useScheduleBondLessFeeQuery = (
  collatorAddress: QueryOptional<string>,
  amount: QueryOptional<BN>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const debouncedAmount = useDebounce(amount);

  const scheduleBondLessFeeQuery = useQuery(
    ['scheduleDelegatorBondLess-fee', selectedAccount?.address, collatorAddress, debouncedAmount],
    getScheduleBondLessFee(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      keepPreviousData: true,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!debouncedAmount &&
        !!debouncedAmount.gtn(0),
    },
  );

  return { scheduleBondLessFeeQuery };
};
