import { FormattedMessage } from 'react-intl';
import { Decimal } from 'decimal.js';
import { useNavigate } from 'react-router-dom';
import { Button, Container, TokenIcon, Text } from 'ui';
import { ReactComponent as BackIcon } from 'icons/back.svg';
import { Asset, QueryOptional, TokenStatistics } from 'core';
import { SwapInputType } from 'modules';
import { TokenDetailHeaderCard } from './TokenDetailHeaderCard';

interface TokenDetailHeaderProps {
  asset?: Asset;
  stats?: QueryOptional<TokenStatistics>;
}

export function TokenDetailHeader({ asset, stats }: TokenDetailHeaderProps) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/tokens');
  };

  const handleTrade = () => {
    navigate(`/?${SwapInputType.FirstToken}=${asset?.symbol}`);
  };

  return (
    <Container column fullWidth>
      <Button variant="secondary" LeadIcon={BackIcon} onClick={handleBack} className="py-0">
        <FormattedMessage id="token.detail.back.cta" />
      </Button>

      <Container fullWidth justifyContent="space-between" alignItems="center" className="mt-14">
        <Container alignItems="center" data-testid="token-name">
          <TokenIcon size="xl" token={asset || ''} className="mr-4" />
          <Container column>
            <Text type="display-2">{asset?.symbol}</Text>
            <Text type="title-4" color="secondary">
              {asset?.name}
            </Text>
          </Container>
        </Container>
        <Button variant="primary" size="m" onClick={handleTrade} data-testid="trade-token">
          <FormattedMessage id="token.detail.trade.cta" />
        </Button>
      </Container>

      <div className="grid grid-cols-2 gap-4 w-full mt-14">
        <TokenDetailHeaderCard
          titleId="token.detail.price"
          value={stats?.priceInUSD}
          trend={new Decimal(stats?.priceChange24hInPerc ?? 0)}
          data-testid="price"
        />
        <TokenDetailHeaderCard
          titleId="token.detail.volume"
          value={stats?.volume24hInUSD}
          trend={new Decimal(stats?.volumeChange24hInPerc ?? 0)}
          data-testid="volume"
        />
      </div>
    </Container>
  );
}
