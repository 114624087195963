import { useEffect, useRef, useState } from 'react';
import { Container, Text, useClickOutside } from 'ui';
import { useTx } from '../useTx';
import { TxNotification } from './TxNotification';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import cls from 'classnames';
import { animated, useSpring, useTransition } from '@react-spring/web';
import { Transaction } from 'core';

export const TxNotificationProvider = () => {
  const { list: _list } = useTx();

  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const txRefs = useRef(new Map<string, HTMLDivElement | null>());

  const txHeights = useRef(new Map<string, number>());

  const list = [..._list].filter((tx) => tx.isVisible).reverse();
  const visible = isExpanded ? list : list.slice(0, 1);

  const containerSizeSpring = useSpring({
    width: isExpanded ? 550 : 450,
    duration: 200,
    delay: 100,
  });

  const buttonTransition = useTransition(list.length > 1, {
    from: { opacity: 0, maxWidth: 0, x: 10 },
    enter: { opacity: 1, maxWidth: 50, x: 0 },
    leave: { opacity: 0, maxWidth: 0, x: 10 },
    config: { duration: 200 },
  });

  useEffect(() => {
    if (visible.length < 2 && isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpanded, visible.length]);

  useClickOutside(
    [containerRef.current, buttonRef.current],
    (isOut) => isOut && isExpanded && setIsExpanded(false),
  );

  useEffect(() => {
    visible.forEach((tx) => {
      const ref = txRefs.current.get(tx.id);
      if (ref && txHeights.current.get(tx.id) === undefined) {
        txHeights.current.set(tx.id, ref.offsetHeight);
      }
    });
  }, [visible]);

  const listTransition = useTransition(visible, {
    from: (_) => {
      const style = { opacity: 0, y: 20 };

      return { ...style, maxHeight: 0 };
    },
    update: (_) => {
      const style = { opacity: 1, y: 0 };
      return { ...style, maxHeight: txHeights.current.get(_.id) };
    },
    enter: (tx: Transaction) => {
      const style = { opacity: 1, y: 0 };
      return { ...style, maxHeight: txHeights.current.get(tx.id) };
    },
    leave: (_) => {
      const style = { opacity: 0, y: -20 };
      return { ...style, maxHeight: 0 };
    },
    trail: 50 / Math.max(visible.length, 1),
    keys: (tx) => tx.id,
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const renderButton = () => {
    return buttonTransition((style, isVisible) => {
      return (
        isVisible && (
          <animated.div style={style} className="mt-6">
            <Container
              justifyContent="center"
              alignItems="center"
              onClick={toggleExpanded}
              ref={buttonRef}
              className="bg-widget w-9 h-9 p-2 rounded-full cursor-pointer border border-default/[.14]"
            >
              {isExpanded ? (
                <CloseIcon className="w-4 h-auto fill-icon-secondary" />
              ) : (
                <Text type="body-s">+{list.length - 1}</Text>
              )}
            </Container>
          </animated.div>
        )
      );
    });
  };

  const renderNotifications = () => {
    if (list.length === 0) {
      return null;
    }

    return (
      <animated.div style={containerSizeSpring}>
        <Container alignItems="center" column fullWidth className={cls('px-4')}>
          {listTransition((style, tx) => (
            <animated.div key={tx.id} style={style} className={cls('w-full')}>
              <div id={tx.id} ref={(ref) => ref && txRefs.current.set(tx.id, ref)} className="py-2">
                <TxNotification tx={tx} />
              </div>
            </animated.div>
          ))}
        </Container>
      </animated.div>
    );
  };

  return (
    <Container
      alignItems="start"
      className="fixed top-6 left-1/2 -translate-x-1/2 z-40"
      ref={containerRef}
    >
      {renderNotifications()}
      {renderButton()}
    </Container>
  );
};
