import { ApiPromise } from '@polkadot/api';
import { buildWeightLimit } from '../deposit/payload/weight';
import { StashChannel, StashToken } from '../../../stash';
import { buildWithdrawalDestination } from './payload/destination';
import { WalletAccount } from '../../../injected-web3';

interface WithdrawalBaseParams {
  channel: StashChannel;
  destinationAccount: WalletAccount;
  amount: string;
  asset: StashToken;
  api: ApiPromise;
}

export function withdrawalExtrinsic({
  destinationAccount,
  amount,
  channel,
  asset,
  api,
}: WithdrawalBaseParams) {
  const destination = buildWithdrawalDestination(destinationAccount, channel, api);
  const weightLimit = buildWeightLimit(channel);

  return api.tx.xTokens.transfer(asset.tokenId, amount, destination, weightLimit);
}
