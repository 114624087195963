import { useQuery } from '@tanstack/react-query';
import { ChartHistoryInterval } from '../../../../../types';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { fetchSingleTokenVolumeHistory } from '../services/tokenVolumeHistoryService';

export const useTokenVolumeHistoryQuery = (
  symbol: QueryOptional<string>,
  days: number,
  interval: ChartHistoryInterval,
) => {
  const singleTokenVolumeHistoryQuery = useQuery(
    ['single-volume-history', symbol, days, interval],
    fetchSingleTokenVolumeHistory,
    { ...DEFAULT_QUERY_OPTIONS, enabled: !!symbol, retry: 1 },
  );

  return { singleTokenVolumeHistoryQuery };
};
