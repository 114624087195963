import { z } from 'zod';
import { encodeAddress } from '@polkadot/util-crypto';
import { ApiPromise } from '@polkadot/api';
import { Codec } from '@polkadot/types/types';
import { findEvents } from '../block/event';
import { BalancesTransferDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';

const BASE_SS58_PREFIX = 42;

export function validateBalancesTransfer(api: ApiPromise, eventsCodec: Codec, address: string) {
  const events = findEvents<z.infer<typeof BalancesTransferDataSchema>>(
    eventsCodec,
    api,
    BalancesTransferDataSchema,
    XcmEvent.Transfer,
  );

  if (events.length === 0) {
    return null;
  }

  return events
    .filter(({ event }) => {
      const fromAddress = encodeAddress(event.data.from, BASE_SS58_PREFIX);

      return fromAddress === address;
    })
    .map(({ event }) => encodeAddress(event.data.from, BASE_SS58_PREFIX));
}
