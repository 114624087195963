import { keyBy } from 'lodash-es';
import { PoolWithRatio as SdkPoolWithRatio } from '@mangata-finance/sdk';
import { AssetRegistryAssetMetadata } from '../../../../services';
import { transformToAsset } from '../../../token';
import { PoolWithRatio } from '../../Pool';
import { PoolKey } from '../services/AllPoolsService';

export interface TransformPoolsResult {
  byLPId: Record<PoolKey, PoolWithRatio>;
  baseList: PoolWithRatio[];
  list: PoolWithRatio[];
  byId: Record<string, PoolWithRatio>;
  byAdjacentId: Record<string, PoolWithRatio[]>;
}

const getInvertedPool = (pool: PoolWithRatio) => ({
  ...pool,
  secondAsset: pool.firstAsset,
  secondTokenAmount: pool.firstTokenAmount,
  secondTokenId: pool.firstTokenId,
  secondTokenRatio: pool.firstTokenRatio,
  firstAsset: pool.secondAsset,
  firstTokenAmount: pool.secondTokenAmount,
  firstTokenId: pool.secondTokenId,
  firstTokenRatio: pool.secondTokenRatio,
  id: `${pool.secondTokenId}-${pool.firstTokenId}`,
  symbols: [pool.secondAsset.symbol, pool.firstAsset.symbol],
});

export const transformAllPools =
  (
    assets: AssetRegistryAssetMetadata[] | null | undefined,
    isFirstPoolTokenLeader: (pool: PoolWithRatio) => boolean,
  ) =>
  (pools: SdkPoolWithRatio[] | null): TransformPoolsResult | null => {
    if (!pools || !assets) {
      return null;
    }

    const basePools = pools
      .map((pool) => {
        const firstNativeAsset = assets.find((asset) => asset.id === pool.firstTokenId);
        const secondNativeAsset = assets.find((asset) => asset.id === pool.secondTokenId);

        if (firstNativeAsset && secondNativeAsset) {
          const serializedPool: PoolWithRatio = {
            ...pool,
            firstAsset: transformToAsset(firstNativeAsset),
            secondAsset: transformToAsset(secondNativeAsset),
            id: `${pool.firstTokenId}-${pool.secondTokenId}`,
            symbols: [firstNativeAsset.symbol, secondNativeAsset.symbol],
          };

          return isFirstPoolTokenLeader(serializedPool)
            ? getInvertedPool(serializedPool)
            : serializedPool;
        }

        return null;
      })
      .filter(Boolean) as PoolWithRatio[];

    const invertedPools = basePools.map(getInvertedPool);
    const poolsData = [...basePools, ...invertedPools].filter(Boolean) as PoolWithRatio[];
    const adjacentPools = poolsData.reduce((acc: Record<PoolKey, PoolWithRatio[]>, pool) => {
      return {
        ...acc,
        [pool.firstTokenId]: [...(acc[pool.firstTokenId] || []), pool],
        [pool.secondTokenId]: [...(acc[pool.secondTokenId] || []), pool],
      };
    }, {});

    return {
      baseList: basePools,
      list: poolsData,
      byId: keyBy(poolsData, 'id'),
      byLPId: keyBy(basePools, 'liquidityTokenId'),
      byAdjacentId: adjacentPools,
    };
  };
