import { Codec } from '@polkadot/types/types';
import { ApiPromise } from '@polkadot/api';
import { z } from 'zod';
import { findEvents } from '../block/event';
import { MessageQueueProcessedDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';

export function messageQueueValidator(api: ApiPromise, eventsCodec: Codec) {
  const events = findEvents<z.infer<typeof MessageQueueProcessedDataSchema>>(
    eventsCodec,
    api,
    MessageQueueProcessedDataSchema,
    XcmEvent.Processed,
  );

  const hashes = events.map(({ event: { data } }) => data.id);

  return hashes;
}
