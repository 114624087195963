import cls from 'classnames';
import { Container, Grid, Text } from 'ui';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { CollatorRowItem } from 'modules';
import { Asset, ClassName, TestId } from 'core';
import { fromBN, toBN } from '@mangata-finance/sdk';
import { last } from 'lodash-es';

interface CollatorListSectionProps extends ClassName, TestId {
  list: Array<ParachainStakingCollatorCandidate> | null;
  assets: Map<string, Asset>;
  titleId: string;
  descId: string;
  collatorApy?: Map<string, string> | null;
}

const renderHeader = () => (
  <Grid cols={5} gap="l" className="px-4 w-full mt-2 mb-4">
    <Text id="staking.list.header.collator" color="secondary" />
    <Text id="staking.list.header.totalStake" color="secondary" />
    <Text id="staking.list.header.token" color="secondary" />
    <Text id="staking.list.header.apr" color="secondary" />
    <Text id="staking.list.header.minStake" color="secondary" />
  </Grid>
);

export const calculateMinBond = (collator: ParachainStakingCollatorCandidate, asset: Asset) => {
  if (collator.bottomDelegations.length === 0 && collator.topDelegations.length < 13) {
    return '1';
  }

  const min = last(collator.topDelegations)?.amount.add(toBN('1', asset.decimals)) ?? null;

  return min ? fromBN(min, asset.decimals) : null;
};

const renderList = (
  list: Array<ParachainStakingCollatorCandidate> | null,
  assets: Map<string, Asset>,
  collatorApy?: Map<string, string> | null,
) => {
  return list?.map((candidate) => {
    const asset = assets.get(candidate.liquidityToken.toString());
    const minBond = asset ? calculateMinBond(candidate, asset) : null;
    const bondedAmount = asset !== undefined ? fromBN(candidate.bond.toBn(), asset.decimals) : null;
    const apy = collatorApy?.get(candidate.id.toString());

    return (
      <CollatorRowItem
        key={candidate.id.toString()}
        apr={apy}
        data={candidate}
        bond={bondedAmount}
        minBond={minBond ?? undefined}
        lpAsset={assets.get(candidate.liquidityToken.toString()) ?? null}
      />
    );
  });
};

export function CollatorListSection({
  list,
  collatorApy,
  assets,
  titleId,
  descId,
  className,
  'data-testid': testId,
}: CollatorListSectionProps) {
  return (
    <Container
      column
      fullWidth
      className={cls('bg-widget rounded-lg px-2 py-6', className)}
      data-testid={testId}
    >
      <Container column fullWidth className="px-4">
        <Text type="title-3" id={titleId} />
        <Text color="secondary" id={descId} className="mt-1" />
      </Container>

      <Container column fullWidth className="mt-6">
        {renderHeader()}
        {renderList(list, assets, collatorApy)}
      </Container>
    </Container>
  );
}
