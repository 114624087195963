import { Asset, AssetType, ClassName, EnvConfig, TestId } from 'core';
import cls from 'classnames';
import { SyntheticEvent } from 'react';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down.svg';
import { Button, TokenIcon, Text, Container } from '../../';
import { getTokenI18nDesc } from '../TokenRow/TokenRow';

const PREVENT_FOCUS_ONCLICK = (e: SyntheticEvent) => e.preventDefault();

export interface TokenSelectButtonProps extends TestId, ClassName {
  onClick(): void;
  isSelectable?: boolean;
  selectedToken: Asset | null;
}

export const TokenSelectButton = ({
  selectedToken,
  onClick,
  'data-testid': testId = 'tokenSelectButton',
  className,
  isSelectable = true,
}: TokenSelectButtonProps) => {
  if (!selectedToken && isSelectable) {
    return (
      <Button
        data-testid={testId}
        onClick={onClick}
        size="s"
        onMouseDown={PREVENT_FOCUS_ONCLICK}
        className={cls('normal-case px-3 whitespace-nowrap select-token')}
      >
        <Text weight="semibold" id="token.select.button" />
      </Button>
    );
  }

  const TokenComponent = selectedToken && (
    <Container alignItems="center">
      <TokenIcon
        size={selectedToken.type === AssetType.LP ? 'm' : 'l'}
        token={selectedToken}
        iconClassName={selectedToken.type === AssetType.LP ? '' : '!w-[24px]'}
        className="mr-2 min-w-[24px]"
      />
      <Container column className="mr-2 relative top-[2px]">
        <Text type="title-1" className="leading-[25px] truncate" weight="normal">
          {selectedToken.symbol}
        </Text>
        {EnvConfig.isRollupEnv && (
          <Text
            type="body-m"
            color="secondary"
            className="leading-[16px] truncate "
            id={getTokenI18nDesc(selectedToken.type, selectedToken.origin)}
          />
        )}
      </Container>
    </Container>
  );

  if (!isSelectable) {
    return (
      <Container className="h-full" alignItems="center">
        {TokenComponent}
      </Container>
    );
  }

  return (
    <Button
      data-testid={testId}
      onMouseDown={PREVENT_FOCUS_ONCLICK}
      onClick={onClick}
      variant="base"
      className={cls(
        'flex content-center hover:bg-base min-w-[initial]',
        'px-[6px] change-token !max-w-[210px]',
        className,
      )}
    >
      {TokenComponent}
      <ChevronDown className="fill-icon-secondary min-w-[20px] w-[20px] h-[100%]" />
    </Button>
  );
};
