import { Option } from '@polkadot/types';
import { ParachainStakingDelegator } from '@polkadot/types/lookup';
import { QueryOptional, QueryServiceReturnType } from '../../../../services';
import { isNil, isNull } from 'lodash-es';
import { listDelegators } from '../services/delegatorService';

export const transformDelegatorList = (_data: QueryServiceReturnType<typeof listDelegators>) => {
  if (isNil(_data)) {
    return null;
  }

  const data = _data
    .map(([, _opt]) => {
      const opt = _opt.unwrapOr(null);
      return [opt?.id.toString(), opt];
    })
    .filter(($): $ is [string, ParachainStakingDelegator] => !isNull($[1]));

  return new Map(data);
};

export const transformDelegatorState = (
  _data: QueryOptional<Option<ParachainStakingDelegator>>,
) => {
  if (isNil(_data)) {
    return null;
  }

  const data = _data.unwrapOr(null);

  return data;
};
