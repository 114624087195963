import { WalletAccount } from '../../../services';
import { useAccounts } from '../../account';
import { useChannels } from '../../channel';
import { useStashTokens } from '../../token';
import { useDepositQuery } from '../deposit/query/useDepositQuery';

export interface UseDepositProps {
  amount: string | null;
  assetId: string | null;
  channelId: string | null;
  originAccount: WalletAccount | null;
}

export const useDeposits = (props: UseDepositProps) => {
  const {
    stashTokensQuery: { data: tokens },
  } = useStashTokens();

  const { selectedAccount } = useAccounts();

  const {
    channelsQuery: { data: channels },
  } = useChannels();

  const params = { ...props, tokens, channels, selectedAccount };

  const { depositFeeQuery, depositExtrinsicQuery, submitDepositMutation } = useDepositQuery(params);

  return {
    depositFeeQuery,
    depositExtrinsicQuery,
    submitDepositMutation,
  };
};
