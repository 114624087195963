import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { useApi } from '../../../../../services';
import { useAccounts } from '../../../../account';
import { useWallet } from '../../../../wallet';
import { submitScheduleBondLess } from '../services/ScheduleDelegatorBondLessService';

export const useScheduleBondLessMutation = () => {
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const scheduleBondLessMutation = useMutation(
    ['scheduleDelegatorBondLess'],
    submitScheduleBondLess(api, selectedAccount?.address, wallet, transactionStore),
  );
  return { scheduleBondLessMutation };
};
