import { FormattedMessage } from 'react-intl';
import { Container, Text, Button, TokenInput } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useAddStakeInputs } from './useAddStakeInputs';
import { AddStakeUIStoreFormError, useAddStakeStore } from './store/useAddStakeStore';
import { AddStakeWidgetDetails } from './details/AddStakeWidgetDetails';
import { getSubmitMessage, useAddStakeValidation } from './useAddStakeValidation';
import {
  useScheduleDelegatorBondMore,
  Asset,
  isAmountGtZero,
  TestId,
  useReservesForTx,
  TxType,
  TxAsset,
  useSafeTransaction,
  useMetamaskNetwork,
} from 'core';
import { fromBN } from '@mangata-finance/sdk';
import { useCallback } from 'react';
import { useTx } from '../../../Tx';

export interface AddStakeWidgetProps extends TestId {
  collatorAddress: string;
  currentStakeAmount: string | null;
  onClose?: () => void;
  stakedAsset: Asset | null;
}

export const AddStakeWidget = ({
  collatorAddress,
  currentStakeAmount,
  stakedAsset,
  onClose,
  'data-testid': testId = 'add-stake-widget',
}: AddStakeWidgetProps) => {
  const store = useAddStakeStore();
  const { amount, formError, dispose } = store;
  const { isAllowed } = useTx();
  const { sources } = useReservesForTx<TxType.StakeChange>(TxType.StakeChange, stakedAsset?.id);
  const { sources: activateLiqSources } = useReservesForTx<TxType.ActivateLP>(
    TxType.ActivateLP,
    stakedAsset?.id,
  );
  const { isChainSwitchRequired } = useMetamaskNetwork();
  const { handleAmountChange } = useAddStakeInputs(stakedAsset);

  const txAsset: TxAsset | null = stakedAsset ? { ...stakedAsset, amount } : null;

  const { scheduleBondMoreFeeQuery, scheduleBondMoreMutation } = useScheduleDelegatorBondMore(
    collatorAddress,
    txAsset,
  );

  const feeTxAsset = scheduleBondMoreFeeQuery.data?.current;
  const { isInvalid } = useAddStakeValidation(scheduleBondMoreFeeQuery.data?.current, stakedAsset);

  const handleClose = useCallback(() => {
    dispose();
    onClose?.();
  }, [dispose, onClose]);

  const handleSubmit = () => {
    if (!stakedAsset || !sources.hasAvailable) return;

    scheduleBondMoreMutation.mutate({
      collatorAddress,
      txAsset: {
        ...stakedAsset,
        amount,
      },
      sources,
      activateLiqSources,
      onDone: handleClose,
    });
  };

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: txAsset ? [txAsset] : [],
      feeTxAsset,
      type: TxType.Swap,
    },
    handleSubmit,
  );

  return (
    <Container
      fullWidth
      className="transition-all bg-widget p-6 rounded-lg"
      alignItems="start"
      justifyContent="start"
      data-testid={`${testId}-container`}
      column
    >
      <Container alignItems="center" fullWidth justifyContent="space-between" className="mb-4">
        <Text type="title-1" id="staking.add.title" />
        <Button
          variant="secondary"
          TrailIcon={CloseIcon}
          onClick={handleClose}
          className="py-0"
          data-testid="cancel-button"
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      </Container>
      <TokenInput
        isSelectable={false}
        labelId="staking.add.amount.label"
        selectedToken={stakedAsset}
        onAmountChange={handleAmountChange}
        isInvalid={formError[AddStakeUIStoreFormError.InsufficientFunds]}
        amount={amount}
        freeBalance={
          sources.totalAvailableBalance
            ? fromBN(sources.totalAvailableBalance, stakedAsset?.decimals)
            : null
        }
        data-testid={`${testId}-tokenInput`}
      />
      <AddStakeWidgetDetails
        feeData={feeTxAsset}
        isFeeLoading={scheduleBondMoreFeeQuery.isLoading}
        currentStakeAmount={currentStakeAmount}
        stakedAsset={stakedAsset}
        data-testid={`${testId}-details`}
      />
      <Container fullWidth className="mt-6">
        <Button
          variant="primary"
          size="l"
          fullWidth
          isDisabled={
            isInvalid ||
            !isAmountGtZero(amount) ||
            !isAllowed(TxType.StakeChange) ||
            scheduleBondMoreFeeQuery.isLoading
          }
          data-testid={`${testId}-submit`}
          onClick={submitTxWithChecks}
        >
          <FormattedMessage id={getSubmitMessage(store, isChainSwitchRequired)} />
        </Button>
      </Container>
    </Container>
  );
};
