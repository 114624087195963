import { BN_ZERO } from '@polkadot/util';
import { QueryOptional } from '../../../services';
import { useReserveBalancesQuery } from '..';
import { useInternalBalance } from '../internal/useInternalBalance';

export const useReserves = (liquidityTokenId: QueryOptional<string>) => {
  const { balances, internalBalancesQuery } = useInternalBalance();
  const { reserveBalancesQuery } = useReserveBalancesQuery();
  const isLoading = reserveBalancesQuery.isLoading || internalBalancesQuery.isLoading;

  if (!liquidityTokenId) {
    return {
      activatedUnstakedReserves: null,
      stakedUnactivatedReserves: null,
      stakedAndActivatedReserves: null,
      unspentReserves: null,
      totalBalance: null,
      availableBalance: null,
      activatedNativeRewardsLiquidity: null,
      isLoading,
    };
  }

  const availableBalance = balances?.get(liquidityTokenId)?.free || BN_ZERO;
  const reservesBalance = reserveBalancesQuery.data?.get(liquidityTokenId);
  const unspentReserves = reservesBalance?.unspentReserves.toBn() || BN_ZERO;
  const activatedUnstakedReserves = reservesBalance?.activatedUnstakedReserves?.toBn() || BN_ZERO;
  const stakedUnactivatedReserves = reservesBalance?.stakedUnactivatedReserves?.toBn() || BN_ZERO;
  const stakedAndActivatedReserves = reservesBalance?.stakedAndActivatedReserves?.toBn() || BN_ZERO;
  const activatedNativeRewardsLiquidity = activatedUnstakedReserves.add(stakedAndActivatedReserves);

  const totalBalance = unspentReserves
    ?.add(activatedUnstakedReserves)
    .add(availableBalance || BN_ZERO);

  return {
    activatedNativeRewardsLiquidity,
    activatedUnstakedReserves,
    stakedUnactivatedReserves,
    stakedAndActivatedReserves,
    unspentReserves,
    availableBalance,
    totalBalance,
    isLoading,
  };
};
