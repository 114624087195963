import { XcmBeneficiary } from '../../types';

export function buildBeneficiary(address: string): XcmBeneficiary {
  return {
    V3: {
      parents: 0,
      interior: {
        X1: {
          AccountId32: {
            id: address,
          },
        },
      },
    },
  };
}
