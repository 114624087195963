import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum RemoveStakeUIStoreFormError {
  InsufficientFee = 'InsufficientFee',
  InsufficientFunds = 'InsufficientFunds',
  MinAmountNotReached = 'MinAmountNotReached',
}

export interface RemoveStakeUIStore {
  amount: number;
  formError: Partial<Record<RemoveStakeUIStoreFormError, boolean>>;
  setFormError: (value: Partial<Record<RemoveStakeUIStoreFormError, boolean>>) => void;
  setAmount: (value: number) => void;
  dispose: () => void;
}

export const INITIAL_STATE = {
  amount: 25,
  formError: {},
};

export const useRemoveStakeStore = create(
  devtools<RemoveStakeUIStore>((set, get) => ({
    ...INITIAL_STATE,
    setAmount: (amount) => set({ amount }),
    setFormError: (formError) => set({ formError }),
    dispose: () => set(INITIAL_STATE),
  })),
);
