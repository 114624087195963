import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { useApi } from '../../../../../services';
import { submitDelegate } from '../services/DelegateMutationService';
import { useAccounts } from '../../../../account';
import { useWallet } from '../../../../wallet';

export const useDelegateMutation = () => {
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const delegateMutation = useMutation(
    ['delegate'],
    submitDelegate(api, selectedAccount?.address, wallet, transactionStore),
  );

  return { delegateMutation };
};
