import cls from 'classnames';
import { useEffect } from 'react';

import { useCopyToClipboard } from '../../hooks';
import { TestId } from '../../types';
import { Text } from '../Text/Text';
import { Container } from '../Container/Container';
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip';
import { FormatAmount } from '../Format';
import { Asset, ClassName } from 'core';
import { FormatAmountProps } from '../Format/FormatAmount';

export interface AmountTooltipProps
  extends TestId,
    FormatAmountProps,
    ClassName,
    Pick<TooltipProps, 'placement'> {
  token?: Asset;
  id: string;
}

const COPY_MESSAGE_TIMEOUT = 2000;

export function AmountTooltip({
  value,
  id,
  options,
  className,
  placement,
  'data-testid': testId = 'AmountTooltip',
}: AmountTooltipProps) {
  const { isCopied, copyToClipboard, resetIsCopied } = useCopyToClipboard(value);

  useEffect(() => {
    if (isCopied) {
      setTimeout(resetIsCopied, COPY_MESSAGE_TIMEOUT);
    }
  }, [isCopied, resetIsCopied]);

  return (
    <Tooltip
      id={id}
      onClick={copyToClipboard}
      data-testid={testId}
      leftOffset={false}
      className={cls('cursor-pointer', className)}
      placement={placement}
      tooltipContent={
        <Container alignItems="center" data-testid={`${testId}-content`}>
          <Text color="inverted">
            <FormatAmount value={value} />
          </Text>
          <Text
            color="inverted"
            className="ml-2"
            weight="semibold"
            data-testid={`${testId}-copy`}
            id={`amount.tooltip.${isCopied ? 'copied' : 'copy'}`}
          />
        </Container>
      }
      inPortal
    >
      <FormatAmount value={value} options={options || { precision: 3 }} />
    </Tooltip>
  );
}
