import { usePromotedPoolATvlQueries } from './atvl/query/usePromotedPoolATvlQuery';
import { getPromotedPoolsRewards } from './rewards/services/PromotedPoolRewards';
import { usePromotedPoolsMetadataQuery } from './metadata/query/usePromotedPoolsMetadataQuery';
import { useAllPoolsQuery } from '../all/query/useAllPoolsQuery';
import { useNativeTokenQuery } from '../../token';
import { usePromotedPoolsApr } from './apr/usePromotedPoolsApr';

export const usePromotedPools = () => {
  const {
    nativeAssetsQuery: { data: assets },
  } = useNativeTokenQuery();
  const {
    allPoolsQuery: { data: pools },
  } = useAllPoolsQuery();
  const { metadataQuery } = usePromotedPoolsMetadataQuery();

  const poolRewardsByLPId = getPromotedPoolsRewards(metadataQuery.data);
  const poolWeightData = metadataQuery.data?.promotedPoolWeightData;
  const promotedPoolsLPIds = Object.keys(poolWeightData || {});

  const { promotedPoolATvlQueriesByLPId } = usePromotedPoolATvlQueries({
    liquidityTokenIds: promotedPoolsLPIds,
    pools,
  });
  const { poolAprsByLPId, isLoading: isAprLoading } = usePromotedPoolsApr({
    assets,
    pools,
    poolRewardsByLPId,
    promotedPoolATvlQueriesByLPId,
  });

  const promotedPools = poolWeightData ? pools?.baseList.filter((pool) => pool.isPromoted) : null;

  return {
    poolAprsByLPId,
    promotedPoolATvlQueriesByLPId,
    poolRewardsByLPId,
    promotedPools,
    isLoading: isAprLoading,
  };
};
