import cls from 'classnames';
import { ReactNode } from 'react';

import { ClassName, TestId } from '../../types';
import { Text } from '..';

type ItemRowVariant = 'primary' | 'secondary';

export interface ItemRowProps
  extends ClassName,
    TestId,
    Pick<React.DetailsHTMLAttributes<HTMLDivElement>, 'onClick'> {
  title: string;
  description?: string;
  rightElement: ReactNode;
  verticalCenter?: boolean;
  variant?: ItemRowVariant;
  descriptionClassName?: string;
}

const STYLES = {
  primary: {
    description: '',
  },
  secondary: {
    description: 'mt-[2px]',
  },
};

export function ItemRow({
  className,
  'data-testid': testId,
  verticalCenter = true,
  description,
  rightElement,
  title,
  onClick,
  variant = 'primary',
  descriptionClassName,
}: ItemRowProps) {
  return (
    <div
      className={cls(
        'flex justify-between rounded',
        cls('flex', verticalCenter && 'items-center'),
        onClick && 'cursor-pointer hover:bg-base',
        className,
      )}
      data-testid={testId}
      onClick={onClick}
    >
      {description !== undefined ? (
        <div className="flex flex-col">
          <Text type="title-3">{title}</Text>
          <Text
            type={variant === 'primary' ? 'body-s' : 'body-m'}
            weight={variant === 'primary' ? 'semibold' : 'normal'}
            color="secondary"
            className={cls(STYLES[variant].description, descriptionClassName)}
          >
            {description}
          </Text>
        </div>
      ) : (
        <Text type="title-4">{title}</Text>
      )}
      <div className={cls('flex', verticalCenter ? 'items-center' : 'h-fit pt-[2px]')}>
        {rightElement}
      </div>
    </div>
  );
}
