import { useMutation } from '@tanstack/react-query';
import { useSDK as useMetamask } from '@metamask/sdk-react';
import { useRollupGasPriceQuery, useTransactionStore } from '../../../..';
import { useRolldownContract } from '../../../contract/useRolldownContract';
import { useEffect, useState } from 'react';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { submitRollupApprove } from '../services/rollupApproveService';

export const useRollupApproveMutation = (
  chainId: QueryOptional<string>,
  isAllowanceSufficient: boolean,
) => {
  const [isApproveInProgress, setIsApproveInProgress] = useState(false);
  const { contract } = useRolldownContract(chainId);
  const { provider } = useMetamask();
  const transactionStore = useTransactionStore();
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);

  const rollupApproveMutation = useMutation(
    submitRollupApprove(transactionStore, provider, contract, rollupGasPriceQuery.data),
    {
      ...DEFAULT_QUERY_OPTIONS,
      onMutate: () => {
        setIsApproveInProgress(true);
      },
    },
  );

  const isSuccess = rollupApproveMutation.data?.isSuccess;
  const onDone = rollupApproveMutation.data?.onDone;
  const reset = rollupApproveMutation.reset;

  useEffect(() => {
    if (rollupApproveMutation.isError || !isSuccess) {
      setIsApproveInProgress(false);
      reset();
    }
  }, [rollupApproveMutation.isError, isSuccess, reset]);

  useEffect(() => {
    if (isAllowanceSufficient && isApproveInProgress) {
      onDone?.();
      setIsApproveInProgress(false);
      reset();
    }
  }, [isAllowanceSufficient, onDone, reset, isApproveInProgress]);

  return {
    rollupApproveMutation,
    isApproveInProgress,
  };
};
