import { Transaction, TxStatus } from 'core';
import { FormattedMessage } from 'react-intl';
import { Container, Text, LinkButton } from 'ui';
import { TxModalError } from '../TxModalError/TxModalError';

export interface TxModalStatusProps {
  tx: Transaction;
}

export function TxModalStatus({ tx }: TxModalStatusProps) {
  const { status, explorerUrl } = tx;

  const renderStatus = () => {
    switch (status) {
      case TxStatus.Pending:
        return <Text color="secondary" id="modal.transaction.pending.title" />;
      case TxStatus.Confirming:
        return <Text color="secondary" id="modal.transaction.confirming.title" />;
      case TxStatus.Success:
        return (
          explorerUrl && (
            <Container>
              <LinkButton variant="secondary" href={explorerUrl} target="_blank">
                <FormattedMessage id="modal.transaction.success.link" />
              </LinkButton>
            </Container>
          )
        );
      case TxStatus.Error:
        return <TxModalError tx={tx} />;
    }
  };

  return <Container className="mt-1">{renderStatus()}</Container>;
}
