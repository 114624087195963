import { useQuery } from '@tanstack/react-query';
import {
  DEFAULT_QUERY_OPTIONS,
  QUERY_REFETCH_INTERVAL,
  QueryOptional,
  useWeb3,
} from '../../../../../services';
import { fetchRollupTokenBalances } from '../services/rollupTokenBalanceService';
import { useRollupTokensQuery } from '../../list/query/useRollupTokensQuery';

export const useRollupTokenBalancesQuery = (
  userAddress: QueryOptional<string>,
  chainId: QueryOptional<string>,
) => {
  const { rollupTokensQuery } = useRollupTokensQuery();
  const web3s = useWeb3();

  const tokens = rollupTokensQuery.data;

  const rollupTokenBalancesQuery = useQuery(
    ['rollup-token-balances', userAddress, chainId],
    fetchRollupTokenBalances(web3s, tokens),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!tokens && !!userAddress && !!web3s,
      refetchInterval: QUERY_REFETCH_INTERVAL.externalBalance,
    },
  );

  return {
    rollupTokenBalancesQuery,
  };
};
