import { useQuery } from '@tanstack/react-query';
import { QueryOptional, DEFAULT_QUERY_OPTIONS, useApi } from '../../../../../services';
import { isAmountGtZero } from '../../../../../validation';
import { useUserStore } from '../../../../user/useUserStore';
import { getRollupWithdrawalFee } from '../services/rollupWithdrawalService';
import { useAdaptiveFee } from '../../../../fee/adaptive';
import { useRollupChainsQuery } from '../../../stash/query/useRollupChainsQuery';

export const useRollupWithdrawalFeeQuery = (
  chainId: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
  amount: QueryOptional<string>,
) => {
  const api = useApi();
  const { selectedAccount } = useUserStore();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const chainKey = rollupChainsQuery.data?.find((c) => c.chainId === chainId)?.key;

  const rollupWithdrawalFeeQuery = useQuery(
    ['rollup-withdrawal-fee', tokenAddress, selectedAccount?.address, amount, chainKey],
    getRollupWithdrawalFee(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled: !!tokenAddress && isAmountGtZero(amount) && isAdaptiveFeeReady,
    },
  );

  return {
    rollupWithdrawalFeeQuery,
  };
};
