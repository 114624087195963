import { Decimal } from 'decimal.js';
import { TokenStatistics, getAssetFromTokenStats } from 'core';
import { Container, TokenIcon, Text, Trend, FormatAmount, FormatCurrency } from 'ui';

interface TokenListItemProps {
  stats: TokenStatistics;
  onClick?: () => void;
}

export function TokenListItem({ stats, onClick }: TokenListItemProps) {
  return (
    <div
      className="grid grid-cols-7 gap-2 px-4 py-3 transition items-center hover:bg-base rounded-lg my-1 w-full"
      data-testid={`${stats.symbol}-token-row`}
    >
      <Container alignItems="center" onClick={onClick} className="cursor-pointer col-span-2">
        <TokenIcon size="m" token={getAssetFromTokenStats(stats)} />
        <Container column alignItems="start" className="ml-4 overflow-hidden">
          <Text>{stats.symbol}</Text>
          <Text type="body-s" color="secondary" className="text-ellipsis">
            {stats.tokenName}
          </Text>
        </Container>
      </Container>

      <Container>
        <Text>
          $
          <FormatAmount
            data-testid="priceInUSD"
            value={stats.priceInUSD}
            options={{ precision: 2, nonZeroPrecision: true }}
          />
        </Text>
      </Container>

      <Container>
        <Trend data-testid="priceChange24hInPerc" value={new Decimal(stats.priceChange24hInPerc)} />
      </Container>

      <Container>
        <Text>
          <FormatCurrency
            data-testid="volume24hInUSD"
            value={stats.volume24hInUSD}
            options={{ precise: true }}
          />
        </Text>
      </Container>

      <Container>
        <Trend
          data-testid="volumeChange24hInPerc"
          value={new Decimal(stats.volumeChange24hInPerc)}
        />
      </Container>

      <Container>
        <Text>
          <FormatCurrency
            data-testid="liquidity24hInUSD"
            value={stats.liquidity24hInUSD}
            options={{ precise: true }}
          />
        </Text>
      </Container>
    </div>
  );
}
