import { ActiveMiningIcon, Text, Container, Button } from 'ui';
import cls from 'classnames';
import {
  ClassName,
  TxAsset,
  TxType,
  useInternalBalance,
  useMangataAsset,
  usePoolRewards,
  useRewards,
  useSafeTransaction,
} from 'core';
import { PoolWithShare } from '@mangata-finance/sdk';
import { FormattedMessage } from 'react-intl';
import {
  ClaimRewardsConfirmModal,
  ClaimRewardsConfirmModalType,
} from '../ClaimRewardsConfirmModal/ClaimRewardsConfirmModal';
import { useState } from 'react';
import Decimal from 'decimal.js';
import { PoolRewardsInfo } from './components/PoolRewardsInfo';

interface PoolLiqMiningRewardsCardProps extends ClassName {
  pool: PoolWithShare;
}

export function PoolLiqMiningRewardsCard({ className, pool }: PoolLiqMiningRewardsCardProps) {
  const { claimableRewards } = usePoolRewards(pool.liquidityTokenId);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { getFreeBalance } = useInternalBalance();
  const { mangataAsset } = useMangataAsset();

  const nativeRewardsAmount =
    mangataAsset && claimableRewards && claimableRewards.get(mangataAsset.id)?.[1];

  const {
    claimAllPoolRewardsFeeQuery,
    claimAllPoolRewardsMutation: { mutate: claimRewards },
  } = useRewards(pool.liquidityTokenId);

  const submitClaimRewards = () => {
    claimRewards({ lpId: pool.liquidityTokenId });
  };

  const { submitTxWithChecks: handleClaimWithChecks } = useSafeTransaction(
    {
      receivingAssets:
        mangataAsset && nativeRewardsAmount
          ? [{ ...mangataAsset, amount: nativeRewardsAmount }]
          : [],
      adaptiveFee: claimAllPoolRewardsFeeQuery.data,
      type: TxType.ClaimPoolRewards,
    },
    submitClaimRewards,
  );

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const confirmClaimRewards = () => {
    closeConfirmModal();
    handleClaimWithChecks();
  };

  const isFeeSufficient = (feeTxAsset: TxAsset | null) =>
    feeTxAsset
      ? new Decimal(getFreeBalance(feeTxAsset) || '0').gte(feeTxAsset.amount || '0')
      : true;

  return claimableRewards.size > 0 ? (
    <>
      <Container
        className={cls('bg-widget rounded-xl shadow-xl border border-default', className)}
        fullWidth
        justifyContent="space-between"
        alignItems="center"
        column
      >
        <Container
          alignItems="center"
          fullWidth
          justifyContent="space-between"
          className="py-6 px-4 bg-gradient-to-r from-app/[.1] from-0% via-app/[.1] to-premium/[.1] to-100%"
        >
          <Container alignItems="center">
            <ActiveMiningIcon className="mr-4" size="m" />
            <Container column>
              <Text type="title-3" weight="normal" id="position.overview.native.rewards.title" />
              <Text id="position.overview.native.rewards.desc" color="secondary" />
            </Container>
          </Container>
          <Button
            variant="accent"
            onClick={openConfirmModal}
            isDisabled={claimableRewards.size === 0}
          >
            <FormattedMessage id="common.claim.all.cta" />
          </Button>
        </Container>
        <div className="w-full border border-default" />
        <Container column fullWidth className="pb-4 px-6">
          <PoolRewardsInfo pool={pool} />
        </Container>
      </Container>
      <ClaimRewardsConfirmModal
        type={ClaimRewardsConfirmModalType.Single}
        isOpen={isConfirmModalOpen}
        rewards={Array.from(claimableRewards.values())}
        feeAsset={claimAllPoolRewardsFeeQuery.data?.current || null}
        onConfirm={confirmClaimRewards}
        onCancel={closeConfirmModal}
        isFeeInsuff={!isFeeSufficient(claimAllPoolRewardsFeeQuery.data?.current || null)}
      />
    </>
  ) : null;
}
