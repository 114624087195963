import { useQuery } from '@tanstack/react-query';
import { useSDK } from '../../../../../services';
import { PoolWithRatio } from '../../../Pool';
import { getPromotedPoolBlockRewards } from '../services/PromotedPoolBlockRewardsService';
import { useDebounce } from '../../../../../hooks/useDebounce';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const usePromotedPoolBlockRewards = (
  _firstAmount: string | null,
  pool: PoolWithRatio | null | undefined,
) => {
  const sdk = useSDK();
  const firstAmount = useDebounce<string | null>(_firstAmount);

  const promotedPoolBlockRewardsQuery = useQuery(
    ['promoted-pool-liquidity-rewards', firstAmount],
    getPromotedPoolBlockRewards(sdk, pool),
    { ...queryOptions, enabled: !!pool && pool.isPromoted, keepPreviousData: true },
  );

  return { promotedPoolBlockRewardsQuery };
};
