import { useAllPoolsQuery } from '../../pool/all/query/useAllPoolsQuery';

import { Asset } from '../Token';
import { useTokenMetadata } from '../metadata';
import { transformToAsset } from '../shared';

export const useAssets = () => {
  const {
    allPoolsQuery: { data: pools },
  } = useAllPoolsQuery();

  const { liquidityAssetsQuery, nativeAssetsQuery } = useTokenMetadata({
    pools: pools?.list,
  });

  const assets = [...(nativeAssetsQuery.data ?? []), ...(liquidityAssetsQuery.data ?? [])].map(
    transformToAsset,
  );
  const assetsMap = new Map<string, Asset>(assets.map((asset) => [asset.id, asset]));
  const symbolIdMap = new Map<string, string>(assets.map((asset) => [asset.symbol, asset.id]));

  return {
    assetsMap,
    assets,
    symbolIdMap,
  };
};
