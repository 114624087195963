import toformat from 'toformat';
import { Decimal } from 'decimal.js';
import { ClassName, TestId } from 'core';

export interface FormatAmountOptions {
  precision?: number;
  nonZeroPrecision?: boolean;
  maxChars?: number;
  minThreshold?: string;
}

export interface FormatAmountProps extends TestId {
  value: string | null | undefined;
  options?: FormatAmountOptions;
}

type FormatAmountComponentProps = FormatAmountProps & ClassName;

const estimateCommasFromMaxChars = (maxChars: number): number => {
  const digitsCount = maxChars - Math.floor((maxChars - 1) / 4);

  return Math.floor((digitsCount - 1) / 3);
};

const formatToLocaleString = (strValue: string, options?: FormatAmountOptions) => {
  const value = new toformat(Decimal)(strValue);
  const [, fraction] = strValue.split('.');

  if (!fraction) {
    return value.toFormat();
  }

  if (options?.nonZeroPrecision) {
    const firstNonZeroDigitPosition = fraction.search(/[1-9]/);

    if (firstNonZeroDigitPosition < 2) {
      return value.toFormat(firstNonZeroDigitPosition + (options?.precision || 0));
    }

    return value.toFormat(firstNonZeroDigitPosition + (options?.precision || 0)).replace(/00$/, '');
  }

  if (options?.precision !== undefined) {
    return value.toFormat(options?.precision).replace('.000', '');
  }

  return value.toFormat();
};

export function formatAmount(
  strValue: string | null | undefined,
  options?: FormatAmountOptions,
  attempt = 0,
): string | null {
  if (!strValue) {
    return null;
  }

  if (options?.minThreshold && new Decimal(strValue).lessThan(options.minThreshold)) {
    return '<0.001';
  }

  const localeString = formatToLocaleString(strValue, options);

  if (options?.maxChars && localeString.length > options.maxChars) {
    if (attempt === 0) {
      const trimmedLocale = formatToLocaleString(new Decimal(strValue).toFixed(0), options);

      const charsLeft = options.maxChars - trimmedLocale.length;
      const decimals = Math.max(0, charsLeft - 1);
      const roundedValue = new Decimal(strValue).toDecimalPlaces(decimals, 0).toString();
      options.precision = decimals;

      return formatAmount(roundedValue, options, attempt + 1);
    } else {
      const intCount = options.maxChars - estimateCommasFromMaxChars(options.maxChars);
      const minAmount = Array.from({ length: intCount }, (_) => 9).join('');

      if (new Decimal(strValue).gt(minAmount)) {
        return `>${formatToLocaleString(minAmount, options)}`;
      }
    }
  }

  if (localeString.search(/[1-9]/) === -1 && localeString.includes('.')) {
    return `<${localeString.replace(/0$/, '1')}`;
  }

  return localeString;
}

export function FormatAmount({
  value,
  options,
  className,
  'data-testid': testId,
}: FormatAmountComponentProps) {
  return (
    <span className={className} data-testid={testId}>
      {formatAmount(value, options)}
    </span>
  );
}
