import { Asset, useTokenPriceHistoryQuery, useTokenVolumeHistoryQuery } from 'core';
import { last } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Decimal } from 'decimal.js';
import {
  Text,
  ChartDropdown,
  Container,
  formatAmount,
  LineChart,
  Timeframe,
  timeframes,
  TimeframeSelector,
} from 'ui';
import { useIntl } from 'react-intl';

enum TokenDetailChartType {
  Volume,
  Price,
}

interface TokenDetailChartProps {
  asset?: Asset;
}

export function TokenDetailChart({ asset }: TokenDetailChartProps) {
  const intl = useIntl();
  const [currentTimeframe, setTimeframe] = useState<Timeframe>(timeframes[1]);
  const [chartType, setChartType] = useState<TokenDetailChartType>(TokenDetailChartType.Price);

  const { singlePriceHistoryQuery } = useTokenPriceHistoryQuery(asset?.symbol, null, {
    days: currentTimeframe.days,
    interval: currentTimeframe.interval,
  });

  const { singleTokenVolumeHistoryQuery } = useTokenVolumeHistoryQuery(
    asset?.symbol,
    currentTimeframe.days,
    currentTimeframe.interval,
  );

  const dataByType = {
    [TokenDetailChartType.Volume]: singleTokenVolumeHistoryQuery.data?.volumes,
    [TokenDetailChartType.Price]: singlePriceHistoryQuery.data?.prices,
  };

  const data = dataByType[chartType]?.map(([timestamp, volume]) => ({
    x: new Date(timestamp),
    y: volume,
  }));

  const onChartSelect = (i: number) => {
    setChartType(i === 0 ? TokenDetailChartType.Volume : TokenDetailChartType.Price);
  };

  const isChartEmpty = useMemo(() => {
    if (!asset) {
      return false;
    }

    switch (chartType) {
      case TokenDetailChartType.Volume:
        return !singleTokenVolumeHistoryQuery.isLoading && singleTokenVolumeHistoryQuery.isError;
      case TokenDetailChartType.Price:
        return !singlePriceHistoryQuery.isLoading && singlePriceHistoryQuery.isError;
    }
  }, [
    asset,
    chartType,
    singlePriceHistoryQuery.isError,
    singlePriceHistoryQuery.isLoading,
    singleTokenVolumeHistoryQuery.isError,
    singleTokenVolumeHistoryQuery.isLoading,
  ]);

  const latestPrice = last(singlePriceHistoryQuery.data?.prices)?.[1] || null;
  const volumeSum = singleTokenVolumeHistoryQuery.data?.volumes
    .reduce((acc, record) => {
      return acc.add(record[1]);
    }, new Decimal(0))
    .toString();

  const formatYAxis = (value: string) =>
    formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '';
  const formatTooltipYValue = (value: string) =>
    `$${formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '0'}`;

  const renderChartContent = () => {
    if (isChartEmpty || !data) {
      return null;
    }

    switch (chartType) {
      case TokenDetailChartType.Price:
        return (
          <>
            <Text type="title-2" color="secondary" className="mr-1">
              1 {asset?.symbol} =
            </Text>
            <Text type="title-2" className="mr-2">
              ${formatAmount(latestPrice, { precision: 3, nonZeroPrecision: true })}
            </Text>
          </>
        );
      case TokenDetailChartType.Volume:
        return (
          <Text type="title-2" className="mr-1">
            ${formatAmount(volumeSum, { precision: 3, nonZeroPrecision: true })}
          </Text>
        );
    }
  };

  return (
    <Container
      fullWidth
      column
      className="mt-14 h-[300px] overflow-visible"
      data-testid="token-detail-chart"
    >
      <Container fullWidth justifyContent="space-between" alignItems="center">
        <ChartDropdown
          onSelect={onChartSelect}
          data={[
            intl.formatMessage({ id: 'token.detail.chart.select.volume' }),
            intl.formatMessage({ id: 'token.detail.chart.select.price' }),
          ]}
          activeIndex={chartType === TokenDetailChartType.Volume ? 0 : 1}
        />
        <TimeframeSelector onSelect={setTimeframe} currentTimeframe={currentTimeframe} />
      </Container>

      <Container className="mt-6 ml-4" alignItems="center">
        {renderChartContent()}
      </Container>

      <LineChart
        data={data}
        timeframe={currentTimeframe}
        id={`token-detail-${currentTimeframe.key}`}
        formatYAxis={formatYAxis}
        formatTooltipYValue={formatTooltipYValue}
        isEmpty={isChartEmpty}
      />
    </Container>
  );
}
