import { useQuery } from '@tanstack/react-query';
import { TxAsset, TxType, useAccounts, useAdaptiveFee, useReservesForTx } from '../../../..';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { getScheduleBondMoreFee } from '../services/ScheduleDelegatorBondMoreFeeService';

export const useScheduleBondMoreFeeQuery = (
  collatorAddress: QueryOptional<string>,
  stakedAsset: QueryOptional<TxAsset>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const { sources: stakeSources } = useReservesForTx(TxType.StakeChange, stakedAsset?.id);
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, stakedAsset?.id);

  const scheduleBondMoreFeeQuery = useQuery(
    ['scheduleDelegatorBondMore-fee', selectedAccount?.address, collatorAddress, stakedAsset],
    getScheduleBondMoreFee(api, stakeSources, activateLiqSources),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!stakeSources &&
        !!activateLiqSources,
    },
  );

  return { scheduleBondMoreFeeQuery };
};
