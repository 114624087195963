import { ClassName, TestId } from '../../types';
import { Checkbox, ItemRow, Toggle } from '..';

export interface SettingsRowProps
  extends ClassName,
    TestId,
    Pick<React.DetailsHTMLAttributes<HTMLDivElement>, 'onClick'> {
  title: string;
  description?: string;
  variant?: 'checkbox' | 'toggle';
  value?: boolean;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
}

export function SettingsRow({
  className,
  'data-testid': testId = 'settingsRow',
  description,
  title,
  onChange,
  variant = 'toggle',
  value,
  isDisabled,
}: SettingsRowProps) {
  const COMMON_INPUT_PROPS = {
    'data-testid': `${testId}-${variant}`,
    onChange,
    value,
  };
  const ACCESSORY_MAP = {
    toggle: <Toggle {...COMMON_INPUT_PROPS} isDisabled={isDisabled} />,
    checkbox: (
      <Checkbox
        {...COMMON_INPUT_PROPS}
        isDisabled={isDisabled}
        id={`checkbox-${title.slice(0, 4)}`}
      />
    ),
  };

  return (
    <ItemRow
      className={className}
      data-testid={testId}
      title={title}
      variant="secondary"
      description={description}
      rightElement={ACCESSORY_MAP[variant]}
      descriptionClassName="mt-0"
    />
  );
}
