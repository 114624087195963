import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { fetchRollupTokenAllowance } from '../services/rollupTokenAllowanceService';
import { useRolldownContract } from '../../../contract/useRolldownContract';
import { useRollupTokensQuery } from '../../list/query/useRollupTokensQuery';

export const useRollupTokenAllowanceQuery = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
) => {
  const { rollupTokensQuery } = useRollupTokensQuery();
  const rolldown = useRolldownContract(chainId);

  const tokens = rollupTokensQuery.data;
  const token = tokens?.find((t) => t.address === tokenAddress);

  const rollupTokenAllowanceQuery = useQuery(
    ['rollup-token-allowance', userAddress, token?.address],
    fetchRollupTokenAllowance(rolldown.address, userAddress, token),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!token && !token.isNative,
      refetchInterval: 3000,
    },
  );

  return {
    rollupTokenAllowanceQuery,
  };
};
