import { MangataInstance } from '@mangata-finance/sdk';
import { PoolWithRatio } from '../../Pool';

export type GetInvestedPoolsQueryKey = [
  queryKey: string,
  address: string | undefined,
  txCount: number,
];
export type PoolKey = string;

export interface AllPoolsQueryData {
  byId: Record<PoolKey, PoolWithRatio>;
  list: PoolWithRatio[];
  byAdjacentId: Record<PoolKey, PoolWithRatio[]>;
}
export const getAllPools = (sdk: MangataInstance | null) => async () => {
  if (!sdk) {
    return null;
  }

  return sdk.query.getPools();
};
