import { WalletSource, RouteDataError, TxType } from 'core';
import { SwapFormError } from '../modules/Swap/useSwapValidation';

export default {
  'common.mainToken': 'MGX',
  'common.defaultChain': 'Mangata X',
  'common.learnMore': 'Learn More',
  'common.cancel': 'Cancel',
  'common.search': 'Search',
  'common.lpTokens': 'LP Tokens',
  'common.apr': 'APY',
  'common.token.native': 'Native',
  'common.claim.cta': 'Claim',
  'common.claim.all.cta': 'Claim All',
  'common.add.liq.cta': 'Add Liquidity',
  'common.remove.cta': 'Remove',
  'common.collator': 'Collator',
  'common.stakeApr': 'Stake APY',
  'common.add': 'Add',
  'common.remove': 'Remove',
  'common.disabled': 'Disabled',
  'common.show.more': 'Show more',
  'common.show.less': 'Show less',
  'common.on': 'on',
  'common.switch.defaultNetwork': 'Switch to Goerli',
  'wallet.connect': 'Connect Wallet',
  'wallet.open': 'Open',
  'wallet.isMobile': 'Mobile',
  'wallet.deposit': 'Deposit',
  'wallet.withdraw': 'Withdraw',
  'wallet.header.connected': 'CONNECTED',
  'wallet.connectWallet.header': 'Select a Wallet',
  'wallet.connect.installedWallets': 'Installed Wallets',
  'wallet.connect.popularWallets': 'Popular Wallets',
  'wallet.extension.connect': 'Connect',
  'wallet.toast.connected': 'Wallet Connected',
  [`wallet.extension.${WalletSource.Talisman}`]: 'Talisman',
  [`wallet.extension.${WalletSource.PolkadotJS}`]: 'Polkadot{.js}',
  [`wallet.extension.${WalletSource.SubwalletJS}`]: 'SubWallet',
  [`wallet.extension.${WalletSource.Enkrypt}`]: 'Enkrypt',
  [`wallet.extension.${WalletSource.NovaWallet}`]: 'Nova Wallet',
  [`wallet.extension.${WalletSource.Metamask}`]: 'Metamask',
  'wallet.notConnected.header': 'No Wallet Connected',
  'wallet.notConnected.title': 'Connect Your Wallet',
  'wallet.notConnected.cta': 'Connect wallet',
  'wallet.notConnected.info.text':
    'Wallet is your key to Web3. Connecting the wallet is safe. There will be no transactions without your approval.',
  'wallet.connecting.info.text': 'New to wallets?',
  'wallet.connecting.info.cta': 'Learn more',
  'wallet.modal.account.connected.title': 'Connected account',
  'wallet.modal.account.disconnect': 'Disconnect',
  'wallet.modal.account.select.title': 'Select account',
  'wallet.modal.account.connected.header': 'Connected with {wallet}',
  'wallet.modal.account.connecting.header': 'Connecting {wallet}...',
  'wallet.modal.account.change.title': 'Connect with another account',
  'wallet.modal.account.loading.title': 'Loading accounts',
  'wallet.modal.account.loading.desc': 'If loading is taking too long, refresh the browser.',
  'wallet.modal.account.empty.title': 'No accounts found',
  'wallet.modal.account.empty.desc': 'Create an account in your wallet to get started.',
  'wallet.modal.connect.genericError.title': 'Something went wrong',
  'wallet.modal.connect.notFound.title': 'Wallet not found ',
  'wallet.modal.connect.notFound.desc':
    'It seems like {wallet} is not installed on your computer. Please install the {wallet} wallet extension or refresh your browser if it is already installed.',
  'wallet.tabs.tokens': 'My Tokens',
  'wallet.tabs.positions': 'My Positions',
  'swap.token.warning.title': 'Be aware of token fraud!',
  'swap.token.warning.description':
    'Anyone can create a token, including fake  \n versions of already existing tokens and tokens \n representing projects that do not exist. You might \n be unable to sell such a token back once you \n purchase it.',
  'token.settings.modal.title': 'Manage token list',
  'token.settings.show.allAvailableTokens': 'Show all available tokens',
  'token.settings.show.mangataDefaultTokens': 'Mangata default tokens',
  'token.settings.show.userOwnedTokens': 'User Owned tokens',
  'token.settings.show.liquidityPoolTokens': 'Liquidity pool tokens',
  'token.settings.show.otherTokens': 'Other tokens',
  'token.select.button': 'Select token',
  'token.select.empty': 'No results',
  'token.select.modal.title': 'Select a token',
  'token.select.modal.search': 'Search Token',
  'token.settings.show.tokensCount': '{count} tokens',
  'swap.input.firstToken.label': 'You Pay',
  'swap.input.secondToken.label': 'You Get',
  'swap.details.minimumRecieved': 'Minimum received',
  'swap.details.priceImpact': 'Price impact',
  'swap.details.commission': 'Commission',
  'swap.details.fee': 'Fee',
  'swap.details.rate': 'Rate',
  'swap.details.show': 'Trade details',
  'swap.details.hide': 'Hide',
  'swap.button.swap': 'Swap',
  'error.toast': 'Something went wrong',
  [`modal.tx.${TxType.Withdraw}.pending`]: 'Withdrawing',
  [`modal.tx.${TxType.Withdraw}.success`]: 'Withdrawn',
  [`modal.tx.${TxType.Deposit}.pending`]: 'Depositing',
  [`modal.tx.${TxType.Deposit}.success`]: 'Deposited',
  [`modal.tx.${TxType.Swap}.pending`]: 'Swapping',
  [`modal.tx.${TxType.Swap}.success`]: 'Swapped',
  [`modal.tx.${TxType.Claim}.pending`]: 'Claiming Rewards',
  [`modal.tx.${TxType.Claim}.success`]: 'Rewards Claimed',
  [`modal.tx.${TxType.Stake}.pending`]: 'Staking',
  [`modal.tx.${TxType.Stake}.success`]: 'Staked',
  [`modal.tx.${TxType.ClaimAll}.pending`]: 'Claiming All Rewards',
  [`modal.tx.${TxType.ClaimAll}.success`]: 'Rewards Claimed',
  [`modal.tx.${TxType.ActivateLP}.pending`]: 'Activating Liquidity',
  [`modal.tx.${TxType.ActivateLP}.success`]: 'Liquidity Activated',
  [`modal.tx.${TxType.CreatePool}.pending`]: 'Creating Pool',
  [`modal.tx.${TxType.CreatePool}.success`]: 'Pool Created',
  [`modal.tx.${TxType.StakeChange}.pending`]: 'Scheduling Stake Change',
  [`modal.tx.${TxType.StakeChange}.success`]: 'Stake Change Scheduled',
  [`modal.tx.${TxType.AddLiquidity}.pending`]: 'Adding Liquidity',
  [`modal.tx.${TxType.AddLiquidity}.success`]: 'Liquidity Added',
  [`modal.tx.${TxType.DeactivateLP}.pending`]: 'Deactivating Liquidity',
  [`modal.tx.${TxType.DeactivateLP}.success`]: 'Liquidity Deactivated',
  [`modal.tx.${TxType.Claim3rdParty}.pending`]: 'Claiming Rewards',
  [`modal.tx.${TxType.Claim3rdParty}.success`]: 'Rewards Claimed',
  [`modal.tx.${TxType.RemoveLiquidity}.pending`]: 'Removing Liquidity',
  [`modal.tx.${TxType.RemoveLiquidity}.success`]: 'Liquidity Removed',
  [`modal.tx.${TxType.ClaimPoolRewards}.pending`]: 'Claiming Rewards',
  [`modal.tx.${TxType.ClaimPoolRewards}.success`]: 'Rewards Claimed',
  [`modal.tx.${TxType.ConfirmStakeDecrease}.pending`]: 'Removing Stake',
  [`modal.tx.${TxType.ConfirmStakeDecrease}.success`]: 'Stake Removed',
  [`modal.tx.${TxType.ConfirmStakeIncrease}.pending`]: 'Adding Stake',
  [`modal.tx.${TxType.ConfirmStakeIncrease}.success`]: 'Stake Added',
  [`modal.tx.${TxType.ActivateLPFor3rdPartyRewards}.pending`]: 'Activating',
  [`modal.tx.${TxType.ActivateLPFor3rdPartyRewards}.success`]: 'Activated',
  [`modal.tx.${TxType.DeactivateLPFor3rdPartyRewards}.pending`]: 'Deactivating',
  [`modal.tx.${TxType.DeactivateLPFor3rdPartyRewards}.success`]: 'Activated',
  [`modal.tx.${TxType.RollupDeposit}.success`]: 'Depositing to Gasp',
  [`modal.tx.${TxType.RollupDeposit}.pending`]: 'Depositing to Gasp',
  [`modal.tx.${TxType.ApproveContract}.success`]: 'Contract approved',
  [`modal.tx.${TxType.ApproveContract}.pending`]: 'Approving contract',
  [`modal.tx.${TxType.RollupWithdrawal}.success`]: 'Withdrawn from Gasp',
  [`modal.tx.${TxType.RollupWithdrawal}.pending`]: 'Withdrawing from Gasp',
  'modal.transaction.error.tooLowDelegationCountToDelegate.desc':
    'Delegation count is too low to delegate',
  'modal.transaction.error.exceedMaxTotalDelegatorsPerCandidate.desc':
    'The maximum number of delegators per candidate has been reached',
  'modal.transaction.error.validatorBlockLimitReached.desc':
    'Unable to verify XCM transfer within the block limit.',
  'modal.transaction.error.title': 'Transaction Failed',
  'modal.transaction.error.unknown.desc': 'Something went wrong. Please try again later.',
  'modal.transaction.error.desc': '{error}',
  'modal.transaction.error.slippage.title': 'Transaction Failed',
  'modal.transaction.error.slippage.desc': 'Transaction failed due to slippage',
  'modal.transaction.error.cta': 'Ok, close',
  'modal.transaction.confirming.title': 'Confirm Transaction',
  'modal.transaction.pending.title': 'Transaction Pending',
  'modal.transaction.success.title': 'Transaction Completed',
  'modal.transaction.confirming.desc': 'Confirm the transaction \n in your wallet.',
  'modal.transaction.pending.desc': 'Wait until the transaction \n is processed.',
  'modal.transaction.success.cta': 'Confirm',
  'modal.transaction.success.link': 'View on Subscan',
  'modal.transaction.header.for': 'for',
  'modal.transaction.header.+': '+',
  'swap.banner.cta.addLiquidity': 'add liquidity',
  [`swap.banner.${RouteDataError.NoPools}`]:
    "The pool for the selected tokens doesn't exist yet. Check back later or {addLiquidity} to enable trading for the selected pair.",
  [`swap.banner.${RouteDataError.InsufficientLiquidity}`]:
    'The liquidity in the pool for the selected tokens is too low. Check back later or {addLiquidity} to enable trading for the selected pair.',
  [`swap.route.error.${SwapFormError.InsufficientMgx}`]:
    'You need at least {lockAmount} {nativeToken} available to be temporarily locked in your wallet to ensure a secure gasless swap and prevent spam.',
  'swap.button.cta': 'Swap tokens',
  'swap.button.connectWallet': 'Connect wallet',
  'swap.button.enterAmount': 'Enter amount',
  'swap.button.selectTokens': 'Select tokens',
  'swap.button.insufficientFunds': 'Insufficient balance',
  'swap.button.insufficientMgx': 'Insufficient balance of MGX',
  'swap.txProgress.error.desc': 'Extrinsic failed: {name} with message: {msg}',
  'swap.routing.title': 'Automatic routing',
  'swap.routing.desc':
    'Automatic transaction routing optimises swap route \n to get the best exchange rate.',
  'swap.fee.insufficient':
    'You need at least {lockAmount} MGX available to be temporarily locked in your wallet to ensure a secure gasless swap and prevent spam. \n \n The locking mechanism is not used for whitelisted trades exceeding 10,000 MGX.',
  'swap.fee.lock':
    'To ensure a secure transaction, a refundable fee of {lockAmount} MGX will be temporarily locked in your wallet. \n \n The locking mechanism is always used for non-whitelisted trades under 10,000 MGX and for all multi-swap trades.',
  'bridge.accountSelect.from': 'From',
  'bridge.accountSelect.to': 'To',
  'bridge.accountSelect.noAccount': 'No account found',
  'bridge.details.originFee': 'Origin Chain Fee',
  'bridge.details.destinationFee': 'Destination Chain Fee',
  'bridge.button.selectChain': 'Select chain',
  'bridge.button.selectToken': 'Select token',
  'bridge.button.selectAccount': 'Select account',
  'bridge.button.amount': 'Enter an amount',
  'bridge.button.deposit': 'Deposit',
  'bridge.button.withdraw': 'Withdraw',
  'deposit.error.minAmount': 'The deposit amount has to be higher than the destination chain fee.',
  'withdrawal.error.minAmount': 'Minimum withdrawal amount is {value}',
  'withdrawal.error.originFee': 'Insufficient {token} balance to cover origin chain fees.',
  'deposit.error.originFee':
    'Insufficient {token} balance on {chain} to cover the origin chain fees.',
  'bridge.error.insufficientBalance': 'Insufficient account balances.',
  'bridge.withdrawal.accountSelect.title': 'Select Destination Account',
  'bridge.deposit.accountSelect.title': 'Select Origin Account',
  'bridge.withdrawal.title': 'Withdraw',
  'bridge.deposit.title': 'Deposit',
  'bridge.ethAccInfo.deposit.text':
    'Only Ethereum addresses are supported when depositing from {chain}.',
  'bridge.ethAccInfo.withdraw.text':
    'Only Ethereum addresses are supported when withdrawing to {chain}.',
  'bridge.deposit.to': 'Depositing to',
  'bridge.withdraw.from': 'Withdrawing from',
  'chainSelector.label': 'Select chain',
  'chainSelector.from': 'From',
  'chainSelector.to': 'To',
  'chainSelector.defaultChain': 'Mangata X',
  'chainSelector.originChain': 'Select origin chain',
  'chainSelector.destinationChain': 'Select destination chain',
  'pools.page.title': 'Liquidity pools',
  'pools.page.desc': 'Provide liquidity, earn trading commissions & rewards',
  'pools.page.tab.promoted': 'Promoted Pools',
  'pools.page.tab.all': 'All Pools',
  'pool.detail.back.cta': 'Back to pools',
  'common.rewards.apr': 'Rewards APY',
  'pool.detail.tvl': 'TVL',
  'pool.detail.volume': 'Volume / 24h',
  'pool.detail.rewards.monthly': 'Rewards / Month',
  'pool.detail.liquidity.chart.title': 'Pool Liquidity History',
  'lineChart.yAxis.usd': 'Value in USD',
  'pool.provide.details.cancel': 'Cancel',
  'pool.provide.details.title': 'Adding liquidity',
  'pool.detail.my.position': 'My pool position',
  'pool.provide.details.pool.share': 'Expected Share of Pool',
  'pool.provide.details.pool.fee': 'Fee',
  'pool.provide.submit.enterAmount': 'Enter amount',
  'pool.provide.submit.insufficientFunds': 'Insufficient balance',
  'pool.provide.submit.insufficientFee': 'Insufficient balance to cover fee',
  'pool.provide.submit.add': 'Add liquidity',
  'pool.provide.submit.create': 'Create pool',
  'pool.provide.submit.connectWallet': 'Connect wallet',
  'pool.provide.details.est.rewards.title': 'Est. 30 days rewards',
  'pool.provide.details.est.rewards.desc':
    'With promoted liquidity pools, you will earn commission from trades proportional to your pool share, as well as special liquidity mining rewards.',
  'pool.provide.fee.insufficient': "You don't have enough MGX (KSM/TUR) to pay the fees",
  'pools.page.create.banner.title': 'Create a new liquidity pool',
  'pool.create.title': 'Create liquidity pool',
  'pool.create.already.exists.info':
    'The selected pool already exists. You can add liquidity to this pool and start earning rewards.',
  'wallet.connect.empty.title': 'No Wallet Installed',
  'wallet.connect.empty.description':
    'Install a Polkadot wallet to securaly manage and trade crypto assets.',
  'wallet.connect.empty.hint': 'Consider popular wallets like {first}, {second} or {third}.',
  'pool.detail.history.empty': 'No data available for the selected period. \n Check back soon!',
  'pools.page.search.placeholder': 'Search in liquidity pools',
  'pools.page.search.empty.title': 'No liquidity pool found',
  'pools.page.search.empty.desc':
    'Feeling adventurous? Create a liquidity pool \n that you are looking for by yourself!',
  'pools.page.search.empty.cta': 'Create a new pool',
  'position.remove.widget.title': 'Remove liquidity',
  'position.remove.widget.input.title': 'Amount to remove',
  'position.remove.widget.receive.label': 'You will receive',
  'position.page.title': 'My Positions',
  'position.page.search.empty.title': 'No Positions Found',
  'position.page.search.empty.desc':
    'No positions matched search criteria. Try to refine your search.',
  'position.list.search.placeholder': 'Search positions',
  'position.rewards.title': 'My Rewards',
  'position.disconnected.title': 'No wallet connected',
  'position.disconnected.desc':
    'Connect your wallet to view and manage your liquidity pool positions.',
  'position.disconnected.cta': 'Connect Wallet',
  'position.empty.title': 'Liquidity Pool Positions',
  'position.empty.desc':
    'Dive in and earn liquidity mining rewards by contributing to any promoted pool!',
  'position.empty.cta': 'Discover promoted pools',
  'position.page.desc': 'Manage your positions in liquidity pools',
  'position.card.rewardsWidget.title': 'Active Rewards',
  'position.card.rewardsWidget.notEligible':
    'Not promoted liquidity pools are not eligible for rewards.',
  'position.page.list.title': 'My Positions',
  'positions.detail.back.cta': 'Back to positions',
  'positions.detail.myShare': 'My Pool share',
  'positions.detail.add.cta': 'Add',
  'positions.detail.remove.cta': 'Remove',
  'positions.detail.myLP': 'My LP tokens',
  'positions.detail.token.share': '{symbol} Share',
  'position.detail.autoCompounding.title': 'Auto-compounding',
  'position.detail.autoCompounding.desc': 'Powered by {app}',
  'position.detail.autoCompounding.cta': 'Get Started',
  'position.claim.all.title': 'Claim all rewards in 1-click',
  'position.claim.all.noRewards': 'Nothing to claim',
  'position.claim.allClaimed': 'All Rewards Claimed',
  'position.claimSingle.title': 'Liquidity Mining Rewards',
  'position.claim.all.cta': 'Claim all rewards',
  'position.claim.all.noPosition.title': 'Provide Liquidity & Earn Rewards',
  'position.claim.all.noPosition.desc':
    'Provide liquidity into promoted liquidity pools to start earning special rewards.',
  'position.claim.all.noPosition.cta': 'Start earning',
  'position.claimSingle.cta': 'Claim',
  'position.claim.single.confirm.title': 'Claim Rewards',
  'position.claim.all.confirm.title': 'Claim All Rewards',
  'position.claim.all.confirm.desc':
    'Claiming all collected rewards from all of your positions in promoted liquidity pools.',
  'position.claim.single.confirm.desc':
    'Claiming collected rewards from your position in the promoted liquidity pool.',
  'position.claim.confirm.fee': 'Transaction Fee',
  'position.claim.all.confirm.submit': 'Claim All Rewards',
  'position.claim.single.confirm.submit': 'Claim Rewards',
  'position.claim.confirm.cancel': 'Cancel',
  'activateLiquidityCta.desc':
    'Liquidity mining is not active in {count} liquidity {count, plural, =0 {pool} =1 {pool} other {pools}}. Consider activating it to start earning more rewards.',
  'position.claimSingle.tooltip.title': 'Liquidity mining is active',
  'position.claimSingle.tooltip.desc':
    'On the top of trading commissions, you are earning special liquidity mining rewards. Don’t forget to claim your rewards regularly.',
  'position.claimSingle.liquidityMining': 'Liquidity Mining',
  'position.claimSingle.deactivated': 'Deactivated',
  'position.claimSingle.active': 'Active',
  'position.claimSingle.activatedTokens': 'Activated LP Tokens',
  'position.claimSingle.eligibleTokens': 'Eligible LP Tokens',
  'modal.low.balance.title': 'Warning! Low {feeSymbol} Balance',
  'modal.low.balance.adaptive.desc':
    'This transaction will bring your {feeSymbol} token amount under minimal required balance, which will restrict your next trading activities. Please ensure you have always enough MGX, KSM or TUR to continue trading smoothly. \n \n Adjust the trade, or deposit more MGX, KSM or TUR into your account.',
  'modal.low.balance.mgx.desc':
    'This transaction will bring your {feeSymbol} token amount under minimal required balance, which will restrict your next trading activities. Please ensure you have always enough {feeSymbol} to continue trading smoothly. \n \n Adjust the trade, or deposit more {feeSymbol} into your account.',
  'modal.low.balance.desc.restrict': 'restrict your trading activities',
  'modal.low.balance.cta': 'Proceed anyways',
  'modal.low.balance.check.desc':
    'I understand the consequences of low {feeSymbol} balance and I want to proceed.',
  'modal.low.balance.cancel': 'Cancel',
  'modal.low.balance.current.label': 'Current balance',
  'modal.low.balance.after.label': 'Balance After Transaction',
  'position.claim.insuff.fee': 'You don’t have enough funds to cover the transaction fee.',
  'position.activate.liquidity.confirm.title': 'Activate Liquidity Mining',
  'position.activate.liquidity.confirm.desc':
    'Activate liquidity mining to earn rewards. \n The longer you maintain your active \n position, the greater your earning \n potential.',
  'position.activate.liquidity.confirm.submit': 'Activate mining',
  'position.toggle.liquidity.insuff.fee':
    'You don’t have enough funds to cover the transaction fee.',
  'position.toggle.liquidity.confirm.fee': 'Transaction fee',
  'position.toggle.liquidity.confirm.cancel': 'Cancel',
  'position.deactivate.liquidity.confirm.submit': 'Deactivate mining',
  'position.deactivate.liquidity.confirm.title': 'Deactivate Liquidity Mining',
  'position.deactivate.liquidity.confirm.desc':
    'When you deactivate liquidity mining, you \n will stop earning mining rewards. You can \n reactivate liquidity mining anytime.',
  'tokens.page.title': 'Tokens',
  'tokens.page.desc': 'Explore tokens listed on Mangata',
  'tokens.page.search.placeholder': 'Search token',
  'tokens.page.search.empty.title': 'Token not found',
  'tokens.page.search.empty.desc': 'No tokens matched your search. Stay tuned for new additions!',
  'tokens.page.owned.empty.title': 'No tokens',
  'tokens.page.owned.empty.cta': 'How to get MGX',
  'tokens.page.owned.empty.desc':
    "You don't have any tokens in your wallet. Get MGX & start your crypto trading journey on Mangata X.",
  'tokens.list.header.token': 'Token',
  'tokens.list.header.price': 'Price (USD)',
  'tokens.list.header.priceChange': 'Price (24h)',
  'tokens.list.header.volume': 'Volume',
  'tokens.list.header.volumeChange': 'Volume (24h)',
  'tokens.list.header.liquidity': 'Liquidity',
  'tokens.list.search.placeholder': 'Search token',
  'tokens.disconnected.title': 'No wallet connected',
  'tokens.disconnected.desc': 'Connect wallet to view your tokens.',
  'tokens.disconnected.cta': 'Connect Wallet',
  'token.detail.back.cta': 'Back to Tokens',
  'token.detail.trade.cta': 'Trade Token',
  'token.detail.price': 'Price',
  'token.detail.volume': 'Volume / 24h',
  'token.detail.pools.title': 'Token Pools',
  'token.detail.chart.select.price': 'Token price',
  'token.detail.chart.select.volume': 'Trading volume',
  'swap.pair.dropdown.title': 'Price history',
  'swap.show.chart.cta': 'Show analytics',
  'swap.hide.chart.cta': 'Hide analytics',
  'staking.page.title': 'Staking',
  'staking.detail.stats.apr': 'Stake APY',
  'staking.detail.stats.rewards': 'Rewards / 24h',
  'staking.detail.stats.token': 'Staked Token',
  'staking.detail.stats.minStake': 'Minimal Stake',
  'staking.detail.stats.totalStake': 'Total Stake',
  'staking.detail.stats.delegators': 'Delegators',
  'collator.state.active': 'Active',
  'collator.state.waiting': 'Waiting',
  'staking.collators.active.title': 'Active Collators',
  'staking.collators.active.desc':
    'Maximize your earnings by staking your LP tokens with trusted collators.',
  'staking.collators.waiting.title': 'Waiting Collators',
  'staking.collators.waiting.desc':
    'Support waiting collators with your LP tokens and boost your earnings potential!',
  'staking.list.header.collator': 'Collator',
  'staking.list.header.apr': 'APY',
  'staking.list.header.rewards': 'Rewards/24h',
  'staking.list.header.totalStake': 'Total Stake',
  'staking.list.header.token': 'Staked Token',
  'staking.list.header.minStake': 'Min Stake',
  'staking.new.title': 'Stake',
  'staking.new.amount.label': 'LP Token',
  'staking.new.expected.apr': 'Expected APY',
  'staking.new.expected.rewards': 'Expected Daily Rewards',
  'staking.new.fee': 'Fee',
  'staking.new.minAmount.info':
    'Minimum stake to start earning rewards with this collator is {minStakeAmount} {symbol}.',
  'modal.transaction.stake.header.title': 'Staking',
  'modal.transaction.stake.header.title.success': 'Successfully Staked',
  'staking.new.submit.cta': 'Stake',
  'common.submit.btn.enterAmount': 'Enter amount',
  'common.submit.btn.insufficientFunds': 'Insufficient balance',
  'staking.new.submit.minAmountNotReached': 'Insufficient amount',
  'staking.new.submit.insufficientFunds': 'Insufficient balance',
  'staking.new.banner.title': 'Maximize your token potential. \n Stake & earn rewards!',
  'staking.new.banner.cta': 'Start staking',
  'staking.new.banner.myStake': 'My stake',
  'staking.new.banner.manage': 'Manage position',
  'staking.detail.label.staking': 'Staking',
  'staking.detail.label.collator': 'Collator Stats',
  'staking.detail.collator.title': 'Collator',
  'staking.detail.back': 'Back to Collators',
  'liq.mining.detail.widget.mgx.rewards.title': 'MGX Rewards',
  'liq.mining.detail.widget.claim.rewards.title': 'Claimable Rewards',
  'liq.mining.detail.widget.lp.tokens': 'Active / Available LP Tokens',
  'liq.mining.detail.widget.lp.deactivate': 'Deactivate all',
  'liq.mining.detail.widget.lp.activate': 'Activate all',
  'positionCard.position': 'Pool Position',
  'positionCard.share': 'Pool Share:',
  'positionCard.lpTokenInfo': 'Activated / Available LP Tokens',
  'position.overview.staking.rewards.title': 'Total Staking Rewards',
  'position.overview.staking.rewards.desc': 'All staking rewards are claimed automatically.',
  'position.overview.staking.rewards.24h': 'Last 24 Hours',
  'position.overview.native.rewards.title': 'Total Mining Rewards',
  'position.overview.native.rewards.desc': 'Claim all mining rewards in 1 click!',
  'position.overview.native.liqMining.title': 'Native Liquidity Mining',
  'position.overview.3rdParty.liqMining.title': '{symbol} Mining',
  'position.overview.rewards.notActive': 'Not Active',
  'position.overview.myShare': 'My Share',
  'positions.staking.cta.title': 'You have {amount} {symbol} free LP Tokens available for staking.',
  'positions.staking.cta.button': 'Add Staking Position',
  'staking.add.title': 'Add Stake',
  'staking.add.amount.label': 'LP token',
  'staking.add.newAmount.info': 'New Stake position',
  'staking.add.fee': 'Fee',
  'staking.remove.title': 'Remove Stake',
  'staking.remove.amount.label': 'LP token',
  'staking.remove.newAmount.info': 'New Stake position',
  'staking.remove.fee': 'Fee',
  'modal.transaction.error.notExistingDelegation.desc': 'There is no active delegation to increase',
  'modal.transaction.error.requestAlreadyExists.desc': 'Delegation request already exists',
  'staking.remove.submit.cta': 'Schedule stake change',
  'staking.add.submit.cta': 'Schedule stake change',
  'stakingCard.claimedRewards': 'Auto-Claimed Rewards',
  'stakingCard.rewards.month': 'Last Month',
  'stakingCard.rewards.day': 'Last 24 Hours',
  'stakingCard.myStake': 'My Stake',
  'stakingCard.changeRequest': 'Stake change request',
  'stakingCard.changeRequestValue.add': 'Adding {amount} {symbol}',
  'stakingCard.changeRequestValue.remove': 'Removing {amount} {symbol}',
  'stakingCard.changeRequestInfo':
    "It may take up to 8 hours to process your stake change. Don't forget to execute the transaction afterward.",
  'stakingCard.changeScheduled': 'Stake Change Scheduled',
  'stakingCard.confirmChange': 'Confirm Stake Change',
  'stakingCard.confirmChange.cta': 'Confirm',
  'staking.empty.title': 'No Staking Positions',
  'staking.empty.desc':
    'Maximize your tokens potential. Stake with trusted collators & earn rewards!',
  'staking.empty.cta': 'Start staking',
  'staking.modal.limit.reached.title': 'Staking Limit Reached',
  'staking.modal.limit.reached.desc':
    "You've reached the limit of {limitCount} active staking positions. To create a new stake, you need to release one of the existing positions.",
  'staking.modal.limit.reached.ok': 'Ok, I got it',
  'stakingNotification.cta': 'Go to Position',
  'stakingNotification.ready.desc':
    'Your {symbol} stake change request is now ready for confirmation.',
  'stakingNotification.scheduled.desc':
    'Your {symbol} stake change request is scheduled and processing.',
  'position.card.bonusEarning.title': 'Bonus earning potential',
  'position.card.bonusEarning.desc':
    'The longer you keep your position active, the more you \n earn and the bigger your bonus reward potential is.',
  'position.card.bonusEarning.period': 'Mining period',
  'position.card.bonusEarning.potential': 'Bonus Potential',
  'position.card.bonusEarning.tooltip.title': 'Boost Your Earnings!',
  'position.card.bonusEarning.tooltip.desc':
    "The longer you keep your position active, the greater your reward potential. \n \n Note: Adding more liquidity may seem to dial back progress, but increases your total earning potential. You're setting the stage for even greater rewards!",
  'position.card.bonusEarning.potential.value': '{current} / {max} Points',
  'swap.widget.settings.title': 'Swap Settings',
  'swap.widget.settings.slippage.title': 'Slippage Tolerance (Fixed)',
  'swap.widget.settings.slippage.desc':
    'The highest difference between the \n expected price, and the actual executed \n price of a trade.',
  'swap.widget.settings.slippage.value': 'Max {percentage}%',
  'swap.widget.settings.routing.title': 'Automatic Transaction Routing',
  'swap.widget.settings.routing.desc':
    'Automatic routing optimises transaction \n to get the best exchange rates.',
  'positions.liqMiningCard.activate.info': 'Activate LP Tokens to start earning {symbol} Rewards',
  'positions.liqMiningCard.type.native': 'Native',
  'positions.liqMiningCard.type.3rdParty': '3rd Party',
  'position.remove.widget.aboveMaxAmount':
    'You can only withdraw the amount of liquidity corresponding to your non-staked LP tokens. To remove all liquidity, you need to un-stake all staked LP tokens in this position.',
  'position.remove.widget.notAllowed.slider.track.title': '{stakedPerc}% Locked in Staking',
  'position.remove.widget.notAllowed.slider.track.desc':
    'You can only withdraw the amount of liquidity corresponding to your non-staked LP tokens. To remove all liquidity, you need to un-stake all staked LP tokens in this position.',
  'position.deactivate.3rdParty.liquidity.confirm':
    'Plase note, all 3rd party liquidity mining rewards will be deactivated as well.',
  'position.remove.3rdParty.liquidity.confirm':
    'Plase note, all 3rd party liquidity mining rewards will be deactivated as well.',
  'position.remove.liquidity.confirm.title': 'Remove Liquidity',
  'position.removeAndDeactivate.liquidity.confirm.desc':
    'Removing your position mining deactivates your reward earnings.',
  'position.remove.liquidity.confirm.desc':
    'Removing liquidity from this pool will withdraw your contributed assets.',
  'position.remove.liquidity.confirm.submit': 'Remove Liquidity',
  'tokenInput.balance.title': 'Balance:',
  'bridge.details.gasFee': 'Gas Fee',
  'bridge.button.approve': 'Approve Deposit',
  'bridge.error.insufficientTokenBalance': '{symbol} token balance is insufficient',
  'bridge.button.approving': 'Enabling Deposit...',
  'modal.transaction.approveContract.header.title': 'Approving contract',
  'modal.transaction.approveContract.header.title.success': 'Contract Approved',
  'modal.transaction.rollupDeposit.header.title': 'Depositing to Gasp',
  'modal.transaction.rollupDeposit.header.title.success': 'Depositing to Gasp',
  'modal.transaction.rollupDeposit.description': 'Please confirm the transaction in your wallet',
  'bridge.error.insufficientNativeTokenBalance': '{symbol} balance is insufficient',
  'bridge.error.insufficientBalanceForOriginFee':
    '{symbol} balance is insufficient for transaction fee.',
  'rollup.deposit.title': 'Deposit',
  'rollup.deposit.success.title': 'Funds Incoming!',
  'rollup.deposit.success.desc':
    'Your deposit is successfully underway. Please note, it typically takes {durationText} for the tokens to be reflected in your account balance due to blockchain processing times.',
  'rollup.deposit.success.desc.duration': 'about 2 hours',
  'rollup.deposit.success.cta': 'Ok, I understand',
  'rollup.deposit.success.link': 'View on Explorer',
  'nativeTokenWidget.discoverMining.desc':
    'Discover Liquidity mining & LP tokens staking to increase your earning potential!',
  'nativeTokenWidget.onboarding.title': 'How to get MGX',
  'nativeTokenWidget.onboarding.step1.title': 'Connect Wallet',
  'nativeTokenWidget.onboarding.step1.desc':
    'Connect your wallet & authorise access to your account.',
  'nativeTokenWidget.onboarding.step2.title': 'Deposit KSM',
  'nativeTokenWidget.onboarding.step2.desc':
    'Deposit at least 0.1 KSM by clicking on DEPOSIT in the wallet interface.',
  'nativeTokenWidget.onboarding.step3.title': 'Swap KSM for MGX',
  'nativeTokenWidget.onboarding.step3.desc': 'Swap deposited KSM to MGX',
  'nativeTokenWidget.onboarding.help.title': 'Need more help?',
  'nativeTokenWidget.onboarding.help.link': 'Read detailed guide',
  'nativeTokenWidget.getTokens': 'Get MGX',
};
