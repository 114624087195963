import { SubmittableExtrinsic } from '@polkadot/api/types';

enum PaymentInfoAssetUnit {
  Mili = 'm',
  Micro = 'µ',
}

export interface PaymentInfo {
  fee: string;
  feeAsset: string;
}

export async function getPaymentInfo(
  extrinsic: SubmittableExtrinsic<'promise'>,
  address: string,
): Promise<PaymentInfo> {
  const paymentInfo = await extrinsic.paymentInfo(address);

  const value = paymentInfo.partialFee.toString();
  let [, symbol] = paymentInfo.partialFee.toHuman().split(' ');

  switch (symbol.charAt(0)) {
    case PaymentInfoAssetUnit.Mili:
    case PaymentInfoAssetUnit.Micro:
      symbol = symbol.slice(1);
      break;
  }

  return {
    fee: value,
    feeAsset: symbol,
  };
}
