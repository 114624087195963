import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { activateLiquidity } from '../services/ActivateLiquidityMutationService';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useWallet } from '../../../wallet';

export const useActivateLiqudityMutation = () => {
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const activateLiquidityMutation = useMutation(
    ['activate-liquidity'],
    activateLiquidity(api, selectedAccount?.address, wallet, transactionStore),
  );
  return { activateLiquidityMutation };
};
