import {
  TokenFilterType,
  TokenStatistics,
  useAccounts,
  useAssets,
  useInternalBalance,
  useSearch,
  useTokenStats,
} from 'core';
import { TokenFilter } from './components/Filter/TokenFilter';
import cls from 'classnames';
import { Decimal } from 'decimal.js';
import { isEmpty, isNil, isNull } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Container, Search, Skeleton, Text } from 'ui';
import { EmptyOwnedTokens } from './components/EmptyOwnedTokens/EmptyOwnedTokens';
import { TokenListItem } from './components/TokenListItem/TokenListItem';
import { TokensEmptySearchResult } from './components/TokensEmptySearchResult/TokensEmptySearchResult';
import { DisconnectedWallet } from '../../../layouts/disconnected-wallet/DisconnectedWallet';
import { useWalletUIStore } from 'modules';

export function TokenList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    tokenStatsListQuery: { data: _stats },
  } = useTokenStats();

  const { toggleSelectWallet } = useWalletUIStore();

  const { selectedAccount } = useAccounts();

  const {
    searchState: [isSearchOpen],
    entries,
    toggleSearch,
    isActive: isSearchActive,
    ref,
  } = useSearch<TokenStatistics>({ list: _stats, toSearch: ['symbol', 'tokenName'] });

  const { assetsMap } = useAssets();
  const { balances } = useInternalBalance();

  const filterQuery = searchParams.get('filter');

  const stats = entries
    ?.filter(($) => {
      return filterQuery === TokenFilterType.Owned ? balances?.has($.tokenId) : true;
    })
    .sort(tokenListSortFn);

  const handleRowClick = (id: string) => () => {
    const asset = assetsMap.get(id);
    if (!asset) return null;

    navigate(`/tokens/${asset.symbol}`);
  };

  const ListSkeleton = new Array(4).fill(true).map((_, i) => (
    <div key={i} className="grid grid-cols-6 gap-4 w-full px-4 py-3 items-center">
      <Container alignItems="center">
        <Skeleton className="w-[32px] h-[32px] !rounded-full mr-2" />
        <Skeleton className="w-[60px] h-[10px]" />
      </Container>
      {new Array(5).fill(true).map((_, j) => (
        <Skeleton key={j} className="w-[80px] h-[10px]" />
      ))}
    </div>
  ));

  const renderList = () => {
    if (isNil(stats)) return ListSkeleton;

    if (isEmpty(stats)) {
      if (isNull(selectedAccount) && !isSearchActive) {
        return (
          <DisconnectedWallet
            titleId="tokens.disconnected.title"
            descId="tokens.disconnected.desc"
            ctaId="tokens.disconnected.cta"
            onCtaClick={toggleSelectWallet}
          />
        );
      }

      if (filterQuery === TokenFilterType.Owned) return <EmptyOwnedTokens />;

      return <TokensEmptySearchResult />;
    }

    return stats?.map((stat) => (
      <TokenListItem key={stat.tokenId} stats={stat} onClick={handleRowClick(stat.tokenId)} />
    ));
  };

  const renderHeader = () =>
    !isEmpty(stats) && (
      <div className="grid grid-cols-7 gap-2 w-full px-4 py-2">
        <Text
          className="col-span-2"
          type="title-4"
          id="tokens.list.header.token"
          color="secondary"
        />
        <Text type="title-4" id="tokens.list.header.price" color="secondary" />
        <Text type="title-4" id="tokens.list.header.priceChange" color="secondary" />
        <Text type="title-4" id="tokens.list.header.volume" color="secondary" />
        <Text type="title-4" id="tokens.list.header.volumeChange" color="secondary" />
        <Text type="title-4" id="tokens.list.header.liquidity" color="secondary" />
      </div>
    );

  return (
    <Container className="pb-[120px]" fullWidth column ref={ref}>
      <Container
        justifyContent="space-between"
        alignItems="center"
        fullWidth
        className="px-4 mt-8 mb-6"
      >
        {!isSearchOpen && (
          <Container>
            <TokenFilter />
          </Container>
        )}
        <Search
          placeholderId="tokens.list.search.placeholder"
          isSearchOpen={isSearchOpen}
          toggleSearch={toggleSearch}
          containerRef={ref}
          data-testid="search"
        />
      </Container>
      <Container column fullWidth className={cls('p-4 rounded-lg', !isEmpty(stats) && 'bg-widget')}>
        {renderHeader()}
        {renderList()}
      </Container>
    </Container>
  );
}

function tokenListSortFn(a: TokenStatistics, b: TokenStatistics) {
  const aVolumeDecimal = new Decimal(a.liquidity24hInUSD);
  const bVolumeDecimal = new Decimal(b.liquidity24hInUSD);

  return bVolumeDecimal.cmp(aVolumeDecimal);
}
