import { BN_ZERO } from '@mangata-finance/sdk';
import { BN } from '@polkadot/util';
import {
  ActivateLiquidityFor3rdPartyRewardsReserveSource,
  ActivateLiquidityReserveSource,
  QueryOptional,
  ReserveSourceType,
  StakeReserveSource,
} from '../../../services';
import { TxType } from '../../transaction';
import { useReserves } from './useReserves';

type TxTypeToReserveSource = {
  [TxType.ActivateLP]: ActivateLiquidityReserveSource;
  [TxType.ConfirmStakeDecrease]: StakeReserveSource;
  [TxType.StakeChange]: StakeReserveSource;
  [TxType.ConfirmStakeIncrease]: StakeReserveSource;
  [TxType.ActivateLPFor3rdPartyRewards]: ActivateLiquidityFor3rdPartyRewardsReserveSource;
};

export const useReservesForTx = <T extends keyof TxTypeToReserveSource>(
  type: T,
  id: QueryOptional<string>,
): {
  sources: ReserveSourceForTx<TxTypeToReserveSource[T]>;
} => {
  const reserves = useReserves(id);

  return {
    sources: getReservesByTxType<T>(type, reserves),
  };
};

function getReservesByTxType<T extends keyof TxTypeToReserveSource>(
  type: T,
  reserves: ReturnType<typeof useReserves>,
): ReserveSourceForTx<TxTypeToReserveSource[T]> {
  switch (type) {
    case TxType.ActivateLP: {
      return new ReserveSourceForTx([
        [ReserveSourceType.AvailableBalance, reserves.availableBalance],
        [ReserveSourceType.UnspentReserves, reserves.unspentReserves],
        [ReserveSourceType.StakedUnactivatedReserves, reserves.stakedUnactivatedReserves],
      ]) as ReserveSourceForTx<TxTypeToReserveSource[T]>;
    }
    case TxType.ConfirmStakeDecrease:
    case TxType.StakeChange:
    case TxType.ConfirmStakeIncrease: {
      return new ReserveSourceForTx([
        [ReserveSourceType.AvailableBalance, reserves.availableBalance],
        [ReserveSourceType.UnspentReserves, reserves.unspentReserves],
        [ReserveSourceType.ActivatedUnstakedReserves, reserves.activatedUnstakedReserves],
      ]) as ReserveSourceForTx<TxTypeToReserveSource[T]>;
    }
    case TxType.ActivateLPFor3rdPartyRewards: {
      return new ReserveSourceForTx([
        [ReserveSourceType.NativeRewardsLiquidity, reserves.activatedNativeRewardsLiquidity],
        [ReserveSourceType.AvailableBalance, reserves.availableBalance],
        [ReserveSourceType.UnspentReserves, reserves.unspentReserves],
        [ReserveSourceType.StakedUnactivatedReserves, reserves.stakedUnactivatedReserves],
      ]) as ReserveSourceForTx<TxTypeToReserveSource[T]>;
    }
    default: {
      return new ReserveSourceForTx([
        [ReserveSourceType.AvailableBalance, reserves.availableBalance],
      ]) as ReserveSourceForTx<TxTypeToReserveSource[T]>;
    }
  }
}

export class ReserveSourceForTx<T extends TxTypeToReserveSource[keyof TxTypeToReserveSource]> {
  constructor(public sources: Array<[T, BN | null]>) {}

  get available(): Array<[T, BN]> {
    return this.sources
      .filter(([, value]) => value !== null && value.gt(BN_ZERO))
      .map(([source, value]) => [source, value ?? BN_ZERO]);
  }

  get isBatch() {
    return this.available.length > 1;
  }

  get(source: T) {
    return this.sources.find(([s]) => s === source)?.[1] ?? BN_ZERO;
  }

  get hasAvailable() {
    return this.available.length > 0;
  }

  get firstAvailableSource() {
    return this.available[0][0];
  }

  get totalAvailableBalance() {
    return this.available.reduce((acc, [, value]) => acc.add(value), BN_ZERO);
  }
}
