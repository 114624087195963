import { useQuery } from '@tanstack/react-query';
import { useSDK } from '../../../../services';
import { getAddLiqudityFee } from '../services/AddLiquidityFeeService';
import { useDebounce } from '../../../../hooks/useDebounce';
import { TxAsset } from '../../../transaction';
import { useAllPoolsQuery } from '../../../pool/all/query/useAllPoolsQuery';
import { useAdaptiveFee } from '../../../fee/adaptive';

export type AddLiqudityFeeParams = {
  firstTxAsset: TxAsset | null;
  secondTxAsset: TxAsset | null;
  selectedAddress: string | null;
};

export const useAddLiqudityFeeQuery = (params: AddLiqudityFeeParams) => {
  const { allPoolsQuery } = useAllPoolsQuery();
  const firstTxAsset = useDebounce(params.firstTxAsset);
  const secondTxAsset = useDebounce(params.secondTxAsset);
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const sdk = useSDK();

  const poolIds = allPoolsQuery.data?.list?.map((pool) => pool.id);

  const addLiquidityFeeQuery = useQuery(
    ['add-liquidity-fee', params.selectedAddress, firstTxAsset?.amount, secondTxAsset?.amount],
    getAddLiqudityFee(sdk, poolIds, firstTxAsset, secondTxAsset),
    {
      select: getAdaptiveFee,
      enabled:
        allPoolsQuery.isSuccess &&
        isAdaptiveFeeReady &&
        !!firstTxAsset?.amount &&
        !!secondTxAsset?.amount &&
        !!params.selectedAddress,
      keepPreviousData: true,
    },
  );

  return { addLiquidityFeeQuery };
};
