import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface DevToolsStore {
  isOpen: boolean;
  userAddressValue: string | null;
  toggleOpen: () => void;
  setUserAddressValue: (userAddressValue: string | null) => void;
}

export const useDevToolsStore = create(
  devtools<DevToolsStore>((set, get) => ({
    isOpen: false,
    userAddressValue: '',
    toggleOpen: () => set({ isOpen: !get().isOpen }),
    setUserAddressValue: (userAddressValue) => set({ userAddressValue }),
  })),
);
