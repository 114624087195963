import { Decimal } from 'decimal.js';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { AmountTooltip, Button, Container, FormatAmount, Text, WidgetDetailRow } from 'ui';
import { PoolWithShare } from 'core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { useState } from 'react';
import { useTransition, animated } from '@react-spring/web';

interface PositionDetailsProps {
  pool: PoolWithShare;
  initialState?: PositionWidgetState;
  renderAddLiq: (onCancel: () => void) => React.ReactNode;
  renderRemoveLiq: (onCancel: () => void) => React.ReactNode;
}

export enum PositionWidgetState {
  AddPosition,
  RemovePosition,
  Default,
}

export const PositionDetails = ({
  pool,
  renderAddLiq,
  renderRemoveLiq,
  initialState = PositionWidgetState.Default,
}: PositionDetailsProps) => {
  const poolShare = pool && fromBN(pool.share, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);

  const [widgetState, setWidgetState] = useState(initialState);
  const [isWidgetOpen, setIsWidgetOpen] = useState(initialState !== PositionWidgetState.Default);
  const intl = useIntl();

  const firstAmount = poolShare
    ? new Decimal(poolShare)
        .mul(fromBN(pool.firstTokenAmount, pool.firstAsset.decimals))
        .toDecimalPlaces(pool.firstAsset.decimals)
    : null;
  const secondAmount = poolShare
    ? new Decimal(poolShare)
        .mul(fromBN(pool.secondTokenAmount, pool.secondAsset.decimals))
        .toDecimalPlaces(pool.secondAsset.decimals)
    : null;

  const bannerTransition = useTransition(!isWidgetOpen, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 44 },
    leave: { opacity: 0, height: 0 },
  });

  const widgetTransition = useTransition(isWidgetOpen, {
    from: { opacity: 0, maxHeight: 0 },
    enter: { opacity: 1, maxHeight: widgetState === PositionWidgetState.AddPosition ? 800 : 550 },
    leave: { opacity: 0, maxHeight: 0 },
    config: {
      duration: widgetState === PositionWidgetState.AddPosition ? 500 : 300,
    },
  });

  const handleSetStakeWidget = (state: PositionWidgetState) => () => {
    setWidgetState(state);
    setIsWidgetOpen(true);
  };

  const handleClose = () => {
    setIsWidgetOpen(false);
  };

  return (
    <>
      {bannerTransition((style, visible) =>
        visible ? (
          <animated.div style={style} className="w-full overflow-hidden">
            <Container alignItems="center" justifyContent="space-between" className="w-full">
              <Container>
                <Container column>
                  <Text id="position.overview.myShare" color="secondary" type="title-4" />
                  <Text type="title-2">
                    <FormatAmount
                      value={pool.sharePercentage}
                      options={{ precision: 2, nonZeroPrecision: true }}
                      className="mr-[2px]"
                    />
                    %
                  </Text>
                </Container>
              </Container>
              <Container>
                <Button
                  variant="outlined"
                  className="h-10"
                  size="s"
                  onClick={handleSetStakeWidget(PositionWidgetState.RemovePosition)}
                  data-testid={`remove-button`}
                >
                  <FormattedMessage id="common.remove.cta" />
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleSetStakeWidget(PositionWidgetState.AddPosition)}
                  size="s"
                  className="ml-2 h-[42px]"
                  LeadIcon={PlusIcon}
                  iconClassName="w-6 relative right-1 mr-0"
                  data-testid={`add-liquidity-button`}
                >
                  <FormattedMessage id="common.add.liq.cta" />
                </Button>
              </Container>
            </Container>
          </animated.div>
        ) : null,
      )}
      {widgetTransition((style, visible) =>
        visible ? (
          <animated.div style={style} className="w-full overflow-hidden flex ">
            {widgetState === PositionWidgetState.AddPosition
              ? renderAddLiq(handleClose)
              : renderRemoveLiq(handleClose)}
          </animated.div>
        ) : null,
      )}
      <WidgetDetailRow
        label={intl.formatMessage({ id: 'positions.detail.myLP' })}
        className="mt-6"
        value={
          <Text>
            <AmountTooltip
              id="position.lp"
              value={fromBN(pool.totalLPTokens)}
              options={{ precision: 3 }}
              className="mr-1"
              data-testid="positionLP"
            />
            {pool.symbols.join('-')}
          </Text>
        }
      />
      <WidgetDetailRow
        label={intl.formatMessage(
          { id: 'positions.detail.token.share' },
          { symbol: pool.firstAsset.symbol },
        )}
        value={
          <Text>
            <AmountTooltip
              data-testid="positionTokenA"
              id="positionTokenA"
              value={firstAmount?.toFixed()}
              className="mr-1"
            />
            {pool.firstAsset.symbol}
          </Text>
        }
      />
      <WidgetDetailRow
        label={intl.formatMessage(
          { id: 'positions.detail.token.share' },
          { symbol: pool.secondAsset.symbol },
        )}
        value={
          <Text>
            <AmountTooltip
              data-testid="positionTokenB"
              id="positionTokenB"
              value={secondAmount?.toFixed()}
              className="mr-1"
            />
            {pool.secondAsset.symbol}
          </Text>
        }
      />
    </>
  );
};
