import cls from 'classnames';

import { Container, Text } from 'ui';
import { ClassName, TestId } from 'core';
import { IconCircle } from '../../../components/IconCircle/IconCircle';

interface AccountsErrorLayoutProps extends TestId, ClassName {
  error: string | null;
}

export function AccountsErrorLayout({
  'data-testid': testId = 'accountsError',
  className,
  error,
}: AccountsErrorLayoutProps) {
  const renderErrorContent = () => {
    return (
      <>
        <IconCircle icon="error" size="l" />
        <Text type="title-2" id="wallet.modal.connect.genericError.title" className="mt-4" />
        <Text type="body-m" color="secondary" className="mt-1 max-w-[350px]">
          {error}
        </Text>
      </>
    );
  };
  return (
    <Container
      className={cls('w-full h-[270px] text-center', className)}
      alignItems="center"
      justifyContent="center"
      column
      data-testid={testId}
    >
      {renderErrorContent()}
    </Container>
  );
}
