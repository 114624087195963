import { ClassName, TestId } from 'core';
import { RewardsBadge, formatAmount, Container, Text } from 'ui';
import cls from 'classnames';

interface RewardsAprBadgeProps extends ClassName, TestId {
  value: string | null;
}

export const RewardsAprBadge = ({
  value,
  className,
  'data-testid': testId,
}: RewardsAprBadgeProps) => {
  if (!value) {
    return null;
  }

  return (
    <Container alignItems="center" data-testid={testId} className={cls(className)}>
      <Text type="label" color="secondary" className="text-center ml-4" id="common.rewards.apr" />
      <RewardsBadge
        variant="apr"
        className="ml-3"
        value={formatAmount(value, { maxChars: 8, nonZeroPrecision: true, precision: 2 })}
      />
    </Container>
  );
};
