import { Container, Text } from 'ui';
import cls from 'classnames';
import { ClassName } from 'core';

import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg';
import { useNavigate } from 'react-router';

type StakingNotificationState = 'ready' | 'scheduled';

interface StakingNotificationProps extends ClassName {
  state: StakingNotificationState;
  symbol: string;
}

const CONTAINER_CLASS: Record<StakingNotificationState, string> = {
  ready: 'bg-accent/[.15]',
  scheduled: 'bg-warning/[.15]',
};

const ICON_CLASS: Record<StakingNotificationState, string> = {
  ready: 'fill-icon-accent stroke-icon-accent',
  scheduled: 'stroke-icon-warning fill-icon-warning',
};

export function StakingNotification({ className, state, symbol }: StakingNotificationProps) {
  const navigate = useNavigate();
  const isReady = state === 'ready';

  const goToPosition = () => {
    navigate(`/positions/${symbol}/staking`);
  };

  const renderIcon = () => {
    switch (state) {
      case 'ready':
        return <InfoIcon className="fill-icon-accent w-6 h-auto" />;
      case 'scheduled':
        return <ClockIcon className="stroke-icon-warning fill-icon-warning w-6 h-auto" />;
      default:
        return null;
    }
  };

  return (
    <Container
      fullWidth
      alignItems="center"
      justifyContent="space-between"
      className={cls('rounded my-2', CONTAINER_CLASS[state], className)}
    >
      <Container alignItems="center">
        <Container className={cls('p-2 rounded-l', CONTAINER_CLASS[state])}>
          {renderIcon()}
        </Container>
        <Text
          color={isReady ? 'accent' : 'warning'}
          className="ml-4"
          id={`stakingNotification.${state}.desc`}
          values={{
            symbol,
          }}
        />
      </Container>
      <Container alignItems="center" className="p-2 pr-4 cursor-pointer" onClick={goToPosition}>
        <Text type="link" color={isReady ? 'accent' : 'warning'} id="stakingNotification.cta" />
        <ArrowIcon className={cls('w-4 h-auto ml-1.5', ICON_CLASS[state])} />
      </Container>
    </Container>
  );
}
