import { usePoolTvlQueries } from '../../../pool';
import { Decimal } from 'decimal.js';
import { last } from 'lodash-es';
import { useAssets } from '../../asset';
import { useTokensIssuanceQuery } from '../../issuance';
import { AssetType } from '../../Token';
import { useTokenPriceHistoryQuery } from './query/useTokenPriceHistoryQuery';

export const useTokenPrice = (id: string) => {
  const { assetsMap } = useAssets();
  const asset = assetsMap.get(id);

  const poolsTvlMap = usePoolTvlQueries();
  const {
    tokensIssuanceQuery: { data: issuance, isLoading: isIssuanceLoading },
  } = useTokensIssuanceQuery();

  const {
    singlePriceHistoryQuery: { data: priceHistory, isLoading: isPriceHistoryLoading },
  } = useTokenPriceHistoryQuery(asset?.type === AssetType.Native ? asset.symbol : undefined);

  const isLoading =
    isIssuanceLoading || asset?.type === AssetType.Native ? isPriceHistoryLoading : false;

  const calculateFiatValueForLpToken = (amount: string) => {
    const tvl = poolsTvlMap.get(id);

    if (!tvl) {
      return null;
    }

    const lpIssuance = issuance?.get(id);

    if (!lpIssuance) {
      return null;
    }

    const lpSupply = new Decimal(lpIssuance.toString()).div(`1e${asset?.decimals}`);
    const singleLpTokenValue = new Decimal(tvl).div(lpSupply);

    return singleLpTokenValue.mul(amount).toFixed(2);
  };

  const calculateFiatValueForNativeToken = (amount: string) => {
    const price = last(priceHistory?.prices)?.[1];
    if (!price) {
      return null;
    }

    return new Decimal(amount).mul(price).toFixed(2);
  };

  const getFiatValue = (amount: string) => {
    if (amount === '') {
      return null;
    }

    if (!asset) {
      return null;
    }

    switch (asset.type) {
      case AssetType.LP:
        return calculateFiatValueForLpToken(amount);
      case AssetType.Native:
        return calculateFiatValueForNativeToken(amount);
    }
  };

  return {
    getFiatValue,
    isLoading,
  };
};
