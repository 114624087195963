import { Asset } from 'core';

export const filterTokenListBySearch = (list: Asset[], _searchTerm: string) => {
  return list.filter((token) => {
    if (_searchTerm === '') return true;

    const searchTerm = _searchTerm.toLowerCase();
    const tokenSymbol = token.symbol.toLowerCase();
    const tokenName = token.name.toLowerCase();

    return tokenSymbol.includes(searchTerm) || tokenName.includes(searchTerm);
  });
};
