import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

interface FilterPillProps extends ClassName, TestId {
  isActive: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  label: string;
}

export function FilterPill({
  isActive,
  isDisabled,
  onClick,
  label,
  className,
  'data-testid': testId,
}: FilterPillProps) {
  const TEXT_COLOR = isDisabled ? 'btn-disabled' : isActive ? 'highlight' : 'secondary';
  const CONTAINER_CLASS = {
    active: 'border-highlight bg-highlight/[.1] hover:bg-highlight/[.2]',
    default: 'border-default hover:bg-base focus:border-highlight',
  };

  return (
    <Button
      data-testid={testId}
      onClick={onClick}
      variant="base"
      className={cls(
        'border px-4 py-2',
        CONTAINER_CLASS[isActive ? 'active' : 'default'],
        isDisabled && 'cursor-default hover:bg-transparent',
        className,
      )}
    >
      <Text type="title-4" color={TEXT_COLOR} className="_highlight-accent">
        {label}
      </Text>
    </Button>
  );
}
