export const isIconAvailable = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const onLoad = () => resolve(true);
    const onError = () => resolve(false);

    const img = new Image();
    img.onload = onLoad;
    img.onerror = onError;
    img.src = url;
  });
};
