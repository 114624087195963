import { Link } from 'react-router-dom';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { Container, Text, TokenIcon, RewardsBadge, formatAmount } from 'ui';
import { TestId } from 'core';

interface PoolListItemProps extends TestId {
  pool: PoolWithRatio;
  apr?: string | null;
}

export function PoolListItem({ pool, apr, 'data-testid': testId }: PoolListItemProps) {
  return (
    <Link
      to={`/pools/${pool.symbols.join('-')}`}
      className="focus:outline-0 group"
      state={{ isFromPoolItem: true }}
      data-testid={testId}
    >
      <Container
        justifyContent="center"
        column
        className="bg-widget w-[232px] pt-6 px-4 pb-5 rounded cursor-pointer hover:bg-base group-focus:bg-base"
      >
        <Container justifyContent="space-between" fullWidth>
          <TokenIcon size="m" isCompact={false} token={[pool.firstAsset, pool.secondAsset]} />
          {apr && (
            <RewardsBadge
              value={formatAmount(apr, { precision: 0, maxChars: 5 })}
              variant="apr"
              data-testid="rewards-badge"
            />
          )}
        </Container>
        <Text type="title-2" className="mt-4">
          {pool.firstAsset.symbol} / {pool.secondAsset.symbol}
        </Text>
      </Container>
    </Link>
  );
}
