import { useMemo } from 'react';
import { QueryOptional } from '../../../../services';
import { CollatorRewards } from './CollatorRewards';
import { useCollatorRewardsQuery } from './query/useCollatorRewardsQuery';

export const useCollatorRewards = (address: QueryOptional<string>) => {
  const { collatorDailyRewardsQueries, isLoading } = useCollatorRewardsQuery(
    address ? [address] : null,
  );

  const collatorDailyRewardsMap = useMemo(() => {
    const collatorRewards = collatorDailyRewardsQueries
      .map((query) => query.data)
      .filter((data): data is CollatorRewards[] => !!data)
      .flat();

    return collatorRewards.reduce((acc, rewards) => {
      if (acc.has(rewards.collator)) {
        const entry = acc.get(rewards.collator);
        acc.set(rewards.collator, entry ? [...entry, rewards] : [rewards]);
        return acc;
      }

      acc.set(rewards.collator, [rewards]);
      return acc;
    }, new Map<string, CollatorRewards[]>());
  }, [collatorDailyRewardsQueries]);

  return {
    collatorDailyRewardsQueries,
    collatorDailyRewardsMap,
    isLoading,
  };
};
