import { Button, Container, Text } from 'ui';
import { ReactComponent as DisconnectedWalletIcon } from 'icons/empty-wallet.svg';
import { ClassName, TestId } from 'core';
import cls from 'classnames';

interface DisconnectedWalletProps extends TestId, ClassName {
  onCtaClick: () => void;
  titleId: string;
  descId: string;
  ctaId?: string;
}

export function DisconnectedWallet({
  onCtaClick,
  titleId,
  descId,
  ctaId,
  'data-testid': testId,
  className,
}: DisconnectedWalletProps) {
  return (
    <Container
      data-testid={testId}
      column
      fullWidth
      alignItems="center"
      justifyContent="center"
      className={cls('p-14 text-center', className)}
    >
      <DisconnectedWalletIcon className="w-[64px] h-auto stroke-icon-neutral" />
      <Text type="title-2" id={titleId} className="mt-8" />
      <Text color="secondary" id={descId} className="mt-2" />
      <Button variant="primary" size="m" onClick={onCtaClick} className="mt-12">
        <Text weight="semibold" type="btn-m" id={ctaId} />
      </Button>
    </Container>
  );
}
