import axios from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../../../services';
import { ChartHistoryInterval } from '../../../../../types';
import { EnvConfig } from '../../../../../envConfig';

export type SinglePriceHistoryQueryKey = Readonly<
  [
    queryKey: string,
    symbol: QueryOptional<string>,
    days: number | undefined,
    interval: ChartHistoryInterval | undefined,
  ]
>;

export type PairPriceHistoryQueryKey = Readonly<
  [
    queryKey: string,
    baseSymbol: QueryOptional<string>,
    targetSymbol: QueryOptional<string>,
    days: number | undefined,
    interval: ChartHistoryInterval | undefined,
  ]
>;

export interface PriceHistoryResult {
  prices: Array<[number, string]>;
}

export const fetchTokenPairPriceHistory = async ({
  queryKey,
}: QueryFunctionContext<PairPriceHistoryQueryKey>): Promise<PriceHistoryResult | null> => {
  const [, baseSymbol, targetSymbol, days, interval] = queryKey;

  const url = `${EnvConfig.STASH_URL}/price-history/pair/${baseSymbol}/${targetSymbol}`;

  const { data } = await axios.get(url, { params: { days, interval } });

  return data;
};

export const fetchSingleTokenPriceHistory = async ({
  queryKey,
}: QueryFunctionContext<SinglePriceHistoryQueryKey>): Promise<PriceHistoryResult | null> => {
  const [, symbol, days, interval] = queryKey;

  const url = `${EnvConfig.STASH_URL}/price-history/${symbol}`;

  const { data } = await axios.get(url, { params: { days, interval } });

  return data;
};
