import { FormattedMessage } from 'react-intl';
import {
  ActiveMiningIcon,
  Button,
  Container,
  ErrorBanner,
  FormatAmount,
  Modal,
  Text,
  TokenIcon,
} from 'ui';
import { Asset, TxAsset, useMangataAsset } from 'core';
import cls from 'classnames';

export enum ClaimRewardsConfirmModalType {
  All = 'all',
  Single = 'single',
}

interface ClaimRewardsConfirmModalProps {
  isOpen: boolean;
  feeAsset: TxAsset | null;
  rewards: Array<[Asset, string]>;
  onConfirm: () => void;
  onCancel: () => void;
  isFeeInsuff: boolean;
  type: ClaimRewardsConfirmModalType;
}

export function ClaimRewardsConfirmModal({
  isOpen,
  onConfirm,
  onCancel,
  feeAsset,
  rewards,
  isFeeInsuff,
  type,
}: ClaimRewardsConfirmModalProps) {
  const { mangataAsset } = useMangataAsset();
  if (!feeAsset) return null;

  const renderRewards = () => {
    return rewards.map(([token, amount]) => {
      const isMangataToken = token.id === mangataAsset?.id;
      return (
        <Container
          key={token.id}
          fullWidth
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Container>
            <TokenIcon token={token} size="xs" className="mr-2" />
            <Text
              id={`position.overview.${isMangataToken ? 'native' : '3rdParty'}.liqMining.title`}
              values={{ symbol: token.symbol }}
            />
          </Container>
          <Text type="title-4" weight="normal" className="_accent-premium" color="accent">
            <FormatAmount
              value={amount}
              options={{ nonZeroPrecision: true, precision: 2, maxChars: 9 }}
            />{' '}
            {token.symbol}
          </Text>
        </Container>
      );
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      closeIconClassName="hidden"
      className="p-10 w-[400px]"
    >
      <Container column className="rounded-lg">
        <ActiveMiningIcon size="l" />
        <Text
          type="title-1"
          id={`position.claim.${type}.confirm.title`}
          weight="semibold"
          className="mt-4"
        />
        <Text id={`position.claim.${type}.confirm.desc`} className="mt-2" />

        <Container column fullWidth className="mt-6">
          {renderRewards()}
        </Container>

        <Container className="mt-2" justifyContent="space-between" fullWidth>
          <Text color={isFeeInsuff ? 'alert' : 'secondary'} id="position.claim.confirm.fee" />
          <Text color={isFeeInsuff ? 'alert' : 'primary'}>
            ≈{' '}
            <FormatAmount
              value={feeAsset.amount}
              options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
            />{' '}
            {feeAsset.symbol}
          </Text>
        </Container>
        {isFeeInsuff && <ErrorBanner msgId="position.claim.insuff.fee" />}
        <Container
          column
          className={cls(isFeeInsuff ? 'mt-[48px]' : 'mt-10')}
          justifyContent="space-between"
          fullWidth
        >
          <Button
            fullWidth
            variant="accent"
            onClick={onConfirm}
            isDisabled={isFeeInsuff}
            data-testid="submit"
          >
            <FormattedMessage id={`position.claim.${type}.confirm.submit`} />
          </Button>
          <Button fullWidth variant="outlined" className="mt-4" onClick={onCancel}>
            <FormattedMessage id="position.claim.confirm.cancel" />
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}
