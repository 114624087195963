import { toBN, BN_HUNDRED, fromBN } from '@mangata-finance/sdk';
import { BN } from '@polkadot/util';
import { useCallback, useEffect } from 'react';
import { Asset, SwapType, useSwap } from 'core';
import { MAX_SLIPPAGE } from './widget/SwapWidget';
import { useSwapStore } from './store/useSwapStore';
import { isAmountGtZero, usePreviousValue } from 'core';
import { SwapInputType } from './SwapPage';

export const useSwapInputs = () => {
  const { getRoute } = useSwap();
  const {
    firstToken,
    secondToken,
    firstAmount,
    secondAmount,
    isAutoRoutingEnabled,
    lastInputChange,
    setFirstToken,
    setSecondToken,
    setFirstAmount,
    setSecondAmount,
    setRouteDetails,
    setLastInputChange,
  } = useSwapStore();

  const prevState = usePreviousValue({
    firstAmount,
    firstToken,
    secondAmount,
    secondToken,
    isAutoRoutingEnabled,
    lastInputChange,
  });

  const switchTokens = () => {
    setFirstToken(secondToken);
    setSecondToken(firstToken);
    setFirstAmount(secondAmount);
    setLastInputChange(SwapInputType.FirstToken);
  };

  const calculateRouteDetails = useCallback(
    (
      inputAmount: string,
      inputAsset: Asset | null,
      outputAsset: Asset | null,
      swapType: SwapType,
    ) => {
      if (inputAsset && outputAsset && isAmountGtZero(inputAmount)) {
        const amount = toBN(
          inputAmount,
          swapType === SwapType.EXACT_IN ? inputAsset?.decimals : outputAsset?.decimals,
        );
        const res = getRoute(inputAsset, outputAsset, amount, swapType, isAutoRoutingEnabled);

        const baseDetails = { ...res, type: swapType };

        if (res?.bestAmount) {
          if (swapType === SwapType.EXACT_IN) {
            const minAmountOutBN = res?.bestAmount.mul(new BN(100 - MAX_SLIPPAGE)).div(BN_HUNDRED);
            setSecondAmount(fromBN(res.bestAmount, outputAsset.decimals));
            setRouteDetails({ ...baseDetails, minAmountOutBN });
          } else {
            const minAmountOutBN = amount.mul(new BN(100 - MAX_SLIPPAGE)).div(BN_HUNDRED);
            setFirstAmount(fromBN(res.bestAmount, inputAsset.decimals));
            setRouteDetails({ ...baseDetails, minAmountOutBN });
          }
        } else {
          setRouteDetails(baseDetails);

          if (swapType === SwapType.EXACT_IN) {
            setSecondAmount('');
          } else {
            setFirstAmount('');
          }
        }
      }
    },
    [getRoute, setRouteDetails, setFirstAmount, setSecondAmount, isAutoRoutingEnabled],
  );

  useEffect(() => {
    if (
      (prevState || firstToken || secondToken) &&
      (prevState?.firstAmount !== firstAmount ||
        prevState?.firstToken?.id !== firstToken?.id ||
        prevState?.secondAmount !== secondAmount ||
        prevState?.secondToken?.id !== secondToken?.id ||
        prevState?.lastInputChange !== lastInputChange ||
        prevState?.isAutoRoutingEnabled !== isAutoRoutingEnabled) &&
      isAmountGtZero(lastInputChange === SwapInputType.FirstToken ? firstAmount : secondAmount)
    ) {
      if (lastInputChange === SwapInputType.FirstToken) {
        calculateRouteDetails(firstAmount, firstToken, secondToken, SwapType.EXACT_IN);
      } else {
        calculateRouteDetails(secondAmount, firstToken, secondToken, SwapType.EXACT_OUT);
      }
    }
  }, [
    firstAmount,
    firstToken,
    secondAmount,
    secondToken,
    lastInputChange,
    isAutoRoutingEnabled,
    prevState,
    calculateRouteDetails,
  ]);

  const handleTokenSelect = (input: SwapInputType) => (token: Asset | null) => {
    if (input === SwapInputType.FirstToken) {
      setFirstToken(token);
      if (token && token.id === secondToken?.id) {
        setSecondToken(null);
        setSecondAmount('');
        return;
      }
    } else if (input === SwapInputType.SecondToken) {
      setSecondToken(token);
      if (token && token.id === firstToken?.id) {
        setFirstToken(null);
        setFirstAmount('');
        return;
      }
    }
    setLastInputChange(
      input === SwapInputType.FirstToken ? SwapInputType.SecondToken : SwapInputType.FirstToken,
    );
  };

  const handleAmountChange =
    (input: SwapInputType) =>
    (_amount: string | null = '') => {
      const amount = _amount ?? '';

      if (input === SwapInputType.FirstToken) {
        setFirstAmount(amount);
        setSecondAmount('');
      } else if (input === SwapInputType.SecondToken) {
        setSecondAmount(amount);
        setFirstAmount('');
      }

      if (!isAmountGtZero(amount)) {
        setRouteDetails(null);
      }
      setLastInputChange(input);
    };

  return {
    switchTokens,
    handleTokenSelect,
    handleAmountChange,
  };
};
