import cls from 'classnames';

import { Container, Text } from 'ui';
import { ClassName, TestId } from 'core';
import lockImg from 'images/lock.png';

interface LockedWalletLayoutProps extends TestId, ClassName {}

export function LockedWalletLayout({ 'data-testid': testId, className }: LockedWalletLayoutProps) {
  return (
    <Container
      className={cls('w-full h-full', className)}
      alignItems="center"
      justifyContent="center"
      column
      data-testid={testId}
    >
      <img src={lockImg} alt="lock" className="w-[160px] h-auto" />
      <Text type="title-2" id="wallet.modal.locked.title" className="mt-4" />
      <Text type="body-m" color="secondary" id="wallet.modal.locked.desc" className="mt-1" />
    </Container>
  );
}
