import { useQuery } from '@tanstack/react-query';
import { fetchRollupGasPrice } from '../services/rollupGasPriceService';
import { useWeb3, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../services';

export const useRollupGasPriceQuery = (chainId: QueryOptional<string>) => {
  const web3 = useWeb3();
  const chainWeb3 = (chainId && web3?.[chainId]) || null;

  const rollupGasPriceQuery = useQuery(
    ['rollup-gas-price', chainId],
    fetchRollupGasPrice(chainWeb3),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!chainWeb3,
    },
  );

  return {
    rollupGasPriceQuery,
  };
};
