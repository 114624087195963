import { InjectedWallet } from '../../../services';

export const fetchAccounts = (wallet: InjectedWallet | null | undefined) => async () => {
  if (!wallet) return null;

  try {
    return wallet.accounts;
  } catch (error) {
    console.error('Unable to get accounts');
    return null;
  }
};
