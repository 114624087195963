import { useState, useEffect, useRef } from 'react';

export const useDebounce = <T>(value: T, delay = 350) => {
  const [debouncedValue, setDebouncedValue] = useState<T>();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
