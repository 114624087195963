import { IdLookupFn } from '../../Xcm';
import { ChainType } from '../../../../../stash';
import { xcmpMessageSentValidator, upwardMessageSentValidator } from '../../validators';

export const getOriginIdentifiersForWithdrawal: IdLookupFn = async (
  api,
  _,
  channel,
  header,
  address,
) => {
  if (!address) {
    return null;
  }

  const block = await api.rpc.chain.getBlock(header.hash);
  const apiAtHash = await api.at(header.hash);
  const eventsCodec = await apiAtHash.query.system.events();

  switch (channel.chainType) {
    case ChainType.Parachain:
      return xcmpMessageSentValidator(api, eventsCodec, address, block);
    case ChainType.RelayChain:
      return upwardMessageSentValidator(api, eventsCodec, address, block);
  }
};
