import { useAssets, useCollators, useCollatorsApy } from 'core';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { Container } from 'ui';
import { CollatorListSection } from './section/CollatorListSection';

export function CollatorList() {
  const {
    collatorsMapQuery: { data: collatorsMap },
    selectedCandidatesQuery: { data: selectedCandidates },
  } = useCollators();

  const { assetsMap } = useAssets();

  const collators = collatorsMap
    ? Array.from(collatorsMap.values()).sort((a, b) => b.bond.cmp(a.bond))
    : [];

  const activeList = collators.filter((candidate) => {
    return selectedCandidates?.has(candidate.id.toString()) && candidate.state.isActive;
  });

  const addresses = activeList.map((candidate) => candidate.id.toString());

  const { collatorApyMap } = useCollatorsApy(addresses);

  const waitingList = collators.filter((candidate) => !candidate.state.isActive) ?? null;

  const apyMap = useMemo(
    () =>
      new Map(
        activeList
          .map((candidate) => {
            return [
              candidate.id.toString(),
              collatorApyMap
                ?.get(candidate.id.toString())
                ?.get(candidate.liquidityToken.toString()),
            ];
          })
          .filter((entry): entry is [string, string] => !isNil(entry[0]) && !isNil(entry[1])),
      ),
    [activeList, collatorApyMap],
  );

  return (
    <Container column fullWidth>
      <CollatorListSection
        assets={assetsMap}
        list={activeList}
        collatorApy={apyMap}
        titleId="staking.collators.active.title"
        descId="staking.collators.active.desc"
        data-testid="active-collators-list"
      />
      <CollatorListSection
        className="mt-6"
        assets={assetsMap}
        list={waitingList}
        titleId="staking.collators.waiting.title"
        descId="staking.collators.waiting.desc"
        data-testid="waiting-collators-list"
      />
    </Container>
  );
}
