import { useQuery } from '@tanstack/react-query';
import {
  DEFAULT_QUERY_OPTIONS,
  QUERY_REFETCH_INTERVAL,
  QueryOptional,
  useWeb3,
} from '../../../../../services';
import { fetchRollupNativeTokenBalance } from '../services/rollupTokenBalanceService';

export const useRollupNativeTokenBalanceQuery = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
) => {
  const web3 = useWeb3();
  const chainWeb3 = (chainId && web3?.[chainId]) || null;

  const rollupNativeTokenBalanceQuery = useQuery(
    ['rollup-native-token-balance', userAddress, chainId],
    fetchRollupNativeTokenBalance(chainWeb3, userAddress),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!chainWeb3 && !!userAddress,
      refetchInterval: QUERY_REFETCH_INTERVAL.externalBalance,
    },
  );

  return {
    rollupNativeTokenBalanceQuery,
  };
};
