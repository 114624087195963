import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { useSDK } from '../../../../services/sdk/useSDK';
import { submitAddLiqudity } from '../services/AddLiquidityMutationService';
import { QueryOptional } from '../../../../services';
import { PoolWithRatio } from '../../Pool';
import { useWallet } from '../../../wallet';

interface UseAddLiquidityMutationParams {
  pools: QueryOptional<PoolWithRatio[]>;
}

export const useAddLiqudityMutation = ({ pools }: UseAddLiquidityMutationParams) => {
  const transactionStore = useTransactionStore();
  const sdk = useSDK();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const poolIds = pools?.map((pool) => pool.id);
  const addLiquidityMutation = useMutation(
    submitAddLiqudity(sdk, transactionStore, poolIds, selectedWallet),
  );

  return { addLiquidityMutation };
};
