import cls from 'classnames';
import { HTMLProps } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';

import { Children, ClassName, TestId } from '../../types';

export interface TooltipProps
  extends ClassName,
    TestId,
    Children,
    Pick<HTMLProps<HTMLDivElement>, 'onClick'> {
  tooltipContent: React.ReactNode;
  id?: string;
  leftOffset?: boolean;
  tooltipClassName?: string;
  inPortal?: boolean;
  isClickable?: boolean;
  placement?: PlacesType;
}

export function Tooltip({
  children,
  className,
  'data-testid': testId,
  tooltipContent,
  tooltipClassName,
  onClick,
  id = 'tooltip',
  leftOffset = true,
  inPortal = false,
  placement = 'bottom',
  isClickable = true,
}: TooltipProps) {
  const Tooltip = (
    <ReactTooltip
      id={id}
      clickable={isClickable}
      className={cls(
        '!bg-inverted !text-inverted !px-3 z-40 !rounded-xl shadow-xl',
        inPortal ? leftOffset && 'translate-x-[45%]' : '!left-3',
        tooltipClassName,
      )}
      classNameArrow={cls(leftOffset && '!left-5')}
    >
      {tooltipContent}
    </ReactTooltip>
  );

  return (
    <div
      data-testid={`${testId}-anchor`}
      data-tooltip-id={id}
      data-tooltip-place={placement}
      className={cls('w-[fit-content] relative inline', className)}
      onClick={onClick}
    >
      {children}
      {inPortal ? createPortal(Tooltip, document.body) : Tooltip}
    </div>
  );
}
