import cls from 'classnames';

import { Container, Spinner, Text } from 'ui';
import { ClassName, TestId } from 'core';

interface WalletLoadingLayoutProps extends TestId, ClassName {}

export function WalletLoadingLayout({
  'data-testid': testId = 'walletLoading',
  className,
}: WalletLoadingLayoutProps) {
  return (
    <Container
      className={cls('w-full h-full my-[75px]', className)}
      alignItems="center"
      justifyContent="center"
      column
      data-testid={testId}
    >
      <Spinner className="!w-[48px]" />
      <Text type="title-2" id="wallet.modal.account.loading.title" className="mt-6" />
      <Text
        type="body-m"
        color="secondary"
        id="wallet.modal.account.loading.desc"
        className="mt-1"
      />
    </Container>
  );
}
