import { Container } from 'ui';
import { ThirdPartyLiquidityMiningCard } from './3rdParty/ThirdPartyLiquidityMiningCard';
import { NativeLiquidityMiningCard } from './native/NativeLiquidityMiningCard';
import {
  useMangataAsset,
  useAssets,
  use3rdPartyTokensQuery,
  useInvestedPoolFromPath,
  use3rdPartyRewardsPoolApr,
  Asset,
  useNativeRewards,
} from 'core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { BN_ZERO } from '@polkadot/util';

export const LiquidityMiningTabPage = () => {
  const navigate = useNavigate();
  const { mangataAsset } = useMangataAsset();
  const { assetsMap } = useAssets();
  const { pool } = useInvestedPoolFromPath();
  const { rewardQueriesByLpId } = useNativeRewards();
  const { thirdPartyTokensQuery } = use3rdPartyTokensQuery();
  const { aprMap } = use3rdPartyRewardsPoolApr(pool);

  const rewardsAmount = (pool && rewardQueriesByLpId[pool.liquidityTokenId]?.data) ?? BN_ZERO;
  const isEnabled = pool?.isPromoted || rewardsAmount.gtn(0);

  useEffect(() => {
    if (pool && !isEnabled) {
      navigate(`/positions/${pool.symbols.join('-')}/overview`);
    }
  }, [pool, isEnabled, navigate]);

  const rewardTokenIds = pool?.liquidityTokenId
    ? thirdPartyTokensQuery?.data?.get(pool?.liquidityTokenId)
    : null;
  const rewardTokens = rewardTokenIds
    ?.map((id) => assetsMap.get(id))
    .filter((asset): asset is Asset => !!asset);

  if (!mangataAsset || !isEnabled) {
    return null;
  }

  return (
    <Container fullWidth column>
      <NativeLiquidityMiningCard />
      {rewardTokens?.map((asset) => (
        <ThirdPartyLiquidityMiningCard
          asset={asset}
          key={asset.id}
          apr={aprMap?.get(asset.id) || null}
        />
      ))}
    </Container>
  );
};
