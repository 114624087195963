import { Option } from '@polkadot/types/codec';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { QueryOptional, QueryServiceReturnType } from '../../../../../services';
import { isNil, isNull } from 'lodash-es';
import { listCollators, listSelectedCandidates } from '../services/collatorService';

export const transformCollatorList = (_data: QueryServiceReturnType<typeof listCollators>) => {
  if (isNil(_data)) {
    return null;
  }

  const data = _data
    .map(([, opt]) => {
      const obj = opt.unwrapOr(null);
      return [obj?.id.toString(), obj];
    })
    .filter(($): $ is [string, ParachainStakingCollatorCandidate] => !isNull($[1]));

  return new Map(data);
};

export const transformSelectedCandidates = (
  data: QueryServiceReturnType<typeof listSelectedCandidates>,
) => {
  if (isNil(data)) {
    return null;
  }

  return new Set(data.map((item) => item.toString()));
};

export const transformCollatorState = (
  _data: QueryOptional<Option<ParachainStakingCollatorCandidate>>,
) => {
  if (isNil(_data)) {
    return null;
  }

  const data = _data.unwrapOr(null);

  return data;
};
