import { isEmpty } from 'lodash-es';
import cls from 'classnames';
import { useCallback } from 'react';
import {
  ClassName,
  NotificationKey,
  TestId,
  WalletAccount,
  useAccounts,
  useModalStore,
  useUserStore,
} from 'core';
import { toast } from 'react-hot-toast';
import { Container, Text } from 'ui';

import { NoAccountsLayout } from '../noAccounts/NoAccountsLayout';
import { ConnectedAccountCard } from '../../../components/ConnectedAccountCard/ConnectedAccountCard';
import { AccountItem } from './item/AccountItem';
import { LockedWalletLayout } from '../../Locked/LockedWalletLayout';

interface AccountListLayoutProps extends TestId, ClassName {}

export function AccountListLayout({
  'data-testid': testId = 'accountList',
  className,
}: AccountListLayoutProps) {
  const {
    selectedAccount,
    accountsQuery: { data: accounts },
    setSelectedAccount,
  } = useAccounts();
  const { isWalletUnlocked } = useUserStore();
  const { closeAccountSettings } = useModalStore();

  const anotherAccounts = accounts?.filter(
    (account) => account.address !== selectedAccount?.address && account.type !== 'ethereum',
  );

  const handleAddressSelect = useCallback(
    (account: WalletAccount) => {
      setSelectedAccount(account);
      closeAccountSettings();
      toast(NotificationKey.WALLET_CONNECTED);
    },
    [setSelectedAccount, closeAccountSettings],
  );

  if (!isEmpty(accounts) && isWalletUnlocked) {
    return (
      <Container
        column
        data-testid={testId}
        className={cls('w-full', !anotherAccounts && 'pb-4', className)}
        alignItems="stretch"
      >
        {selectedAccount && (
          <>
            <Text
              type="title-4"
              className="ml-4 mb-4"
              id="wallet.modal.account.connected.title"
              color="secondary"
            />
            <ConnectedAccountCard className="mb-6 mx-4" />
          </>
        )}
        {anotherAccounts && !isEmpty(anotherAccounts) && (
          <>
            <Text
              type="title-4"
              className="ml-4 mb-4"
              id={`wallet.modal.account.${selectedAccount ? 'change' : 'select'}.title`}
              color="secondary"
            />
            <Container
              column
              className="mr-2 overflow-y-auto max-h-[calc(80vh_-_285px)] overflow-x-visible pb-4"
            >
              {anotherAccounts.map((account) => (
                <AccountItem
                  data-testid={`${testId}-item`}
                  key={account.address}
                  data={account}
                  onClick={handleAddressSelect}
                />
              ))}
            </Container>
          </>
        )}
      </Container>
    );
  }

  return (
    <Container fullWidth alignItems="center" className="mb-[75px] mt-[30px]">
      {isWalletUnlocked ? <NoAccountsLayout /> : <LockedWalletLayout />}
    </Container>
  );
}
