import { Button, Container, FormatAmount, Text, Tooltip } from 'ui';
import cls from 'classnames';
import {
  ClassName,
  TestId,
  TxType,
  useAccounts,
  useAssets,
  useExecuteDelegationRequest,
  useReservesForTx,
  useSession,
} from 'core';
import { ParachainStakingBond } from '@polkadot/types/lookup';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { useState } from 'react';
import { StakingCardHeader } from './StakingCardHeader';
import { StakingCardRewards } from './StakingCardRewards';
import { StakingCardMyStake } from './StakingCardMyStake';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { FormattedMessage } from 'react-intl';
import { AddStakeWidget } from '../../../Staking/widgets/add/AddStakeWidget';
import { RemoveStakeWidget } from '../../../Staking/widgets/remove/RemoveStakeWidget';
import { ParachainStakingDelegator } from '@polkadot/types/lookup';

export interface StakingCardProps extends ClassName, TestId {
  delegation: ParachainStakingBond;
  requests?: ParachainStakingDelegator['requests']['requests'];
}

export function StakingCard({
  delegation,
  requests,
  className,
  'data-testid': testId,
}: StakingCardProps) {
  const { assetsMap } = useAssets();
  const { selectedAccount } = useAccounts();
  const {
    currentSession: { data: session },
  } = useSession();

  const { executeDelegationRequestMutation } = useExecuteDelegationRequest(
    delegation.owner.toString(),
  );

  const request = Array.from(requests?.entries() ?? []).find(([, req]) => {
    return req.collator.eq(delegation.owner);
  })?.[1];

  const lpAsset = assetsMap.get(delegation.liquidityToken.toString());

  const { sources } = useReservesForTx(TxType.ConfirmStakeDecrease, lpAsset?.id);
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, lpAsset?.id);

  const [isBondMoreActive, setIsBondMoreActive] = useState(false);
  const [isBondLessActive, setIsBondLessActive] = useState(false);

  const toggleBondMore = () => setIsBondMoreActive(!isBondMoreActive);
  const toggleBondLess = () => setIsBondLessActive(!isBondLessActive);

  const isStakeChangeActive = isBondMoreActive || isBondLessActive;
  const isStakeChangeReady = session && request?.whenExecutable.lte(session);

  const confirmStakeChange = () => {
    if (!request || !lpAsset) {
      return null;
    }

    executeDelegationRequestMutation.mutate({
      asset: {
        ...lpAsset,
        amount: fromBN(request.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
      },
      request,
      sources,
      activateLiqSources,
    });
  };

  const renderStakeChangeForms = () => {
    if (!selectedAccount || !lpAsset) return null;

    if (isBondMoreActive) {
      return (
        <Container className="px-6 mt-2" fullWidth>
          <AddStakeWidget
            onClose={toggleBondMore}
            collatorAddress={delegation.owner.toString()}
            currentStakeAmount={fromBN(
              delegation.amount.toBn(),
              BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
            )}
            stakedAsset={lpAsset}
          />
        </Container>
      );
    }

    if (isBondLessActive) {
      return (
        <Container className="px-6 mt-2" fullWidth>
          <RemoveStakeWidget
            onClose={toggleBondLess}
            collatorAddress={delegation.owner.toString()}
            currentStakeAmount={fromBN(
              delegation.amount.toBn(),
              BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
            )}
            stakedAsset={lpAsset}
          />
        </Container>
      );
    }
  };

  return (
    <Container
      fullWidth
      column
      justifyContent="center"
      className={cls('bg-widget rounded-lg py-6', className)}
      data-testid={testId}
    >
      <StakingCardHeader delegation={delegation} />
      <StakingCardRewards delegation={delegation} />

      <div className="my-4 bg-widget w-full h-[1px]" />

      {!isStakeChangeActive && lpAsset && session && (
        <StakingCardMyStake
          toggleBondMore={toggleBondMore}
          toggleBondLess={toggleBondLess}
          delegation={delegation}
          lpAsset={lpAsset}
          request={request}
          session={session}
        />
      )}

      {!isStakeChangeActive && request && (
        <Container fullWidth className="px-6 mt-6">
          <Container
            fullWidth
            alignItems="center"
            justifyContent="space-between"
            className="p-4 bg-widget rounded-lg"
          >
            <Container column>
              <Text color="secondary" type="title-4" id="stakingCard.changeRequest" />
              <Text
                id={`stakingCard.changeRequestValue.${
                  request.action.isIncrease ? 'add' : 'remove'
                }`}
                values={{
                  amount: (
                    <Text type="body-m" color="primary">
                      <FormatAmount
                        value={fromBN(request.amount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
                        options={{ precision: 3, nonZeroPrecision: false, maxChars: 15 }}
                      />
                    </Text>
                  ),
                  symbol: (
                    <Text type="body-m" color="secondary">
                      {lpAsset?.symbol ?? ''}
                    </Text>
                  ),
                }}
              />
            </Container>
            {isStakeChangeReady ? (
              <Button size="s" onClick={confirmStakeChange}>
                <FormattedMessage id="stakingCard.confirmChange.cta" />
              </Button>
            ) : (
              <Tooltip
                tooltipContent={
                  <Container className="max-w-[200px] p-2">
                    <Text id="stakingCard.changeRequestInfo" />
                  </Container>
                }
                className="flex items-center"
                inPortal
                leftOffset={false}
              >
                <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-icon-secondary'} />
              </Tooltip>
            )}
          </Container>
        </Container>
      )}

      {renderStakeChangeForms()}
    </Container>
  );
}
