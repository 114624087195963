import { useQuery } from '@tanstack/react-query';
import { fetchRollupChains } from '../services/rollupStashService';
import { transformRollupChains } from '../transformers/rollupStashTransformer';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useRollupChainsQuery = () => {
  const rollupChainsQuery = useQuery(['rollup-channels'], fetchRollupChains, {
    select: transformRollupChains,
    ...queryOptions,
  });

  return {
    rollupChainsQuery,
  };
};
