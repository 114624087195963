import {
  TxType,
  useAccounts,
  useModalStore,
  useWallet,
  useRollupChainsQuery,
  useRollupTokenBalancesQuery,
  useRollupTokensQuery,
  useRollupDepositMutation,
  transformToAsset,
  useRollupTokenAllowance,
  isAmountGtZero,
  useRollupApproveMutation,
  useRollupDepositFeeQuery,
  useRollupApproveFeeQuery,
  useTransaction,
  NATIVE_TOKEN_ADDRESS,
  useRollupNativeTokenBalanceQuery,
} from 'core';
import { useEffect } from 'react';
import { DepositUIState, useDepositStore } from '../store/useDepositStore';
import { isEmpty } from 'lodash-es';

export const useRollupDepositState = () => {
  const {
    originAccount,
    setOriginAccount,
    asset,
    amount,
    setSuccess,
    uiState,
    setUIState,
    dispose,
    chain,
  } = useDepositStore();
  const { rollupDepositMutation } = useRollupDepositMutation(chain?.id);
  const { isDepositOpen, closeDeposit } = useModalStore();
  const {
    selectedAccount,
    accountsQuery: { data: allAccounts },
  } = useAccounts();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { getLatestTxByType, removeTx } = useTransaction();
  const { rollupTokenBalancesQuery } = useRollupTokenBalancesQuery(
    originAccount?.address,
    chain?.id,
  );
  const { rollupTokensQuery } = useRollupTokensQuery();
  const { isAllowanceSufficient } = useRollupTokenAllowance(
    chain?.id,
    originAccount?.address,
    asset?.id,
    amount,
  );
  const { rollupApproveMutation, isApproveInProgress } = useRollupApproveMutation(
    chain?.id,
    isAllowanceSufficient,
  );
  const { rollupDepositFeeQuery } = useRollupDepositFeeQuery(
    chain?.id,
    originAccount?.address,
    asset?.id,
    amount,
    isAllowanceSufficient,
  );
  const { rollupApproveFeeQuery } = useRollupApproveFeeQuery(
    chain?.id,
    originAccount?.address,
    asset?.id,
    amount,
  );
  const { rollupNativeTokenBalanceQuery } = useRollupNativeTokenBalanceQuery(
    chain?.id,
    originAccount?.address,
  );

  const approveTx = getLatestTxByType(TxType.ApproveContract);
  const depositTx = getLatestTxByType(TxType.RollupDeposit);

  const balances = rollupTokenBalancesQuery.data;
  const stashChain = rollupChainsQuery.data?.find((c) => c.chainId === chain?.id);
  const rollupTokens = rollupTokensQuery.data;
  const latestDepositTx = getLatestTxByType(TxType.Deposit);
  const isDepositSuccessful = !!rollupDepositMutation.data;
  const isFeeLoading = isAllowanceSufficient
    ? rollupDepositFeeQuery.isLoading
    : rollupApproveFeeQuery.isLoading;

  const isDepositReady = !!(
    isAmountGtZero(amount) &&
    asset &&
    originAccount &&
    stashChain &&
    selectedAccount &&
    !isFeeLoading &&
    selectedWallet &&
    !rollupNativeTokenBalanceQuery.isInitialLoading
  );

  useEffect(() => {
    if (isEmpty(allAccounts) || !selectedAccount) {
      return;
    }

    if (!originAccount) {
      setOriginAccount(selectedAccount);
      return;
    }
  }, [allAccounts, originAccount, selectedAccount, setOriginAccount]);

  useEffect(() => {
    if (isAllowanceSufficient && uiState === DepositUIState.ApproveSpendingCap) {
      setUIState(DepositUIState.Default);
      removeTx(approveTx?.id);
    }
  }, [approveTx, approveTx?.id, isAllowanceSufficient, removeTx, setUIState, uiState]);

  useEffect(() => {
    if (isDepositSuccessful) {
      setSuccess(true);
      setUIState(DepositUIState.Success);
    }
  }, [isDepositSuccessful, setSuccess, setUIState]);

  const freeBalance = asset?.id ? rollupTokenBalancesQuery.data?.get(asset?.id) : null;

  const handleClose = () => {
    dispose();
    closeDeposit();
    setOriginAccount(selectedAccount);
  };

  const submitDeposit = () => {
    const depositAsset = (() => {
      if (asset?.id === NATIVE_TOKEN_ADDRESS) {
        return rollupTokens?.find((t) => t.name === asset?.name && t.symbol === asset?.symbol);
      }

      return rollupTokens?.find((t) => t.address === asset?.id);
    })();

    if (
      !originAccount ||
      !selectedAccount ||
      !asset ||
      !amount ||
      !selectedWallet ||
      !stashChain ||
      !depositAsset ||
      isApproveInProgress
    ) {
      return;
    }

    if (isAllowanceSufficient) {
      setUIState(DepositUIState.Depositing);
      rollupDepositMutation.mutate({
        amount,
        token: depositAsset,
        userAddress: originAccount.address,
        gasAmount: rollupDepositFeeQuery.data?.gasAmount,
        chain: stashChain,
      });
    } else {
      setUIState(DepositUIState.ApproveSpendingCap);
      rollupApproveMutation.mutate({
        amount,
        token: depositAsset,
        userAddress: originAccount.address,
        gasAmount: rollupApproveFeeQuery.data?.gasAmount,
        chain: stashChain,
      });
    }
  };

  const depositTokens = rollupTokensQuery.data
    ?.filter((token) => token.chainId === chain?.id)
    .map(transformToAsset);
  const depositBalances = balances;

  return {
    submitDeposit,
    depositTokens,
    depositBalances,
    handleClose,
    isDepositOpen: isDepositOpen && !latestDepositTx?.isVisible,
    isDepositReady,
    freeBalance,
    accounts: allAccounts,
    selectedAccount,
    isApproveInProgress,
    isDepositing: rollupDepositMutation.isLoading,
    approveTx,
    depositTx,
  };
};
