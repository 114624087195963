import {
  Button,
  Container,
  Modal,
  AmountTooltip,
  Skeleton,
  Text,
  TokenInput,
  WidgetDetailRow,
  ErrorBanner,
  OldAccountSelect,
  shortenAddress,
  TokenIcon,
} from 'ui';
import { OldChainSelector } from './components/ChainSelector_Old/ChainSelector';
import { useDepositStore } from './store/useDepositStore';
import { ReactComponent as ArrowDownRight } from 'assets/icons/ArrowCircleDownRight.svg';
import { DepositValidationError, useDepositValidation } from './useDepositValidation';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeeAssetAmount, TxType, useMangataAsset, XcmOperation, useChannels } from 'core';
import { EthereumAccountInfo } from './components/EthereumAccountInfo/EthereumAccountInfo';
import { useTransition, animated } from '@react-spring/web';
import { useTx } from '../Tx';
import { useXcmDepositState } from './useXcmDepositState';

export function XcmDeposit() {
  const {
    chain,
    computed: { buttonLabelId, hasAmount },
    originAccount,
    asset,
    setAsset,
    amount,
    setAmount,
    setChain,
  } = useDepositStore();
  const intl = useIntl();
  const { isAllowed } = useTx();

  const { getError, fee } = useDepositValidation();
  const {
    handleClose,
    depositTokens,
    depositBalances,
    isDepositOpen,
    submitDeposit,
    accounts,
    freeBalance,
    handelAccountSelect,
    selectedAccount,
    isEthChain,
    isDepositReady,
  } = useXcmDepositState();

  const { mangataAsset } = useMangataAsset();
  const { channelsQuery } = useChannels();

  const ethBannerTransition = useTransition(isEthChain, {
    from: { maxHeight: 0 },
    enter: { maxHeight: 100 },
    leave: { maxHeight: 0 },
  });

  const renderFee = (fee?: FeeAssetAmount | null, watchError?: DepositValidationError[]) => {
    if (fee) {
      return (
        <Text
          type="label"
          color={error !== null && watchError?.includes(error) ? 'alert' : 'secondary'}
          className="mr-2"
          data-testid="fee-value"
        >
          <AmountTooltip
            id={fee.amount}
            value={fee.amount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
          />
          {fee.asset.symbol}
        </Text>
      );
    }

    return <Skeleton width="80px" height="10px" rounded />;
  };

  const getErrorText = () => {
    const originFeeChain = chain?.id === fee?.raw.origin.asset.channelId ? chain?.title : null;

    switch (error) {
      case DepositValidationError.InsufficientBalance:
        return intl.formatMessage({ id: 'bridge.error.insufficientBalance' });
      case DepositValidationError.MinAmount:
        return intl.formatMessage({ id: 'deposit.error.minAmount' });
      case DepositValidationError.OriginFeeDifferentAsset:
      case DepositValidationError.OriginFeeSameAsset:
        return intl.formatMessage(
          { id: 'deposit.error.originFee' },
          { token: fee?.raw.origin.asset.symbol, chain: originFeeChain },
        );
    }
  };

  const error = getError();

  return (
    <Modal
      isClosableOnOverlayClick={false}
      isOpen={isDepositOpen}
      onClose={handleClose}
      className="w-[480px] rounded-xl py-6"
      overlayClassName="bg-base/80"
      data-testid="deposit-modal"
    >
      <Container fullWidth column>
        <Text id="bridge.deposit.title" type="display-2" />

        <OldChainSelector
          chain={chain}
          setChain={setChain}
          operation={XcmOperation.Deposit}
          channels={channelsQuery.data}
        />

        <Container className="mt-3 pl-12" fullWidth>
          <OldAccountSelect
            accounts={accounts}
            selectedAccount={originAccount}
            onSelect={handelAccountSelect}
            data-testid="account-select-from"
          />
        </Container>

        {chain && depositTokens && (
          <TokenInput
            isInvalid={
              error === DepositValidationError.InsufficientBalance ||
              error === DepositValidationError.MinAmount ||
              error === DepositValidationError.OriginFeeSameAsset
            }
            selectedToken={asset}
            onTokenSelect={setAsset}
            onAmountChange={setAmount}
            tokens={depositTokens}
            balances={depositBalances}
            freeBalance={freeBalance}
            amount={amount || ''}
            className="mt-6 mb-2"
            settingsVisible={false}
          />
        )}

        {ethBannerTransition((style, visible) =>
          visible ? (
            <Container fullWidth column className="mt-2">
              <animated.div style={style} className="w-full overflow-hidden">
                <EthereumAccountInfo operation={XcmOperation.Deposit} chain={chain?.title} />
              </animated.div>
            </Container>
          ) : null,
        )}

        {chain && asset && amount && hasAmount && originAccount && (
          <Container column fullWidth className="my-2">
            <WidgetDetailRow
              label={
                <Text
                  id="bridge.details.originFee"
                  type="label"
                  color={
                    error === DepositValidationError.OriginFeeSameAsset ||
                    error === DepositValidationError.OriginFeeDifferentAsset
                      ? 'alert'
                      : 'secondary'
                  }
                  className="mr-2"
                />
              }
              value={renderFee(fee?.origin, [
                DepositValidationError.OriginFeeDifferentAsset,
                DepositValidationError.OriginFeeSameAsset,
              ])}
              data-testid="origin-fee"
              className="mt-2"
            />
            <WidgetDetailRow
              label={
                <Text
                  color={error === DepositValidationError.MinAmount ? 'alert' : 'secondary'}
                  id="bridge.details.destinationFee"
                  type="label"
                  className="mr-2"
                />
              }
              value={renderFee(fee?.destination, [DepositValidationError.MinAmount])}
              data-testid="destination-fee"
            />
          </Container>
        )}

        {error !== null && (
          <ErrorBanner data-testid="deposit-error-message">{getErrorText()}</ErrorBanner>
        )}

        {selectedAccount && (
          <Container column className="mt-4" fullWidth>
            <Container alignItems="center" className="-mx-6" style={{ width: 'calc(100% + 48px)' }}>
              <div className="h-[1px] bg-input w-6" />
              <Text
                id="bridge.deposit.to"
                uppercase
                color="secondary"
                className="mx-2 whitespace-nowrap"
              />
              <div className="h-[1px] bg-input w-full" />
            </Container>

            <Container
              fullWidth
              alignItems="center"
              justifyContent="space-between"
              className="mt-4 rounded-xl bg-widget p-4"
            >
              <Container alignItems="center">
                <ArrowDownRight className="w-8 h-auto fill-icon-default" />
                <Container column className="ml-4">
                  <Text type="title-3">{selectedAccount.name}</Text>
                  <Text type="body-s" color="secondary">
                    {shortenAddress(selectedAccount.address)}
                  </Text>
                </Container>
              </Container>
              {mangataAsset && (
                <Container>
                  <Text color="secondary" id="common.on" />
                  <TokenIcon token={mangataAsset} size="xs" className="mx-2" />
                  <Text id="common.defaultChain" />
                </Container>
              )}
            </Container>
          </Container>
        )}

        <Button
          isDisabled={!isDepositReady || error !== null || !isAllowed(TxType.Deposit)}
          variant="primary"
          size="l"
          fullWidth
          onClick={submitDeposit}
          data-testid="submit-deposit-button"
          className="mt-6"
        >
          <FormattedMessage id={buttonLabelId ?? ''} />
        </Button>
      </Container>
    </Modal>
  );
}
