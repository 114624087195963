import BN from 'bn.js';
import { toBN } from '@mangata-finance/sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDepositExtrinsic, getDepositFee, submitDeposit } from '../services/depositService';
import { WalletAccount, useApi, useChannelApi } from '../../../../services';
import { useTransactionStore } from '../../../transaction';
import { transformFee } from '../transformers/depositTransformer';
import { StashTokensData } from '../../../token';
import { StashChannel } from '../../../../services';
import { UseDepositProps } from '../useDeposits';
import { useWallet } from '../../../wallet';

interface DepositQueryProps extends UseDepositProps {
  tokens: StashTokensData | undefined;
  channels: StashChannel[] | undefined;
  selectedAccount: WalletAccount | null;
  originAccount: WalletAccount | null;
}

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useDepositQuery = ({
  amount,
  assetId,
  channelId,
  tokens,
  selectedAccount,
  originAccount,
}: DepositQueryProps) => {
  const mangataApi = useApi();
  const { api, isApiReady, channel } = useChannelApi(channelId);
  const transactionStore = useTransactionStore();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const stashToken = tokens?.xcmTokens?.find((token) => token.tokenId === assetId) ?? null;
  const address = selectedAccount?.address ?? null;
  const amountFromBN =
    amount && stashToken ? toBN(amount, parseInt(stashToken.decimals)).toString() : null;

  const depositExtrinsicQuery = useQuery(
    ['deposit-extrinsic', address, amountFromBN, stashToken, channel],
    getDepositExtrinsic(api),
    {
      enabled: !!(
        selectedWallet &&
        selectedAccount?.address &&
        stashToken &&
        amountFromBN &&
        new BN(amountFromBN).gt(new BN(0)) &&
        channel &&
        isApiReady
      ),
      ...queryOptions,
    },
  );

  const depositFeeQuery = useQuery(
    ['deposit-fee', address, depositExtrinsicQuery.dataUpdatedAt],
    getDepositFee(depositExtrinsicQuery.data),
    {
      select: transformFee({ channel, asset: stashToken, tokens: tokens?.allTokens ?? undefined }),
      enabled:
        !!depositExtrinsicQuery.data &&
        !!amountFromBN &&
        new BN(amountFromBN).gt(new BN(0)) &&
        !!address &&
        !!originAccount?.address,
      ...queryOptions,
    },
  );

  const submitDepositMutation = useMutation({
    mutationKey: ['deposit-submit'],
    mutationFn: submitDeposit(mangataApi, transactionStore, selectedWallet),
  });

  return {
    depositExtrinsicQuery,
    depositFeeQuery,
    submitDepositMutation,
  };
};
