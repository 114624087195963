import { TestId, useAccounts, useModalStore, useWallet } from 'core';
import { Container, Modal, Text, WalletLogo } from 'ui';

import { WalletLoadingLayout } from './loading/WalletLoadingLayout';
import { AccountListLayout } from './list/AccountListLayout';
import { AccountsErrorLayout } from './error/AccountsErrorLayout';

export type SelectAccountModalProps = TestId;

export const SelectAccountModal = ({
  'data-testid': testId = 'selectAccountModal',
}: SelectAccountModalProps) => {
  const { isAccountSettingsOpen, closeAccountSettings } = useModalStore();

  const { selectedWallet } = useWallet();

  const {
    selectedAccount,
    accountsQuery: { isError, error, isSuccess },
  } = useAccounts();

  const renderLayout = () => {
    switch (true) {
      case isSuccess:
        return <AccountListLayout />;
      case isError:
        return <AccountsErrorLayout error={error} />;
      default:
        return <WalletLoadingLayout />;
    }
  };

  return selectedWallet ? (
    <Modal
      isOpen={isAccountSettingsOpen}
      onClose={closeAccountSettings}
      className="w-[520px] px-4 flex-col pb-0"
      data-testid={testId}
    >
      <Container className="px-4 py-2 mb-6" alignItems="center">
        <WalletLogo className="w-9" wallet={selectedWallet} />
        <Text
          type="title-2"
          className="ml-4"
          id={`wallet.modal.account.${selectedAccount ? 'connected' : 'connecting'}.header`}
          values={{ wallet: selectedWallet }}
        />
      </Container>
      {renderLayout()}
    </Modal>
  ) : null;
};
