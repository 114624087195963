import { TestId, useInvestedPools } from 'core';
import { Container, TabGroup } from 'ui';
import { useWalletUIStore } from '../../store/useWalletUIStore';
import { MyPositions } from '../MyPositions/MyPositions';
import { MyTokens } from '../MyTokens/MyTokens';
import { useIntl } from 'react-intl';

type ConnectWalletLayoutProps = TestId;

export const ConnectedLayout = ({ 'data-testid': testId }: ConnectWalletLayoutProps) => {
  const { activeTab, setActiveTab } = useWalletUIStore();
  const { data: investedPools } = useInvestedPools();

  const investedPoolsCount = investedPools?.baseList.length;

  const intl = useIntl();

  const tabs = [
    { label: intl.formatMessage({ id: 'wallet.tabs.tokens' }) },
    { label: intl.formatMessage({ id: 'wallet.tabs.positions' }), count: investedPoolsCount },
  ];

  return (
    <>
      <Container data-testid={testId} fullWidth wrap={false} alignItems="center">
        <TabGroup activeIndex={activeTab} data={tabs} onSelect={setActiveTab} size="s" />
      </Container>
      <>
        {activeTab === 0 && <MyTokens />}
        {activeTab === 1 && <MyPositions />}
      </>
    </>
  );
};
