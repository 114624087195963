import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { getStakingLiquidityTokens } from '../services/stakingMetadataService';
import { transformerStakingLiquidityTokens } from '../transformers/stakingMetadataTransformer';

export const useStakingMetadataQuery = () => {
  const api = useApi();

  const stakingLiquidityTokensQuery = useQuery(
    ['staking-liquidity-tokens'],
    getStakingLiquidityTokens(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!api,
      select: transformerStakingLiquidityTokens,
    },
  );

  return {
    stakingLiquidityTokensQuery,
  };
};
