import { PoolWithShare } from '@mangata-finance/sdk';
import { Decimal } from 'decimal.js';
import { use3rdPartyRewards, useAssets, useMangataAsset, usePoolRewards, useSession } from 'core';
import { PoolRewardsInfoRow } from './PoolRewardsInfoRow';
import { uniq } from 'lodash-es';

interface PoolRewardsInfoProps {
  pool: PoolWithShare;
}

export const PoolRewardsInfo = ({ pool }: PoolRewardsInfoProps) => {
  const { poolPendingRewardsSchedules, claimableRewards } = usePoolRewards(pool.liquidityTokenId);
  const { mangataAsset } = useMangataAsset();
  const {
    currentSession: { data: session },
  } = useSession();
  const { assetsMap } = useAssets();
  const {
    thirdPartyRewardsAmountQuery: { data: rewardsAmounts },
  } = use3rdPartyRewards();

  const poolRewardsAmounts = rewardsAmounts?.get(pool.liquidityTokenId);

  if (!mangataAsset || !session) {
    return null;
  }

  const rewardTokenIds = uniq([
    mangataAsset.id,
    ...(poolPendingRewardsSchedules?.keys() || []),
    ...(Array.from(poolRewardsAmounts?.entries() || []).map(([rewardTokenId, amount]) => {
      return new Decimal(amount?.toString() ?? '0').gt(0) ? rewardTokenId : null;
    }) || []),
  ]).filter(Boolean);

  const renderRewards = () => {
    return rewardTokenIds.map((id) => {
      const asset = id && assetsMap.get(id);
      const amount = (asset && claimableRewards.get(asset.id)?.[1]) ?? '0';

      return (
        asset && <PoolRewardsInfoRow key={asset.id} pool={pool} asset={asset} amount={amount} />
      );
    });
  };

  return <>{renderRewards()}</>;
};
