import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { useCallback, useEffect } from 'react';
import { AddLiquidityUIStore, useProvideLiquidityStore } from './store/useProvideLiquidityStore';
import { AdaptiveTxFee, useAccounts, useInternalBalance, isAmountGtZero } from 'core';
import { Decimal } from 'decimal.js';
import { some } from 'lodash-es';
import { ProvideLiquidityFormError, PendingNewPool } from './ProvideLiquidity';

export const useProvideLiquidityValidation = (
  pool: PoolWithRatio | PendingNewPool | null,
  feeData: AdaptiveTxFee | null | undefined,
) => {
  const { setFormError, firstAmount, secondAmount, formError } = useProvideLiquidityStore();
  const { getFreeBalance } = useInternalBalance();
  const { selectedAccount } = useAccounts();

  const isInvalid = some(formError, Boolean);
  const currentFee = feeData?.current;
  const firstTokenBalanceStr = getFreeBalance(pool?.firstAsset) ?? '0';
  const secondTokenBalanceStr = getFreeBalance(pool?.secondAsset) ?? '0';
  const feeBalanceStr = getFreeBalance(currentFee) ?? '0';
  const isFirstAmountValid = isAmountGtZero(firstAmount);
  const isSecondAmountValid = isAmountGtZero(secondAmount);
  const areInputsFilled = isFirstAmountValid && isSecondAmountValid;
  const poolFirstAssetId = pool?.firstAsset.id;
  const poolSecondAssetId = pool?.secondAsset.id;

  const validateForm = useCallback(() => {
    const feeBalance = new Decimal(feeBalanceStr);
    const newFormError: Partial<Record<ProvideLiquidityFormError, boolean>> = {};

    if (!selectedAccount?.address) {
      setFormError({});
      return null;
    }

    if (isFirstAmountValid && new Decimal(firstTokenBalanceStr).lessThan(firstAmount)) {
      newFormError[ProvideLiquidityFormError.InsufficientFirstAssetFunds] = true;
    }

    if (isSecondAmountValid && new Decimal(secondTokenBalanceStr).lessThan(secondAmount)) {
      newFormError[ProvideLiquidityFormError.InsufficientSecondAssetFunds] = true;
    }

    if (currentFee && isFirstAmountValid && isSecondAmountValid) {
      if (feeBalance.lessThan(currentFee.amount ?? 0)) {
        newFormError[ProvideLiquidityFormError.InsufficientFee] = true;
      }

      if (
        poolFirstAssetId === currentFee.id &&
        feeBalance.lessThan(new Decimal(currentFee.amount).add(firstAmount))
      ) {
        newFormError[ProvideLiquidityFormError.InsufficientFee] = true;
      }

      if (
        poolSecondAssetId === currentFee.id &&
        feeBalance.lessThan(new Decimal(currentFee.amount).add(secondAmount))
      ) {
        newFormError[ProvideLiquidityFormError.InsufficientFee] = true;
      }
    }

    setFormError(newFormError);
    return null;
  }, [
    poolFirstAssetId,
    poolSecondAssetId,
    firstAmount,
    secondAmount,
    isFirstAmountValid,
    isSecondAmountValid,
    firstTokenBalanceStr,
    secondTokenBalanceStr,
    selectedAccount?.address,
    feeBalanceStr,
    setFormError,
    currentFee,
  ]);

  useEffect(() => {
    validateForm();
  }, [firstAmount, secondAmount, validateForm]);

  const isDisabled = !firstAmount || !secondAmount || isInvalid || !areInputsFilled;

  return {
    isDisabled,
    isInvalid,
    areInputsFilled,
  };
};

export const getSubmitMessage = (
  state: AddLiquidityUIStore,
  isWalletConnected: boolean,
  isPoolCreated: boolean,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case !isWalletConnected:
      return 'pool.provide.submit.connectWallet';
    case !state.firstAmount || !state.secondAmount:
      return 'pool.provide.submit.enterAmount';
    case !!state.formError[ProvideLiquidityFormError.InsufficientFirstAssetFunds]:
    case !!state.formError[ProvideLiquidityFormError.InsufficientSecondAssetFunds]:
      return 'pool.provide.submit.insufficientFunds';
    case !!state.formError[ProvideLiquidityFormError.InsufficientFee]:
      return 'pool.provide.submit.insufficientFee';
    case !isPoolCreated:
      return 'pool.provide.submit.create';
    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';

    default:
      return 'pool.provide.submit.add';
  }
};
