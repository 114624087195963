import { create } from 'zustand';
import { Asset } from '../../../token';
import { TxType } from '../../Transaction';

export interface SafeTxFeeData {
  asset: Asset;
  remainingBalanceAfterTx: string;
  currentBalance: string;
  isAdaptive: boolean;
}

export interface useSafeTxModalStore {
  isOpen: boolean;
  type: TxType | null;
  fee: SafeTxFeeData | null;
  isProceedClicked: boolean;
  isConsentChecked: boolean;
  onProceed: () => void;
  onClose: () => void;
  dispose: () => void;
  toggleConsentChecked: () => void;
  triggerModal: (fee: SafeTxFeeData, type: TxType) => void;
}

const INITIAL_STATE = {
  isOpen: false,
  fee: null,
  type: null,
  isProceedClicked: false,
  isConsentChecked: false,
};

export const useSafeTxModalStore = create<useSafeTxModalStore>((set) => ({
  ...INITIAL_STATE,
  dispose: () => set(() => INITIAL_STATE),
  onProceed: () => set(() => ({ isProceedClicked: true, isOpen: true })),
  onClose: () => set(() => ({ isProceedClicked: false, isOpen: false })),
  toggleConsentChecked: () => set((state) => ({ isConsentChecked: !state.isConsentChecked })),
  triggerModal: (fee: SafeTxFeeData, type: TxType) => set(() => ({ isOpen: true, fee, type })),
}));
