import { Button, Container, Text } from 'ui';
import { ReactComponent as EmptyPositionsIcon } from 'icons/empty-positions.svg';
import { Link } from 'react-router-dom';
import { TestId } from 'core';

export function EmptyPositions({ 'data-testid': testId }: TestId) {
  return (
    <Container
      data-testid={testId}
      column
      fullWidth
      alignItems="center"
      justifyContent="center"
      className="p-14 text-center"
    >
      <EmptyPositionsIcon className="w-[64px] h-auto stroke-icon-neutral" />
      <Text type="title-2" id="position.empty.title" className="mt-8" />
      <Text color="secondary" id="position.empty.desc" className="mt-2" />
      <Link to="/pools/promoted" className="mt-12">
        <Button variant="primary" size="m">
          <Text weight="semibold" type="btn-m" id="position.empty.cta" />
        </Button>
      </Link>
    </Container>
  );
}
