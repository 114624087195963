import cls from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Banner, Button, Container, ErrorBanner, FormatAmount, Modal, Skeleton, Text } from 'ui';
import { PoolWithShare, TxAsset } from 'core';

interface RemoveLiquidityModalProps {
  isOpen: boolean;
  feeAsset: TxAsset | null;
  onConfirm: () => void;
  onCancel: () => void;
  isFeeInsuff: boolean;
  isBatchDeactivation: boolean;
  pool: PoolWithShare;
}

export function RemoveLiquidityModal({
  pool,
  isOpen,
  onConfirm,
  onCancel,
  feeAsset,
  isFeeInsuff,
  isBatchDeactivation,
}: RemoveLiquidityModalProps) {
  const intl = useIntl();
  const isWithDeactivation = pool.activatedLPTokens.gtn(0);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      closeIconClassName="hidden"
      className="p-10 w-[402px]"
    >
      <Container column className="rounded-lg">
        <Text type="title-1" id={`position.remove.liquidity.confirm.title`} weight="semibold" />
        <Text
          type="body-l"
          id={`position.${
            isWithDeactivation ? 'removeAndDeactivate' : 'remove'
          }.liquidity.confirm.desc`}
          className="mt-2"
        />
        {isBatchDeactivation && (
          <Banner
            variant="alert"
            message={intl.formatMessage({ id: 'position.deactivate.3rdParty.liquidity.confirm' })}
            className="mt-4"
            showIcon={false}
          />
        )}
        <Container className="mt-6" justifyContent="space-between" fullWidth>
          <Text
            color={isFeeInsuff ? 'alert' : 'secondary'}
            id="position.toggle.liquidity.confirm.fee"
          />
          {feeAsset ? (
            <Text color={isFeeInsuff ? 'alert' : 'primary'}>
              ≈{' '}
              <FormatAmount
                value={feeAsset.amount}
                options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
                data-testid="fee-amount"
              />{' '}
              {feeAsset.symbol}
            </Text>
          ) : (
            <Skeleton height="14px" width="70px" />
          )}
        </Container>
        {isFeeInsuff && <ErrorBanner msgId="position.toggle.liquidity.insuff.fee" />}
        <Container
          column
          className={cls(isFeeInsuff ? 'mt-[48px]' : 'mt-[82px]')}
          justifyContent="space-between"
          fullWidth
        >
          <Button
            fullWidth
            variant="alert"
            onClick={onConfirm}
            isDisabled={!feeAsset || isFeeInsuff}
            data-testid="confirm-fee-amount"
          >
            <FormattedMessage id="position.remove.liquidity.confirm.submit" />
          </Button>
          <Button fullWidth variant="outlined" className="mt-4" onClick={onCancel}>
            <FormattedMessage id="position.toggle.liquidity.confirm.cancel" />
          </Button>
        </Container>
      </Container>
    </Modal>
  );
}
