import { ActiveMiningIcon, Text, Container, FormatAmount } from 'ui';
import { BN } from '@polkadot/util';
import cls from 'classnames';
import { Asset, ClassName, PoolWithShare } from 'core';
import { fromBN } from '@mangata-finance/sdk';
import { ReactComponent as PauseIcon } from 'icons/pause-circle.svg';
import { EligibleLpTokensCard } from '../../EligibleLpTokensCard/EligibleLpTokensCard';

interface LiquidityMiningCardSummaryProps extends ClassName {
  amount: BN;
  isActive: boolean;
  pool: PoolWithShare;
  activeLPAmount: BN;
  availableLPAmount: BN;
  asset: Asset;
}
export function LiquidityMiningCardSummary({
  amount,
  className,
  isActive,
  pool,
  activeLPAmount,
  availableLPAmount,
  asset,
}: LiquidityMiningCardSummaryProps) {
  return (
    <Container
      className={cls(className)}
      fullWidth
      justifyContent="space-between"
      alignItems="center"
    >
      <Container alignItems="center">
        <Container column>
          <Text id="liq.mining.detail.widget.claim.rewards.title" color="secondary" type="body-m" />
          <Container data-testid="claimable-rewards-value" alignItems="center" className="mt-1">
            {isActive ? (
              <ActiveMiningIcon className="mr-2" />
            ) : (
              <PauseIcon className="mr-2 fill-icon-secondary w-[15px] h-auto" />
            )}
            <Text
              type="body-l"
              className="_accent-premium _highlight-accent"
              color={isActive ? 'accent' : 'highlight'}
            >
              <FormatAmount
                value={fromBN(amount, asset.decimals)}
                options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
              />{' '}
              {asset.symbol}
            </Text>
          </Container>
        </Container>
      </Container>
      <Container column className="max-w-[55%] whitespace-nowrap">
        <EligibleLpTokensCard
          pool={pool}
          maxChars={8}
          activeLPAmount={activeLPAmount}
          availableLPAmount={availableLPAmount}
        />
      </Container>
    </Container>
  );
}
