import Decimal from 'decimal.js';
import { flow } from 'lodash-es';

export interface FormatCurrencyOptions {
  withoutSymbol?: boolean;
  precise?: boolean;
}

const getNumberFormatOptions = (_options?: FormatCurrencyOptions): Intl.NumberFormatOptions => {
  const options = { precise: true, ..._options };

  if (options?.precise) {
    return {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
  } else {
    return {
      maximumFractionDigits: 0,
    };
  }
};

export function formatCurrency(inputValue: string | null, options?: FormatCurrencyOptions) {
  if (!inputValue) {
    return null;
  }

  const formatOptions = getNumberFormatOptions(options);

  const formatToLocale = (amount: string) => {
    return parseFloat(amount).toLocaleString('en-us', {
      notation: 'compact',
      ...formatOptions,
    });
  };

  const removeTrailingZeros = (amount: string) => {
    if ((formatOptions?.minimumFractionDigits || 0) > 0) {
      const regex = new RegExp(`\\.0{${formatOptions.minimumFractionDigits}}$`);
      return amount.replace(regex, '');
    }
    return amount;
  };

  const roundSmallAmounts = (amount: string) => {
    const [integer, fraction] = amount.split('.');

    try {
      if (!/[^0-9.]/.test(amount) && new Decimal(amount).eq(0)) {
        if (fraction?.length > 0) {
          const roundedUpResult = `${integer}.${fraction.replace(/0(?=[^0]*$)/, '1')}`;

          return `<${roundedUpResult}`;
        } else {
          return '<1';
        }
      }
    } catch (e) {
      console.error('Rounding error', e);
      return amount;
    }

    return amount;
  };

  const prependSymbol = (amount: string) => {
    if (amount[0] === '<' && !options?.withoutSymbol) {
      return amount.replace('<', '< $');
    }

    return options?.withoutSymbol ? amount : `$${amount}`;
  };

  return flow([formatToLocale, roundSmallAmounts, removeTrailingZeros, prependSymbol])(inputValue);
}
