import { QueryOptional } from '../../../../services';
import { useScheduleBondMoreFeeQuery } from './query/useScheduleDelegatorBondMoreFeeQuery';
import { useScheduleBondMoreMutation } from './query/useScheduleDelegatorBondMoreMutation';
import { TxAsset } from '../../../..';

export const useScheduleDelegatorBondMore = (
  collatorAddress: QueryOptional<string>,
  stakedAsset: QueryOptional<TxAsset>,
) => {
  const { scheduleBondMoreMutation } = useScheduleBondMoreMutation();
  const { scheduleBondMoreFeeQuery } = useScheduleBondMoreFeeQuery(collatorAddress, stakedAsset);

  return {
    scheduleBondMoreMutation,
    scheduleBondMoreFeeQuery,
  };
};
