import cls from 'classnames';
import { Children, ClassName, TestId } from 'core';
import { Container, Text } from 'ui';

interface CollatorDetailCardProps extends Children, ClassName, TestId {
  titleId: string;
}

export function CollatorDetailCard({
  titleId,
  children,
  className,
  'data-testid': testId = 'collator-detail-card',
}: CollatorDetailCardProps) {
  return (
    <Container
      column
      className={cls('rounded px-4 py-3 bg-widget min-w-[200px]', className)}
      data-testid={testId}
    >
      <Text type="label" color="secondary" id={titleId} className="mb-1" />
      <Container>{children}</Container>
    </Container>
  );
}
