import { useQuery } from '@tanstack/react-query';
import { useReservesForTx } from '../../../balance';
import { TxType, useAccounts, useAdaptiveFee, useInternalBalance } from '../../../../';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../services';
import { getActivateLiquidityFee } from '../services/ActivateLiquidityFeeService';

export const useActivateLiquidityFeeQuery = (
  liquidityId: QueryOptional<string>,
  amount: QueryOptional<string>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { sources } = useReservesForTx(TxType.ActivateLP, liquidityId);
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const activateLiquidityFeeQuery = useQuery(
    ['activate-liquidity-fee', liquidityId, selectedAccount?.address, amount],
    getActivateLiquidityFee(api, sources),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!liquidityId &&
        !!amount &&
        !!balances &&
        !!sources,
    },
  );

  return { activateLiquidityFeeQuery };
};
