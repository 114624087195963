import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { RemoveLiquidityFormError } from '../RemoveLiquidity';

export interface RemoveLiquidityUIStore {
  removeAmount: number;
  formError: Partial<Record<RemoveLiquidityFormError, boolean>>;
  setFormError: (value: Partial<Record<RemoveLiquidityFormError, boolean>>) => void;
  setRemoveAmount: (value: number) => void;
  dispose: () => void;
}

const INITIAL_STATE = {
  removeAmount: 25,
  formError: {},
};

export const useRemoveLiquidityStore = create(
  devtools<RemoveLiquidityUIStore>((set, get) => ({
    ...INITIAL_STATE,
    setRemoveAmount: (removeAmount) => set({ removeAmount }),
    setFormError: (formError) => set({ formError }),
    dispose: () => set(INITIAL_STATE),
  })),
);
