import { useAllPoolsQuery } from '../all/query/useAllPoolsQuery';
import { AddLiqudityFeeParams, useAddLiqudityFeeQuery } from './query/useAddLiquidityFeeQuery';
import { useAddLiqudityMutation } from './query/useAddLiquidityMutation';

export const useAddLiqudity = (params: AddLiqudityFeeParams) => {
  const {
    allPoolsQuery: { data: pools },
  } = useAllPoolsQuery();
  const { addLiquidityMutation } = useAddLiqudityMutation({ pools: pools?.list });
  const { addLiquidityFeeQuery } = useAddLiqudityFeeQuery(params);

  return {
    addLiquidityMutation,
    addLiquidityFeeQuery,
  };
};
