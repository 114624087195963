import { useQuery } from '@tanstack/react-query';
import {
  DEFAULT_QUERY_OPTIONS,
  QueryOptional,
  useAccounts,
  useAdaptiveFee,
  useApi,
  useInternalBalance,
} from '../../../../';
import { getDeactivateLiquidityFor3rdPartyRewardsFee } from '../services/deactivateLiqFor3rdPartyRewardsFeeService';

export const useDeactivateLiquidityFor3rdPartyRewardsFeeQuery = (
  liquidityId: QueryOptional<string>,
  rewardTokenId: QueryOptional<string>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const deactivateLiquidityFeeQuery = useQuery(
    ['deactivate-3rd-party-liquidity-fee', liquidityId, selectedAccount?.address, rewardTokenId],
    getDeactivateLiquidityFor3rdPartyRewardsFee(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!liquidityId &&
        !!rewardTokenId &&
        !!balances,
    },
  );

  return { deactivateLiquidityFeeQuery };
};
