import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SwitchIcon } from 'icons/arrows-down-up.svg';
import {
  useSwap,
  useWallet,
  useSafeTransaction,
  TxType,
  useUserStore,
  useAssets,
  useFilteredInternalAssets,
  useMetamaskNetwork,
} from 'core';
import { Button, Container, Text, TokenInput } from 'ui';
import { useIntl } from 'react-intl';
import { ReactComponent as SettingsIcon } from 'icons/h_sliders.svg';
import { useWalletUIStore } from '../../../Wallet';
import { SwapInputType } from '../../SwapPage';
import { SwapBanner } from '../../components/SwapBanner/SwapBanner';
import { SwapDetails } from '../../components/SwapDetails/SwapDetails';
import { useSwapStore } from '../../store/useSwapStore';
import { useSwapInputs } from '../../useSwapInputs';
import { useSwapValidation, SwapFormError, getSubmitMessage } from '../../useSwapValidation';
import { animated, useSpring } from '@react-spring/web';

export const MAX_SLIPPAGE = 1;

export const SwapWidgetContent = () => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedAccount } = useUserStore();
  const { swapMutation } = useSwap();
  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();
  const { assets } = useAssets();
  const { getFreeBalance, tokenListFreeBalances, filteredAssets } = useFilteredInternalAssets();
  const { switchTokens, handleAmountChange, handleTokenSelect } = useSwapInputs();
  const { isDisabled, feeTxAsset } = useSwapValidation();
  const { toggleSelectWallet } = useWalletUIStore();
  const store = useSwapStore();
  const [switchTokensToggle, setSwitchTokensToggle] = useState(false);
  const { isChainSwitchRequired } = useMetamaskNetwork();

  const {
    firstToken,
    secondToken,
    firstAmount,
    secondAmount,
    routeDetails,
    formError,
    setFirstToken,
    setSecondToken,
    setSettingsOpen,
    dispose,
  } = store;
  const firstSymbol = searchParams.get(SwapInputType.FirstToken);
  const secondSymbol = searchParams.get(SwapInputType.SecondToken);

  const switchIconSpring = useSpring({
    transform:
      switchTokensToggle && (firstToken !== null || secondToken !== null)
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
  });

  const toggleTokenSwitch = () => {
    switchTokens();
    setSwitchTokensToggle(!switchTokensToggle);
  };

  useEffect(() => {
    if (firstSymbol && firstToken?.symbol !== firstSymbol) {
      const firstAsset = assets.find((asset) => asset.symbol === firstSymbol);

      if (firstAsset) {
        setFirstToken(firstAsset);
        setSearchParams({}, { replace: true });
      }
    }

    if (secondSymbol && firstSymbol !== secondSymbol && secondToken?.symbol !== secondSymbol) {
      const secondAsset = assets.find((asset) => asset.symbol === secondSymbol);

      if (secondAsset) {
        setSecondToken(secondAsset);
        setSearchParams({}, { replace: true });
      }
    }
  }, [
    assets,
    firstSymbol,
    secondSymbol,
    firstToken?.symbol,
    secondToken?.symbol,
    setFirstToken,
    setSecondToken,
    setSearchParams,
  ]);

  useEffect(() => {
    if (firstAmount === '' && formError !== null) {
      store.setFormError(null);
    }
  }, [firstAmount, formError, store]);

  const handleSubmit = () => {
    if (
      firstToken &&
      secondToken &&
      firstAmount &&
      secondAmount &&
      routeDetails?.bestRoute &&
      routeDetails.minAmountOutBN &&
      selectedAccount?.address &&
      (selectedWallet || selectedAccount?.type)
    ) {
      swapMutation.mutate({
        firstToken: { ...firstToken, amount: firstAmount },
        secondToken: { ...secondToken, amount: secondAmount },
        route: routeDetails.bestRoute,
        minAmountOut: routeDetails.minAmountOutBN,
        selectedAccount,
        onDone: dispose,
      });
    }
  };

  const { submitTxWithChecks } = useSafeTransaction(
    {
      receivingAssets: secondToken ? [{ ...secondToken, amount: secondAmount }] : [],
      spendingAssets: firstToken ? [{ ...firstToken, amount: firstAmount }] : [],
      feeTxAsset,
      type: TxType.Swap,
    },
    handleSubmit,
  );

  const handleSettingsOpen = () => setSettingsOpen(true);

  return (
    <>
      <Container alignItems="center" fullWidth justifyContent="space-between">
        <Text type="display" id="swap.widget.title" />
        <SettingsIcon
          className="w-12 p-3 h-auto stroke-icon-secondary cursor-pointer"
          onClick={handleSettingsOpen}
        />
      </Container>
      <Container className="mt-6" fullWidth column alignItems="center">
        <TokenInput
          tokens={filteredAssets}
          balances={tokenListFreeBalances}
          labelId="swap.input.firstToken.label"
          selectedToken={firstToken}
          onTokenSelect={handleTokenSelect(SwapInputType.FirstToken)}
          onAmountChange={handleAmountChange(SwapInputType.FirstToken)}
          amount={firstAmount}
          freeBalance={firstToken?.id ? getFreeBalance(firstToken) : null}
          isInvalid={formError === SwapFormError.InsufficientFunds}
          data-testid="firstToken"
          isBorderless
        />
        <Container
          className="rounded-full bg-widget p-2 -my-4 z-10 cursor-pointer hover:scale-105 transition-transform"
          onClick={toggleTokenSwitch}
        >
          <animated.div style={switchIconSpring}>
            <SwitchIcon data-testid="switchTokens" className="w-6 h-6 stroke-icon-secondary" />
          </animated.div>
        </Container>
        <TokenInput
          tokens={filteredAssets}
          balances={tokenListFreeBalances}
          labelId="swap.input.secondToken.label"
          selectedToken={secondToken}
          onTokenSelect={handleTokenSelect(SwapInputType.SecondToken)}
          onAmountChange={handleAmountChange(SwapInputType.SecondToken)}
          amount={secondAmount}
          freeBalance={secondToken?.id ? getFreeBalance(secondToken) : null}
          data-testid="secondToken"
          isBorderless
        />
      </Container>
      {routeDetails?.error && (
        <SwapBanner variant={routeDetails?.error} data-testid="errorBanner" className="mt-6" />
      )}
      {(!isDisabled || formError) && <SwapDetails />}
      <Container fullWidth className="mt-6">
        <Button
          isDisabled={isDisabled || swapMutation.isLoading}
          variant="primary"
          size="l"
          fullWidth
          onClick={selectedAccount ? submitTxWithChecks : toggleSelectWallet}
          data-testid="submitSwap"
        >
          {intl.formatMessage({
            id: getSubmitMessage(store, !!selectedAccount, isChainSwitchRequired),
          })}
        </Button>
      </Container>
    </>
  );
};
