import { Container, Text, Tooltip } from 'ui';
import { ReactComponent as LockPeriodIcon } from 'assets/icons/lock_period.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { useIntl } from 'react-intl';
import { useFeeMetadataQuery } from 'core';

interface FeeDetailsProps {
  isInsufficient: boolean;
  isGasless: boolean;
}

export const FeeDetails = ({ isInsufficient, isGasless }: FeeDetailsProps) => {
  const intl = useIntl();
  const { mgxLockAmount } = useFeeMetadataQuery();

  const TooltipContent = (
    <Text
      className="whitespace-pre-line"
      id={`swap.fee.${!isGasless && isInsufficient ? 'insufficient' : 'lock'}`}
      values={{ lockAmount: mgxLockAmount }}
    />
  );

  return (
    <Tooltip
      tooltipContent={(!isGasless || isInsufficient) && TooltipContent}
      tooltipClassName="!w-[336px] !h-[auto]"
      className="_highlight-accent"
      id="swapFee"
      inPortal
    >
      <Container alignItems="center">
        <Text color={isInsufficient ? 'alert' : 'highlight'}>
          {isGasless ? '0' : mgxLockAmount} {intl.formatMessage({ id: 'common.mainToken' })}
        </Text>
        {!isGasless && !isInsufficient && (
          <LockPeriodIcon className="w-[14px] h-[14px] fill-icon-highlight ml-1" />
        )}
        {isInsufficient && <WarningIcon className="w-[18px] h-[auto] fill-icon-alert ml-1" />}
      </Container>
    </Tooltip>
  );
};
