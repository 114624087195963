import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { ConnectWallet } from './components/ConnectWallet/ConnectWallet';
import { Button, Container, Text } from 'ui';
import {
  EnvConfig,
  EthereumKeyPairTypes,
  TestId,
  WalletAccount,
  XCMKeypairTypes,
  useModalStore,
} from 'core';
import { useWalletUIStore } from '../../store/useWalletUIStore';
import { Deposit, Withdrawal } from '../../../Bridge';
import { ReactComponent as WithdrawIcon } from 'icons/ArrowUpRight.svg';
import { ReactComponent as DepositIcon } from 'icons/ArrowDownRight.svg';

export interface WalletHeaderProps extends TestId {
  animating: boolean;
  selectedWallet: string | null;
  selectedAccount: WalletAccount | null;
}

export function WalletHeader({
  animating,
  selectedWallet,
  selectedAccount,
  'data-testid': testId,
}: WalletHeaderProps) {
  const { isWalletOpen, setIsWalletOpen } = useWalletUIStore();
  const { openWithdrawal, openDeposit, isDepositOpen, isWithdrawalOpen } = useModalStore();

  const showAccountInfo = selectedWallet && selectedAccount;
  const accountType = selectedAccount?.type;
  const showConnect = !animating && !isWalletOpen && !selectedAccount;
  const isXcmAccount = !!accountType && XCMKeypairTypes.includes(accountType);
  const isMetamaskAccount = !!accountType && EthereumKeyPairTypes.includes(accountType);
  const isTransferDisabled = EnvConfig.isKusamaEnv ? isMetamaskAccount : isXcmAccount;

  const toggleWalletVisibility = () => setIsWalletOpen(!isWalletOpen);

  return (
    <>
      {isDepositOpen && <Deposit />}
      {isWithdrawalOpen && <Withdrawal />}
      {showConnect && <ConnectWallet data-testid={testId} onToggle={toggleWalletVisibility} />}
      {showAccountInfo && (
        <Container column fullWidth data-testid={testId}>
          <AccountInfo
            account={selectedAccount}
            open={isWalletOpen}
            wallet={selectedWallet}
            onToggle={toggleWalletVisibility}
            data-testid={testId}
          />

          {isWalletOpen && (
            <Container fullWidth className="p-3">
              <Button
                size="s"
                onClick={openDeposit}
                fullWidth
                variant="secondary"
                className="mr-3"
                isDisabled={isTransferDisabled}
              >
                <DepositIcon className="mr-2 w-5 h-auto stroke-icon-neutral" />
                <Text color="primary" id="wallet.deposit" />
              </Button>
              <Button
                size="s"
                onClick={openWithdrawal}
                fullWidth
                variant="secondary"
                isDisabled={isTransferDisabled}
              >
                <WithdrawIcon className="mr-2 w-5 h-auto stroke-icon-neutral" />
                <Text color="primary" id="wallet.withdraw" />
              </Button>
            </Container>
          )}
        </Container>
      )}
    </>
  );
}
