import { z } from 'zod';

export const HorizontalMessageSchema = z.object({
  sentAt: z.string(),
  data: z.string(),
});

export const ExtrinsicDataSchema = z.object({
  horizontalMessages: z.record(z.string(), z.array(HorizontalMessageSchema)),
});

export const ExtrinsicSchema = z.object({
  isSigned: z.boolean(),
  method: z.object({
    method: z.string(),
    section: z.string(),
  }),
  signer: z
    .object({
      Id: z.string(),
    })
    .or(z.string())
    .optional(),
});

export const BlockSchema = z.object({
  extrinsics: z.array(ExtrinsicSchema),
});
