import cls from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { Container } from '../Container/Container';
import { TestId } from 'core';

interface ChainSelectItem extends TestId {
  id: string;
  icon: string;
  title: string;
}

interface ChainSelect extends TestId {
  dropdownTitle?: string;
  data?: Array<ChainSelectItem>;
  selectedItem: ChainSelectItem | null;
  onSelect?: (item: ChainSelectItem | null) => void;
  triggerContent: React.ReactNode;
}

export function OldChainSelect({
  data,
  triggerContent,
  selectedItem,
  onSelect,
  dropdownTitle,
}: ChainSelect) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      if (!ref.current?.contains(e.target as Node) && isOpen) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref, isOpen]);

  function openSelectList() {
    setIsOpen(!isOpen);
  }

  const handleSelect = (chain: ChainSelectItem) => () => {
    setIsOpen(false);
    if (chain.id !== selectedItem?.id) {
      onSelect?.(chain);
    }
  };

  return (
    <div className="relative w-full" ref={ref}>
      <Button
        size="s"
        variant={selectedItem ? 'secondary' : 'primary'}
        className="normal-case h-auto py-2 px-3"
        TrailIcon={ChevronDown}
        iconClassName="w-5 h-auto"
        onClick={openSelectList}
        data-testid="chain-select-btn"
        fullWidth
      >
        {triggerContent}
      </Button>

      <Container
        column
        fullWidth
        className={cls(
          'mt-3 py-4 absolute left-0 bg-base border-1 border-default shadow-primary rounded-lg z-10 min-w-[250px]',
          isOpen ? '' : 'hidden',
        )}
      >
        {dropdownTitle && (
          <Text type="title-4" color="secondary" className="px-4">
            {dropdownTitle}
          </Text>
        )}

        <Container column fullWidth className="mt-2" data-testid="chain-select-list">
          {data?.map((item) => (
            <Container
              key={item.id}
              onClick={handleSelect(item)}
              fullWidth
              alignItems="center"
              className="py-2 px-4 cursor-pointer hover:bg-widget"
              data-testid={`${item.title}-chain`}
            >
              <img
                src={item.icon}
                className="mr-3 w-[24px] rounded-full border border-white"
                alt={`${item.title} chain logo`}
              />
              <Text color="primary">{item.title}</Text>
            </Container>
          ))}
        </Container>
      </Container>
    </div>
  );
}
