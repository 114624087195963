import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QUERY_REFETCH_INTERVAL, useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { useUserStore } from '../../../user/useUserStore';
import { getInternalBalances } from '../services/InternalBalancesService';

export const useInternalBalancesQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { selectedAccount } = useUserStore();

  const address = selectedAccount?.address;

  const internalBalancesQuery = useQuery(
    ['internal-balances', address, finalisedTxCount],
    getInternalBalances(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!address && !!api,
      refetchInterval: QUERY_REFETCH_INTERVAL.internalBalance,
    },
  );

  return {
    internalBalancesQuery,
  };
};
