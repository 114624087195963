import { BN } from '@polkadot/util';
import { BN_ZERO, Mangata, fromBN } from '@mangata-finance/sdk';
import { getOutputAmount, getInputAmount } from './SwapAmountService';
import { AllPoolsQueryData } from '../../../pool/all/services/AllPoolsService';
import { RouteData, RouteDataError, SwapRoute } from '../../Swap';
import { Asset } from '../../../token';

const MAX_HOPS_LIMIT = 6;
const NO_ROUTE_DATA = { bestRoute: null, bestAmount: null };

export const routeExactIn = (
  pools: AllPoolsQueryData | null,
  tokenIn: Asset,
  tokenOut: Asset,
  amountIn: BN,
  isAutoRoutingEnabled: boolean,
): RouteData => {
  const noPools = { ...NO_ROUTE_DATA, error: RouteDataError.NoPools };
  if (!pools) {
    return noPools;
  }

  const explorePaths = (
    queue: SwapRoute[],
    visited: SwapRoute,
    result: RouteData | null,
  ): RouteData | null => {
    if (queue.length === 0) {
      return result;
    }

    const path = queue[0];
    const remainingQueue = queue.slice(1);
    const lastTokenInPath = path[path.length - 1];

    if (path.length > (isAutoRoutingEnabled ? MAX_HOPS_LIMIT : 2)) {
      return explorePaths(remainingQueue, visited, result);
    }

    if (lastTokenInPath.id === tokenOut.id) {
      let amountOut = amountIn;
      let priceImpact = 0;

      for (let i = 0; i < path.length - 1; i++) {
        const [tokenIn, tokenOut] = [path[i], path[i + 1]];
        const pool = pools.byId[`${tokenIn.id}-${tokenOut.id}`];

        if (!pool) {
          return null;
        }

        const previousAmountOut = amountOut;
        const outPutResult = getOutputAmount(
          pool.firstTokenAmount,
          pool.secondTokenAmount,
          amountOut,
        );
        amountOut = outPutResult.value;

        if (amountOut.eq(BN_ZERO)) {
          if (!result) {
            result = {
              ...NO_ROUTE_DATA,
              error: outPutResult.isAmountSufficient
                ? RouteDataError.InsufficientLiquidity
                : RouteDataError.InsufficientInputAmount,
            };
          }
        }

        const priceImpactStr = Mangata.getPriceImpact({
          poolReserves: [pool.firstTokenAmount, pool.secondTokenAmount],
          decimals: [pool.firstAsset.decimals, pool.secondAsset.decimals],
          tokenAmounts: [
            fromBN(previousAmountOut, pool.firstAsset.decimals),
            fromBN(amountOut, pool.secondAsset.decimals),
          ],
        });

        if (priceImpactStr) {
          priceImpact += parseFloat(priceImpactStr);
        }
      }

      if (!result || (result.bestAmount && amountOut.gt(result.bestAmount))) {
        result = { bestRoute: path, bestAmount: amountOut, priceImpact };
      }
    }

    const adjacentPools = pools.byAdjacentId?.[lastTokenInPath.id];

    if (!adjacentPools || Object.keys(adjacentPools).length === 0) {
      return explorePaths(remainingQueue, [...visited, lastTokenInPath], result);
    }

    const newPaths = adjacentPools
      .map((pool) => {
        const nextTokenId =
          pool.firstTokenId === lastTokenInPath.id ? pool.secondAsset : pool.firstAsset;
        if (!visited.includes(nextTokenId)) {
          return [...path, nextTokenId];
        }
        return null;
      })
      .filter(Boolean) as SwapRoute[];
    return explorePaths([...remainingQueue, ...newPaths], [...visited, lastTokenInPath], result);
  };

  const res = explorePaths([[tokenIn]], [tokenIn], null);

  if (!res) {
    return noPools;
  }

  return res;
};

export const routeExactOut = (
  pools: AllPoolsQueryData | null,
  tokenIn: Asset,
  tokenOut: Asset,
  amountOut: BN,
  isAutoRoutingEnabled: boolean,
): RouteData => {
  const noPools = { ...NO_ROUTE_DATA, error: RouteDataError.NoPools };
  if (!pools) {
    return noPools;
  }

  const explorePaths = (
    queue: SwapRoute[],
    visited: SwapRoute,
    result: RouteData | null,
  ): RouteData | null => {
    if (queue.length === 0) {
      return result;
    }

    const path = queue[0];
    const remainingQueue = queue.slice(1);
    const lastTokenInPath = path[path.length - 1];

    if (path.length > (isAutoRoutingEnabled ? MAX_HOPS_LIMIT : 2)) {
      return explorePaths(remainingQueue, visited, result);
    }

    if (lastTokenInPath.id === tokenOut.id) {
      let amountIn = amountOut;
      let priceImpact = 0;

      for (let i = path.length - 1; i > 0; i--) {
        const [tokenIn, tokenOut] = [path[i - 1], path[i]];
        const pool = pools.byId[`${tokenIn.id}-${tokenOut.id}`];
        if (!pool) {
          return null;
        }

        const previousAmountIn = amountIn;
        const inputResult = getInputAmount(pool.firstTokenAmount, pool.secondTokenAmount, amountIn);
        amountIn = inputResult.value;

        if (amountIn.eq(BN_ZERO)) {
          if (!result) {
            result = {
              ...NO_ROUTE_DATA,
              error: inputResult.isAmountSufficient
                ? RouteDataError.InsufficientLiquidity
                : RouteDataError.InsufficientInputAmount,
            };
          }
        }

        const priceImpactStr = Mangata.getPriceImpact({
          poolReserves: [pool.firstTokenAmount, pool.secondTokenAmount],
          decimals: [pool.firstAsset.decimals, pool.secondAsset.decimals],
          tokenAmounts: [
            fromBN(amountIn, pool.firstAsset.decimals),
            fromBN(previousAmountIn, pool.secondAsset.decimals),
          ],
        });

        if (priceImpactStr) {
          priceImpact += parseFloat(priceImpactStr);
        }
      }

      if (
        !result ||
        (amountIn.gt(BN_ZERO) && (!result.bestAmount || amountIn.lt(result.bestAmount)))
      ) {
        result = { bestRoute: path, bestAmount: amountIn, priceImpact };
      }
    }

    const adjacentPools = pools.byAdjacentId?.[lastTokenInPath.id];

    if (!adjacentPools || Object.keys(adjacentPools).length === 0) {
      return explorePaths(remainingQueue, [...visited, lastTokenInPath], result);
    }

    const newPaths = adjacentPools
      .map((pool) => {
        const nextTokenId =
          pool.firstTokenId === lastTokenInPath.id ? pool.secondAsset : pool.firstAsset;
        if (!visited.includes(nextTokenId)) {
          return [...path, nextTokenId];
        }
        return null;
      })
      .filter(Boolean) as SwapRoute[];

    return explorePaths([...remainingQueue, ...newPaths], [...visited, lastTokenInPath], result);
  };

  const res = explorePaths([[tokenIn]], [tokenIn], null);

  if (!res) {
    return noPools;
  }

  return res;
};
