import axios from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../../../services';
import { ChartHistoryInterval } from '../../../../../types';
import { EnvConfig } from '../../../../../envConfig';

export type SingleVolumeHistoryQueryKey = Readonly<
  [queryKey: string, symbol: QueryOptional<string>, days: number, interval: ChartHistoryInterval]
>;

export interface VolumeHistoryResult {
  volumes: Array<[number, string]>;
}

export const fetchSingleTokenVolumeHistory = async ({
  queryKey,
}: QueryFunctionContext<SingleVolumeHistoryQueryKey>): Promise<VolumeHistoryResult | null> => {
  const [, symbol, days, interval] = queryKey;

  const { data } = await axios.get(
    `${EnvConfig.STASH_URL}/volume-history/${symbol}?days=${days}&interval=${interval}`,
  );

  return data;
};
