import { ChainType } from '../../../../../stash';
import { IdLookupFn } from '../../Xcm';
import { xcmpSuccessValidator, messageQueueValidator } from '../../validators';

export const getDestinationIdentifiersForWithdrawal: IdLookupFn = async (
  api,
  _,
  channel,
  header,
) => {
  const apiAtHash = await api.at(header.hash);
  const eventsCodec = await apiAtHash.query.system.events();

  switch (channel.chainType) {
    case ChainType.Parachain:
      return xcmpSuccessValidator(api, eventsCodec);
    case ChainType.RelayChain:
      return messageQueueValidator(api, eventsCodec);
  }
};
