import { Token } from '@mangata-finance/sdk';
import { pick } from 'lodash-es';
import { AssetRegistryAssetMetadata, StashToken } from '../../../../services';
import { Asset, AssetOrigin, AssetType } from '../../Token';
import { TxAsset } from '../../../transaction';
import { EnvConfig } from '../../../../envConfig';
import { RollupToken } from '../../../rollup/stash/RollupStash';

export const transformToAsset = (
  asset: Token | AssetRegistryAssetMetadata | TxAsset | RollupToken | StashToken,
): Asset => ({
  ...pick(asset, ['name', 'symbol']),
  id: 'address' in asset ? asset.address : 'tokenId' in asset ? asset.tokenId : asset.id,
  decimals: parseInt(asset.decimals.toString()),
  origin: 'origin' in asset ? asset.origin : EnvConfig.isRollupEnv ? AssetOrigin.Native : undefined,
  type: !asset.symbol.includes('-') ? AssetType.Native : AssetType.LP,
});
