import { Decimal } from 'decimal.js';
import { Contract, Web3 } from 'web3';
import { rolldownAbi } from '../../../ContractAbis.json';
import { SDKProvider as MetamaskProvider } from '@metamask/sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional, TransactionStore, TxType, transformToAsset } from '../../../../../';
import { MetamaskTx } from '../../../../transaction/MetamaskTx';
import { RollupToken, RollupStashChain } from '../../../stash/RollupStash';
import { NATIVE_TOKEN_ADDRESS } from '../../../token/list/services/rollupTokensService';

export type RollupDepositQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<boolean>,
];

export interface RollupDepositMutationParams {
  userAddress: QueryOptional<string>;
  token: RollupToken;
  amount: string;
  gasAmount: QueryOptional<string>;
  chain: QueryOptional<RollupStashChain>;
}

const web3 = new Web3();

export const getRollupDepositFee =
  (rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>) =>
  async ({ queryKey }: QueryFunctionContext<RollupDepositQueryParams>) => {
    const [, tokenAddress, userAddress, amount, gasPrice] = queryKey;

    if (!tokenAddress || !amount || !userAddress || !gasPrice || !rolldownContract) {
      return null;
    }

    const isNativeToken = tokenAddress === NATIVE_TOKEN_ADDRESS;
    const amountInWei = web3.utils.toWei(amount, 'ether');

    const depositMethod = (() => {
      if (isNativeToken) {
        return rolldownContract.methods.deposit_eth();
      } else {
        return rolldownContract.methods.deposit(tokenAddress, amountInWei);
      }
    })();

    const gasAmount =
      (
        await depositMethod.estimateGas({
          from: userAddress,
          value: isNativeToken ? new Decimal(amountInWei).toHex() : undefined,
        })
      ).toString() || '0';
    const totalInWei = new Decimal(gasAmount).mul(gasPrice).toString();

    return {
      gasAmount,
      ethAmount: web3.utils.fromWei(totalInWei, 'ether'),
    };
  };

export const submitRollupDeposit =
  (
    transactionStore: TransactionStore,
    provider: QueryOptional<MetamaskProvider>,
    rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>,
    gasPrice: QueryOptional<string | null>,
  ) =>
  async ({ token, userAddress, amount, gasAmount, chain }: RollupDepositMutationParams) => {
    if (!provider || !gasPrice || !userAddress || !rolldownContract || !chain || !gasAmount) {
      return;
    }

    const amountDec = new Decimal(`${amount}e${token.decimals}`);
    const depositMethod = (() => {
      if (token.isNative) {
        return rolldownContract.methods.deposit_eth();
      } else {
        return rolldownContract.methods.deposit(token.address, amountDec.toFixed());
      }
    })();

    const txParams = {
      from: userAddress,
      to: rolldownContract.options.address,
      data: depositMethod.encodeABI(),
      gasPrice: new Decimal(gasPrice).toHex(),
      gas: new Decimal(gasAmount).toHex(),
      value: token.isNative ? amountDec.toHex() : undefined,
    };

    const tx = new MetamaskTx(provider, transactionStore)
      .create(TxType.RollupDeposit)
      .setMetadata({ tokens: [{ ...transformToAsset(token), amount }] })
      .setOptions({ showExplorerLink: false, doneOnTrigger: false, isVisible: false })
      .setTx(txParams, chain.explorerUrl)
      .build();

    const txHash = await tx.send();

    return !!txHash;
  };
