import { Button, Container, Text } from 'ui';
import successImg from '../../assets/success.png';
import { FormattedMessage } from 'react-intl';

interface DepositSuccessProps {
  onClose(): void;
  handleActivityTabNavigation?(): void;
}

export const DepositSuccess = ({ onClose }: DepositSuccessProps) => {
  return (
    <Container column alignItems="center" justifyContent="center" className="h-full">
      <img src={successImg} alt="success" className="w-20 h-20 mx-auto" />
      <Text type="title-1" id="bridge.deposit.success.title" className="mt-4 mb-2" />
      <Container className="text-center w-3/4" justifyContent="center">
        <Text color="secondary" id="bridge.deposit.success.desc" values={{ br: <br /> }} />
      </Container>
      <Button className="mt-8" onClick={onClose}>
        <FormattedMessage id="bridge.deposit.success.cta" />
      </Button>
    </Container>
  );
};
