import Decimal from 'decimal.js';
import { QueryOptional } from '../../../services';
import { useRollupTokenAllowanceQuery } from './allowance/query/useRollupTokenAllowanceQuery';
import { useRollupApproveFeeQuery } from './approve/query/useRollupApproveFeeQuery';
import { NATIVE_TOKEN_ADDRESS } from './list/services/rollupTokensService';

export const useRollupTokenAllowance = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
  amount: QueryOptional<string>,
) => {
  const { rollupTokenAllowanceQuery } = useRollupTokenAllowanceQuery(
    chainId,
    userAddress,
    tokenAddress,
  );
  const { rollupApproveFeeQuery } = useRollupApproveFeeQuery(
    chainId,
    userAddress,
    tokenAddress,
    amount,
  );

  const isNativeToken = tokenAddress === NATIVE_TOKEN_ADDRESS;
  const allowedAmount = new Decimal(rollupTokenAllowanceQuery.data || 0);
  const isAllowanceSufficient = allowedAmount.gte(amount || 0) || isNativeToken;

  return {
    rollupTokenAllowanceQuery,
    rollupApproveFeeQuery,
    isAllowanceSufficient,
    allowedAmount,
  };
};
