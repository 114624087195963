import cls from 'classnames';
import Decimal from 'decimal.js';
import { first, last } from 'lodash-es';
import { forwardRef, useState } from 'react';
import {
  ChartDropdown,
  Container,
  LineChart,
  Timeframe,
  TimeframeSelector,
  timeframes,
  Text,
  Trend,
  formatAmount,
} from 'ui';
import { useTokenPriceHistoryQuery, ChartHistoryInterval, ClassName } from 'core';
import { useSwapStore } from '../store/useSwapStore';

const getDeltaPerc = (firstAmount: string | null, secondAmount: string | null) => {
  if (!firstAmount || !secondAmount) {
    return null;
  }

  const firstDec = new Decimal(firstAmount);
  const secondDec = new Decimal(secondAmount);

  const delta = secondDec.minus(firstDec);
  const average = firstDec.plus(secondDec).dividedBy(2);

  return delta.dividedBy(average).times(100);
};

const extraPairToken = 'USDT';

type SwapChartProps = ClassName;

export const SwapChart = forwardRef<HTMLDivElement, SwapChartProps>(({ className }, ref) => {
  const { firstToken, secondToken } = useSwapStore();
  const [currentTimeframe, setTimeframe] = useState<Timeframe>(timeframes[1]);
  const [activePairIndex, setActivePairIndex] = useState(0);

  const basePairOptions = [
    [firstToken?.symbol, secondToken?.symbol],
    [secondToken?.symbol, firstToken?.symbol],
  ];
  const pairOptions = [firstToken?.symbol, secondToken?.symbol].includes(extraPairToken)
    ? basePairOptions
    : [
        ...basePairOptions,
        [firstToken?.symbol, extraPairToken],
        [secondToken?.symbol, extraPairToken],
      ];

  const activePair = pairOptions[activePairIndex] || pairOptions[0];
  const areTokensSelected = firstToken && secondToken;

  const { pairPriceHistoryQuery } = useTokenPriceHistoryQuery(activePair[0], activePair[1], {
    days: currentTimeframe.days,
    interval: currentTimeframe.interval,
  });

  const { pairPriceHistoryQuery: latestPriceQuery } = useTokenPriceHistoryQuery(
    activePair[0],
    activePair[1],
    {
      days: 1,
      interval: ChartHistoryInterval.Day,
    },
  );

  const latestPrice =
    last(latestPriceQuery.data?.prices)?.[1] ||
    last(pairPriceHistoryQuery.data?.prices)?.[1] ||
    null;
  const firstHistoryPrice = first(pairPriceHistoryQuery.data?.prices)?.[1] || null;
  const deltaPerc = getDeltaPerc(firstHistoryPrice, latestPrice);

  const data = pairPriceHistoryQuery.data?.prices.map(([timestamp, volume]) => ({
    x: new Date(timestamp),
    y: volume,
  }));

  const onPairSelect = (i: number) => setActivePairIndex(i);

  const formatYAxis = (value: string) =>
    formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '';
  const formatTooltipYValue = (value: string) =>
    `${formatAmount(value, { nonZeroPrecision: true, precision: 2 }) || '0'} ${activePair[1]}`;

  return (
    <Container
      className={cls('mt-10 w-full h-[360px] overflow-visible', className)}
      column
      ref={ref}
    >
      {areTokensSelected && (
        <Container column fullWidth>
          <Container fullWidth justifyContent="space-between" alignItems="center">
            <ChartDropdown
              onSelect={onPairSelect}
              data={pairOptions.map((pair) => pair.join(' / '))}
              titleId="swap.pair.dropdown.title"
              activeIndex={activePairIndex > (pairOptions?.length || 0) ? 0 : activePairIndex}
            />
            <TimeframeSelector onSelect={setTimeframe} currentTimeframe={currentTimeframe} />
          </Container>
          {latestPrice && (
            <Container className="mt-6 ml-4" alignItems="center">
              <Text type="title-2" color="secondary" className="mr-1">
                1 {activePair[0]} =
              </Text>
              <Text type="title-2" className="mr-2">
                {formatAmount(latestPrice, { precision: 3, nonZeroPrecision: true })}{' '}
                {activePair[1]}
              </Text>
              {deltaPerc && <Trend value={deltaPerc} withBackground />}
            </Container>
          )}
        </Container>
      )}
      <LineChart
        className="pt-6"
        data={data}
        timeframe={currentTimeframe}
        id={`swap-${currentTimeframe.key}`}
        formatYAxis={formatYAxis}
        formatTooltipYValue={formatTooltipYValue}
        isEmpty={
          !areTokensSelected || !pairPriceHistoryQuery.isLoading || pairPriceHistoryQuery.isError
        }
      />
    </Container>
  );
});
