import { BN } from '@polkadot/util';
import { useSessionQuery } from '../../session/query/useSessionQuery';
import { use3rdPartyRewardsSchedulesQuery } from './query/use3rdPartyRewardsSchedulesQuery';

export const use3rdPartyTotalPendingSchedules = () => {
  const { thirdPartyRewardsSchedulesQuery } = use3rdPartyRewardsSchedulesQuery();
  const {
    currentSession: { data: sessionId },
  } = useSessionQuery();

  const totalPendingSchedules = thirdPartyRewardsSchedulesQuery.data?.reduce((acc, schedule) => {
    if (!schedule || !sessionId || !schedule.lastSession.gt(sessionId)) {
      return acc;
    }

    const liqTokenId = schedule.liqToken.toString();
    const rewardTokenId = schedule.rewardToken.toString();
    const remainingSessionCount = schedule.lastSession.sub(sessionId);
    const amountToAdd = schedule.amountPerSession.mul(remainingSessionCount);

    if (!acc.has(liqTokenId)) {
      acc.set(liqTokenId, new Map<string, BN>());
    }

    const rewardTokenMap = acc.get(liqTokenId);

    if (rewardTokenMap?.has(rewardTokenId)) {
      const currentAmount = rewardTokenMap.get(rewardTokenId);

      if (currentAmount) {
        rewardTokenMap.set(rewardTokenId, currentAmount.add(amountToAdd));
      }
    } else if (rewardTokenMap) {
      rewardTokenMap.set(rewardTokenId, amountToAdd);
    }

    return acc;
  }, new Map<string, Map<string, BN>>());

  return { totalPendingSchedules };
};
