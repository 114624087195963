import { Codec } from '@polkadot/types/types';
import { ApiPromise } from '@polkadot/api';
import { z } from 'zod';
import { findEvents } from '../block/event';
import { XcmpQueueDataSchema } from '../schema';
import { XcmEvent } from '../Xcm';

export function xcmpSuccessValidator(api: ApiPromise, eventsCodec: Codec) {
  const events = findEvents<z.infer<typeof XcmpQueueDataSchema>>(
    eventsCodec,
    api,
    XcmpQueueDataSchema,
    XcmEvent.XcmpSuccess,
  );

  const hashes = events.map(({ event: { data } }) => data.messageHash);

  return hashes;
}
