import { PalletProofOfStakeRewardInfo } from '@polkadot/types/lookup';
import { QueryServiceReturnType } from '../../../../services';
import { getNativeRewardsInfo } from '../service/nativeRewardsInfoService';

export const transformNativeRewardsInfo = (
  _data: QueryServiceReturnType<typeof getNativeRewardsInfo>,
) => {
  if (!_data) {
    return null;
  }

  const rewardsInfo: [string, PalletProofOfStakeRewardInfo][] = _data.map(([storageKey, data]) => {
    const key = storageKey.toHuman();
    const id = Array.isArray(key) ? key[1]?.toString() : null;

    return [id || storageKey.toString(), data];
  });

  return new Map(rewardsInfo);
};
