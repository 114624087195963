import { isEmpty } from 'lodash-es';
import { Decimal } from 'decimal.js';
import { Container, Skeleton } from 'ui';
import { useAllPoolsQuery, usePoolTvlQueries, usePromotedPools } from 'core';
import { PoolListItem } from './item/PoolListItem';
import { CreatePoolBanner } from '../all/banner/CreatePoolBanner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { usePoolsUIStore } from '../store/usePoolsUIStore';
import { PoolsSearchListEmpty } from './empty/PoolSearchListEmpty';
import { useMemo } from 'react';

export interface PoolsListProps {
  onlyPromoted?: boolean;
  className?: string;
  searchQuery?: string;
}

const splitQuery = (query: string) => {
  return query.replace(/ \/ |\/| - |-| /g, ',').split(',');
};

const isPoolFound = (query: string | null) => (pool: PoolWithRatio) => {
  if (!query) return true;

  const searchPlaces = [
    pool.firstAsset.symbol,
    pool.firstAsset.name,
    pool.secondAsset.symbol,
    pool.secondAsset.name,
  ];

  const queryParts = splitQuery(query).filter((q) => !isEmpty(q));

  return queryParts.every((q) =>
    searchPlaces.some((place) => place.toLowerCase().includes(q.toLowerCase())),
  );
};

export function PoolsList({ onlyPromoted }: PoolsListProps) {
  const { poolAprsByLPId, promotedPools, isLoading } = usePromotedPools();
  const { allPoolsQuery } = useAllPoolsQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');
  const { isSearchOpen, setIsSearchOpen } = usePoolsUIStore();

  const tvl = usePoolTvlQueries();

  const sortedPools = useMemo(() => {
    if (!promotedPools || !allPoolsQuery.data?.baseList || isLoading || !tvl) {
      return null;
    }
    const promoted = promotedPools.sort(sortPromotedPoolsByApr(poolAprsByLPId));
    const all = allPoolsQuery.data?.baseList.sort(sortPoolsByTvl(tvl));

    return {
      promoted,
      all,
    };
  }, [promotedPools, allPoolsQuery.data?.baseList, isLoading, tvl, poolAprsByLPId]);

  const list = onlyPromoted && !searchQuery ? sortedPools?.promoted : sortedPools?.all;
  const filteredList = list?.filter(isPoolFound(searchQuery));

  const ListSkeleton = new Array(9)
    .fill(true)
    .map((_, i) => <Skeleton key={i} className="w-[232px] h-[116px] !rounded" />);

  const isFilteredListEmpty = allPoolsQuery.isSuccess && !filteredList?.length && searchQuery;

  const handleCreatePoolClick = () => {
    navigate('/pools/new');
    setIsSearchOpen(false);
  };

  return (
    <>
      {!onlyPromoted && !searchQuery && !isSearchOpen && <CreatePoolBanner className="mt-10" />}
      <Container justifyContent="center" column className="grid grid-cols-2 gap-4 mt-10 pb-6">
        {(!isLoading &&
          filteredList?.map((pool) => (
            <PoolListItem
              key={pool.id}
              pool={pool}
              apr={poolAprsByLPId[pool.liquidityTokenId]}
              data-testid={`pool-item-${pool.firstAsset.symbol}-${pool.secondAsset.symbol}`}
            />
          ))) ||
          ListSkeleton}
      </Container>
      {isFilteredListEmpty && <PoolsSearchListEmpty onCreatePoolClick={handleCreatePoolClick} />}
    </>
  );
}

const sortPromotedPoolsByApr =
  (poolAprById: Record<string, string | null>) => (a: PoolWithRatio, b: PoolWithRatio) => {
    const aApr = poolAprById[a.liquidityTokenId];
    const bApr = poolAprById[b.liquidityTokenId];

    if (!aApr && !bApr) return 0;
    if (!aApr) return 1;
    if (!bApr) return -1;

    return Number(bApr) - Number(aApr);
  };

const sortPoolsByTvl = (tvl: Map<string, string>) => (a: PoolWithRatio, b: PoolWithRatio) => {
  const aTvl = tvl.get(a.liquidityTokenId);
  const bTvl = tvl.get(b.liquidityTokenId);

  if (!aTvl && !bTvl) return 0;
  if (!aTvl) return 1;
  if (!bTvl) return -1;

  const aTvlDecimal = new Decimal(aTvl);
  const bTvlDecimal = new Decimal(bTvl);

  return bTvlDecimal.cmp(aTvlDecimal);
};
