import { Codec } from '@polkadot/types/types';
import { StorageKey } from '@polkadot/types';
import { u32 } from '@polkadot/types/primitive';
import { PalletProofOfStakeRewardInfo } from '@polkadot/types/lookup';
import { QueryOptional, QueryServiceReturnType } from '../../../../services';
import { getAll3rdPartyRewardsInfo } from '../services/3rdPartyRewardsService';

export const transform3rdPartyTokens = (_data: [StorageKey<[u32, u32]>, Codec][] | null) => {
  if (!_data) {
    return null;
  }

  const rewardTokensPerPool = new Map<string, string[]>();

  _data.forEach(([storageKey]) => {
    const {
      args: [_liquidityTokenId, _rewardTokenId],
    } = storageKey;
    const liquidityTokenId = _liquidityTokenId.toString();
    const rewardTokenId = _rewardTokenId.toString();

    if (!liquidityTokenId || !rewardTokenId) {
      return;
    }

    if (!rewardTokensPerPool.has(liquidityTokenId)) {
      rewardTokensPerPool.set(liquidityTokenId, [rewardTokenId]);
    } else {
      const currentRewardTokenIds = rewardTokensPerPool.get(liquidityTokenId);

      if (currentRewardTokenIds) {
        rewardTokensPerPool.set(liquidityTokenId, [...currentRewardTokenIds, rewardTokenId]);
      }
    }
  });

  return rewardTokensPerPool;
};

export const transformAll3rdPartyRewardsInfo =
  (address: QueryOptional<string>) =>
  (
    _data: QueryServiceReturnType<typeof getAll3rdPartyRewardsInfo>,
  ): Map<string, Map<string, PalletProofOfStakeRewardInfo>> | null => {
    if (!_data || !address) {
      return null;
    }

    const rewardTokensPerPool = new Map();

    _data.forEach(([storageKey, data]) => {
      const {
        args: [_itemAddress, [_liquidityTokenId, _rewardTokenId]],
      } = storageKey;
      const liquidityTokenId = _liquidityTokenId.toString();
      const rewardTokenId = _rewardTokenId.toString();

      if (!liquidityTokenId || !rewardTokenId || address !== _itemAddress.toString()) {
        return;
      }

      if (!rewardTokensPerPool.has(liquidityTokenId)) {
        rewardTokensPerPool.set(liquidityTokenId, new Map());
      }

      const rewardsMap = rewardTokensPerPool.get(liquidityTokenId);
      rewardsMap.set(rewardTokenId, data);
    });

    return rewardTokensPerPool;
  };
