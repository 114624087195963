import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum AddStakeUIStoreFormError {
  InsufficientFee = 'InsufficientFee',
  InsufficientFunds = 'InsufficientFunds',
}

export interface AddStakeUIStore {
  amount: string;
  formError: Partial<Record<AddStakeUIStoreFormError, boolean>>;
  setFormError: (value: Partial<Record<AddStakeUIStoreFormError, boolean>>) => void;
  setAmount: (value: string) => void;
  dispose: () => void;
}

export const INITIAL_STATE = {
  amount: '',
  formError: {},
};

export const useAddStakeStore = create(
  devtools<AddStakeUIStore>((set, get) => ({
    ...INITIAL_STATE,
    setAmount: (amount) => set({ amount }),
    setFormError: (formError) => set({ formError }),
    dispose: () => set(INITIAL_STATE),
  })),
);
