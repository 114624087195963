import { ApiPromise } from '@polkadot/api';
import { ChainType, StashChannel } from '../../../../stash';
import { XcmDestination } from '../../types';
import { WalletAccount } from '../../../../injected-web3';

export function buildWithdrawalDestination(
  account: WalletAccount,
  channel: StashChannel,
  api: ApiPromise,
): XcmDestination {
  switch (channel.chainType) {
    case ChainType.RelayChain:
      return {
        V3: {
          parents: 1,
          interior: {
            X1: {
              AccountId32: {
                id: api.createType('AccountId32', account.address).toHex(),
              },
            },
          },
        },
      };
    default:
      return {
        V3: {
          parents: 1,
          interior: {
            X2: [
              {
                Parachain: channel.channelId,
              },
              account.type === 'ethereum'
                ? {
                    AccountKey20: {
                      key: api.createType('AccountId20', account.address).toHex(),
                    },
                  }
                : {
                    AccountId32: {
                      id: api.createType('AccountId32', account.address).toHex(),
                    },
                  },
            ],
          },
        },
      };
  }
}
