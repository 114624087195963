import { Container, Text } from 'ui';
import { ReactComponent as NoResultImage } from 'images/search-clouds.svg';

export const PositionListNoResults = () => {
  return (
    <Container column alignItems="center" justifyContent="center" className="w-full pt-[120px]">
      <NoResultImage className="w-[64px] h-auto " />
      <Text type="title-2" id="position.page.search.empty.title" className="mt-5" />
      <Text
        color="secondary"
        id="position.page.search.empty.desc"
        className="mt-2 whitespace-pre-line text-center w-[280px]"
      />
    </Container>
  );
};
