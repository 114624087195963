import { QueryOptional } from '../../../services';
import { useActivateLiqudityFor3rdPartyRewardsFeeQuery } from './query/useActivateLiquidityFor3rdPartyRewardsFeeQuery';
import { useActivateLiqudityFor3rdPartyRewardsMutation } from './query/useActivateLiquidityFor3rdPartyRewardsMutation';

export const useActivateLiquidityFor3rdPartyRewards = ({
  liquidityTokenId,
  rewardTokenId,
}: {
  liquidityTokenId: QueryOptional<string>;
  rewardTokenId: QueryOptional<string>;
}) => {
  const { activateLiqFor3rdPartyRewardsMutation } = useActivateLiqudityFor3rdPartyRewardsMutation();
  const { activateLiquidityFor3rdPartyRewardsFeeQuery } =
    useActivateLiqudityFor3rdPartyRewardsFeeQuery(liquidityTokenId, rewardTokenId);

  return { activateLiqFor3rdPartyRewardsMutation, activateLiquidityFor3rdPartyRewardsFeeQuery };
};
