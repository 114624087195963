import { useQuery } from '@tanstack/react-query';

import { fetchAccounts } from '../services/accountService';
import { useWallet } from '../../wallet/useWallet';
import { useUserStore } from '../../user/useUserStore';
import { WalletAccount } from '../../../services';

export const useAccountsQuery = () => {
  const { selectedWallet: walletName } = useUserStore();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();

  const accountsQuery = useQuery<WalletAccount[] | null, string, WalletAccount[]>(
    ['accounts', walletName, wallet?.accounts],
    fetchAccounts(wallet),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!wallet && walletName !== null,
      retryOnMount: false,
      retry: false,
    },
  );

  return accountsQuery;
};
