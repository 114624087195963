import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';

export type GetReserveBalancesQueryKey = Readonly<
  [queryKey: string, address: string | undefined, txCount: number]
>;

export const getReserveBalances =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<GetReserveBalancesQueryKey>) => {
    const [, address] = queryKey;

    if (!api || !address) {
      return null;
    }

    return api.query.multiPurposeLiquidity.reserveStatus.entries(address);
  };
