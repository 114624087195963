import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import {
  GetChannelBalanceQueryKey,
  getChannelTokenBalance,
} from './services/externalBalanceService';
import { StashChannel } from '../../../services';
import { zipObject } from 'lodash-es';
import {
  ChannelTokenBalanceResult,
  transformBalanceResponse,
} from './transformers/externalBalanceTransformer';
import { useStashTokens } from '../../token';
import { useChannelApi } from '../../../services/api';
import { useTransaction } from '../../transaction';

type ChannelBalanceQueryOptions = UseQueryOptions<
  ChannelTokenBalanceResult | null | undefined,
  unknown,
  string | null | undefined,
  GetChannelBalanceQueryKey
>;

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useExternalBalances = (
  channelId: StashChannel['channelId'] | null,
  address: string | null,
) => {
  const { stashTokensQuery } = useStashTokens();
  const { api, isApiReady } = useChannelApi(channelId);
  const { finalisedTxCount } = useTransaction();

  const allTokens = stashTokensQuery.data?.allTokens;
  const channelTokens = allTokens?.filter((token) => token.channelId === channelId);

  const queries: ChannelBalanceQueryOptions[] = channelTokens
    ? channelTokens.map((token) => ({
        queryKey: ['channelBalances', token.tokenId, channelId, address, finalisedTxCount],
        queryFn: getChannelTokenBalance(api, token.balancePath, token.destinationTokenId),
        select: transformBalanceResponse(token),
        enabled: isApiReady && !!api && !!address,
        ...queryOptions,
      }))
    : [];

  const balanceQueries = useQueries({ queries });
  const tokenIds = channelTokens ? channelTokens.map((token) => token.tokenId) : [];
  const channelTokenKeys =
    channelTokens?.map((token) => `${token.channelId}:${token.tokenId}`) ?? [];

  return {
    channelTokens,
    balanceQueriesById: zipObject(tokenIds, balanceQueries),
    allIds: tokenIds,
    balanceByChannelTokenKey: zipObject(channelTokenKeys, balanceQueries),
  };
};
