import { Container, Text } from 'ui';
import { ReactComponent as BoltIcon } from 'icons/round-bolt.svg';
import { useNavigate } from 'react-router';

export const NativeTokenWidgetDiscoverMining = () => {
  const navigate = useNavigate();

  const goToPools = () => {
    navigate('/pools');
  };

  return (
    <Container
      onClick={goToPools}
      fullWidth
      alignItems="center"
      style={{
        backgroundImage: `linear-gradient(to right, rgb(var(--color-surface-premium)), rgb(var(--color-surface-premium))`,
      }}
      className="rounded-lg px-5 py-4 bg-no-repeat cursor-pointer bg-cover bg-right-top"
    >
      <Container>
        <BoltIcon className="w-8 h-auto fill-icon-inverted" />
      </Container>
      <Container className="ml-5 pr-2">
        <Text type="title-4" color="inverted" id="nativeTokenWidget.discoverMining.desc" />
      </Container>
    </Container>
  );
};
