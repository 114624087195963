import { useDelegatorQuery, useDelegatorStateQuery } from './query/useDelegatorQuery';

export const useDelegators = () => {
  const { delegatorMapQuery } = useDelegatorQuery();
  const { delegatorStateQuery, delegatorStateRequests } = useDelegatorStateQuery();

  return {
    delegatorMapQuery,
    delegatorStateQuery,
    delegatorStateRequests,
  };
};
