import { useQuery } from '@tanstack/react-query';
import { QueryOptional, DEFAULT_QUERY_OPTIONS } from '../../../../../services';
import { isAmountGtZero } from '../../../../../validation';
import { useRolldownContract } from '../../useRolldownContract';
import { getRollupDepositFee } from '../services/rollupDepositService';
import { useRollupGasPriceQuery } from '../../../gas/query/useRollupGasPriceQuery';

export const useRollupDepositFeeQuery = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
  amount: QueryOptional<string>,
  isAllowanceSufficient: boolean,
) => {
  const rolldown = useRolldownContract(chainId);
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);

  const rollupDepositFeeQuery = useQuery(
    [
      'rollup-deposit-fee',
      tokenAddress,
      userAddress,
      amount,
      rollupGasPriceQuery.data,
      isAllowanceSufficient,
    ],
    getRollupDepositFee(rolldown.contract),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled:
        !!tokenAddress &&
        !!userAddress &&
        isAmountGtZero(amount) &&
        isAllowanceSufficient &&
        !!rolldown.contract,
    },
  );

  return {
    rollupDepositFeeQuery,
  };
};
