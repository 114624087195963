import cls from 'classnames';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useAddLiqudity,
  useDebounce,
  useSafeTransaction,
  TxType,
  useUserStore,
  useFilteredInternalAssets,
  useAllPoolsQuery,
  usePoolFromPath,
  useMetamaskNetwork,
} from 'core';
import { Button, TokenInput, Container, Text } from 'ui';
import { useProvideLiquidityStore } from './store/useProvideLiquidityStore';
import { useProvideLiquidityInputs, PoolInput } from './useProvideLiquidityInputs';
import { getSubmitMessage, useProvideLiquidityValidation } from './useProvideLiquidityValidation';
import { ProvideLiquidityDetails } from './details/ProvideLiquidityDetails';
import { PoolAlreadyExistBanner } from './banner/PoolAlreadyExistBanner';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import {
  ProvideLiquidityFormError,
  ProvideLiquidityWidgetProps,
  ProvideLiquidityWidgetType,
} from './ProvideLiquidity';
import { useWalletUIStore } from '../../Wallet/store/useWalletUIStore';
import { useTx } from '../../Tx';

export const ProvideLiquidityWidget = ({
  type,
  title,
  className,
  onCancel,
  onSuccess,
  'data-testid': testId,
}: ProvideLiquidityWidgetProps) => {
  const store = useProvideLiquidityStore();
  const { firstAmount, secondAmount, firstAsset, secondAsset, formError, dispose } = store;
  const { getFreeBalance, tokenListFreeBalances, filteredAssets } = useFilteredInternalAssets();
  const { selectedAccount } = useUserStore();
  const { allPoolsQuery } = useAllPoolsQuery();
  const { toggleSelectWallet } = useWalletUIStore();
  const { pool: poolFromPath } = usePoolFromPath();
  const { isChainSwitchRequired } = useMetamaskNetwork();

  const { isAllowed } = useTx();

  const pool = poolFromPath || allPoolsQuery.data?.byId[`${firstAsset?.id}-${secondAsset?.id}`];
  const selectedFirstToken = pool?.firstAsset || firstAsset;
  const selectedSecondToken = pool?.secondAsset || secondAsset;

  const isCreateType = type === ProvideLiquidityWidgetType.Create;
  const pendingNewPool = isCreateType &&
    !pool &&
    firstAsset &&
    secondAsset && { firstAsset, secondAsset };

  const {
    handleAmountChange,
    handleTokenChange,
    firstTxAsset,
    secondTxAsset,
    getExpectedSharePerc,
  } = useProvideLiquidityInputs(pool);

  const { addLiquidityFeeQuery, addLiquidityMutation } = useAddLiqudity({
    firstTxAsset,
    secondTxAsset,
    selectedAddress: selectedAccount?.address || null,
  });
  const feeData = useDebounce(addLiquidityFeeQuery.data, 10);
  const { isDisabled, areInputsFilled } = useProvideLiquidityValidation(
    pool || pendingNewPool || null,
    feeData,
  );

  const isTxAllowed =
    isAllowed(TxType.RemoveLiquidity) &&
    (type === ProvideLiquidityWidgetType.Create
      ? isAllowed(TxType.CreatePool)
      : isAllowed(TxType.AddLiquidity)) &&
    isAllowed(TxType.DeactivateLP) &&
    isAllowed(TxType.ActivateLP);

  const submitAddLiquidity = () => {
    if (!firstTxAsset || !secondTxAsset || !selectedAccount) {
      return;
    }

    addLiquidityMutation.mutate({
      firstToken: firstTxAsset,
      secondToken: secondTxAsset,
      selectedAccount,
      secondAssetBalance: getFreeBalance(secondTxAsset),
      firstAssetBalance: getFreeBalance(firstTxAsset),
      onDone: () => {
        store.dispose();
        if (pool) {
          onSuccess?.(pool);
        }
      },
    });
  };

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: firstTxAsset && secondTxAsset ? [firstTxAsset, secondTxAsset] : [],
      adaptiveFee: feeData,
      type: TxType.AddLiquidity,
    },
    submitAddLiquidity,
  );

  useEffect(() => dispose, [dispose]);

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      className={cls(className)}
      data-testid={testId}
      fullWidth
    >
      <Container
        fullWidth
        className="transition-all bg-widget p-6 rounded-md"
        alignItems="start"
        justifyContent="start"
        column
      >
        <Container alignItems="center" fullWidth justifyContent="space-between">
          <Text type="title-1" id={pool ? 'pool.provide.details.title' : title} />
          <Button
            variant="secondary"
            TrailIcon={CloseIcon}
            onClick={onCancel}
            className="py-0"
            data-testid="cancel-button"
          >
            <FormattedMessage id="pool.provide.details.cancel" />
          </Button>
        </Container>
        <Container className="mt-6" fullWidth column>
          <TokenInput
            tokens={isCreateType ? filteredAssets : undefined}
            onTokenSelect={isCreateType ? handleTokenChange(PoolInput.First) : undefined}
            isSelectable={isCreateType}
            amount={firstAmount}
            balances={tokenListFreeBalances}
            selectedToken={selectedFirstToken || null}
            freeBalance={getFreeBalance(selectedFirstToken)}
            isInvalid={!!formError[ProvideLiquidityFormError.InsufficientFirstAssetFunds]}
            onAmountChange={handleAmountChange(PoolInput.First)}
            data-testid="firstToken"
            className="rounded-br-none rounded-bl-none"
          />
          <Container className="h-[0px] w-full relative z-20">
            <Container
              className="absolute inset-0 w-[24px] h-[24px] rounded-full m-[auto] z-20 bg-widget"
              justifyContent="center"
              alignItems="center"
            >
              <PlusIcon className="fill-icon-secondary w-[20px] h-auto" />
            </Container>
          </Container>
          <TokenInput
            tokens={isCreateType ? filteredAssets : undefined}
            onTokenSelect={isCreateType ? handleTokenChange(PoolInput.Second) : undefined}
            isSelectable={isCreateType}
            amount={secondAmount}
            balances={tokenListFreeBalances}
            isInvalid={!!formError[ProvideLiquidityFormError.InsufficientSecondAssetFunds]}
            selectedToken={selectedSecondToken || null}
            onAmountChange={handleAmountChange(PoolInput.Second)}
            freeBalance={getFreeBalance(selectedSecondToken)}
            data-testid="secondToken"
            className="rounded-tl-none rounded-tr-none"
          />
        </Container>
        {isCreateType && !!pool && !areInputsFilled && <PoolAlreadyExistBanner />}
        {areInputsFilled && (
          <ProvideLiquidityDetails
            expectedShare={getExpectedSharePerc()}
            isFeeLoading={addLiquidityFeeQuery.isFetching}
            firstAmount={firstAmount}
            feeData={feeData?.current || null}
            pool={pool || null}
          />
        )}
        <Container fullWidth className={cls(pool?.isPromoted ? 'mt-6' : 'mt-7')}>
          <Button
            variant="primary"
            size="l"
            fullWidth
            onClick={selectedAccount ? submitTxWithChecks : toggleSelectWallet}
            isDisabled={isDisabled || !isTxAllowed || addLiquidityFeeQuery.isLoading}
            data-testid="submit-button"
          >
            <FormattedMessage
              id={getSubmitMessage(store, !!selectedAccount, !!pool, isChainSwitchRequired)}
            />
          </Button>
        </Container>
      </Container>
    </Container>
  );
};
