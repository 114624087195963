import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { activateLiquidityFor3rdPartyRewards } from '../services/activateLiqFor3rdPartyRewardsService';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useWallet } from '../../../wallet';

export const useActivateLiqudityFor3rdPartyRewardsMutation = () => {
  const { selectedAccount } = useAccounts();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const activateLiqFor3rdPartyRewardsMutation = useMutation(
    ['activate-liquidity-for-3rd-party-rewards'],
    activateLiquidityFor3rdPartyRewards(api, selectedAccount?.address, wallet, transactionStore),
  );

  return { activateLiqFor3rdPartyRewardsMutation };
};
