import { Container, Text, FormatAmount, Button } from 'ui';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { Asset } from 'core';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { StakingCardProps } from './StakingCard';
import { FormattedMessage } from 'react-intl';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { isUndefined } from 'lodash-es';
import { StakingCardStakeChangeChip } from './StakingCardStakeChangeChip';
import { u32 } from '@polkadot/types';

interface StakingCardMyStakeProps extends Pick<StakingCardProps, 'delegation'> {
  toggleBondLess: () => void;
  toggleBondMore: () => void;
  lpAsset: Asset;
  request?: ParachainStakingDelegationRequest;
  session: u32;
}

export function StakingCardMyStake({
  toggleBondLess,
  toggleBondMore,
  lpAsset,
  delegation,
  request,
  session,
}: StakingCardMyStakeProps) {
  const isStakeChangeReady = request?.whenExecutable.lte(session);

  return (
    <Container fullWidth className="px-6" alignItems="center" justifyContent="space-between">
      <Container column>
        <Text uppercase weight="bold" id="stakingCard.myStake" />
        <Container className="mt-2">
          <Text className="mr-1">
            <FormatAmount
              value={fromBN(delegation.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
              options={{ precision: 3, nonZeroPrecision: true }}
            />
          </Text>
          <Text color="secondary">{lpAsset.symbol}</Text>
        </Container>
      </Container>

      {isUndefined(request) ? (
        <Container>
          <Button size="s" variant="outlined" onClick={toggleBondLess}>
            <FormattedMessage id="common.remove" />
          </Button>
          <Button
            size="s"
            variant="outlined"
            LeadIcon={PlusIcon}
            iconClassName="fill-icon-neutral w-[20px] h-auto mr-1"
            className="ml-2"
            onClick={toggleBondMore}
          >
            <FormattedMessage id="common.add" />
          </Button>
        </Container>
      ) : (
        !isUndefined(isStakeChangeReady) && (
          <StakingCardStakeChangeChip isReady={isStakeChangeReady} />
        )
      )}
    </Container>
  );
}
