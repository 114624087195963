import { BN } from '@polkadot/util';
import { QueryOptional } from '../../../../services';
import { useScheduleBondLessFeeQuery } from './query/useScheduleDelegatorBondLessFeeQuery';
import { useScheduleBondLessMutation } from './query/useScheduleDelegatorBondLessMutation';

export const useScheduleDelegatorBondLess = (
  collatorAddress: QueryOptional<string>,
  amount: QueryOptional<BN>,
) => {
  const { scheduleBondLessMutation } = useScheduleBondLessMutation();
  const { scheduleBondLessFeeQuery } = useScheduleBondLessFeeQuery(collatorAddress, amount);

  return {
    scheduleBondLessMutation,
    scheduleBondLessFeeQuery,
  };
};
