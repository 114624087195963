import { useMutation } from '@tanstack/react-query';
import { submitRollupWithdrawal } from '../services/rollupWithdrawalService';
import { DEFAULT_QUERY_OPTIONS, useApi, useTransactionStore, useWallet } from '../../../../..';

export const useRollupWithdrawalMutation = () => {
  const api = useApi();
  const transactionStore = useTransactionStore();
  const {
    selectedWalletQuery: { data: wallet },
  } = useWallet();

  const rollupWithdrawalMutation = useMutation(
    submitRollupWithdrawal(api, transactionStore, wallet),
    {
      ...DEFAULT_QUERY_OPTIONS,
    },
  );

  return {
    rollupWithdrawalMutation,
  };
};
