import { Asset } from 'core';
import { useAddStakeStore } from './store/useAddStakeStore';

export const useAddStakeInputs = (selectedAsset: Asset | undefined | null) => {
  const { amount, setAmount } = useAddStakeStore();

  const handleAmountChange = (value: string | null) => {
    setAmount(value || '');
  };

  return {
    amount,
    handleAmountChange,
  };
};
