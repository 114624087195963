import { EnvConfig } from '../../../envConfig';
import { useNativeTokenQuery } from '../metadata';
import { transformToAsset } from '../shared';

export const useMangataAsset = () => {
  const { nativeAssetsQuery } = useNativeTokenQuery();

  const mangataNativeAsset =
    nativeAssetsQuery.data?.find((asset) => asset.id === EnvConfig.TOKEN_ID) ?? null;
  const mangataAsset = mangataNativeAsset ? transformToAsset(mangataNativeAsset) : null;

  return {
    mangataAsset,
  };
};
