import {
  PoolWithRatio as SdkPoolWithRatio,
  PoolWithShare as SdkPoolWithShare,
} from '@mangata-finance/sdk';
import { Asset } from '../token';
import { BN } from '@polkadot/util';

interface WithPoolMetadata {
  firstAsset: Asset;
  secondAsset: Asset;
  id: string;
  symbols: [string, string];
}

export interface PoolWithShare extends SdkPoolWithShare, WithPoolMetadata {
  sharePercentage: string;
  totalLPTokens: BN;
}

export type PoolWithRatio = SdkPoolWithRatio & WithPoolMetadata;

export enum AddLiquidityTxSucess {
  PoolCreated = 'PoolCreated',
  LiquidityMinted = 'LiquidityMinted',
}

export enum RemoveLiquidityTxSuccess {
  LiquidityBurned = 'LiquidityBurned',
}
