import { Container, Text } from 'ui';
import { ReactComponent as CoinsIcon } from 'icons/coins.svg';
import { ReactComponent as ExternalLinkIcon } from 'icons/link-ext.svg';

export function EmptyOwnedTokens() {
  return (
    <Container column alignItems="center" justifyContent="center" className="w-full py-[120px]">
      <CoinsIcon className="w-[64px] h-auto stroke-icon-neutral" />
      <Text type="title-2" id="tokens.page.owned.empty.title" className="mt-6" />
      <Text
        color="secondary"
        id="tokens.page.owned.empty.desc"
        className="mt-2 whitespace-pre-line text-center max-w-[320px]"
      />
      <a
        href="https://docs.mangata.finance/welcome/guides/trading/how-to-get-mgx"
        className="mt-6"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Container alignItems="center">
          <Text color="accent" type="title-4" id="tokens.page.owned.empty.cta" />
          <ExternalLinkIcon className="fill-icon-accent w-[20px] h-auto" />
        </Container>
      </a>
    </Container>
  );
}
