import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { useUserStore } from '../../../user/useUserStore';
import { getReserveBalances } from '../services/ReserveBalancesService';
import { transformReserveBalances } from '../transformers/ReserveBalancesTransformer';

export const useReserveBalancesQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { selectedAccount } = useUserStore();

  const address = selectedAccount?.address;

  const reserveBalancesQuery = useQuery(
    ['reserve-balances', address, finalisedTxCount],
    getReserveBalances(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!address && !!api,
      select: transformReserveBalances,
      keepPreviousData: true,
    },
  );

  return {
    reserveBalancesQuery,
  };
};
