import Gleap from 'gleap';
import { EnvConfig } from '../../../envConfig';

export function initGleap(key: string) {
  if (key === '') {
    return;
  }

  Gleap.initialize(EnvConfig.GLEAP_API_KEY);
}
