import { ExtrinsicPath } from '../../../../../stash';
import { IdLookupFn } from '../../Xcm';
import { validateBalancesTransfer, xcmpMessageSentValidator } from '../../validators';

export const getOriginIdentifiersForDeposit: IdLookupFn = async (
  api,
  token,
  _,
  header,
  address,
) => {
  if (!address) {
    return null;
  }

  const block = await api.rpc.chain.getBlock(header.hash);
  const apiAtHash = await api.at(header.hash);
  const eventsCodec = await apiAtHash.query.system.events();

  switch (token.extrinsicPath) {
    case ExtrinsicPath.XcmPalletLimitedTransfer: {
      return validateBalancesTransfer(api, eventsCodec, address);
    }
    case ExtrinsicPath.TransferMultiasset:
    case ExtrinsicPath.PolkadotXcmLimitedTransfer: {
      return xcmpMessageSentValidator(api, eventsCodec, address, block);
    }
    default:
      return null;
  }
};
