import { QueryOptional } from '../../../services';
import { useDeactivateLiquidityFor3rdPartyRewardsFeeQuery } from './query/useDeactivateLiqFor3rdPartyRewardsFeeQuery';
import { useDeactivateLiqudityFor3rdPartyRewardsMutation } from './query/useDeactivateLiquidityFor3rdPartyRewardsMutation';

export const useDeactivateLiquidityFor3rdPartyRewards = ({
  liquidityTokenId,
  rewardTokenId,
}: {
  liquidityTokenId: QueryOptional<string>;
  rewardTokenId: QueryOptional<string>;
}) => {
  const { deactivateLiqFor3rdPartyRewardsMutation } =
    useDeactivateLiqudityFor3rdPartyRewardsMutation();
  const { deactivateLiquidityFeeQuery } = useDeactivateLiquidityFor3rdPartyRewardsFeeQuery(
    liquidityTokenId,
    rewardTokenId,
  );

  return { deactivateLiqFor3rdPartyRewardsMutation, deactivateLiquidityFeeQuery };
};
