import { animated, useSpring } from '@react-spring/web';
import { Asset, useMangataAsset } from 'core';
import { useRef } from 'react';
import { Container, Text, FormatAmount, TokenIcon } from 'ui';

interface RewardsListProps {
  isExpanded: boolean;
  rewards: Array<[Asset, string]>;
}

export const RewardsList = ({ isExpanded, rewards }: RewardsListProps) => {
  const { mangataAsset } = useMangataAsset();
  const containerRef = useRef<HTMLDivElement>(null);

  const expand = useSpring({
    maxHeight: isExpanded ? containerRef.current?.scrollHeight ?? 0 : 0,
    opacity: isExpanded ? 1 : 0,
    transform: isExpanded ? 'translateY(0px)' : 'translateY(-20px)',
    from: { maxHeight: 0, opacity: 0, transform: 'translateY(0px)' },
  });

  return (
    <animated.div style={expand} className="w-full overflow-hidden" ref={containerRef}>
      <Container className="mt-4 mb-1 mx-12" column justifyContent="space-between" grow>
        {rewards.map(([token, amount]) => {
          const isMangataToken = token.id === mangataAsset?.id;
          return (
            <Container
              key={token.id}
              fullWidth
              alignItems="center"
              justifyContent="space-between"
              className="mt-4"
            >
              <Container>
                <TokenIcon token={token} size="xs" className="mr-2" />
                <Text
                  id={`position.overview.${isMangataToken ? 'native' : '3rdParty'}.liqMining.title`}
                  values={{ symbol: token.symbol }}
                />
              </Container>
              <Text type="title-4" weight="normal" color="highlight">
                <FormatAmount
                  value={amount}
                  options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
                />{' '}
                {token.symbol}
              </Text>
            </Container>
          );
        })}
      </Container>
    </animated.div>
  );
};
