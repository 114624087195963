import { useParams } from 'react-router';
import { useAllPoolsQuery } from './query/useAllPoolsQuery';

export const usePoolFromPath = () => {
  const params = useParams();
  const { allPoolsQuery } = useAllPoolsQuery();

  const poolSymbol = params?.poolSymbol;
  const pool = poolSymbol
    ? allPoolsQuery.data?.list.find(({ symbols }) => poolSymbol === symbols.join('-'))
    : null;
  const isNotFound = poolSymbol && allPoolsQuery.data && !pool;

  return {
    pool,
    isNotFound,
    poolSymbol,
  };
};
