import { useLocation } from 'react-router';

import { Container, Text, NavItem, BetaBadge } from 'ui';

import { ReactComponent as swapIcon } from 'icons/swap.svg';
import { ReactComponent as poolsIcon } from 'icons/pools.svg';
import { ReactComponent as positionsIcon } from 'icons/positions.svg';
import { ReactComponent as tokensIcon } from 'icons/tokens.svg';
import { ReactComponent as stakingIcon } from 'icons/staking.svg';
import { ReactComponent as ShieldIcon } from 'icons/shield-success.svg';
import { ReactComponent as MangataLogo } from 'icons/mangatax-logo.svg';
import { useIntl } from 'react-intl';
import { useDelegators, useSession } from 'core';
import { BN_ZERO } from '@mangata-finance/sdk';

export const Navbar = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { delegatorStateRequests: requests } = useDelegators();

  const {
    currentSession: { data: session },
  } = useSession();

  const hasReadyStakeChange = Array.from(requests ?? []).some(([, r]) =>
    r?.whenExecutable.lte(session ?? BN_ZERO),
  );

  const firstRoute = pathname.split('/')[1];

  return (
    <Container
      column
      justifyContent="space-between"
      alignItems="center"
      className="h-screen w-[264px] min-w-[264px] z-20 bg-base"
    >
      <Container column alignItems="start" fullWidth className="px-6 h-full">
        <Container alignItems="center" className="mt-6">
          <MangataLogo className="h-[50px] w-[186px]" />
          <BetaBadge />
        </Container>
        <Container className="h-full" alignItems="center">
          <Container column fullWidth>
            <NavItem
              isActive={pathname === '/'}
              path="/"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.swap' })}
              Icon={swapIcon}
              data-testid="nav-swap"
            />
            <NavItem
              isActive={firstRoute === 'pools'}
              path="/pools/promoted"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.pools' })}
              Icon={poolsIcon}
              data-testid="nav-pools"
            />
            <NavItem
              isActive={firstRoute === 'tokens'}
              path="/tokens"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.tokens' })}
              Icon={tokensIcon}
              data-testid="nav-tokens"
            />
            <NavItem
              isActive={firstRoute === 'staking'}
              path="/staking"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.staking' })}
              Icon={stakingIcon}
              data-testid="nav-staking"
            />
            <NavItem
              isActive={firstRoute === 'positions'}
              hasNotification={hasReadyStakeChange}
              path="/positions"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.positions' })}
              Icon={positionsIcon}
              data-testid="nav-positions"
            />
          </Container>
        </Container>
      </Container>
      <Container column justifyContent="start" fullWidth className="p-6">
        <Container alignItems="center">
          <ShieldIcon className="w-6 h-6 fill-icon-secondary mr-2" />
          <Text type="body-s" weight="semibold" color="secondary" id="navigation.footer.title" />
        </Container>
        <Text color="secondary" className="mt-2 text-[10px]" id="navigation.footer.description" />
      </Container>
    </Container>
  );
};
