import { rolldownAbi } from '../ContractAbis.json';
import { useMemo } from 'react';
import { QueryOptional, useWeb3 } from '../../../services';
import { useRollupChainsQuery } from '../stash/query/useRollupChainsQuery';

export const useRolldownContract = (chainId: QueryOptional<string>) => {
  const web3 = useWeb3();
  const chainWeb3 = (chainId && web3?.[chainId]) || null;

  const { rollupChainsQuery } = useRollupChainsQuery();
  const rollDownContractAddress = rollupChainsQuery.data?.find(
    (chain) => chain.chainId === chainId,
  )?.rolldownContract;

  const contract = useMemo(
    () => (chainWeb3 ? new chainWeb3.eth.Contract(rolldownAbi, rollDownContractAddress) : null),
    [chainWeb3, rollDownContractAddress],
  );

  return {
    contract,
    address: rollDownContractAddress,
  };
};
