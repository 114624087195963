import { useDelegateFeeQuery } from './query/useDelegateFeeQuery';
import { useDelegateMutation } from './query/useDelegateMutation';
import { QueryOptional } from '../../../../services';
import { Asset } from '../../../token';
import { ReserveSource } from '../../../balance';

export const useDelegate = (
  collatorAddress: QueryOptional<string>,
  stakedAsset: QueryOptional<Asset>,
  reserveSource: QueryOptional<ReserveSource>,
  candidateDelegationCount: QueryOptional<number>,
  delegatorDelegationCount: QueryOptional<number>,
) => {
  const { delegateMutation } = useDelegateMutation();
  const { delegateFeeQuery } = useDelegateFeeQuery(
    collatorAddress,
    stakedAsset,
    reserveSource,
    candidateDelegationCount,
    delegatorDelegationCount,
  );

  return {
    delegateMutation,
    delegateFeeQuery,
  };
};
