import { Transaction, TransactionMetadata, TxAsset, TxMetadataTokens, TxStatus } from 'core';
import { Container, Text, TokenIcon, FormatAmount } from 'ui';
import { isUndefined } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

interface TxModalContentProps {
  tx: Transaction;
}

const hasTokenMetadata = (metadata: TransactionMetadata): metadata is TxMetadataTokens => {
  return !isUndefined(metadata?.tokens);
};

const renderToken = (token: TxAsset, metadata: TransactionMetadata) => {
  return (
    <Container key={token.id} alignItems="center">
      <TokenIcon type="diagonal" size="xxs" isCompact token={token} className="ml-2" />
      <Text className="ml-2" color="primary">
        {metadata.amountPrefix && <Text className="mr-1">{metadata.amountPrefix}</Text>}
        <FormatAmount
          value={token.amount}
          options={{
            precision: 3,
            minThreshold: '0.001',
          }}
        />{' '}
        {token.symbol}
      </Text>
    </Container>
  );
};

const hasMetadata = (metadata: TransactionMetadata) => {
  return !!(metadata?.symbol && metadata?.amount);
};

const renderTokenMetadata = (tx: Transaction) => {
  if (!hasTokenMetadata(tx.metadata)) {
    return null;
  }

  const metadata = tx.metadata;

  if (metadata.tokens.length === 1) {
    return renderToken(metadata.tokens[0], metadata);
  }

  return metadata.tokens.map((token, i) => {
    return (
      <Container key={i}>
        {renderToken(token, metadata)}
        {i !== metadata.tokens.length - 1 && (
          <Text
            color="secondary"
            className="ml-2"
            id={metadata.joinSymbolId ?? 'modal.transaction.header.plus'}
          />
        )}
      </Container>
    );
  });
};

const renderMetadata = (tx: Transaction) => {
  const metadata = tx.metadata;

  if (hasTokenMetadata(metadata)) {
    return renderTokenMetadata(tx);
  }

  return (
    <>
      {metadata?.symbol && (
        <TokenIcon size="xxs" type="diagonal" isCompact token={metadata.symbol} className="ml-2" />
      )}
      {hasMetadata(metadata) && (
        <Container>
          {metadata.amountPrefix && <Text className="ml-2">{metadata.amountPrefix}</Text>}
          <Text className="ml-2">
            <FormatAmount
              value={metadata.amount}
              options={{
                precision: 3,
                minThreshold: '0.001',
              }}
            />{' '}
            {metadata.symbol}
          </Text>
        </Container>
      )}
    </>
  );
};

export function TxModalContent({ tx }: TxModalContentProps) {
  return (
    <Container fullWidth alignItems="center">
      <Container className="max-w-[45%]">
        <Text className="overflow-hidden whitespace-nowrap text-ellipsis">
          <FormattedMessage
            id={`modal.tx.${tx.type}.${tx.status === TxStatus.Success ? 'success' : 'pending'}`}
          />
        </Text>
      </Container>
      {renderMetadata(tx)}
    </Container>
  );
}
