import { Asset, CollatorState, EnvConfig, TestId } from 'core';
import { Container, FormatAmount, FormatCurrency, Grid, ShortAddress, Text, TokenIcon } from 'ui';
import { BlinkingBolt } from '../BlinkingBolt/BlinkingBolt';
import { CollatorAprChip } from '../CollatorAprChip/CollatorAprChip';
import { ReactComponent as PauseIcon } from 'icons/pause.svg';
import { isNull, isUndefined } from 'lodash-es';
import { Link } from 'react-router-dom';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';

interface CollatorRowItemProps extends TestId {
  data: ParachainStakingCollatorCandidate;
  bond: string | null;
  lpAsset: Asset | null;
  apr?: string;
  rewards24h?: string;
  minBond?: string;
}

export function CollatorRowItem({
  data,
  bond,
  lpAsset,
  apr,
  minBond,
  'data-testid': testId = 'collator-row-item',
}: CollatorRowItemProps) {
  const state = data.state.isActive ? CollatorState.Active : CollatorState.Waiting;
  const isActive = state === CollatorState.Active;
  const formatAmountOptions = { nonZeroPrecision: true, precision: 2, maxChars: 10 };
  const id = data.id.toString();

  return (
    <Link to={`/staking/${id}`} className="w-full" data-testid={`${testId}-link`}>
      <Grid
        data-testid={testId}
        cols={EnvConfig.isRollupEnv ? 6 : 5}
        gap="l"
        className="w-full hover:bg-base p-4 rounded-lg transition my-1"
      >
        <Container data-testid="collator-address" alignItems="center">
          {isActive ? (
            <BlinkingBolt size="m" className="mr-2" />
          ) : (
            <PauseIcon className="w-[16px] h-auto fill-icon-warning mr-2" />
          )}
          <Text type="title-4">
            <ShortAddress address={id} withTooltip={false} />
          </Text>
        </Container>

        <Container data-testid="total-stake" alignItems="center" justifyContent="start">
          <Text type="title-4">
            {bond !== null ? (
              <FormatCurrency value={bond} options={{ withoutSymbol: true }} />
            ) : (
              '-'
            )}
          </Text>
        </Container>

        <Container data-testid="staked-token" alignItems="center">
          {lpAsset ? (
            <>
              <TokenIcon type="diagonal" token={lpAsset} size="xs" />
              <Text type="title-4" className="ml-2">
                {lpAsset.symbol}
              </Text>
            </>
          ) : (
            '-'
          )}
        </Container>

        <Container alignItems="center">
          {isUndefined(apr) ? '-' : <CollatorAprChip isActive={isActive} value={apr} />}
        </Container>
        <Container alignItems="center">
          {isUndefined(minBond) || isNull(lpAsset) ? (
            '-'
          ) : (
            <Text type="title-4">
              <FormatAmount data-testid="min-bond" value={minBond} options={formatAmountOptions} />{' '}
              {lpAsset.symbol}
            </Text>
          )}
        </Container>
        {EnvConfig.isRollupEnv && (
          <Container alignItems="center">
            <Text type="title-4">
              {data.delegators.length} / {EnvConfig.MAX_DELEGATION_COUNT}
            </Text>
          </Container>
        )}
      </Grid>
    </Link>
  );
}
