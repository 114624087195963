import { FormEvent, useRef } from 'react';
import cls from 'classnames';

import { Asset, ClassName, EnvConfig, isAmountGtZero, TestId } from 'core';
import { Container, Text, TokenSelector, AmountTooltip, FiatValue } from '../';
import { TokenListBalances } from '../TokenSelector/List/TokenList';
import { isNull } from 'lodash-es';

export interface TokenInputProps extends ClassName, TestId {
  onAmountChange: (value: string | null) => void;
  labelId?: string;
  inputClassName?: string;
  maxAmount?: string;
  amount?: string;
  isDisabled?: boolean;
  selectedToken: Asset | null;
  isInvalid?: boolean;
  settingsVisible?: boolean;
  freeBalance?: string | null;
  onTokenSelect?: (asset: Asset | null) => void;
  tokens?: Asset[];
  isSelectable?: boolean;
  isBorderless?: boolean;
  balances?: TokenListBalances;
  onTokenSelectOverride?: () => void;
}

export function TokenInput({
  onTokenSelect,
  className,
  inputClassName,
  labelId,
  'data-testid': testId = 'tokenInput',
  selectedToken,
  isDisabled,
  freeBalance,
  onAmountChange,
  amount,
  isInvalid,
  isBorderless,
  tokens,
  balances,
  settingsVisible = true,
  isSelectable,
  onTokenSelectOverride,
}: TokenInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAmountChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    const newAmount = currentTarget.value;

    if (selectedToken) {
      const regex = new RegExp(`^\\d*(\\.?\\d{0,${selectedToken?.decimals}})?$`, 'g');

      if (regex.test(newAmount)) {
        onAmountChange(newAmount);
      }
    }
  };

  const handleContainerClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleTokenSelect = (token: Asset | null) => {
    onTokenSelect?.(token);
    onAmountChange(null);
  };

  const TokenSelectorComponent = (
    <TokenSelector
      data-testid={`${testId}-selector`}
      selectedToken={selectedToken}
      onTokenSelect={handleTokenSelect}
      onClickOverride={onTokenSelectOverride}
      tokens={tokens}
      balances={balances}
      settingsVisible={settingsVisible}
      isSelectable={isSelectable}
    />
  );
  const isSelectWithoutLabel = !labelId;

  const INVALID_CONTAINER_CLASS = cls(
    'hover:!border-alert focus-within:!border-alert',
    isBorderless && 'bg-alert focus-within:!bg-alert',
    !isBorderless && 'border-alert',
  );

  const CONTAINER_CLASS = cls(
    !isBorderless
      ? 'border-strong'
      : 'bg-input hover:bg-input-hover border-transparent hover:border-transparent focus-within:!border-transparent',
    'relative border w-full min-h-[80px] rounded-lg',
    'p-4 pb-3 cursor-text transition-colors duration-300',
    !isInvalid && 'hover:border-default focus-within:bg-widget focus-within:!border-highlight',
    isInvalid && INVALID_CONTAINER_CLASS,
    isSelectWithoutLabel && '!pl-4',
    !isSelectWithoutLabel && selectedToken && 'pt-1',
    className,
  );

  const INPUT_CLASS = cls(
    'text-neutral font-title-1 border',
    'bg-transparent align-bottom border-none',
    'focus:ring-0 focus:outline-none w-full text-right p-0',
    'peer placeholder:text-secondary/[.3]',
    inputClassName,
    !selectedToken && 'opacity-60',
    isInvalid && 'text-alert',
  );

  const renderInputMetadata = () => {
    if (!selectedToken) return null;

    return (
      <Container
        alignItems="center"
        justifyContent="end"
        fullWidth
        className={cls('h-3', EnvConfig.isRollupEnv && 'relative bottom-[6px]')}
      >
        {amount && isAmountGtZero(amount) && (
          <Text
            type="title-4"
            color={isInvalid ? 'alert' : 'secondary'}
            className="w-[260px] truncate text-right"
          >
            <FiatValue id={selectedToken.id} amount={amount} />
          </Text>
        )}
      </Container>
    );
  };

  const renderInputHeader = () => {
    return (
      <Container alignItems="center" justifyContent="space-between" fullWidth>
        {!isSelectWithoutLabel ? (
          <Text
            type="title-4"
            color="secondary"
            className={cls('mb-2', selectedToken && 'mt-2')}
            id={labelId}
          />
        ) : (
          <div />
        )}
        {selectedToken && (
          <Container alignItems="center" row>
            <Text color="secondary" id="tokenInput.balance.title" className="mr-1" />
            <Text color="secondary">
              <AmountTooltip
                placement="bottom"
                value={freeBalance ?? '0'}
                options={{ maxChars: 15, precision: 3 }}
                id={`input-balance-${selectedToken.id}`}
              />
            </Text>
          </Container>
        )}
      </Container>
    );
  };

  return (
    <Container
      onClick={handleContainerClick}
      justifyContent="center"
      column
      data-testid={`${testId}-container`}
      className={CONTAINER_CLASS}
    >
      {renderInputHeader()}
      <Container
        row
        fullWidth
        alignItems="start"
        justifyContent="space-between"
        className={cls(isNull(selectedToken) && !isSelectWithoutLabel && 'mb-2')}
      >
        <Container className={cls(selectedToken && isSelectWithoutLabel && 'relative bottom-3')}>
          {TokenSelectorComponent}
        </Container>
        <Container justifyContent="space-between" alignItems="end" className={cls('w-full')}>
          <input
            data-testid={`${testId}-input`}
            disabled={isDisabled || !selectedToken}
            onChange={handleAmountChange}
            placeholder="0.0"
            ref={inputRef}
            value={amount}
            className={INPUT_CLASS}
          />
        </Container>
      </Container>
      {renderInputMetadata()}
    </Container>
  );
}
