import { TestId } from 'core';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { TokenIcon } from '../TokenIcon/TokenIcon';
import { ReactComponent as PromotedIcon } from '../../../assets/icons/promoted.svg';
import { ReactComponent as PoolIcon } from '../../../assets/icons/pool.svg';
import { FormatAmount } from '../Format';
import { Link } from 'react-router-dom';
import { PoolWithShare } from 'core/src/domains/pool/Pool';

export interface PositionRowProps extends TestId {
  pool: PoolWithShare;
}

export function PositionRow({ pool, 'data-testid': testId = 'position-row' }: PositionRowProps) {
  return (
    <Link to={`/positions/${pool.symbols.join('-')}`} className="w-full">
      <Container
        row
        fullWidth
        alignItems="center"
        justifyContent="space-between"
        className="transition-colors p-2 rounded h-[56px] hover:bg-widget/[.15]"
        data-testid={testId}
      >
        <Container alignItems="center">
          <TokenIcon
            size="xs"
            token={[pool.firstAsset, pool.secondAsset]}
            className="mr-2"
            isCompact={false}
          />
          <Text className="uppercase mr-1">{pool.symbols.join(' - ')}</Text>
          {pool.isPromoted && (
            <PromotedIcon className="_accent-premium fill-icon-accent w-[20px] h-[20px]" />
          )}{' '}
        </Container>
        <Container alignItems="center">
          <PoolIcon className="fill-icon-secondary w-[16px] h-[16px] mr-1" />
          <Text className="mr-1">
            <FormatAmount
              value={pool.sharePercentage}
              options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
            />
          </Text>
          <Text color="secondary">%</Text>
        </Container>
      </Container>
    </Link>
  );
}
