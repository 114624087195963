import {
  WalletAccount,
  useAccounts,
  useAssets,
  useChannelApi,
  useInternalBalance,
  useModalStore,
  useStashTokens,
  useWallet,
  useWithdrawals,
} from 'core';
import { KeypairTypeValue, StashChannelTag } from 'core';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useWithdrawalStore } from './store/useWithdrawalStore';

export const useXcmWithdrawalState = () => {
  const { isWithdrawalOpen, closeWithdrawal } = useModalStore();
  const { amount, asset, chain, dispose, destinationAccount, setDestinationAccount } =
    useWithdrawalStore();
  const { assets } = useAssets();
  const { getFreeBalance } = useInternalBalance();

  const {
    withdrawalExtrinsicQuery: { data: extrinsic },
    withdrawalFee: { data: fee },
    submitWithdrawalMutation: { mutate: submitWithdrawalExtrinsic, isLoading: isWithdrawing },
  } = useWithdrawals({
    amount,
    assetId: asset?.id ?? null,
    channelId: chain?.id ?? null,
    destinationAccount,
  });

  const { channel, api: channelApi } = useChannelApi(chain?.id ?? null);

  const {
    stashTokensQuery: { data: stashTokens },
  } = useStashTokens();

  const {
    selectedAccount,
    accountsByType,
    accountsQuery: { data: allAccounts },
  } = useAccounts();

  const isEthChain = channel?.tags?.includes(StashChannelTag.Ethereum) ?? false;

  const accounts = useMemo(() => {
    if (!chain) {
      return allAccounts ?? [];
    }

    if (isEthChain) {
      return accountsByType?.[KeypairTypeValue.Ethereum] ?? [];
    }

    return [
      ...(accountsByType?.[KeypairTypeValue.SR25519] ?? []),
      ...(accountsByType?.[KeypairTypeValue.ECDSA] ?? []),
      ...(accountsByType?.[KeypairTypeValue.ED25519] ?? []),
    ];
  }, [chain, accountsByType, isEthChain, allAccounts]);

  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const handleAccountSelect = (account: WalletAccount) => {
    setDestinationAccount(account);
  };

  useEffect(() => {
    if (isEmpty(accounts) || !selectedAccount) {
      return;
    }

    if (!destinationAccount) {
      setDestinationAccount(isEthChain ? accounts[0] : selectedAccount);
      return;
    }

    if (isEthChain) {
      if (destinationAccount?.type === KeypairTypeValue.Ethereum) {
        return;
      }
      setDestinationAccount(accounts[0]);
      return;
    }

    if (!isEthChain) {
      if (
        destinationAccount?.type === KeypairTypeValue.SR25519 ||
        destinationAccount?.type === KeypairTypeValue.ECDSA ||
        destinationAccount?.type === KeypairTypeValue.ED25519
      ) {
        return;
      }
      setDestinationAccount(selectedAccount);
      return;
    }
  }, [
    selectedAccount,
    setDestinationAccount,
    destinationAccount,
    accounts,
    channel?.tags,
    isEthChain,
  ]);

  const xcmTokens = new Set(
    stashTokens?.xcmTokens.filter((token) => token.channelId === chain?.id).map(($) => $.tokenId),
  );

  const withdrawalAssets =
    assets?.filter((token) => {
      return xcmTokens.has(token.id);
    }) ?? [];

  const withdrawalBalances = new Map<string, string>(
    withdrawalAssets.reduce((acc: [string, string][], asset) => {
      const freeBalance = getFreeBalance(asset);
      return [...acc, [asset.id, freeBalance ?? '0']];
    }, []),
  );

  const handleClose = () => {
    dispose();
    closeWithdrawal();
  };

  const isWithdrawalReady = useMemo(
    () =>
      !!(
        amount &&
        asset &&
        destinationAccount &&
        channel &&
        channelApi &&
        selectedAccount &&
        selectedWallet &&
        fee
      ),
    [amount, asset, destinationAccount, channel, channelApi, selectedAccount, selectedWallet, fee],
  );

  const submitWithdrawal = () => {
    if (
      !destinationAccount ||
      !selectedAccount ||
      !asset ||
      !amount ||
      !selectedWallet ||
      !channel ||
      !channelApi
    ) {
      return;
    }

    const stashToken = stashTokens?.xcmTokens?.find((token) => token.tokenId === asset.id);

    if (!stashToken) {
      return;
    }

    submitWithdrawalExtrinsic({
      amount,
      api: channelApi,
      extrinsic,
      channel,
      destinationAccount,
      asset: stashToken,
      originAccount: selectedAccount,
      onDone: () => {
        dispose();
        closeWithdrawal();
      },
    });
  };

  return {
    isWithdrawalOpen,
    isWithdrawalReady,
    submitWithdrawal,
    selectedAccount,
    selectedWallet,
    accounts,
    withdrawalAssets,
    withdrawalBalances,
    handleClose,
    fee,
    handleAccountSelect,
    isEthChain,
    isWithdrawing,
  };
};
