import { CollatorRewards, CollatorRewardsRes } from '../CollatorRewards';

export const transformCollatorRewards =
  (collator: string) =>
  (data: CollatorRewardsRes[] | null): CollatorRewards[] | null => {
    if (!data) {
      return null;
    }

    return data.map((item) => ({
      collator,
      ...item,
    }));
  };
