import {
  useAccounts,
  useModalStore,
  useExternalBalances,
  useWallet,
  KeypairTypeValue,
  useAssetMetadataQuery,
  useStashTokens,
  transformToAsset,
  WalletAccount,
} from 'core';
import { useEffect, useMemo } from 'react';
import { useDepositStore } from './store/useDepositStore';
import { isEmpty } from 'lodash-es';
import { useChannelApi, useDeposits, StashChannelTag } from 'core';

export const useXcmDepositState = () => {
  const { chain, originAccount, setOriginAccount, asset, amount, dispose } = useDepositStore();

  const { isDepositOpen, closeDeposit } = useModalStore();
  const {
    selectedAccount,
    accountsByType,
    accountsQuery: { data: allAccounts },
  } = useAccounts();

  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const channelId = chain?.id ?? null;
  const { balanceQueriesById, channelTokens } = useExternalBalances(
    chain?.id ?? null,
    originAccount?.address ?? null,
  );
  const { channel, api: channelApi } = useChannelApi(channelId);

  const isEthChain = channel?.tags?.includes(StashChannelTag.Ethereum) ?? false;

  const accounts = useMemo(() => {
    if (!chain) {
      return allAccounts ?? [];
    }

    if (isEthChain) {
      return accountsByType?.[KeypairTypeValue.Ethereum] ?? [];
    }

    return [
      ...(accountsByType?.[KeypairTypeValue.SR25519] ?? []),
      ...(accountsByType?.[KeypairTypeValue.ECDSA] ?? []),
      ...(accountsByType?.[KeypairTypeValue.ED25519] ?? []),
    ];
  }, [chain, accountsByType, isEthChain, allAccounts]);

  const {
    depositExtrinsicQuery: { data: extrinsic },
    depositFeeQuery: { data: fee },
    submitDepositMutation: { mutate: submitDepositExtrinsic },
  } = useDeposits({
    channelId,
    assetId: asset?.id ?? null,
    amount,
    originAccount,
  });

  const isDepositReady = useMemo(
    () =>
      !!(
        amount &&
        asset &&
        originAccount &&
        channel &&
        channelApi &&
        selectedAccount &&
        selectedWallet &&
        fee
      ),
    [amount, asset, originAccount, channel, channelApi, selectedAccount, selectedWallet, fee],
  );

  const {
    stashTokensQuery: { data: stashTokens },
  } = useStashTokens();
  const {
    assetMetadataQuery: { data: assetMetadata },
  } = useAssetMetadataQuery();

  const freeBalance = balanceQueriesById?.[asset?.id ?? '']?.data ?? '0';

  useEffect(() => {
    if (isEmpty(accounts) || !selectedAccount) {
      return;
    }

    if (!originAccount) {
      setOriginAccount(isEthChain ? accounts[0] : selectedAccount);
      return;
    }

    if (isEthChain) {
      if (originAccount.type === KeypairTypeValue.Ethereum) {
        return;
      }
      setOriginAccount(accounts[0]);
      return;
    }

    if (!isEthChain) {
      if (
        originAccount.type === KeypairTypeValue.SR25519 ||
        originAccount.type === KeypairTypeValue.ECDSA ||
        originAccount.type === KeypairTypeValue.ED25519
      ) {
        return;
      }
      setOriginAccount(selectedAccount);
      return;
    }
  }, [selectedAccount, setOriginAccount, originAccount, accounts, channel?.tags, isEthChain]);

  const handleClose = () => {
    dispose();
    closeDeposit();
  };

  const handelAccountSelect = (account: WalletAccount) => {
    setOriginAccount(account);
  };

  const submitDeposit = () => {
    if (
      !originAccount ||
      !selectedAccount ||
      !asset ||
      !amount ||
      !selectedWallet ||
      !channelApi ||
      !channel
    )
      return;

    const stashToken = channelTokens?.find((token) => token.tokenId === asset.id);

    if (!stashToken) {
      return;
    }

    submitDepositExtrinsic({
      amount,
      api: channelApi,
      extrinsic,
      asset: stashToken,
      channel,
      originAccount,
      destinationAccount: selectedAccount,
      onDone: () => {
        dispose();
        closeDeposit();
      },
    });
  };

  const xcmTokens = new Set(
    stashTokens?.xcmTokens.filter((token) => token.channelId === chain?.id).map(($) => $.tokenId),
  );

  const depositTokens =
    assetMetadata
      ?.filter((token) => {
        return xcmTokens.has(token.id);
      })
      .map(transformToAsset) ?? [];

  const depositBalances = new Map<string, string>(
    depositTokens.reduce((acc: [string, string][], asset) => {
      const freeBalance = balanceQueriesById[asset.id].data;

      if (freeBalance === undefined || freeBalance === null) {
        return acc;
      }

      return [...acc, [asset.id, freeBalance]];
    }, []),
  );

  return {
    handelAccountSelect,
    submitDeposit,
    xcmTokens,
    depositTokens,
    depositBalances,
    handleClose,
    isDepositOpen,
    isDepositReady,
    freeBalance,
    accounts,
    selectedAccount,
    isEthChain,
  };
};
