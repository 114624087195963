import { Outlet } from 'react-router-dom';
import { AppContainer, Container, WelcomeBetaOverlay, LowBalanceModal } from 'ui';
import { NativeTokenWidget, TxNotificationProvider, Wallet } from 'modules';
import { Navbar } from '../navbar/Navbar';

export function AppLayout() {
  return (
    <div className="prose flex" data-testid="app-layout">
      <WelcomeBetaOverlay />
      <TxNotificationProvider />
      <Container fullWidth row justifyContent="space-between" alignItems="start">
        <Navbar />
        <AppContainer>
          <Outlet />
        </AppContainer>
        <Container className="absolute right-0 top-0">
          <NativeTokenWidget />
          <Wallet />
        </Container>
        <LowBalanceModal />
      </Container>
    </div>
  );
}
