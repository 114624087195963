import { ClassName, PoolWithShare, QueryOptional } from 'core';
import { Container, Text } from 'ui';
import { ParachainStakingDelegator } from '@polkadot/types/lookup';
import { BN_ZERO } from '@mangata-finance/sdk';
import cls from 'classnames';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as AlertIcon } from 'icons/warning.svg';
import { ReactComponent as HintIcon } from 'icons/question.svg';

interface ActiveRewardsWidgetProps extends ClassName {
  delegator: QueryOptional<ParachainStakingDelegator>;
  pool: PoolWithShare;
  stakeLpTokens: QueryOptional<Set<string>>;
}

export function ActiveRewardsWidget({
  delegator,
  pool,
  stakeLpTokens,
  className,
}: ActiveRewardsWidgetProps) {
  const canStake = stakeLpTokens?.has(pool.liquidityTokenId);
  const canMine = pool.isPromoted;
  const delegations = delegator?.delegations.filter((d) =>
    d.liquidityToken.eq(pool.liquidityTokenId),
  );

  const isStaking =
    canStake &&
    delegations?.some((d) => d.liquidityToken.eq(pool.liquidityTokenId) && d.amount.gt(BN_ZERO));
  const isLPMining = canMine && pool.activatedLPTokens.gt(BN_ZERO);

  const activities = [canStake ? isStaking : null, canMine ? isLPMining : null].filter(
    (a) => a !== null,
  );
  const currentActivities = activities.filter(Boolean).length;

  const hasFullEarnPotential = currentActivities === activities.length;

  if (!pool.isPromoted) {
    return (
      <Container
        alignItems="center"
        justifyContent="space-between"
        fullWidth
        className={cls('p-4 bg-widget rounded-lg', className)}
      >
        <Container className="mr-4">
          <HintIcon className="w-6 h-auto stroke-icon-secondary" />
        </Container>
        <Text id="position.card.rewardsWidget.notEligible" color="secondary" />
      </Container>
    );
  }

  return activities.length > 0 ? (
    <Container
      alignItems="center"
      justifyContent="space-between"
      fullWidth
      className={cls('py-2', className)}
    >
      <Text uppercase weight="semibold" id="position.card.rewardsWidget.title" />
      <Container alignItems="center">
        <Text data-testid="active-reward-number">{`${currentActivities}/${activities.length}`}</Text>
        {hasFullEarnPotential ? (
          <CheckIcon className="ml-1.5 w-6 h-auto stroke-icon-accent" />
        ) : (
          <AlertIcon className="ml-1.5 w-6 h-auto fill-icon-alert" data-testid="reward-hint" />
        )}
      </Container>
    </Container>
  ) : null;
}
