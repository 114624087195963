import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as LightningIcon } from 'icons/lightning.svg';

interface CollatorAprChipProps {
  isActive?: boolean;
  value: string;
}

export function CollatorAprChip({ isActive = false, value }: CollatorAprChipProps) {
  return (
    <Container
      inline
      className={cls('p-[2px] pr-2 rounded ', isActive ? 'bg-accent/[.1]' : 'bg-warning')}
      justifyContent="center"
      alignItems="center"
    >
      <LightningIcon
        className={cls('w-[20px] h-auto', isActive ? 'fill-icon-accent' : 'fill-icon-warning')}
      />

      <Text type="title-4" color={isActive ? 'accent' : 'warning'}>
        {value} %
      </Text>
    </Container>
  );
}
