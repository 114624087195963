import { useQuery } from '@tanstack/react-query';
import { QueryOptional, useApi } from '../../../../services';
import { getRemoveLiquidityFee } from '../services/RemoveLiquidityFeeService';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useAccounts } from '../../../account';
import { useAdaptiveFee } from '../../../fee/adaptive';
import { BN } from '@polkadot/util';

export type RemoveLiquidityFeeParams = {
  firstAssetId: string | null;
  secondAssetId: string | null;
  liquidityTokenId: QueryOptional<string>;
  lpAmount: string | null;
  active3rdPartyLiquidities: QueryOptional<[string, BN][]>;
};

export const useRemoveLiquidityFeeQuery = ({
  firstAssetId,
  secondAssetId,
  liquidityTokenId,
  active3rdPartyLiquidities,
  ...params
}: RemoveLiquidityFeeParams) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const lpAmount = useDebounce(params.lpAmount);
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const removeLiquidityFeeQuery = useQuery(
    [
      'remove-liquidity-fee',
      firstAssetId,
      secondAssetId,
      liquidityTokenId,
      lpAmount,
      selectedAccount?.address,
      active3rdPartyLiquidities,
    ],
    getRemoveLiquidityFee(api),
    {
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!firstAssetId &&
        !!secondAssetId &&
        !!selectedAccount?.address &&
        !!lpAmount &&
        lpAmount !== '0',
      keepPreviousData: true,
    },
  );

  return { removeLiquidityFeeQuery };
};
