import { useSDK as useMetamask } from '@metamask/sdk-react';
import { WalletSource } from '../../types';
import { useRollupChainsQuery } from '../rollup';
import { useUserStore } from '../user/useUserStore';

export const useMetamaskNetwork = () => {
  const { provider, chainId: metamaskChainId, connecting } = useMetamask();
  const { selectedWallet } = useUserStore();
  const { rollupChainsQuery } = useRollupChainsQuery();

  const defaultChain = rollupChainsQuery.data?.[0];
  const isMetamaskWallet = selectedWallet === WalletSource.Metamask;
  const isLoading = connecting || !metamaskChainId || rollupChainsQuery.isInitialLoading;
  const isDefaultChain = metamaskChainId === defaultChain?.chainId;
  const isChainSwitchRequired = isMetamaskWallet && !isDefaultChain && !isLoading;

  const requestChain = (chainId?: string) => {
    const chain = rollupChainsQuery.data?.find((chain) => chain.chainId === chainId);

    if (!chain) {
      console.error(`Chain ${chainId} is not available`);
      return;
    }

    provider?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          blockExplorerUrls: chain.explorerUrl ? [chain.explorerUrl] : undefined,
          iconUrls: [],
          nativeCurrency: {
            name: chain.nativeToken.name,
            symbol: chain.nativeToken.symbol,
            decimals: parseInt(chain.nativeToken.decimals),
          },
          rpcUrls: [chain.rpcUrl],
          chainId: chain.chainId,
          chainName: chain.name,
        },
      ],
    });
  };

  const requestDefaultChain = () => requestChain(defaultChain?.chainId);
  const isChainConnected = (chainId?: string) => !!chainId && metamaskChainId === chainId;

  return {
    isLoading,
    defaultChain,
    isChainSwitchRequired,
    isDefaultChain,
    isChainConnected,
    requestDefaultChain,
    requestChain,
  };
};
