import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { EnvConfig } from '../../../../envConfig';
import { TokenStatistics } from '../../Token';

export async function getTokenListStats() {
  try {
    const { data } = await axios.get<TokenStatistics[]>(`${EnvConfig.STASH_URL}/token/list/stats`);
    return data;
  } catch {
    throw new Error('Unable to fetch token stats');
  }
}

export async function getTokenDetailStats({
  queryKey,
}: QueryFunctionContext<[string, string | undefined]>) {
  try {
    const [, symbol] = queryKey;

    if (!symbol) {
      return null;
    }

    const { data } = await axios.get<TokenStatistics>(
      `${EnvConfig.STASH_URL}/token/${symbol}/stats`,
    );
    return data;
  } catch {
    throw new Error('Unable to fetch token stats');
  }
}
