import { Container, FormatAmount, Text } from 'ui';
import { PoolWithShare } from 'core';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { BN } from '@polkadot/util';

interface EligibleLpTokensCardProps {
  pool: PoolWithShare;
  maxChars?: number;
  activeLPAmount: BN;
  availableLPAmount: BN;
}

export function EligibleLpTokensCard({
  pool,
  maxChars = 15,
  activeLPAmount,
  availableLPAmount,
}: EligibleLpTokensCardProps) {
  return (
    <>
      <Text color="secondary" id="liq.mining.detail.widget.lp.tokens" className="mb-2" />
      <Container fullWidth alignItems="center">
        <Text data-testid="active-eligible-lp-tokens" type="body-l">
          <FormatAmount
            value={fromBN(activeLPAmount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
            options={{ nonZeroPrecision: true, precision: 2, maxChars }}
            className="mr-1"
          />
          {' / '}
          <FormatAmount
            value={fromBN(availableLPAmount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
            options={{ nonZeroPrecision: true, precision: 2, maxChars }}
            className="mr-1"
          />
        </Text>
        <Text color="secondary" type="body-l">
          {pool.symbols.join('-')}
        </Text>
      </Container>
    </>
  );
}
