import { useCallback, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { some } from 'lodash-es';
import { AdaptiveTxFee, Asset, useInternalBalance, isAmountGtZero, useReserves } from 'core';
import {
  AddStakeUIStore,
  AddStakeUIStoreFormError,
  useAddStakeStore,
} from './store/useAddStakeStore';
import { fromBN, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from '@mangata-finance/sdk';

export const useAddStakeValidation = (
  feeData: AdaptiveTxFee['current'] | null | undefined,
  stakedAsset: Asset | undefined | null,
) => {
  const { formError, setFormError, amount } = useAddStakeStore();
  const { getFreeBalance } = useInternalBalance();
  const { totalBalance } = useReserves(stakedAsset?.id);

  const isInvalid = some(formError, Boolean);
  const feeBalanceStr = getFreeBalance(feeData) ?? '0';
  const assetBalanceStr = totalBalance
    ? fromBN(totalBalance, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)
    : '0';
  const isStakedAssetSameAsFee = stakedAsset?.id === feeData?.id;

  const validateForm = useCallback(() => {
    const feeBalance = new Decimal(feeBalanceStr);
    const newFormError: Partial<Record<AddStakeUIStoreFormError, boolean>> = {};

    if (isAmountGtZero(amount)) {
      if (new Decimal(assetBalanceStr).lt(amount)) {
        newFormError[AddStakeUIStoreFormError.InsufficientFunds] = true;
      }

      if (feeData && isStakedAssetSameAsFee && feeBalance.sub(amount).lt(feeData.amount ?? 0)) {
        newFormError[AddStakeUIStoreFormError.InsufficientFee] = true;
      }
    }

    if (feeData) {
      if (feeBalance.lt(feeData.amount ?? 0)) {
        newFormError[AddStakeUIStoreFormError.InsufficientFee] = true;
      }
    }

    setFormError(newFormError);
    return null;
  }, [feeData, feeBalanceStr, setFormError, assetBalanceStr, amount, isStakedAssetSameAsFee]);

  useEffect(() => {
    validateForm();
  }, [feeData, validateForm]);

  return {
    isInvalid,
  };
};

export const getSubmitMessage = (
  state: AddStakeUIStore,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case !isAmountGtZero(state.amount):
      return 'common.submit.btn.enterAmount';
    case !!state.formError[AddStakeUIStoreFormError.InsufficientFunds]:
      return 'common.submit.btn.insufficientFunds';
    case !!state.formError[AddStakeUIStoreFormError.InsufficientFee]:
      return 'common.submit.btn.insufficientFunds';

    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';
    default:
      return 'staking.add.submit.cta';
  }
};
