import { BN } from '@polkadot/util';
import { Asset } from '../token';

export enum SwapType {
  EXACT_IN = 'EXACT_IN',
  EXACT_OUT = 'EXACT_OUT',
}

export interface SwapData extends RouteData {
  type: SwapType;
  minAmountOutBN?: BN;
}

export enum RouteDataError {
  InsufficientLiquidity = 'InsufficientLiquidity',
  InsufficientInputAmount = 'InsufficientInputAmount',
  NoPools = 'NoPools',
}

export type SwapRoute = Asset[];
export interface RouteData {
  bestAmount: BN | null;
  bestRoute: SwapRoute | null;
  priceImpact?: number;
  error?: RouteDataError;
}

export enum SwapTransactionError {
  FailedDueToSlippage = 'SellAssetFailedDueToSlippage',
  FailedOnAtomicSwap = 'MultiSwapAssetFailedOnAtomicSwap',
}
