import { useEffect, useState } from 'react';

export const useMultiHover = <T extends HTMLElement>(_refs: Array<T | null>): boolean => {
  const [hovered, setHovered] = useState<boolean>(false);

  useEffect(() => {
    const onMouseEnter = () => {
      setHovered(true);
    };

    const onMouseLeave = () => {
      setHovered(false);
    };

    const refs = _refs.filter((ref): ref is T => ref !== null);

    refs.forEach((node) => {
      node.addEventListener('mouseenter', onMouseEnter);
      node.addEventListener('mouseleave', onMouseLeave);
    });

    return () => {
      refs.forEach((node) => {
        node.removeEventListener('mouseenter', onMouseEnter);
        node.removeEventListener('mouseleave', onMouseLeave);
      });
    };
  }, [_refs]);

  return hovered;
};
