import { ApiPromise } from '@polkadot/api';
import { QueryFunctionContext } from '@tanstack/react-query';

export type GetRewardsInfoQueryKey = Readonly<
  [queryKey: string, address: string | undefined, txCount: number]
>;
export const getNativeRewardsInfo =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<GetRewardsInfoQueryKey>) => {
    const [, address] = queryKey;

    if (!api || !address) {
      return null;
    }

    return api.query.proofOfStake.rewardsInfo.entries(address);
  };
