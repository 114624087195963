import { useCallback, useEffect } from 'react';
import { Decimal } from 'decimal.js';
import { some } from 'lodash-es';
import {
  AdaptiveTxFee,
  Asset,
  useInternalBalance,
  isAmountGtZero,
  ReserveSource,
  useReserves,
} from 'core';
import {
  StartStakingUIStore,
  StartStakingUIStoreFormError,
  useStartStakingStore,
} from './store/useStartStakingStore';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';

export const useStartStakingValidation = (
  feeData: AdaptiveTxFee['current'] | null | undefined,
  stakedAsset: Asset | undefined | null,
  minStakeAmount: string | null,
  reserveSource: ReserveSource | null,
) => {
  const { formError, setFormError, amount } = useStartStakingStore();
  const { getFreeBalance } = useInternalBalance();
  const { totalBalance } = useReserves(stakedAsset?.id);

  const isInvalid = some(formError, Boolean);
  const feeBalanceStr = getFreeBalance(feeData) ?? '0';
  const assetBalanceStr = totalBalance
    ? fromBN(totalBalance, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)
    : '0';
  const isStakedAssetSameAsFee = stakedAsset?.id === feeData?.id;

  const validateForm = useCallback(() => {
    const feeBalance = new Decimal(feeBalanceStr);
    const newFormError: Partial<Record<StartStakingUIStoreFormError, boolean>> = {};

    if (isAmountGtZero(amount)) {
      if (minStakeAmount && new Decimal(minStakeAmount).gt(amount)) {
        newFormError[StartStakingUIStoreFormError.MinAmountNotReached] = true;
      }

      if (new Decimal(assetBalanceStr).lt(amount)) {
        newFormError[StartStakingUIStoreFormError.InsufficientFunds] = true;
      }

      if (feeData && isStakedAssetSameAsFee && feeBalance.sub(amount).lt(feeData.amount ?? 0)) {
        newFormError[StartStakingUIStoreFormError.InsufficientFee] = true;
      }
    }

    if (feeData) {
      if (feeBalance.lt(feeData.amount ?? 0)) {
        newFormError[StartStakingUIStoreFormError.InsufficientFee] = true;
      }
    }

    setFormError(newFormError);
    return null;
  }, [
    feeData,
    feeBalanceStr,
    setFormError,
    assetBalanceStr,
    amount,
    minStakeAmount,
    isStakedAssetSameAsFee,
  ]);

  useEffect(() => {
    validateForm();
  }, [feeData, validateForm]);

  return {
    isInvalid,
    reserveSource,
  };
};

export const getSubmitMessage = (
  state: StartStakingUIStore,
  isChainSwitchRequired: boolean,
): string => {
  switch (true) {
    case !isAmountGtZero(state.amount):
      return 'common.submit.btn.enterAmount';
    case !!state.formError[StartStakingUIStoreFormError.MinAmountNotReached]:
      return 'staking.new.submit.minAmountNotReached';
    case !!state.formError[StartStakingUIStoreFormError.InsufficientFunds]:
      return 'staking.new.submit.insufficientFunds';
    case !!state.formError[StartStakingUIStoreFormError.InsufficientFee]:
      return 'common.submit.btn.insufficientFunds';

    case isChainSwitchRequired:
      return 'common.switch.defaultNetwork';
    default:
      return 'staking.new.submit.cta';
  }
};
