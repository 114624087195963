import { TokenFilterType } from 'core';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, FilterPill } from 'ui';

export function TokenFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterQuery = searchParams.get('filter');

  const applyFilter = (filter: TokenFilterType) => () => {
    setSearchParams({
      filter,
    });
  };

  useEffect(() => {
    if (!filterQuery) {
      searchParams.set('filter', TokenFilterType.All);
      setSearchParams(searchParams);
    }
  }, [filterQuery, searchParams, setSearchParams]);

  return (
    <Container>
      <FilterPill
        onClick={applyFilter(TokenFilterType.All)}
        isActive={filterQuery === TokenFilterType.All}
        label="All"
        className="mr-2"
        data-testid="all-tokens"
      />
      <FilterPill
        onClick={applyFilter(TokenFilterType.Owned)}
        isActive={filterQuery === TokenFilterType.Owned}
        label="My Tokens"
        data-testid="my-tokens"
      />
    </Container>
  );
}
