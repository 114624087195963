import { PalletMultipurposeLiquidityReserveStatusInfo } from '@polkadot/types/lookup';
import { getReserveBalances } from '../services/ReserveBalancesService';
import { QueryServiceReturnType } from '../../../../services';

export const transformReserveBalances = (
  _data: QueryServiceReturnType<typeof getReserveBalances>,
) => {
  if (!_data) {
    return null;
  }

  const reserveBalances: [string, PalletMultipurposeLiquidityReserveStatusInfo][] = _data.map(
    ([storageKey, data]) => {
      const key = storageKey.toHuman();
      const id = Array.isArray(key) ? key[1]?.toString() : null;

      return [id || storageKey.toString(), data];
    },
  );

  return new Map(reserveBalances);
};
