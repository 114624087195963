import { BN_ZERO, hexToBn } from '@polkadot/util';
import {
  useMangataAsset,
  TxType,
  useInvestedPoolFromPath,
  useAll3rdPartyRewardsAmountQuery,
  useActivateLiquidityFor3rdPartyRewards,
  Asset,
  useDeactivateLiquidityFor3rdPartyRewards,
  useClaim3rdPartyRewardsMutation,
  useAll3rdPartyRewardsInfoQuery,
} from 'core';
import { useReservesForTx } from 'core/src/domains/balance';
import { LiquidityMiningCard } from '../../../components/LiquidityMiningCard/LiquidityMiningCard';
import { fromBN } from '@mangata-finance/sdk';

interface ThirdPartyLiquidityMiningCardProps {
  asset: Asset;
  apr: string | null;
}

export const ThirdPartyLiquidityMiningCard = ({
  asset,
  apr,
}: ThirdPartyLiquidityMiningCardProps) => {
  const { mangataAsset } = useMangataAsset();
  const { pool } = useInvestedPoolFromPath();
  const { sources } = useReservesForTx(TxType.ActivateLPFor3rdPartyRewards, pool?.liquidityTokenId);
  const { thirdPartyRewardsAmountQuery } = useAll3rdPartyRewardsAmountQuery();
  const { allThirdPartyRewardsInfoQuery } = useAll3rdPartyRewardsInfoQuery();

  const thirdPartyRewardsInfo =
    pool && allThirdPartyRewardsInfoQuery.data?.get(pool?.liquidityTokenId)?.get(asset.id);
  const poolRewards = pool ? thirdPartyRewardsAmountQuery?.data?.get(pool?.liquidityTokenId) : null;
  const claimableRewards = hexToBn(poolRewards?.get(asset.id)?.toHex()) || BN_ZERO;

  const {
    activateLiqFor3rdPartyRewardsMutation: { mutate: activateLiquidity },
    activateLiquidityFor3rdPartyRewardsFeeQuery: { data: activateLiquidityFee },
  } = useActivateLiquidityFor3rdPartyRewards({
    liquidityTokenId: pool?.liquidityTokenId,
    rewardTokenId: asset.id,
  });

  const {
    deactivateLiqFor3rdPartyRewardsMutation: { mutate: deactivateLiquidity },
    deactivateLiquidityFeeQuery: { data: deactivateLiquidityFee },
  } = useDeactivateLiquidityFor3rdPartyRewards({
    liquidityTokenId: pool?.liquidityTokenId,
    rewardTokenId: asset.id,
  });

  const activatedAmount = thirdPartyRewardsInfo?.activatedAmount?.toBn() || BN_ZERO;

  const {
    claim3rdPartyRewardsMutation: { mutate: claimRewards },
  } = useClaim3rdPartyRewardsMutation();

  if (!mangataAsset || !asset) {
    return null;
  }

  const submitClaimRewards = () => {
    if (!pool) return;

    claimRewards({
      lpId: pool.liquidityTokenId,
      asset,
      amount: fromBN(claimableRewards, asset.decimals),
    });
  };

  const handleActivate = () => {
    if (!pool) return;

    activateLiquidity({
      id: pool.liquidityTokenId,
      rewardTokenId: asset.id,
      amount: sources.totalAvailableBalance,
      symbol: pool.symbols.join('-'),
      balanceSources: sources,
    });
  };

  const handleDeactivate = () => {
    if (!pool) return;

    deactivateLiquidity({
      id: pool.liquidityTokenId,
      rewardTokenId: asset.id,
      amount: activatedAmount,
      symbol: pool.symbols.join('-'),
    });
  };

  if (!pool) {
    return null;
  }

  return (
    <LiquidityMiningCard
      asset={asset}
      type="3rdParty"
      className="mb-5"
      onActivate={handleActivate}
      onDeactivate={handleDeactivate}
      onClaim={submitClaimRewards}
      activateFee={activateLiquidityFee}
      activeLPAmount={thirdPartyRewardsInfo?.activatedAmount || BN_ZERO}
      availableLPAmount={sources.totalAvailableBalance}
      deactivateFee={deactivateLiquidityFee}
      rewardsAmount={claimableRewards}
      isLoading={thirdPartyRewardsAmountQuery.isLoading}
      aprAmount={apr}
    />
  );
};
