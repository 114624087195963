import { Asset } from 'core';
import { TestId } from '../../types/ui';
import { useState } from 'react';
import { Modal } from '../';
import { TokenList, TokenListBalances } from './List/TokenList';
import { TokenSettings } from './Settings/TokenSettings';
import { TokenSelectButton } from './SelectButton/TokenSelectButton';

export interface TokenSelectorProps extends TestId {
  selectedToken: Asset | null;
  onTokenSelect?: (asset: Asset | null) => void;
  settingsVisible?: boolean;
  tokens?: Asset[];
  balances?: TokenListBalances;
  isSelectable?: boolean;
  onClickOverride?: () => void;
}

export const TokenSelector = ({
  'data-testid': testId = 'tokenSelector',
  selectedToken,
  onTokenSelect,
  settingsVisible = true,
  balances,
  tokens,
  isSelectable = true,
  onClickOverride,
}: TokenSelectorProps) => {
  const [isOpen, setOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);

    if (isSettingsOpen) {
      setSettingsOpen(false);
    }
  };
  const handleClick = () => {
    if (onClickOverride) {
      onClickOverride();
      return;
    }
    setOpen(true);
  };
  const toggleSettings = () => setSettingsOpen(!isSettingsOpen);
  const handleTokenSelect = (token: Asset | null) => {
    handleClose();
    onTokenSelect?.(token);
  };

  return (
    <>
      <TokenSelectButton
        data-testid={`${testId}-btn`}
        selectedToken={selectedToken}
        onClick={handleClick}
        isSelectable={isSelectable}
        className="-translate-x-[6px]"
      />
      {isSelectable && tokens && (
        <Modal
          isOpen={isOpen}
          closeIconClassName="hidden"
          onClose={handleClose}
          className="w-[520px] px-4 flex-col min-h-[514px]"
          data-testid={testId}
        >
          {isSettingsOpen ? (
            <TokenSettings onBack={toggleSettings} />
          ) : (
            <TokenList
              settingsVisible={settingsVisible}
              onClose={handleClose}
              onSettingsOpen={toggleSettings}
              onTokenSelect={handleTokenSelect}
              tokens={tokens}
              balances={balances}
            />
          )}
        </Modal>
      )}
    </>
  );
};
