import { BN } from '@polkadot/util';

import { Asset } from '../token';
import { routeExactIn, routeExactOut } from './private/routing/SwapRoutingService';
import { SwapType, RouteData } from './Swap';
import { useSwapMutation } from './private/routing/useSwapMutation';
import { useFeeMetadataQuery } from './private/useFeeMetadataQuery';
import { useTransaction } from '../transaction/useTransaction';
import { useAllPoolsQuery } from '../pool/all/query/useAllPoolsQuery';

export type GetRoute = (
  firstAsset: Asset,
  secondAsset: Asset,
  amount: BN,
  type: SwapType,
  isAutoRoutingEnabled: boolean,
) => RouteData;

export const useSwap = () => {
  const { allPoolsQuery } = useAllPoolsQuery();
  const { swapMutation } = useSwapMutation();
  const { transactionStore, getLatestTxByType } = useTransaction();
  const { feeMetadataQuery, isAmountAboveThreshold, mgxLockAmount } = useFeeMetadataQuery();

  const getRoute: GetRoute = (firstAsset, secondAsset, amount, type, isAutoRoutingEnabled) => {
    const getRouteData = type === SwapType.EXACT_IN ? routeExactIn : routeExactOut;
    const res = getRouteData(
      allPoolsQuery.data || null,
      firstAsset,
      secondAsset,
      amount,
      isAutoRoutingEnabled,
    );

    return res;
  };

  return {
    swapMutation,
    getRoute,
    transactionStore,
    feeMetadataQuery,
    isAmountAboveThreshold,
    getLatestTxByType,
    mgxLockAmount,
  };
};
