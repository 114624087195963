import { ApiPromise } from '@polkadot/api';
import { isNull } from 'lodash-es';

export const getStakingLiquidityTokens = (api: ApiPromise | null) => () => {
  if (isNull(api)) {
    return null;
  }

  return api.query.parachainStaking.stakingLiquidityTokens();
};
