import cls from 'classnames';
import { Asset, ClassName } from 'core';
import { Container, Text } from 'ui';
import { TokenList, TokenListBalances } from 'ui/src/components/TokenSelector/List/TokenList';
import { ReactComponent as BackIcon } from 'icons/back.svg';
import { ReactComponent as ChevronRightIcon } from 'icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface TokenSelectProps extends ClassName {
  tokens: Asset[];
  balances?: TokenListBalances;
  onBack?(): void;
  onClose?(): void;
  onTokenSelect?(token: Asset): void;
}

export const TokenSelect = ({
  tokens,
  balances,
  onBack,
  onClose,
  className,
  onTokenSelect,
}: TokenSelectProps) => {
  return (
    <Container fullWidth className={cls('min-h-[300px]', className)} column>
      <Container fullWidth justifyContent="space-between" className="px-2 mb-5">
        <Container>
          <Container onClick={onBack} className="cursor-pointer">
            <BackIcon className="fill-icon-secondary w-5 h-auto" />
            <Text id="bridge.deposit.title" type="title-3" color="secondary" className="ml-5" />
          </Container>
          <ChevronRightIcon className="fill-icon-secondary w-5 h-auto mx-2" />
          <Text id="bridge.tokenSelect.title" type="title-3" />
        </Container>
        <CloseIcon onClick={onClose} className="fill-icon-secondary w-6 h-auto cursor-pointer" />
      </Container>
      <TokenList
        onTokenSelect={onTokenSelect}
        withHeader={false}
        settingsVisible={false}
        isClosable={false}
        tokens={tokens}
        balances={balances}
      />
    </Container>
  );
};
