import cls from 'classnames';

import { Container, Text } from 'ui';
import { ClassName, TestId } from 'core';
import stoneImg from 'images/stone.png';

interface NoAccountsLayoutProps extends TestId, ClassName {}

export function NoAccountsLayout({ 'data-testid': testId, className }: NoAccountsLayoutProps) {
  return (
    <Container
      className={cls('w-full h-full', className)}
      alignItems="center"
      justifyContent="center"
      column
      data-testid={testId}
    >
      <img src={stoneImg} alt="stone" className="w-[160px] h-auto" />
      <Text type="title-2" id="wallet.modal.account.empty.title" className="mt-4" />
      <Text
        type="body-m"
        color="secondary"
        id="wallet.modal.metamask.empty.desc"
        className="mt-1 max-w-[90%] text-center"
      />
    </Container>
  );
}
