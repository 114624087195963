import { create } from 'zustand';

export interface ModalStore {
  isAccountSettingsOpen: boolean;
  isWithdrawalOpen: boolean;
  isDepositOpen: boolean;
  openAccountSettings(): void;
  closeAccountSettings(): void;
  openWithdrawal(): void;
  closeWithdrawal(): void;
  openDeposit(): void;
  closeDeposit(): void;
}

export const useModalStore = create<ModalStore>((set) => ({
  isAccountSettingsOpen: false,
  isWithdrawalOpen: false,
  isDepositOpen: false,
  openAccountSettings: () => set({ isAccountSettingsOpen: true }),
  closeAccountSettings: () => set({ isAccountSettingsOpen: false }),
  openWithdrawal: () => set({ isWithdrawalOpen: true }),
  closeWithdrawal: () => set({ isWithdrawalOpen: false }),
  openDeposit: () => set({ isDepositOpen: true }),
  closeDeposit: () => set({ isDepositOpen: false }),
}));
