import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { useUserStore } from '../../../user/useUserStore';
import { getAll3rdPartyRewardsInfo } from '../services/3rdPartyRewardsService';
import { transformAll3rdPartyRewardsInfo } from '../transformer/3rdPartyTokensTransformer';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
  retry: false,
};

export const useAll3rdPartyRewardsInfoQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();
  const { selectedAccount } = useUserStore();

  const address = selectedAccount?.address;

  const allThirdPartyRewardsInfoQuery = useQuery({
    queryKey: ['all-3rdParty-rewards-info', finalisedTxCount],
    queryFn: getAll3rdPartyRewardsInfo(api),
    select: transformAll3rdPartyRewardsInfo(address),
    ...queryOptions,
    enabled: !!address && !!api,
  });

  return { allThirdPartyRewardsInfoQuery };
};
