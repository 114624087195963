import { Header } from '@polkadot/types/interfaces';
import { ApiPromise } from '@polkadot/api';
import { StashChannel, StashToken } from '../../../stash';

export enum XcmEvent {
  XcmpMessageSent = 'xcmpQueue.XcmpMessageSent',
  XcmpSuccess = 'xcmpQueue.Success',
  XcmpFail = 'xcmpQueue.Fail',
  Deposited = 'tokens.Deposited',
  Transfer = 'balances.Transfer',
  UpwardMessageSent = 'parachainSystem.UpwardMessageSent',
  Processed = 'messageQueue.Processed',
}

export enum XcmError {
  ValidatorBlockLimitReached = 'XCM_VALIDATOR_BLOCK_LIMIT_REACHED',
}

export enum XcmOperation {
  Deposit,
  Withdrawal,
}

export type IdLookupFn = (
  api: ApiPromise,
  token: StashToken,
  channel: StashChannel,
  header: Header,
  address?: string,
) => Promise<string | string[] | null>;
