import { Routes, Route, Navigate } from 'react-router-dom';

import { AppLayout } from './layouts/app/AppLayout';
import { PoolsPage } from './pages/pools/PoolsPage';
import { PositionsPage } from './pages/positions/PositionsPage';
import { PoolDetailPage } from './pages/pools/detail/PoolDetailPage';
import { AddPoolLiquidityPage } from './pages/pools/detail/addLiquidity/AddPoolLiquidityPage';
import { PoolsList } from './pages/pools/list/PoolsList';
import { CreatePoolPage } from './pages/pools/create/CreatePoolPage';
import { PositionsList } from './pages/positions/list/PositionsList';
import { PositionDetailPage } from './pages/positions/detail/PositionDetailPage';
import { TokensPage } from './pages/tokens/TokensPage';
import { TokenList } from './pages/tokens/list/TokenList';
import { TokenDetail } from './pages/tokens/detail/TokenDetail';
import { StakingPage } from './pages/staking/StakingPage';
import { CollatorList } from './pages/staking/list/CollatorList';
import { StakingDetailPage } from './pages/staking/detail/StakingDetailPage';
import {
  SwapPage,
  PositionWidgetState,
  PositionOverviewTabPage,
  LiquidityMiningTabPage,
  PositionDetailStaking,
} from 'modules';

export function App() {
  return (
    <Routes>
      <Route path="*" element={<AppLayout />}>
        <Route index element={<SwapPage />} />
        <Route path="pools" element={<PoolsPage />}>
          <Route index element={<PoolsList />} />
          <Route path="promoted" element={<PoolsList onlyPromoted />} />
          <Route path="new" element={<CreatePoolPage />} />
        </Route>
        <Route path="pools/:poolSymbol/supply" element={<AddPoolLiquidityPage />} />
        <Route path="pools/:poolSymbol" element={<PoolDetailPage />} />
        <Route path="positions" element={<PositionsPage />}>
          <Route index element={<PositionsList />} />
        </Route>
        <Route path="positions/:poolSymbol" element={<PositionDetailPage />}>
          <Route
            path="add"
            element={<PositionOverviewTabPage initialState={PositionWidgetState.AddPosition} />}
          />
          <Route
            path="remove"
            element={<PositionOverviewTabPage initialState={PositionWidgetState.RemovePosition} />}
          />
          <Route path="mining" element={<LiquidityMiningTabPage />} />
          <Route path="staking" element={<PositionDetailStaking />} />
          <Route path="*" element={<PositionOverviewTabPage />} />
        </Route>
        <Route path="tokens" element={<TokensPage />}>
          <Route index element={<TokenList />} />
        </Route>
        <Route path="tokens/:symbol" element={<TokenDetail />} />
        <Route path="staking" element={<StakingPage />}>
          <Route index element={<CollatorList />} />
        </Route>
        <Route path="staking/:collator" element={<StakingDetailPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}
