import { QueryOptional } from '../../../../../services';
import { CollatorApyRes } from '../CollatorApy';
import Decimal from 'decimal.js';
import { first } from 'lodash-es';

export const transformCollatorApy = (data: QueryOptional<[string, CollatorApyRes]>) => {
  if (!data) {
    return null;
  }

  const [address, _apy] = data;

  return first(
    Object.entries(_apy).map(([lpId, data]) => ({
      ...data,
      liquidityTokenId: lpId,
      apy: new Decimal(data.apy).round().toString(),
      id: address,
    })),
  );
};
