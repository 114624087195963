import { QueryOptional } from '../../../services';
import { PoolWithRatio } from '../../pool';
import { useAssetMetadataQuery } from './query/useAssetMetadataQuery';
import { useNativeTokenQuery } from './query/useNativeTokenQuery';
import { useLiquidityAssetsQuery } from './query/useLiquidityTokenQuery';

export const useTokenMetadata = ({ pools }: { pools: QueryOptional<PoolWithRatio[]> }) => {
  const { assetMetadataQuery } = useAssetMetadataQuery();
  const { nativeAssetsQuery } = useNativeTokenQuery();
  const { liquidityAssetsQuery } = useLiquidityAssetsQuery({
    pools,
    metadata: assetMetadataQuery.data,
  });

  return {
    assetMetadataQuery,
    nativeAssetsQuery,
    liquidityAssetsQuery,
  };
};
