import cls from 'classnames';
import { Link } from 'react-router-dom';
import { Container, Text } from 'ui';
import { ClassName } from 'core';
import { ReactComponent as CreatePoolIcon } from 'icons/create-pool.svg';
import { ReactComponent as ChevronRightIcon } from 'icons/chevron-right.svg';

export type CreatePoolBannerProps = ClassName;

export const CreatePoolBanner = ({ className }: CreatePoolBannerProps) => {
  return (
    <Link to="/pools/new" className={cls('w-full', className)}>
      <Container
        className="w-full bg-widget py-4 px-6 rounded cursor-pointer"
        alignItems="center"
        justifyContent="space-between"
      >
        <Container alignItems="center">
          <CreatePoolIcon className="w-[24px] h-auto mr-3 stroke-icon-accent fill-icon-accent" />
          <Text type="title-4" id="pools.page.create.banner.title" />
        </Container>
        <ChevronRightIcon className="fill-icon-secondary w-[20px] h-[20px]" />
      </Container>
    </Link>
  );
};
