import { useQuery } from '@tanstack/react-query';
import { fetchChannels } from '../../../services';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useChannelsQuery = () => {
  const channelsQuery = useQuery(['channels'], fetchChannels, {
    ...queryOptions,
  });

  return {
    channelsQuery,
  };
};
