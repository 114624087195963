import { useEffect } from 'react';
import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/tx-success.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/spinner-error.svg';

import { animateSuccess, animateError } from './animations.gen';

export type SpinnerState = 'loading' | 'success' | 'error';

export interface SpinnerProps extends ClassName, TestId {
  state?: SpinnerState;
}

export function Spinner({ className, 'data-testid': testId, state }: SpinnerProps) {
  useEffect(() => {
    if (state === 'success') {
      animateSuccess();
    }
    if (state === 'error') {
      animateError();
    }
  }, [state]);

  switch (state) {
    case 'success':
      return (
        <SuccessIcon
          data-testid={`${testId}-success`}
          className={cls('h-[auto] w-[25px] fill-icon-highlight stroke-icon-highlight', className)}
        />
      );
    case 'error':
      return (
        <ErrorIcon
          data-testid={`${testId}-error`}
          className={cls('h-[auto] w-[25px]', className)}
        />
      );
    default:
      return (
        <div>
          <svg
            className={cls(
              'h-[auto] w-[25px] animate-spin',
              'text-neutral stroke-icon-highlight',
              className,
            )}
            width="80"
            height="80"
            viewBox="0 0 80 80"
            data-testid={`${testId}-loading`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="eMq3GpcOVnz2"
              d="M-34.618199,0c0-19.119103,15.499096-34.618199,34.618199-34.618199s34.618199,15.499096,34.618199,34.618199"
              transform="translate(40 40)"
              fill="none"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
      );
  }
}
