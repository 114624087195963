import { animated, useTransition } from '@react-spring/web';
import { useInternalBalance } from 'core';
import { isNull } from 'lodash-es';
import { forwardRef } from 'react';
import { Container } from 'ui';
import { NativeTokenWidgetBalance } from './NativeTokenWidgetBalance';
import { NativeTokenWidgetDiscoverMining } from './NativeTokenWidgetDiscoverMining';
import { NativeTokenWidgetOnboarding } from './NativeTokenWidgetOnboarding';

export const NativeTokenWidgetContent = forwardRef<HTMLDivElement>((_, ref) => {
  const { mgxBalance } = useInternalBalance();

  const hasTokens = mgxBalance?.free.gtn(0);
  const hasLockedTokens = mgxBalance?.reserved.gtn(0);

  const content: Array<[string, JSX.Element | null]> = [
    ['0', hasTokens || hasLockedTokens ? <NativeTokenWidgetBalance /> : null],
    ['1', !hasTokens ? <NativeTokenWidgetOnboarding /> : null],
    ['2', <NativeTokenWidgetDiscoverMining />],
  ].filter((item): item is [string, JSX.Element] => !isNull(item[1]));

  const contentTransition = useTransition(content, {
    from: { opacity: 0, y: 50 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -50 },
    keys: content.map((item) => item[0]),
    trail: 100,
  });

  return (
    <Container
      ref={ref}
      column
      className="border bg-base border-strong rounded-xl p-2 w-[320px] gap-y-2 z-30 -mt-4 shadow-xl"
    >
      {contentTransition((style, item) => (
        <animated.div style={style} className="w-full">
          {item[1]}
        </animated.div>
      ))}
    </Container>
  );
});
