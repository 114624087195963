import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { LayoutSize } from '../layouts';
import { EnvConfig } from 'core';

export function useLayoutSize() {
  const { pathname } = useLocation();

  const layoutSize = useMemo(() => {
    if (EnvConfig.isRollupEnv) {
      switch (pathname) {
        case '/staking':
        case '/tokens':
        case '/positions':
        case '/pools':
        case '/pools/promoted':
          return LayoutSize.Wide;
        default:
          return LayoutSize.Narrow;
      }
    }

    switch (pathname) {
      case '/staking':
      case '/tokens':
      case '/positions':
        return LayoutSize.Wide;
      default:
        return LayoutSize.Narrow;
    }
  }, [pathname]);

  return { layoutSize };
}
