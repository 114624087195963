import { Asset } from '../token';

export enum TxStatus {
  Pending = 'pending',
  Confirming = 'confirming',
  Success = 'success',
  Error = 'error',
}

export enum TxType {
  Swap = 'swap',
  AddLiquidity = 'addLiquidity',
  RemoveLiquidity = 'removeLiquidity',
  CreatePool = 'createPool',
  Claim = 'claim',
  ClaimAll = 'claimAll',
  ClaimPoolRewards = 'claimAllPoolRewards',
  Claim3rdParty = 'claim3rdParty',
  ActivateLP = 'activateLP',
  ActivateLPFor3rdPartyRewards = 'activateLPFor3rdPartyRewards',
  DeactivateLPFor3rdPartyRewards = 'deactivateLPFor3rdPartyRewards',
  DeactivateLP = 'deactivateLP',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Stake = 'stake',
  StakeChange = 'stakeChange',
  ConfirmStakeIncrease = 'confirmStakeIncrease',
  ConfirmStakeDecrease = 'confirmStakeDecrease',
  ApproveContract = 'approveContract',
  RollupDeposit = 'rollupDeposit',
  RollupWithdrawal = 'rollupWithdrawal',
}

export type TxAsset = Asset & { amount: string };
export enum ExtrinsicError {
  Cancelled = 'Cancelled',
  Unknown = 'Unknown',
}

export enum MetamaskTxError {
  DeniedSignature = 'MetaMask Tx Signature: User denied transaction signature.',
  RequestRejected = 'User rejected the request.',
}

export enum TransactionErrorEvent {
  ExtrinsicFailed = 'ExtrinsicFailed',
}
