import { useQuery } from '@tanstack/react-query';
import { fetchPoolLiquidityHistory } from '../services/PoolLiquidityHistoryService';
import { ChartHistoryInterval } from '../../../../../types/ChartHistory';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const usePoolLiquidityHistory = (
  poolSymbols: string | null,
  days: number,
  interval: ChartHistoryInterval,
) => {
  const poolVolumeHistoryQuery = useQuery(
    ['pool-tvl-history', poolSymbols, days, interval],
    fetchPoolLiquidityHistory,
    { ...queryOptions, enabled: !!poolSymbols, retry: 1 },
  );

  return { poolVolumeHistoryQuery };
};
