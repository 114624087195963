import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, BN_ZERO, fromBN } from '@mangata-finance/sdk';
import {
  PoolWithShare,
  useAccounts,
  useDelegators,
  useInvestedPools,
  useNativeRewards,
  useSearch,
  useStakingMetadata,
} from 'core';
import { isEmpty, isNil, isNull } from 'lodash-es';
import { PositionCard } from 'modules';
import { Skeleton, Text, Container, Grid, Search, CountBadge } from 'ui';
import { PositionListNoResults } from '../noResults/PositionListNoResults';
import { PositionListNotifications } from '../notifications/PositionListNotifications';

const ListSkeleton = new Array(6)
  .fill(true)
  .map((_, i) => <Skeleton key={i} className="w-full h-[120px] !rounded-lg" />);

export function InvestedPositionList() {
  const { data: _pools, isLoading } = useInvestedPools();

  const {
    delegatorMapQuery: { data: delegators },
  } = useDelegators();

  const {
    stakingLiquidityTokensQuery: { data: stakingLpTokens },
  } = useStakingMetadata();

  const {
    searchState: [isSearchOpen],
    entries: pools,
    isActive: isSearchActive,
    toggleSearch,
    ref,
  } = useSearch<PoolWithShare>({ list: _pools?.baseList, toSearch: ['symbols'] });

  const { rewardQueriesByLpId } = useNativeRewards();
  const { selectedAccount } = useAccounts();

  const positionsCount = _pools?.baseList.length ?? 0;
  const hasLPPositions = isNil(_pools) ? null : positionsCount > 0;

  function renderPositionCard(pool: PoolWithShare) {
    const delegator = selectedAccount && delegators?.get(selectedAccount.address);
    const amount = fromBN(
      rewardQueriesByLpId[pool.liquidityTokenId]?.data ?? BN_ZERO,
      BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
    );

    return (
      <PositionCard
        key={pool.id}
        isActive={!pool.nonActivatedLPTokens.gt(BN_ZERO)}
        amount={amount}
        pool={pool}
        delegator={delegator}
        stakingLpTokens={stakingLpTokens}
        className="w-full mb-5"
      />
    );
  }

  const renderHeader = () => {
    if (isNull(hasLPPositions)) {
      return (
        <Container fullWidth justifyContent="space-between" alignItems="center" className="mt-10">
          <Skeleton className="w-[130px] h-[17px] !rounded-lg" />
          <Skeleton className="w-[24px] h-[24px] !rounded-lg" />
        </Container>
      );
    }

    if (hasLPPositions === true) {
      return (
        <Container
          fullWidth
          justifyContent="space-between"
          alignItems="center"
          className="mb-2 mt-10"
        >
          {!isSearchOpen && (
            <Container alignItems="center">
              <Text id="position.page.list.title" type="title-3" className="mr-2" />
              <CountBadge count={positionsCount} variant="secondary" />
            </Container>
          )}
          <Search
            placeholderId="position.list.search.placeholder"
            isSearchOpen={isSearchOpen}
            toggleSearch={toggleSearch}
            containerRef={ref}
            data-testid="search"
          />
        </Container>
      );
    }
  };

  if (hasLPPositions === false) return null;

  const oddItems = pools?.filter((_, index) => index % 2 === 0) || [];
  const evenItems = pools?.filter((_, index) => index % 2 !== 0) || [];

  return (
    <Container fullWidth column ref={ref}>
      {renderHeader()}
      {!isLoading && <PositionListNotifications className="mt-4" />}
      {isLoading && (
        <Grid cols={2} gap="m" fullWidth className="mt-8">
          {ListSkeleton}
        </Grid>
      )}

      {isSearchActive && isEmpty(pools) && <PositionListNoResults />}

      {!isLoading && (
        <Container fullWidth className="mt-6">
          <Container column fullWidth className="w-1/2 pr-2">
            {oddItems.map(renderPositionCard)}
          </Container>
          <Container column fullWidth className="w-1/2 pl-2">
            {evenItems.map(renderPositionCard)}
          </Container>
        </Container>
      )}
    </Container>
  );
}
