import { FormatCurrencyOptions, formatCurrency } from './formatCurrencyHelpers';

interface FormatAmountProps {
  value: string | null;
  options?: FormatCurrencyOptions;
}

export function FormatCurrency({ value, options }: FormatAmountProps) {
  return <>{formatCurrency(value, options)}</>;
}
