import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, useApi } from '../../../../services';
import { useTransaction } from '../../../transaction';
import { get3rdPartyRewardsSchedulesList } from '../services/3rdPartyRewardsService';
import { transform3rdPartyRewardsScheduleList } from '../transformer/3rdPartyRewardsTransformer';

export const use3rdPartyRewardsSchedulesQuery = () => {
  const api = useApi();
  const { finalisedTxCount } = useTransaction();

  const thirdPartyRewardsSchedulesQuery = useQuery({
    queryKey: ['3rdParty-rewards-schedules-list', finalisedTxCount],
    queryFn: get3rdPartyRewardsSchedulesList(api),
    select: transform3rdPartyRewardsScheduleList,
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api,
  });

  return { thirdPartyRewardsSchedulesQuery };
};
