import {
  Asset,
  WalletAccount,
  isAmountGtZero,
  useAccounts,
  useAssets,
  useInternalBalance,
  useModalStore,
  useRollupChainsQuery,
  useRollupTokensQuery,
  useRollupWithdrawalMutation,
  useWallet,
} from 'core';
import { useEffect, useMemo } from 'react';
import { useWithdrawalStore } from '../store/useWithdrawalStore';

export const useRollupWithdrawalState = () => {
  const { isWithdrawalOpen, closeWithdrawal } = useModalStore();
  const { chain, amount, asset, dispose, destinationAccount, setDestinationAccount } =
    useWithdrawalStore();
  const { assetsMap } = useAssets();
  const { getFreeBalance } = useInternalBalance();
  const { rollupTokensQuery } = useRollupTokensQuery();
  const {
    rollupWithdrawalMutation: { data, mutate, isLoading: isWithdrawing },
  } = useRollupWithdrawalMutation();
  const { rollupChainsQuery } = useRollupChainsQuery();

  const rollupTokens = rollupTokensQuery.data;
  const selectedRollupToken = rollupTokens?.find((token) => token.l2Id === asset?.id);

  const {
    selectedAccount,
    accountsQuery: { data: accounts },
  } = useAccounts();

  const withdrawalAccounts = accounts?.filter((account) => !!account.address);
  const isWithdrawalSuccessful = !!data;

  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const handleAccountSelect = (account: WalletAccount) => {
    setDestinationAccount(account);
  };

  useEffect(() => {
    if (isWithdrawalSuccessful) {
      dispose();
      closeWithdrawal();
    }
  }, [isWithdrawalSuccessful, dispose, closeWithdrawal]);

  useEffect(() => {
    if (!destinationAccount) {
      setDestinationAccount(selectedAccount);
      return;
    }
  }, [selectedAccount, setDestinationAccount, destinationAccount, isWithdrawalOpen]);

  const stashChain = rollupChainsQuery.data?.find((c) => c.chainId === chain?.id);
  const withdrawalAssets = rollupTokens
    ?.map((token) => token?.chainId === chain?.id && assetsMap?.get(token.l2Id))
    .filter(($): $ is Asset => !!$);

  const withdrawalBalances = new Map<string, string>(
    withdrawalAssets?.reduce((acc: [string, string][], asset) => {
      const freeBalance = getFreeBalance(asset);

      if (!asset) return acc;

      return [...acc, [asset.id, freeBalance ?? '0']];
    }, []),
  );

  const handleClose = () => {
    dispose();
    closeWithdrawal();
    setDestinationAccount(selectedAccount);
  };

  const isWithdrawalReady = useMemo(
    () =>
      !!(
        isAmountGtZero(amount) &&
        asset &&
        destinationAccount &&
        selectedAccount &&
        selectedWallet
      ),
    [amount, asset, destinationAccount, selectedAccount, selectedWallet],
  );

  const submitWithdrawal = () => {
    if (
      !destinationAccount ||
      !selectedAccount ||
      !amount ||
      !selectedRollupToken ||
      !selectedWallet
    ) {
      return;
    }

    mutate({
      destinationChain: stashChain,
      destinationAddress: destinationAccount.address,
      userAddress: selectedAccount.address,
      tokenAddress: selectedRollupToken?.address,
      asset,
      amount,
    });
  };

  return {
    isWithdrawalOpen,
    isWithdrawalReady,
    submitWithdrawal,
    selectedAccount,
    selectedWallet,
    withdrawalAccounts,
    withdrawalAssets,
    withdrawalBalances,
    isWithdrawing,
    handleClose,
    handleAccountSelect,
  };
};
