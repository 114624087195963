import { useCallback, useState } from 'react';

export type UseToggle = [boolean, (toValue?: boolean) => void];

export const useToggle = (defaultValue = false): UseToggle => {
  const [value, setValue] = useState(defaultValue);

  const handleToggle = useCallback(
    (toValue?: boolean) => setValue((x) => (toValue === undefined ? !x : toValue)),
    [],
  );

  return [value, handleToggle];
};
