import { Button, Container, FormatAmount, Text } from 'ui';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

interface StakeCtaCardProps {
  balance: string;
  symbol: string;
}

export function StakeCtaCard({ balance, symbol }: StakeCtaCardProps) {
  const navigate = useNavigate();

  const goToStaking = () => {
    navigate('/staking');
  };

  return (
    <Container
      column
      fullWidth
      alignItems="center"
      justifyContent="center"
      className="px-12 py-[68px] border border-dashed rounded-lg border-default/[.5] text-center"
    >
      <Text
        color="secondary"
        type="title-3"
        id="positions.staking.cta.title"
        values={{
          amount: (
            <Text type="title-3" color="primary" weight="semibold">
              <FormatAmount value={balance} options={{ precision: 3, nonZeroPrecision: true }} />
            </Text>
          ),
          symbol: (
            <Text type="title-3" color="primary" weight="semibold">
              {symbol}
            </Text>
          ),
        }}
        className="mx-8"
      />
      <Button
        size="s"
        variant="outlined"
        LeadIcon={PlusIcon}
        iconClassName="fill-icon-neutral w-[20px] h-auto mr-1"
        className="mt-6"
        onClick={goToStaking}
      >
        <FormattedMessage id="positions.staking.cta.button" />
      </Button>
    </Container>
  );
}
