import { useQuery } from '@tanstack/react-query';
import {
  DEFAULT_QUERY_OPTIONS,
  QueryOptional,
  useAccounts,
  useAdaptiveFee,
  useApi,
  useInternalBalance,
} from '../../../../';
import { getDeactivateLiquidityFee } from '../services/DeactivateLiquidityFeeService';
import { BN } from '@polkadot/util';

export const useDeactivateLiquidityFeeQuery = (
  liquidityId: QueryOptional<string>,
  amount: QueryOptional<string>,
  active3rdPartyLiquidities: QueryOptional<[string, BN][]>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { balances } = useInternalBalance();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const deactivateLiquidityFeeQuery = useQuery(
    [
      'deactivate-liquidity-fee',
      liquidityId,
      selectedAccount?.address,
      amount,
      active3rdPartyLiquidities,
    ],
    getDeactivateLiquidityFee(api),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!liquidityId &&
        !!amount &&
        !!balances,
    },
  );

  return { deactivateLiquidityFeeQuery };
};
