import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import {
  ReserveSourceType,
  QueryOptional,
  ActivateLiquidityReserveSource,
} from '../../../../../services';
import { isNil } from 'lodash-es';
import { BN, BN_ZERO } from '@polkadot/util';
import { Asset } from '../../../../token';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { ReserveSource, ReserveSourceForTx } from '../../../../balance';

type DelegateFeeParams = Readonly<
  [
    queryKey: string,
    address: QueryOptional<string>,
    collatorAddress: QueryOptional<string>,
    stakedAsset: QueryOptional<Asset>,
    reserveSource: QueryOptional<ReserveSource>,
    candidateDelegationCount: QueryOptional<number>,
    delegatorDelegationCount: QueryOptional<number>,
  ]
>;

export const getDelegateFee =
  (
    api: ApiPromise | null,
    activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  ) =>
  async ({
    queryKey: [
      ,
      address,
      collatorAddress,
      stakedAsset,
      reserveSource,
      candidateDelegationCount,
      delegatorDelegationCount,
    ],
  }: QueryFunctionContext<DelegateFeeParams>) => {
    if (
      !api ||
      !address ||
      !collatorAddress ||
      !stakedAsset ||
      !reserveSource ||
      isNil(candidateDelegationCount) ||
      isNil(delegatorDelegationCount)
    ) {
      return null;
    }

    const txs = createDelegateTx(
      api,
      collatorAddress,
      reserveSource,
      activateLiqSources,
      candidateDelegationCount,
      delegatorDelegationCount,
      stakedAsset,
    );

    if (txs.length === 1) {
      const feeInfo = await txs[0].paymentInfo(address);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    }

    const txFees = await Promise.all(txs.map((tx) => tx.paymentInfo(address)));

    const batchingFee = await api.tx.utility.batchAll([]).paymentInfo(address);

    const totalFee = txFees
      .reduce((acc, curr) => acc.add(curr.partialFee), BN_ZERO)
      .add(batchingFee.partialFee);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };

const createDelegateTx = (
  api: ApiPromise,
  collatorAddress: string,
  reserveSource: ReserveSource,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  candidateDelegationCount: number,
  delegatorDelegationCount: number,
  stakedAsset: Asset,
) => {
  const createExtrinsic = (amount: BN) =>
    api.tx.parachainStaking.delegate(
      collatorAddress,
      amount.toString(),
      ReserveSourceType.ActivatedUnstakedReserves,
      candidateDelegationCount,
      delegatorDelegationCount,
    );

  if (
    reserveSource.length === 0 &&
    reserveSource[0][0] === ReserveSourceType.ActivatedUnstakedReserves
  ) {
    return [createExtrinsic(reserveSource[0][1])];
  }

  const activationTxs = createLiqActivationTxsForStaking(api, activateLiqSources, stakedAsset);
  const totalAmount = reserveSource.reduce((acc, [_, amount]) => acc.add(amount), BN_ZERO);

  return [...activationTxs, createExtrinsic(totalAmount)];
};
